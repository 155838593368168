import UserModel from "views/User/UserModel.jsx";

class UserProfileModel {
  
  updateValuesUserModel =(customer) =>{
    UserModel.name=customer.name || '';
    UserModel.alias=customer.alias || '';
    UserModel.gender=customer.gender || '';
    UserModel.dateOfBirth=customer.dateOfBirth || '';
    UserModel.residentialStatus=customer.residentialStatus || '';
    UserModel.nationality=customer.nationality || '';
    UserModel.idType=customer.idType;
    UserModel.idNumber=customer.idNumber || '';
    UserModel.occupation=customer.occupation || '';
    UserModel.idExpiryDate=customer.idExpiryDate || '';
    UserModel.politicallyExposed=customer.politicallyExposed;
    UserModel.employer=customer.employer || '';
    UserModel.email=customer.email;
    UserModel.callingCode=customer.callingCode;
    UserModel.phoneNumber=customer.phoneNumber || '';
    UserModel.mobOTP=customer.mobOTP;
    UserModel.homeTelephoneNumber=customer.homeTelephoneNumber || '';
    UserModel.faxNumber=customer.faxNumber;
    UserModel.floor=customer.floor || '';
    UserModel.building=customer.building || '';
    UserModel.apartmentNumber=customer.apartmentNumber || '';
    UserModel.street=customer.street;
    UserModel.street2=customer.street2;
    UserModel.streetNumber=customer.streetNumber;
    UserModel.block=customer.block || '';
    UserModel.source=customer.source;
    UserModel.postal=customer.postal || '';
    UserModel.country=customer.country || '';
    UserModel.allValues=customer.allValues;
    UserModel.monthlyIncome=customer.monthlyIncome;
    UserModel.assessableIncome=customer.assessableIncome;
    UserModel.fieldsNotFromMyInfo=customer.fieldsNotFromMyInfo;
    UserModel.customerState          = customer.customerState;
    UserModel.profileImageFileName   = customer.profileImageFileName;
    UserModel.validationFlag         = true;  
    UserModel.sourceOfIncomeOther        = customer.sourceOfIncomeOther || '';
    UserModel.profileType                = customer.profileType;
    UserModel.purposeOther               = customer.purposeOther || '';
    UserModel.indicativeTransactionLimit = customer.indicativeTransactionLimit;
    UserModel.purpose                    = customer.purpose || [];
    UserModel.sourceOfIncome             = customer.sourceOfIncome || [];
    UserModel.chineseName                = customer.chineseName || '';
    UserModel.altEmail                   = customer.altEmail || '';
    UserModel.myInfoNRIC                 = customer.myInfoNRIC || '';
    UserModel.yearofAssessment           = customer.yearofAssessment ||'';
    UserModel.registeredBusinessName      = customer.registeredBusinessName || '';
    UserModel.princpalBusinessActivites    = customer.princpalBusinessActivites || '';
    UserModel.busiessRegistrationNumber    = customer.busiessRegistrationNumber ||'';
    UserModel.companyLogo    = customer.companyLogo ||'';
    UserModel.dateOfRegistration    = customer.dateOfRegistration || '';
    UserModel.typeOfRegistration  = customer.typeOfRegistration ||'';
    UserModel.registeredBusinessAdderss    = customer.registeredBusinessAdderss || '';
    UserModel.registeredBusinessAdderss2    = customer.registeredBusinessAdderss2 ||'';
    UserModel.contactNumber    = customer.contactNumber ||'';
    UserModel.personnelEmailId    = customer.personnelEmailId || '';
    UserModel.jobTile    = customer.jobTile || '';
    UserModel.taxresident = customer.taxresident || '';
    UserModel.mobileNumberVerified  = customer.mobileNumberVerified || false ;
  }
}
export default UserProfileModel;