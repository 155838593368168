// DEV WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:d7cf1e00-22ab-466a-b111-e2a90eda1916",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_8YdMryEpU",
    "aws_user_pools_web_client_id": "2o1q0p34g568gkkgihntacbqma",
    "oauth": {},
    "aws_user_files_s3_bucket": "forex-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;