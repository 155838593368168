
import React from "react";
import { Button, Row, Col, Container, FormGroup, Input, Breadcrumb, BreadcrumbItem } from "reactstrap";
import axios from 'axios';
import Spacer from "../../components/Spacer/Spacer";
import Notification from "components/modals/Notification";
import Loader from 'react-loader-spinner';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state           = {loadButton:false}
    this.mailSubject     = '';
    this.mailMessage     = '';
    this.handleChange    = this.handleChange.bind(this);
    this.mailSubjectRef  = React.createRef();
    this.requiredDetails =  React.createRef(); 
    this.mailMessageRef  = React.createRef();
  }

  handleChange = (e) =>{
    if(e.target.name === "mailSubject"){
      this.mailSubject = e.target.value;
    }else{
      this.mailMessage = e.target.value;
    }
    this.setState({[e.target.name]:e.target.value});
  }

  handleSubmit = (e) => {
    this.setState({loadButton:true})
    e.preventDefault();
    let data = {
      "subject": this.mailSubject,
      "message": this.mailMessage,
    }
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/contactus";
    axios.post(url,data).then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      this.setState({loadButton:false})
      if(res.data.status === 200){
        this.requiredDetails.current.toggle();
        this.mailSubjectRef.current.value ='';
        this.mailMessageRef.current.value = '';
      }
    }).catch(function (error) {
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
      this.setState({loadButton:false})
    })
  }

  SaveButton(){
    if(this.state.loadButton){
      return (<Button type="secondary" className="pageButton primary" type="submit" style={{float:"right",backgroundColor:'#8c0204'}}><Loader type="Oval" color="#ddd" height="13"  width="13"/></Button>);
    }else{
    return (<Button type="secondary" className="pageButton primary" type="submit" style={{float:"right",backgroundColor:'#8c0204'}}>Continue</Button>);
    }
  }


  render() {
    return (
      <>
      <Notification ref={this.requiredDetails}  modalTitle="Thank you for writing to us. We will get back to you in 1-2 working days."/>
       <Container fluid className="mainContent">
       <Row className="breadcrumbContainer">
               <Col md="12" className="breadcrumbContainerColumn">
               <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                 <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                 <BreadcrumbItem className="breadcrumbItem" active tag="span">Contact</BreadcrumbItem>
               </Breadcrumb> 
               </Col>
       </Row>

       <form className="justify-content-center align-items-center" onSubmit={this.handleSubmit}>
       <Container className="section">
       <Row className="inputRow">
        <Col md="12" className="inputField">
        <Spacer height={10}/>      
          <label style={{color:'#4a4a4a',fontSize:'15px'}} className="inputRowContactPage">Contact Support</label>
        </Col>
        </Row>

       <Row className="inputRow">
          <Col md={{ size: 6, offset: 2 }} className="inputField">
          <Spacer height={10}/> 
          <center>
            <label style={{color:'#4a4a4a',fontSize:'15px'}} className="inputRowContactPage">Write to us</label>
          </center>
          </Col>
        </Row>
        
        <Row className="inputRow">
          <Col md={{ size: 6, offset: 2 }} className="inputField" style={{marginBottom:'10px'}}>
              <FormGroup style={{marginBottom:'0px'}}>
                <Input type="select" className="selectContact" innerRef={this.mailSubjectRef} onChange={this.handleChange} name="mailSubject" required>
                  <option selected disabled hidden value=''>Please select a subject</option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Transaction Inquiry">Transaction Inquiry</option>
                </Input>
              </FormGroup>
          </Col>
        </Row>
        
        
        <Row className="inputRow">
          <Col md={{ size: 6, offset: 2 }} className="inputField" style={{marginBottom:'10px'}}>
            <FormGroup style={{marginBottom:'0px'}}>
              <Input type="textarea" style={{height:'90px'}} innerRef={this.mailMessageRef} required name="mailMessage" onChange={this.handleChange} placeholder="Please enter a message" id="exampleText" />
            </FormGroup>
        </Col>
        </Row>
        <Row className="inputRow">
          <Col md={{ size: 6, offset: 2 }} className="inputField">
            {this.SaveButton()}
        </Col>
        </Row>

       
        <Row className="inputRow">
        <Col md={{ size: 3 }} className="inputField">
        <Spacer height={40}/>
          <label style={{color:'#4a4a4a',fontSize:'24px !important',marginBottom: '13px'}} className="inputRowContactPage">Address:</label><br/>
          <label style={{fontSize:'16px',color:'#4a4a4a !important'}}>SINGAPORE<br/>3 Phillip Street<br/>Royal Group Building
          <br/>#13-01 Singapore 048693</label>
        </Col>
        <Col md={{ size: 4, offset: 1 }} className="inputField">     
        <Spacer height={40}/>
          <label style={{color:'#4a4a4a',fontSize:'15px',marginBottom: '13px'}} className="inputRowContactPage">Working Hours:</label><br/>
          <label style={{fontSize:'16px',color:'#4a4a4a !important'}}>Mon-Fri: 8.30am to 6.00pm<br/>Sat, Sun and Public Holidays: Closed</label>
        </Col>
        <Col md={{ size: 3, offset: 1 }} className="inputField">
        <Spacer height={40}/>      
          <label style={{color:'#4a4a4a',fontSize:'15px',marginBottom: '15px'}} className="inputRowContactPage">Phone numbers:</label><br/>
          <label style={{fontSize:'16px',color:'#4a4a4a !important'}}>Tel: (65) 6221 1755<br/>Fax: (65) 6221 1005</label>
        </Col>
        </Row>
        
        </Container>
        </form>
        </Container>
      </>
    );
  }
}

export default ContactUs;
