import React from "react";
import { Link } from "react-router-dom";
import Stepper from 'react-stepper-horizontal';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loader from 'react-loader-spinner';
import Spacer from '../../components/Spacer/Spacer';
import axios from 'axios';
import uuid from "uuid";
import { Storage } from 'aws-amplify';
import BlockUi from 'react-block-ui';
import mime from "mime-types";
import DocumentsModel from 'views/User/DocumentsModel.jsx';
import document from "assets/img/document.png";
import Notification from "components/modals/Notification";
import corpDocumentName from "variables/CorpDocumentName.jsx"


class CorpDocument extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false, document: "loading", documentState: false, displayStepper: false, allDocumentsUploaded: false, loading: '',
      docTypyeLoad: '', fileUrl: '', loaddocs: true, redirectTo: '', additionalDoc: false, addNext: "add", documentList: [], displayName: false,
      docNum: false, callmodal: false, docName: '', docPageLoad: true, displayButton: true, docSubLoader: false
    }
    this.uploadedFiles = []
    this.myRef = React.createRef()
    this.AccountOpeningForm = React.createRef();
    this.IdentityProof = React.createRef();
    this.AcraReport = React.createRef();
    this.CompanyRegistrationCertificate = React.createRef();
    this.MemorandumAndArticlesOfAssocition = React.createRef();
    this.AccountOpeningFormFile = React.createRef();
    this.IdentityProofFile = React.createRef();
    this.AcraReportFile = React.createRef();
    this.CompanyRegistrationCertificateFile = React.createRef();
    this.MemorandumAndArticlesOfAssociationFile = React.createRef();
    this.AdditionalDocumentFile = React.createRef();
    this.IdentityProofErr = React.createRef();
    this.displayStepper = this.displayStepper.bind(this);
    this.AdditionalDocument = React.createRef();
    this.AdditionalDocumentErr = React.createRef();
    this.error = React.createRef();
    this.buttonLoader = this.buttonLoader.bind(this);
    this.fetchDocumentData = this.fetchDocumentData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.fetchDocByType = this.fetchDocByType.bind(this);
    this.displayNames = this.displayNames.bind(this);
    this.fetchDocByTypeSelect = this.fetchDocByTypeSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.additionalDoc = this.additionalDoc.bind(this);
    this.buttonDisplay = this.buttonDisplay.bind(this);
    this.callmodalmethod = this.callmodalmethod.bind(this);
    this.breadcrumb = this.breadcrumb.bind(this);
    this.docButton = this.docButton.bind(this);
    this.checkIDType = this.checkIDType.bind(this);
    this.documentModel = [];
    this.AccountOpeningFormCount = false;
    this.IdentityProofCount = false;
    this.AcraReportCount = false;
    this.CompanyRegistrationCertificateCount = false;
    this.MemorandumAndArticlesOfAssocitionCount = false;
    this.count = 0;
    this.fetchDocumentData();
  }

  buttonLoader(docType) {
    if (this.state.loading === undefined || this.state.docTypyeLoad === '') {
      return "";
    } else if (this.state.loading === docType && this.state.docTypyeLoad === "loading") {
      return (
        <Button color="forexSecondary" className="float-right btn-circledoc" active>
          <Loader type="Oval" color="#ddd" height="13" width="13" />
        </Button>);
    } else if (this.state.loading === docType && this.state.docTypyeLoad === "uploaded") {
      return (
        <div style={{ paddingRight: '30px', marginRight: '20px;' }}>
          <Button color="forexSecondary" className="float-right btn-circledoc" active>
            <i class="fa fa-check" style={{ color: 'white' }} aria-hidden="true"></i>
          </Button>
        </div>
      );
    } else {
      return "";
    }
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  onChange = (e) => {
    e.preventDefault()
    let doctype, file, documentName;
    doctype = e.target.name;
    file = e.target.files;
    if (doctype !== "IdentityProof") {
      documentName = corpDocumentName[e.target.name];
    }
    if (doctype === "IdentityProof" || doctype === "AdditionalDocument") {
      documentName = this[e.target.name].current.value;
    }

    if (!(this[doctype + 'Count'])) {
      this.count = this.count + 1;
      this[e.target.name + 'Count'] = true;
    }
    this.uploadedFiles = [];
    this.setState({ loading: e.target.name, docTypyeLoad: "loading" })
    if (file !== '' || file !== undefined || file[0].name !== undefined) {
      this.uploadDoc(file, doctype, documentName, 0);
    }
  }

  additionalDoc() {
    if (this.state.additionalDoc) {
      return <>
        <Row>
          <Col md="12" className="sectionHeading">
            <h4>Additional Document</h4>
          </Col>
        </Row>
        <Row className="inputRow">
          <Col md="7" className="inputField">
            <p style={{ color: '#9f9f9f', fontSize: '15px', marginBottom: '0px' }}>Please upload required document</p>
          </Col>
        </Row>
        <Row className="inputRow">
          <Col md="4" className="inputField" style={{ paddingTop: '0px', marginTop: '0px' }}>
            <div className="inputGroup">
              <input type="text" className="inputText" id="otherDoc"
                ref={this.AdditionalDocument}
                style={{ width: '180px', height: '38px', marginTop: '0px', paddingTop: '0px' }}
                value={this.fetchDocByTypeSelect("AdditionalDocument").documentName} autoComplete="off"
                disabled
              />
              <span onClick={(e) => { e.target.previousElementSibling.focus() }}>Document Name</span>
            </div>
          </Col>
          <Col md="1"></Col>
          <Col md="5" className="inputField">
            <div class="input-container" onClick={() => this.AdditionalDocumentFile.current.click()} >
              <input type="file" id="real-input" name="AdditionalDocument" accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState} ref={this.AdditionalDocumentFile} onChange={(event) => this.onChange(event)} multiple></input>
              <button class="browse-btn">Browse..</button>
              <span class="file-info">{this.displayNames("AdditionalDocument")}</span>
            </div>
          </Col>
          <Col md="1">
            <div style={{ paddingRight: '30px', paddingTop: '6px', marginRight: '20px', paddingLeft: '30px' }}>
              {this.buttonLoader("AdditionalDocument")}
            </div>
          </Col>
        </Row>
        <Row>
          <span className="forexSubtitleErr" ref={this.AdditionalDocumentErr} style={{ display: 'none', marginLeft: '15px' }}></span>
        </Row>
        {/* <Row>
            <Col md="3" className="sectionHeading"></Col>
            <Col md="6" className="sectionHeading">
             {this.displayNames("AdditionalDocument")}
        </Col>
        </Row> */}
      </>
    }
  }

  uploadDoc(files, doctype, documentName, pageNum) {
    let fileName = uuid.v4() + '.' + files[pageNum].name.slice((files[pageNum].name.lastIndexOf(".") - 1 >>> 0) + 2);;
    Storage.put(fileName, files[pageNum], {
      level: 'private',
      contentType: mime.contentType(files[pageNum].name)
    })
      .then(result => {
        this.uploadedFiles.push({
          "documentType": doctype,
          "fileName": result.key,
          "pageNumber": pageNum + 1,
          "mimeType": mime.contentType(files[pageNum].name),
          "originalFileName": files[pageNum].name
        })
        if (pageNum < files.length - 1) {
          this.uploadDoc(files, doctype, documentName, pageNum + 1)
        } else {
          this.setState({ [doctype]: this.uploadedFiles })
          this.docurl(doctype, documentName);
        }
      }).catch();
  }

  docurl = (doctype, documentName) => {
    let data = {
      "documentType": doctype,
      "documentName": documentName,
      "uploadedAtTime": new Date().toISOString(),
      "pages": this.uploadedFiles
    }
    const docurl = (process.env.REACT_APP_IS_IN_PROD == "true" ? process.env.REACT_APP_PROD_API_BASE_URL : process.env.REACT_APP_DEV_API_BASE_URL) + "/document";
    axios.post(docurl, data)
      .then(response => {
        if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(response) }
        this.setState({ displayName: true, loading: doctype, docTypyeLoad: "uploaded", loaddocs: false })
      }).catch(error => { if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(error) } })
    if (this.count === 5) {
      this.setState({ docNum: true, loading: doctype, docTypyeLoad: "uploaded", loaddocs: false })
    } else if (doctype === "AdditionalDocument") {
      this.setState({ loading: doctype, docTypyeLoad: "uploaded", loaddocs: false, docNum: true })
    }
  }

  clearError = (e) => {
    this[e.target.name + 'Err'].current.innerHTML = ''
  }

  fetchDocByType(docType) {
    const docs = this.documentModel.filter(doc => doc.documentType === docType);
    if (docType === "AdditionalDocument" && docs[0] !== undefined) {
      if (docs[0].originalFileName === undefined || docs[0].originalFileName === '') {
        return this.state === null || this.state.name === null || this.state.name === "" ? "Upload file" : this.state[docType];
      }
    }
    if (docs[0] === undefined || docs[0] === '') {
      return this.state === null || this.state.name === null || this.state.name === "" ? "Upload file" : this.state[docType];
    } else {
      let originalFileName = docs.map((file) => file.originalFileName);
      return originalFileName
    }
  }
  fetchDocByTypeSelect(docType) {
    const docs = this.documentModel.filter(doc => doc.documentType === docType);
    return docs.length === 0 ? {} : docs[0];
  }


  displayNames(docType) {
    let pageCount = 0;
    const docs = this.state.documentList.filter(doc => doc.documentType === docType);

    if (docs[0] !== undefined && docs[0] !== '' && this.uploadedFiles !== undefined && this.uploadedFiles != '') {
      let getDocType = this.uploadedFiles.filter((d) => d.documentType === docType)
      if (getDocType.length !== 0 && docs[0].originalFileName !== getDocType[0].originalFileName) {
        let name = this.state[docType].map((name) => {
          pageCount = pageCount + 1;
          return <><label style={{ padding: '5px' }}>{name.originalFileName}</label>
            {(this.state[docType].length === pageCount) ? '' : <label>{' '}|{' '}</label>}
          </>
        })
        return name
      }
    }
    if (this.state.loading === docType && this.state.docTypyeLoad === "loading") {
      return "";
    }
    if (docs[0] === undefined || docs[0] === '') {
      if (this.state.displayName) {
        if (this.state[docType] === undefined || this.state[docType] === "") {
          return <label style={{ padding: '10px' }}>Select single or multiple files</label>;
        }
        else {
          let name = this.state[docType].map((name) => {
            pageCount = pageCount + 1;
            return <><label style={{ padding: '5px' }}>{name.originalFileName}</label>
              {(this.state[docType].length === pageCount) ? '' : <label>{' '}|{' '}</label>}
            </>
          })
          return name
        }
      }
      return <label style={{ padding: '10px' }}>Select single or multiple files</label>;
    } else {
      let name = docs[0].pages.map((name) => {
        pageCount = pageCount + 1;
        return <><label style={{ padding: '5px' }}>{name.originalFileName}</label>
          {(docs[0].pages.length === pageCount) ? '' : <label>{' '}|{' '}</label>}
        </>
      })
      return name
    }
  }

  fetchDocumentData = () => {
    axios.get((process.env.REACT_APP_IS_IN_PROD == "true" ? process.env.REACT_APP_PROD_API_BASE_URL : process.env.REACT_APP_DEV_API_BASE_URL) + '/document')
      .then(res => {
        if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(res) }
        if (res.data.status === 200) {
          this.documentModel = res.data.documents.map((d) => {
          this.count = this.count + 1; this[d.documentType + 'Count'] = true;
            return new DocumentsModel(d)
          });
          if (this.count === 5) {
            this.setState({ docNum: true })
          }
          this.setState({ document: "loaded", documentList: res.data.documents, displayButton: false })
          if (this.props.user.attributes.profile === "Approved" && this.fetchDocByTypeSelect("AdditionalDocument").documentType !== undefined) {
            this.setState({ additionalDoc: true, documentStateSet: true, documentState: true, displayStepper: true });
          } else if (this.props.user.attributes.profile === "Approved" || this.props.user.attributes.profile === "Rejected" || this.props.user.attributes.profile === "PendingApproval" || this.props.user.attributes.profile === "AutoApproveWith2Year" || this.props.user.attributes.profile === "AutoApproveWith3Year") {
            this.setState({ documentState: true, displayStepper: true, displayButton: false });
          } else if (this.props.user.attributes.profile === "PendingClarification") {
            this.setState({ additionalDoc: true, displayStepper: true, documentStateSet: true, docName: this.fetchDocByTypeSelect("AdditionalDocument").documentName, callmodal: true });
            this.callmodalmethod();
          }
        }
        this.setState({ docPageLoad: false })

        // this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }).catch(function (error) { if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(error) } })

  }

  callmodalmethod() {
    if (this.state.callmodal) {
      this.toggle();
    }
  }

  handleSubmit() {
    this.setState({ docSubLoader: true })
    axios.get((process.env.REACT_APP_IS_IN_PROD == "true" ? process.env.REACT_APP_PROD_API_BASE_URL : process.env.REACT_APP_DEV_API_BASE_URL) + '/document/verify')
      .then(res => {
        if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(res) }
        if (res.data.status === 200) {
          this.setState({docSubLoader:false});
          localStorage.setItem("redirect","true")
          window.location.reload(true);
        }
      }).catch(function (error) {
        this.error.current.toggle();
        if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(error) }
      })
  }

  buttonDisplay() {
    if ((this.state.documentState) && !(this.state.displayButton)) {
      return;
    } else {
      return <>
        <Col md="4"></Col>
        <Col md="3" style={{ marginLeft: '40px' }}>
          <Link to="/corpUser">  <Button type="secondary" id="back" className="pageButton secondary" style={{ float: "right", backgroundColor: '#8c0204' }} disabled={this.state.documentState}>Back</Button></Link>
        </Col><Col md="3" style={{ paddingRight: '135px' }}>
          {this.docButton()}
        </Col>
      </>
    }
  }

  docButton() {
    if (this.state.docSubLoader) {
      return (<Button type="secondary" className="pageButton primary" style={{ float: "right", backgroundColor: '#8c0204' }} type="submit"><Loader type="Oval" color="#ddd" height="13" width="13" /></Button>);
    }
    else {
      return (<Button type="secondary" className="pageButton primary" onClick={this.handleSubmit} style={{ float: "right", backgroundColor: '#8c0204' }} disabled={!this.state.docNum}>Continue</Button>);
    }
  }

  displayStepper() {
    if (!(this.state.documentState) || !(this.state.displayStepper)) {
      return <>
        <Row className="pageHeading">
          <Col md="6" className="pageHeadingColumn">
            <h2>Upload Documents</h2>
            <h6>Provide valid documents for verification and to facilitate faster processing.</h6>
          </Col>
        </Row>
        <Row style={{ margin: "0% 2%" }}>
          <Col md="2" style={{ padding: 0 }}>
            <Stepper steps={[{}, {}]} activeColor="#585859" defaultColor="#d8d8d8" completeColor="#8b0304" lineMarginOffset={6} activeStep={1} />
          </Col>
        </Row>
      </>
    }
  }
  constructClass() {
    if (this.state.documentState) {
      return "mainContent"
    }
    else {
      return "color mainContent"
    }
  }

  breadcrumb() {
    if (!(this.state.documentState) || !(this.state.displayStepper)) {
      return <>
        <BreadcrumbItem className="breadcrumbItem initial" tag="a">Log In/Register</BreadcrumbItem>
        <BreadcrumbItem className="breadcrumbItem" active tag="span">Register</BreadcrumbItem>
      </>
    } else {
      return <>
        <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
        <BreadcrumbItem className="breadcrumbItem" active tag="span">Document</BreadcrumbItem>
      </>
    }
  }

  checkIDType(e) {
    if (this[e].current.value === '' || this[e].current.value === undefined) {
      this[e + 'Err'].current.innerHTML = "Please select the document type";
      this[e + 'Err'].current.style.display = "inline";
      return '';
    } else {
      this[e + 'File'].current.click()
    }
  }


  render() {
    return (
      <>
        <Notification ref={this.error} modalTitle="Something went wrong. Please try again." />
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px" }} className={this.props.className}>
          <ModalHeader style={{ borderBottom: 'transparent' }} toggle={this.toggle}>
            <p style={{ fontSize: '20px', color: '#4a4a4a', letterSpacing: '-0.11px', paddingLeft: '12px', marginBottom: '0px', paddingTop: '6px' }} className="text-center">Please upload {this.state.docName}</p> </ModalHeader>
          <ModalBody>
            <center>
              <Button color="primary" onClick={this.toggle} style={{ marginRight: '15px', backgroundColor: 'transparent', color: '#052d99', borderColor: 'transparent' }} >OK</Button>{' '}
              <Button color="primary" onClick={this.toggle} style={{ marginRight: '15px', backgroundColor: 'transparent', color: '#8c0204', borderColor: 'transparent' }} >Cancel</Button>
            </center>
          </ModalBody>
        </Modal>
        <BlockUi tag="div" blocking={this.state.docPageLoad} keepInView={true} style={{ height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column" }}>
          <Container fluid className={this.constructClass()}>
            <Row className="breadcrumbContainer">
              <Col md="12" className="breadcrumbContainerColumn">
                <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                  {this.breadcrumb()}
                </Breadcrumb>
              </Col>
            </Row>
            <div ref={this.myRef}>

              {this.displayStepper()}
              <Container className="section">
                <Row>
                  <Col md="12" className="sectionHeading">
                    <Spacer height={30} />
                    <h4>Account Opening Form</h4>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="7" className="inputField">
                    <p style={{ color: '#9f9f9f', fontSize: '15px', marginBottom: '0px' }}>
                      Please upload a scanned copy of Account Opening <br /> Form downloaded in profile section</p>
                  </Col>
                </Row>

                <Row className="inputRow">
                  <Col md="5" className="inputField " style={{ paddingTop: '10px', marginBottom: '35px' }}>
                    <img src={document} style={{ width: '22px', height: '26px' }} />
                    <label style={{ fontSize: '17px', paddingLeft: '18px', color: '#052d99', fontWeight: 'bold' }}>Account Opening Form</label>
                  </Col>
                  <Col md="5" className="inputField">
                    <div class="input-container" onClick={() => this.AccountOpeningFormFile.current.click()} >
                      <input type="file" id="real-input" required={this.fetchDocByType("AccountOpeningForm") === undefined} name="AccountOpeningForm"
                        accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.AccountOpeningFormFile} onChange={(event) => this.onChange(event)} multiple></input>
                      <button class="browse-btn">Browse..</button>
                      <span class="file-info">{this.displayNames("AccountOpeningForm")}</span>
                    </div>
                  </Col>
                  <Col md="1">
                    <div style={{ paddingRight: '30px', paddingTop: '6px', marginRight: '20px', paddingLeft: '30px' }}>
                      {this.buttonLoader("AccountOpeningForm")}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="sectionHeading">
                    <h4>Identity Proof</h4>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="7" className="inputField">
                    <p style={{ color: '#9f9f9f', fontSize: '15px', marginBottom: '0px' }}>Photocopy identity card(front and back) or passort <br />All directors/ All shareholder and All authorized personnal</p>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="3" className="inputField documentSelect" style={{ paddingTop: '9px' }}>
                    <select ref={this.IdentityProof} name="IdentityProof" disabled={this.state.documentState || this.state.documentStateSet} onChange={(event) => this.clearError(event)} required>
                      <option selected disabled hidden value=''>Select</option>
                      <option value="Passport" value="Passport" selected={this.fetchDocByTypeSelect("IdentityProof").documentName == "Passport"}>Passport</option>
                      <option value="National Photo ID" value="National Photo ID" selected={this.fetchDocByTypeSelect("IdentityProof").documentName == "National Photo ID"}>National Photo ID</option>
                    </select>
                    <label>Identity Type</label>
                  </Col>
                  <Col md="2"></Col>
                  <Col md="5" className="inputField">
                    <div class="input-container" style={{ marginBottom: '5px' }} onClick={() => this.checkIDType("IdentityProof")} >
                      <input type="file" id="real-input" required={this.fetchDocByType("IdentityProof") === undefined} name="IdentityProof"
                        accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.IdentityProofFile} onChange={(event) => this.onChange(event)} multiple></input>
                      <button class="browse-btn">Browse..</button>
                      <span class="file-info">{this.displayNames("IdentityProof")}</span>
                    </div>
                    <span className="forexSubtitleErr" ref={this.IdentityProofErr} style={{ display: 'none' }}></span>
                  </Col>
                  <Col md="1">
                    <div style={{ paddingRight: '30px', paddingTop: '6px', marginRight: '20px', paddingLeft: '30px' }}>
                      {this.buttonLoader("IdentityProof")}
                    </div>
                  </Col>
                </Row>
                <Row>

                </Row>
                <Row>
                  <Col md="12" className="sectionHeading">
                    <h4>ACRA Report</h4>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="7" className="inputField">
                    <p style={{ color: '#9f9f9f', fontSize: '15px', marginBottom: '0px' }}>Recent Report (Not more than 6 months old)</p>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="5" className="inputField" style={{ paddingTop: '12px' }}>
                    <img src={document} style={{ width: '22px', height: '26px' }} />
                    <label style={{ fontSize: '17px', paddingLeft: '18px', color: '#052d99', fontWeight: 'bold' }}>ACRA Report</label>
                  </Col>
                  <Col md="5" className="inputField">
                    <div class="input-container" onClick={() => this.AcraReportFile.current.click()} >
                      <input type="file" id="real-input" required={this.fetchDocByType("AcraReport") === undefined} name="AcraReport"
                        accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.AcraReportFile} onChange={(event) => this.onChange(event)} multiple></input>
                      <button class="browse-btn">Browse..</button>
                      <span class="file-info">{this.displayNames("AcraReport")}</span>
                    </div>
                  </Col>
                  <Col md="1">
                    <div style={{ paddingRight: '30px', paddingTop: '6px', marginRight: '20px', paddingLeft: '30px' }}>
                      {this.buttonLoader("AcraReport")}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="sectionHeading">
                    <h4>Company Registeration Certificate</h4>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="7" className="inputField">
                    <p style={{ color: '#9f9f9f', fontSize: '15px', marginBottom: '0px' }}>Certificate with company information ,<br />business license</p>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="5" className="inputField" style={{ paddingTop: '12px' }}>
                    <img src={document} style={{ width: '22px', height: '26px' }} />
                    <label style={{ fontSize: '17px', paddingLeft: '18px', color: '#052d99', fontWeight: 'bold' }}>Company Registeration Certificate</label>
                  </Col>
                  <Col md="5" className="inputField">
                    <div class="input-container" onClick={() => this.CompanyRegistrationCertificateFile.current.click()} >
                      <input type="file" id="real-input" required={this.fetchDocByType("CompanyRegistrationCertificate") === undefined} name="CompanyRegistrationCertificate"
                        accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.CompanyRegistrationCertificateFile} onChange={(event) => this.onChange(event)} multiple></input>
                      <button class="browse-btn">Browse..</button>
                      <span class="file-info">{this.displayNames("CompanyRegistrationCertificate")}</span>
                    </div>
                  </Col>
                  <Col md="1">
                    <div style={{ paddingRight: '30px', paddingTop: '6px', marginRight: '20px', paddingLeft: '30px' }}>
                      {this.buttonLoader("CompanyRegistrationCertificate")}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="sectionHeading">
                    <h4>Memorandum And Articles of Association</h4>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="7" className="inputField">
                    <p style={{ color: '#9f9f9f', fontSize: '15px', marginBottom: '0px' }}>Copy of Memorandum and Articles of Association</p>
                  </Col>
                </Row>
                <Row className="inputRow">
                  <Col md="5" className="inputField " style={{ paddingTop: '12px' }}>
                    <img src={document} style={{ width: '22px', height: '26px' }} />
                    <label style={{ fontSize: '17px', paddingLeft: '18px', color: '#052d99', fontWeight: 'bold' }}>Memorandum and Articles of Association</label>
                  </Col>
                  <Col md="5" className="inputField">
                    <div class="input-container" onClick={() => this.MemorandumAndArticlesOfAssociationFile.current.click()} >
                      <input type="file" id="real-input" required={this.fetchDocByType("MemorandumAndArticlesOfAssociation") === undefined} name="MemorandumAndArticlesOfAssociation"
                        accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.MemorandumAndArticlesOfAssociationFile} onChange={(event) => this.onChange(event)} multiple></input>
                      <button class="browse-btn">Browse..</button>
                      <span class="file-info">{this.displayNames("MemorandumAndArticlesOfAssociation")}</span>
                    </div>
                  </Col>
                  <Col md="1">
                    <div style={{ paddingRight: '30px', paddingTop: '6px', marginRight: '20px', paddingLeft: '30px' }}>
                      {this.buttonLoader("MemorandumAndArticlesOfAssociation")}
                    </div>
                  </Col>
                </Row>
                <div>
                  {this.additionalDoc()}
                </div>
              </Container>
              <Container className="pageFooter" style={{ backgroundColor: "#fbfbfb" }}>
                <Row className="inputRow" style={{ paddingRight: '30px' }}>
                  {this.buttonDisplay()}
                </Row>
              </Container>
            </div>
          </Container>
        </BlockUi>
      </>
    )
  }
}
export default CorpDocument;