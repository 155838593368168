import React from 'react'
import { Row, Col, Input, Button, FormGroup, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import Spacer from '../../components/Spacer/Spacer';
import grouplogo from "assets/img/grouplogo.png";
import grouplogo2 from "assets/img/grouplogo2.png";
import rightA from "assets/img/rightA.png";
import Loader from 'react-loader-spinner';
import { Auth } from 'aws-amplify';
import { isValidEmail, passwordValidationError, passwordValidationLoginError, nameValidationError } from '../../utils/CommonUtils';
import axios from 'axios';
import awsmobile from 'aws-exports';
import { FiLock, FiMail, FiFileText } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import emailDomain from "variables/emailDomain.js";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import ExchangeRateCalculator from "./ExchangeRateCalculator";
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.handleLogin = this.handleLogin.bind(this)
        this.getInputAreaContent = this.getInputAreaContent.bind(this)
        this.getButtons = this.getButtons.bind(this)
        this.clearEmailError = this.clearEmailError.bind(this)
        this.clearPasswordError = this.clearPasswordError.bind(this)
        this.clearOTPError = this.clearOTPError.bind(this)
        this.resendOTP = this.resendOTP.bind(this)
        this.forgetButton = this.forgetButton.bind(this)
        this.clearverifCodeError = this.clearverifCodeError.bind(this)
        this.confirmforgotPassword = this.confirmforgotPassword.bind(this)
        this.forgotPassword = this.forgotPassword.bind(this);
        this.enableverifCode = this.enableverifCode.bind(this);
        this.enablepassword = this.enablepassword.bind(this);
        this.clearforgetpasswordError = this.clearforgetpasswordError.bind(this);
        this.enableforgetPassword = this.enableforgetPassword.bind(this);
        this.createRefs()
        this.state = {
            loading: false, forgetpwd: false, forgetEmaildisplay: false, forgetemail: '',
            loginPage: false, regPage: false, displayText: false, login: true, confirmPassworddisplay: false, loginPageMail: false
            , userExsits: false, userNotExsits: false, profileType: 'Individual',passwordSuccess : false, user: undefined
        }
        this.clearconfirmpasswordError = this.clearconfirmpasswordError.bind(this);
        this.enableconfirmpassword = this.enableconfirmpassword.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.checkConfirmPassword = this.checkConfirmPassword.bind(this);
        this.getVerificationContent = this.getVerificationContent.bind(this);
        this.enableName = this.enableName.bind(this);
        this.clearNameError = this.clearNameError.bind(this);
        this.handleSingUP = this.handleSingUP.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onKeyPressPassword = this.onKeyPressPassword.bind(this);
        this.onKeyPressEmail = this.onKeyPressEmail.bind(this);
        this.onKeyPressName = this.onKeyPressName.bind(this);
        this.onKeyPressPasswordReg = this.onKeyPressPasswordReg.bind(this);
        this.onKeyPressValCode = this.onKeyPressValCode.bind(this);
        this.onKeyPressConfPwd1 = this.onKeyPressConfPwd1.bind(this);
        this.onKeyPressConfPwd2 = this.onKeyPressConfPwd2.bind(this);
        this.onKeyPressForgetPwd = this.onKeyPressForgetPwd.bind(this);
        this.profileType = 'Individual';
        var urlParams = queryString.parse(this.props.location.search);
        this.action = urlParams.state;
        this.reload = this.reload.bind(this);
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then((data) => {
                this.setState({ user: data })
            })
            .catch((err) => {
                this.setState({ user: undefined })
            })
    }

    componentDidMount() {
        if (this.action === "true") {
            this.setState({ loginPage: true, login: false, action: true, displayText: true });
        }
    }

    createRefs() {
        this.email = React.createRef()
        this.password = React.createRef()
        this.emailError = React.createRef()
        this.passwordError = React.createRef()
        this.otpError = React.createRef()
        this.emailHint = React.createRef()
        this.passwordHint = React.createRef()
        this.resendOTPBtn = React.createRef()
        this.otp = React.createRef()
        this.verifCode = React.createRef()
        this.verifCodeError = React.createRef()
        this.forgetpassword = React.createRef()
        this.forgetpasswordError = React.createRef()
        this.forgetpasswordHint = React.createRef()
        this.confirmpassword = React.createRef()
        this.confirmpasswordError = React.createRef()
        this.confirmpasswordHint = React.createRef()
        this.name = React.createRef()
        this.nameError = React.createRef()
        this.profileTypeError = React.createRef()
        this.termsandConditionCheck = React.createRef()
        this.newPassword = React.createRef();
        this.newName = React.createRef();
    }

    resendOTP() {

        if (this.setState.resendingOTP)
            return
        this.setState({ resendingOTP: true })
        this.resendOTPBtn.current.innerHTML = "Resending..."
        Auth.resendSignUp(this.state.email).then(data => {
            this.resendOTPBtn.current.innerHTML = "Email Resent"
            window.setTimeout(() => this.resetResendOTP(), 3000)
        }).catch(err => {
            this.resendOTPBtn.current.innerHTML = "Error while sending email"
            this.resendOTPBtn.current.style.color = "#000"
            window.setTimeout(() => this.resetResendOTP(), 1000)
        })
    }

    handleChange = (e) => {
        this.profileTypeError.current.innerHTML = '';
        this.profileTypeError.current.style.display = 'none'
        this.profileType = e.target.value;
        this.setState({ profileType: this.profileType });
    }
    createCustomer(user) {
        axios.post((process.env.REACT_APP_IS_IN_PROD == "true" ? process.env.REACT_APP_PROD_API_BASE_URL : process.env.REACT_APP_DEV_API_BASE_URL) + '/customer', {
            email: this.state.email,
            name: user.challengeParam.userAttributes.name,
            profileType: user.challengeParam.userAttributes["custom:profileType"],
            storagePath: user.challengeParam.userAttributes["aws.cognito.identity-id." + awsmobile.aws_cognito_identity_pool_id],
            // aws.cognito.identity-id.ap-southeast-1:d7cf1e00-22ab-466a-b111-e2a90eda1916
            // aws.cognito.identity-id.ap-southeast-1:40529db9-36ba-4d03-9f76-f612521b5d6d
            device: navigator.appVersion
        }).then((response) => {
            console.log("res:::"+response,"user:::",user)
            })
            .catch((error) => {
            });
    }

    forgetButton(e) {
        e.preventDefault();
        let email = (this.email.current.value).toLowerCase();
        this.setState({ forgetEmaildisplay: true, loading: false, loginPage: false })
    }

    checkPassword() {
        let password = this.forgetpassword.current.value;
        if (password === '' || password === undefined) {
            this.forgetpasswordError.current.innerHTML = "Password should not be blank"
            this.forgetpasswordError.current.style.display = "inline"
            this.forgetpasswordHint.current.style.display = "none"
            return true;
        } else if (passwordValidationError(password)) {
            this.forgetpasswordError.current.innerHTML = passwordValidationError(password)
            this.forgetpasswordError.current.style.display = "inline"
            this.forgetpasswordHint.current.style.display = "none"
            return true;
        } else
            return false;
    }

    checkConfirmPassword() {
        let password = this.forgetpassword.current.value;
        let confirmPwd = this.confirmpassword.current.value;
        if (confirmPwd === '' || confirmPwd === undefined) {
            this.confirmpasswordError.current.innerHTML = "Password should not be blank"
            this.confirmpasswordError.current.style.display = "inline"
            this.confirmpasswordHint.current.style.display = "none"
            return true;
        } else if (passwordValidationError(confirmPwd)) {
            this.confirmpasswordError.current.innerHTML = passwordValidationError(confirmPwd)
            this.confirmpasswordError.current.style.display = "inline"
            this.confirmpasswordHint.current.style.display = "none"
            return true;
        } else if (password !== confirmPwd) {
            this.confirmpasswordError.current.innerHTML = "Password should match!"
            this.confirmpasswordError.current.style.display = "inline"
            this.confirmpasswordHint.current.style.display = "none"
            return true;
        } else
            return false;
    }


    forgotPassword() {
        let email = (this.email.current.value).toLowerCase();

        if (!isValidEmail(email)) {
            this.emailError.current.innerHTML = "Invalid Email"
            this.emailError.current.style.display = "inline"
            this.emailHint.current.style.display = "none"
            return
        }
        this.setState({ forgetemail: email, loginPageMail: true })
        Auth.forgotPassword(email)
            .then(data => {
                if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(data) }
                this.setState({ confirmPassworddisplay: true, forgetEmaildisplay: false, loading: false })
            })
            .catch(err => {
                if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(err) }
                switch (err.code) {
                    case "UserNotFoundException":
                        this.emailError.current.innerHTML = "Cannot reset password for the user as there is no registered/verified email."
                        this.emailError.current.style.display = "inline"
                        this.emailHint.current.style.display = "none"
                        this.setState({ confirmPassworddisplay: false, forgetEmaildisplay: true, UserNotFound: true, login: false, loginPageMail: false })
                        break;
                    case "LimitExceededException":
                        this.emailError.current.innerHTML = "Attempt limit exceeded, please try after somethime."
                        this.emailError.current.style.display = "inline"
                        this.emailHint.current.style.display = "none"
                        this.setState({ loading: false, loginPageMail: false })

                        break;
                    case "InvalidParameterException":
                        this.emailError.current.innerHTML = "Cannot reset password for the user as there is no registered/verified email."
                        this.emailError.current.style.display = "inline"
                        this.emailHint.current.style.display = "none"
                        this.setState({ loginPageMail: false })
                        break;

                }
            });
    }

    confirmforgotPassword() {
        let email = (this.state.forgetemail).toLowerCase();
        let password = this.forgetpassword.current.value;
        let verifCode = this.verifCode.current.value;
        if (verifCode === undefined || verifCode === '') {
            this.verifCodeError.current.innerHTML = "Verification code should not be empty"
            this.verifCodeError.current.style.display = "inline";
        }
        if (this.checkPassword() || this.checkConfirmPassword()) {
            return
        }
        this.setState({ loading: true })
        Auth.forgotPasswordSubmit(email, verifCode, password)
            .then(data => {
                if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(data) }
                this.setState({ confirmPassworddisplay: false, loading: false, loginPage: true })
            })
            .catch(err => {
                if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(err) }
                switch (err.code) {
                    case "CodeMismatchException":
                        this.verifCodeError.current.innerHTML = "Wrong verification code"
                        this.verifCodeError.current.style.display = "inline";
                        this.setState({ loading: false })
                        break;
                }
            });
    }

    enableverifCode() {
        this.verifCode.current.readOnly = false
    }
    enablepassword() {
        this.password.current.readOnly = false
    }

    enableforgetPassword() {
        this.forgetpassword.current.readOnly = false
    }

    enableconfirmpassword() {
        this.confirmpassword.current.readOnly = false
    }
    enableName() {
        this.name.current.readOnly = false
    }

    resetResendOTP() {
        this.resendOTPBtn.current.innerHTML = "Resend"
        this.resendOTPBtn.current.style.color = ""
        this.setState({ resendingOTP: true })
    }

    clearEmailError() {
        this.emailError.current.style.display = 'none'
        this.emailHint.current.style.display = 'inline'
        this.emailHint.current.style.marginLeft = '15px'
    }
    clearNameError() {
        this.nameError.current.style.display = 'none'
    }

    clearforgetpasswordError() {
        this.forgetpasswordError.current.innerHTML = ''
        this.forgetpasswordError.current.style.display = 'none'
        this.forgetpasswordHint.current.style.display = 'inline'
    }

    clearPasswordError(e) {
        this.passwordError.current.style.display = 'none'
        this.passwordHint.current.style.display = 'inline'
    }

    onKeyPressEmail(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.password.current.focus();
        }
    }

    onKeyPressName(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.email.current.focus();
        }
    }

    onKeyPressForgetPwd(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.forgotPassword();
        }
    }

    onKeyPressPassword(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.handleLogin();
        }
    }
    onKeyPressPasswordReg(e) {
        // if(e.keycode === 13 || e.which === 13){
        //     this.handleSingUP();
        // }
    }
    onKeyPressValCode(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.forgetpassword.current.focus();
        }
    }
    onKeyPressConfPwd1(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.confirmpassword.current.focus();
        }
    }
    onKeyPressConfPwd2(e) {
        if (e.keycode === 13 || e.which === 13) {
            this.confirmforgotPassword();
        }
    }

    clearOTPError() {
        this.otpError.current.style.display = 'none'
    }

    clearverifCodeError() {
        this.verifCodeError.current.innerHTML = ''
        this.verifCodeError.current.style.display = 'none'
        this.verifCodeError.current.style.display = 'inline'
    }

    clearconfirmpasswordError() {
        this.confirmpasswordError.current.innerHTML = ''
        this.confirmpasswordError.current.style.display = 'none'
        this.confirmpasswordHint.current.style.display = 'inline'
    }
    confirmCodeErr() {
        this.confirmCodeErr.current.style.display = 'none'
        this.confirmCodeErr.current.style.display = 'inline'
    }

    reload(e) {
        e.preventDefault();
        window.location.reload();
    }

    handleLogin() {
       

        if(this.state.passwordSuccess){
            if (this.state.user === undefined) {
                this.setState({ notificationText: "Please enter the valid email and password first.", loading: false, passwordSuccess: false })
                this.notification.current.toggle();
                return
            }
        if (this.state.user !== undefined && this.state.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            var newPassword = this.newPassword.current.value;
            var newName = this.newName.current.value;
            var passwordError = passwordValidationError(newPassword)
            if (passwordError !== null || newPassword === null || newPassword === undefined || newPassword === "") {
                this.setState({ notificationText: passwordError, loading: false })
                this.notification.current.toggle();
                return
            }
            if (newName === "" || newName === undefined || newName === null) {
                this.setState({ notificationText: "Enter proper name for your account", loading: false })
                this.notification.current.toggle();
                return
            }
            Auth.completeNewPassword(this.state.user, newPassword, { name: newName }).then(res => {
                console.log(res)
                this.setState({ notificationText: "", loading: false, user: res , loggedIn : true, passwordSuccess : false});
            }).catch(err => {
                console.log("Error: ", err)
            })
        }
    }else{

        const email = (this.email.current.value).toLowerCase();
        const password = this.password.current.value;
        if (!isValidEmail(email)) {
            this.emailError.current.innerHTML = "Invalid Email"
            this.emailError.current.style.display = "inline"
            this.emailHint.current.style.display = "none"
            return
        }
        if (passwordValidationLoginError(password)) {
            this.passwordError.current.innerHTML = passwordValidationError(password)
            this.passwordError.current.style.display = "inline"
            this.passwordHint.current.style.display = "none"
            return
        }


        this.setState({ loading: true, email: email, password: password })
        Auth.signIn(email, password).then(res => {
            if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(res) }
            console.log(res.signInUserSession)
            if(!res.signInUserSession)this.createCustomer(res);
            this.setState({ loading: false, validation: false , user: res, passwordSuccess: true})
            if(res.signInUserSession){
                this.setState({ loggedIn: true } );
            }

        }).catch(err => {
            if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(err) }
            switch (err.code) {
                case "UserNotFoundException":
                    this.setState({ login: false })
                    this.passwordError.current.innerHTML = "Email ID not registered"
                    this.passwordError.current.style.display = "inline"
                    this.passwordHint.current.style.display = "none"
                    this.setState({ loading: false, userNotExsits: true })
                    break;
                case "UserNotConfirmedException":
                    this.setState({ login: false })
                    Auth.resendSignUp(email).then(data => {
                        this.setState({ validation: true, loginPage: false, loading: false, regPage: false, userExsits: false, loginPageCon: true })
                    }).catch(err => { })
                    return
                    break;
                case "NotAuthorizedException":
                    this.passwordError.current.innerHTML = "Wrong Password"
                    this.passwordError.current.style.display = "inline"
                    this.passwordHint.current.style.display = "none"
                    this.setState({ loading: false })
                    break;
            }
        });
    }
         
    }

    handleSingUP() {
        const email = (this.email.current.value).toLowerCase();
        const password = this.password.current.value;
        let name = '';
        if (this.profileType === '' || this.profileType === undefined) {
            this.profileTypeError.current.innerHTML = "Please select this profile type"
            this.profileTypeError.current.style.display = "inline"
            return
        }
        //  if(this.profileType === "Corporate") {
        //      let emailSplit= this.email.current.value.split('@');
        //      let domain=emailSplit[1].split('.');
        //      var test=emailDomain.filter((d) => d === domain[0])
        //     if(test.length>0){
        //         this.emailError.current.innerHTML     = "Please enter comapny Email ID"
        //         this.emailError.current.style.display = "inline"
        //         return 
        //     }
        //  }
        name = this.name.current.value;
        if (!nameValidationError(name)) {
            this.nameError.current.innerHTML = "Invalid name"
            this.nameError.current.style.display = "inline"
            return
        }
        if (!isValidEmail(email)) {
            this.emailError.current.innerHTML = "Invalid Email"
            this.emailError.current.style.display = "inline"
            this.emailHint.current.style.display = "none"
            return
        }
        if (passwordValidationError(password)) {
            this.passwordError.current.innerHTML = passwordValidationError(password)
            this.passwordError.current.style.display = "inline"
            this.passwordHint.current.style.display = "none"
            return
        }
        if (!document.getElementById("termsandConditionCheck").checked) {
            this.termsandConditionCheck.current.innerHTML = "Please agree to the Terms & Conditions and Privacy Policy."
            this.termsandConditionCheck.current.style.display = "inline-block"
            return
        }
        this.setState({ loading: true, email: email, password: password, name: name, profileType: this.profileType })
        Auth.signUp({
            username: (this.email.current.value).toLowerCase(),
            password: this.password.current.value,
            attributes: {
                email: (this.email.current.value).toLowerCase(),
                profile: "ProfileSetup",
                name: this.name.current.value,
                "custom:profileType": this.profileType
            }
        })
            .then(data => {
                if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(data) }
                this.setState({ validation: true, loading: false, regPage: false, userExsits: false })
            })
            .catch(err => {
                if (process.env.REACT_APP_IS_IN_PROD === "false") { console.log(err) }
                switch (err.code) {
                    case "UserNotConfirmedException":
                        this.setState({ login: false })
                        Auth.resendSignUp(this.state.email).then(() => {
                            this.setState({ validation: true, loading: false, regPage: false, userExsits: false })
                        }).catch(err => { })
                        break;
                    case "UsernameExistsException":
                        this.passwordError.current.innerHTML = "User already exsits."
                        this.passwordError.current.style.display = "inline"
                        this.passwordHint.current.style.display = "none"

                        this.setState({ loading: false, userExsits: true, regPage: true })
                        break;
                    case "NotAuthorizedException":
                        this.passwordError.current.innerHTML = "User already exsits."
                        this.passwordError.current.style.display = "inline"
                        this.passwordHint.current.style.display = "none"
                        this.setState({ loading: false, userExsits: true, regPage: true })
                        break;
                    case "NetworkError":
                        this.passwordError.current.innerHTML = "Network error"
                        this.passwordError.current.style.display = "inline"
                        this.passwordHint.current.style.display = "none"
                        this.setState({ loading: false, regPage: false })
                        break;
                }
            });
    }
    getVerificationContent() {
        if (this.state.validation) {
            let resendTxt = this.setState.resendingOTP ? "Re-sending..." : "Re-send email."
            return <>
                <div style={{ marginLeft: '0px' }}>
                    <Col className="pr-1" md="1"></Col>
                    <Col className="pr-1" md="12"  >
                        <br />
                        <h2>Verify Account</h2>
                        <FormGroup>
                            <Spacer height={20} />
                            <Label>Thank you for choosing MoneyWorld Remit. Please check your email and click on the link to verify your account.</Label>
                            <Label>Didn't get an email?{' '}<a href="#"><span className="forexSubtitleBtn" onClick={this.resendOTP} ref={this.resendOTPBtn}>{resendTxt}</span></a></Label>
                            <span className="forexSubtitleErr" ref={this.otpError}></span>
                            <Spacer height={20} />
                            <Label>For any questions, please feel free to contact us at <a href="#">admin@moneyworld.com.sg</a>.</Label>
                        </FormGroup>
                    </Col>
                    <Col className="hidden-xs" md="5" lg={{ size: 5, offset: 1 }}>
                    </Col>
                    <br />
                    <div onClick={() => this.setState({ validation: false, loading: false, regPage: true, userExsits: false })} style={{ float: 'left', marginLeft: '12px' }}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer', transform: 'rotate(180deg)' }}></img>{' '}<label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Back</label></div>
                </div>
            </>
        }
    }
    getInputAreaContent() {
        if (this.state.forgetEmaildisplay) {
            return <>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '15px', paddingRight: '8px' }}><FiMail /></span></InputGroupAddon>
                    <Input type="email" innerRef={this.email} name="email" style={{ 'cursor': 'text', marginTop: '0px' }} onKeyPress={this.onKeyPressForgetPwd} placeholder="Email ID" defaultValue={this.state.email} className="forexLoginInput" onChange={this.clearEmailError} />
                    {this.state.loginPageMail ? <><Button style={{ 'width': '35px', 'height': '35px', 'padding': '7px 0px', 'border-radius': '20px', 'font-size': '10px', 'margin-top': '7px' }} className="btn-circle userButton" active>
                        <Loader type="Oval" color="#fff" height="15" width="15" />
                    </Button></> : <><span onClick={this.forgotPassword}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer' }}></img></span></>}
                </InputGroup>
                <span className="forexSubtitleErr" style={{ marginLeft: '15px' }} ref={this.emailError}></span>
                <span className="forexSubtitle" ref={this.emailHint}>Registered</span>
                <br />
                <div onClick={() => this.setState({ forgetEmaildisplay: false, loading: false, loginPage: true })} style={{ float: 'left', marginTop: '20px', marginLeft: '10px' }}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer', transform: 'rotate(180deg)' }}></img>{' '}<label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Back</label></div>
            </>
        } else if (this.state.confirmPassworddisplay) {
            return <>
                <Spacer height={10} />
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '15px', paddingRight: '8px' }}><IoMdCheckmarkCircleOutline /></span></InputGroupAddon>
                    <Input type="text" onFocus={this.enableverifCode} onKeyPress={this.onKeyPressValCode} readOnly style={{ 'cursor': 'text', 'backgroundColor': '#f1f1f1', marginTop: '0px' }} innerRef={this.verifCode} name="verifCode" placeholder="Enter the verification code sent to your email" className="forexLoginInput" onChange={this.clearverifCodeError} />
                </InputGroup>
                <span className="forexSubtitleErr" ref={this.verifCodeError} style={{ display: 'none', marginLeft: '15px' }}></span>
                <Spacer height={20} />
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '15px', paddingRight: '8px' }}><FiLock /></span></InputGroupAddon>
                    <Input type="password" onFocus={this.enableforgetPassword} onKeyPress={this.onKeyPressConfPwd1} readOnly style={{ 'cursor': 'text', 'backgroundColor': '#f1f1f1', marginTop: '0px' }} innerRef={this.forgetpassword} onBlur={this.checkPassword} name="password" placeholder="Enter new password" className="forexLoginInput" onChange={this.clearforgetpasswordError} />
                </InputGroup>
                <span className="forexSubtitleErr" ref={this.forgetpasswordError} style={{ display: 'none', marginLeft: '15px' }}></span>
                <span className="forexSubtitle" style={{ marginLeft: '17px' }} ref={this.forgetpasswordHint}>At least 8 characters long with uppercase and lowercase letters and digits.</span>
                <Spacer height={20} />
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '15px', paddingRight: '8px' }}><FiLock /></span></InputGroupAddon>
                    <Input type="password" onFocus={this.enableconfirmpassword} onKeyPress={this.onKeyPressConfPwd2} readOnly style={{ 'cursor': 'text', 'backgroundColor': '#f1f1f1', marginTop: '0px' }} innerRef={this.confirmpassword} onBlur={this.checkConfirmPassword} name="password" placeholder="Re-enter new password" className="forexLoginInput" onChange={this.clearconfirmpasswordError} />
                </InputGroup>
                <span className="forexSubtitleErr" ref={this.confirmpasswordError} style={{ display: 'none', marginLeft: '15px' }}></span>
                <span className="forexSubtitle" style={{ marginLeft: '17px' }} ref={this.confirmpasswordHint}>At least 8 characters long with uppercase and lowercase letters and digits.</span>
                <Spacer height={10} />
            </>
        }else if(this.state.passwordSuccess && this.state.user !== undefined && this.state.user.challengeName === 'NEW_PASSWORD_REQUIRED'){
            console.log("HHhh",this.state.user)
        return <>
        <InputGroup>
            <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FiFileText /></span></InputGroupAddon>
            <Input innerRef={this.newName} type="text" style={{ 'cursor': 'text' }} name="newName" placeholder="New Name" className="forexLoginInput" />
        </InputGroup>
        <InputGroup>
            <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FiFileText /></span></InputGroupAddon>
            <Input innerRef={this.newPassword} type="password" style={{ 'cursor': 'text' }} name="newPassword" placeholder="New Password" className="forexLoginInput" />
        </InputGroup>
        <span className="forexSubtitle">At least 8 characters long with uppercase and lowercase letters and digits.</span>
        </>
        }else if (this.state.loginPage && !this.state.passwordSuccess) {
            return <>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FiMail /></span></InputGroupAddon>
                    <Input type="email" innerRef={this.email} onKeyPress={this.onKeyPressEmail} name="email" placeholder="Email ID" defaultValue={this.state.email} className="forexLoginInput" onChange={this.clearEmailError} />
                </InputGroup>
                <span className="forexSubtitleErr" style={{ marginLeft: '15px' }} ref={this.emailError}></span>
                <span className="forexSubtitle" ref={this.emailHint}></span>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FiLock /></span></InputGroupAddon>
                    <Input type="password" onFocus={this.enablepassword} onKeyPress={this.onKeyPressPassword} readOnly style={{ 'cursor': 'text' }} innerRef={this.password} name="password" placeholder="Password" className="forexLoginInput" defaultValue={this.state.password} onChange={this.clearPasswordError} />
                </InputGroup>
                <span className="forexSubtitleErr" ref={this.passwordError} style={{ display: 'none', marginLeft: '15px' }}></span>
                <span className="forexSubtitle" ref={this.passwordHint}></span>
                <Spacer height={5} />
                <a href="" onClick={this.forgetButton} style={{ paddingLeft: '8px' }}><label className="forgetPwd" >Forgot Password</label></a>
                <Spacer height={10} />
            </>

        } else if (this.state.regPage) {
            return <>
                <label>Profile Type:</label>
                <FormGroup check inline style={{ marginLeft: '20px' }}>
                    <Label check >
                        <Input type="radio" value="Individual" onChange={this.handleChange} checked={(this.state.profileType === "Individual") ? true : false} name="profileType" required />{' '}
                        Individual
                        </Label>
                </FormGroup>
                <FormGroup check inline style={{ marginLeft: '20px' }}>
                    <Label check>
                        <Input type="radio" value="Corporate" checked={(this.state.profileType === "Corporate") ? true : false} onChange={this.handleChange} name="profileType" required />{' '}
                        Corporate
                        </Label>
                </FormGroup>
                <br />
                <span className="forexSubtitleErr" style={{ marginLeft: '25px', display: 'none' }} ref={this.profileTypeError}></span>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FaRegUser /></span></InputGroupAddon>
                    <Input type="text" onFocus={this.enableName} onKeyPress={this.onKeyPressName} readOnly innerRef={this.name} style={{ 'cursor': 'text', fontSize: '14px' }} name="name" placeholder="Full Name" defaultValue={this.state.name} className="forexLoginInput" onChange={this.clearNameError} />
                </InputGroup>
                <span className="forexSubtitleErr" style={{ marginLeft: '25px' }} ref={this.nameError}></span>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FiMail /></span></InputGroupAddon>
                    <Input type="email" innerRef={this.email} onKeyPress={this.onKeyPressEmail} name="email" placeholder="Email ID" defaultValue={this.state.email} className="forexLoginInput" onChange={this.clearEmailError} />
                </InputGroup>
                <span className="forexSubtitleErr" style={{ marginLeft: '25px' }} ref={this.emailError}></span>
                <span className="forexSubtitle" ref={this.emailHint}></span>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" ><span style={{ borderWidth: '0px 0px 1px 0px', borderColor: '#8e8e8f', marginTop: '30px', paddingRight: '8px' }}><FiLock /></span></InputGroupAddon>
                    <Input type="password" onFocus={this.enablepassword} onKeyPress={this.onKeyPressPasswordReg} readOnly style={{ 'cursor': 'text', fontSize: '14px' }} innerRef={this.password} name="password" placeholder="Password" className="forexLoginInput" defaultValue={this.state.password} onChange={this.clearPasswordError} />
                </InputGroup>
                <span className="forexSubtitleErr" ref={this.passwordError} style={{ display: 'none', marginLeft: '25px' }}></span>
                <span className="forexSubtitle" style={{ marginLeft: "25px" }} ref={this.passwordHint}>At least 8 characters long with uppercase and lowercase letters and digits.</span>
                <Spacer height={10} />
                <Label check>
                    <Input type="checkbox" id="termsandConditionCheck" style={{ marginLeft: "1px", marginTop: "0.1rem" }} /> <span className="forexSubtitle" style={{ marginLeft: "25px" }}>By choosing to sign up, you're agreeing to our <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</span>
                </Label>
                <span className="forexSubtitleErr" ref={this.termsandConditionCheck} style={{ display: 'none', marginLeft: '25px' }}></span>
                <Spacer height={20} />
            </>

        }
        else if (this.state.login) {
            return <>
                <div style={{ marginLeft: '60px' }}>
                    <label className="logoImgTextLogin"><b>Money Transfer</b></label><br />
                    <label className="logoImgTextLogin"><b>Made Easy!</b></label>
                    <p></p>
                </div>
            </>

        }
    }

    getButtons() {
    
        if (this.state.verifying || this.state.loading) {
            return <>
                <div onClick={this.reload} style={{ float: 'left' }}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer', transform: 'rotate(180deg)' }}></img>{' '}<label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Back</label></div>
                <div style={{ float: 'right' }}><label style={{ color: '#8b0304' }}>Continue</label>{' '}<Button style={{ 'background-color': '#6d0000 !important', 'width': '35px', 'height': '35px', 'padding': '4px 0px', 'border-radius': '20px', 'font-size': '10px', 'margin-top': '0px' }} className="btn-circle userButton" active>
                    <Loader type="Oval" color="#fff" height="15" width="15" />
                </Button>
                </div>
            </>
        } else if (this.state.userExsits) {
            return <>
                <div style={{ display: 'flex', float: 'right' }}>
                    <Button size="md" className="float-right" style={{ marginTop: '0px', marginRight: '0px', minWidth: 110, backgroundColor: '#8b0304 !important', cursor: 'pointer' }} active onClick={() => this.setState({ loginPage: true, regPage: false, password: '', userExsits: false, email: '', login: false, displayText: true })}>
                        Log In
                    </Button>
                    <span onClick={this.handleSingUP}><label style={{ color: '#8b0304', marginLeft: '20px', paddingRight: '2px', marginTop: '5px', 'cursor': 'pointer' }}>Continue</label>{' '}<img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer' }}></img></span>
                </div>
            </>
        } else if (this.state.userNotExsits) {
            return <>
                <div style={{ display: 'flex', float: 'right' }}>
                    <Button size="md" className="float-right" style={{ marginTop: 0, minWidth: 110, backgroundColor: '#8b0304 !important', marginLeft: '10px', cursor: 'pointer' }} active onClick={() => { this.passwordError.current.innerHTML = ''; this.setState({ loginPage: false, login: false, regPage: true, password: '', userExsits: false, userNotExsits: false, email: '', displayText: true }) }}>
                        Register
                    </Button>
                    <span onClick={this.handleLogin}><label style={{ color: '#8b0304', marginLeft: '20px', paddingRight: '2px', marginTop: '5px', cursor: 'pointer' }}>Continue</label>{' '}<img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer' }}></img></span>
                </div>
            </>
        }

        else if (this.state.confirmPassworddisplay) {
            return <>
                <div onClick={() => this.setState({ confirmPassworddisplay: false, forgetEmaildisplay: true, loading: false, loginPageMail: false, email: this.state.forgetemail })} style={{ float: 'left' }}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer', transform: 'rotate(180deg)' }}></img>{' '}<label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Back</label></div>
                <div onClick={this.confirmforgotPassword} style={{ float: 'right' }}><label style={{ color: '#8b0304' }}>Continue</label>{' '}<img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer' }}></img></div>
            </>
        }
        else if (this.state.loginPage) {
            return <>
                <div onClick={this.reload} style={{ float: 'left' }}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer', transform: 'rotate(180deg)' }}></img>{' '}<label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Back</label></div>
                <div onClick={this.handleLogin} style={{ float: 'right' }}><label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Continue</label>{' '}<img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer' }}></img></div>
            </>
        } else if (this.state.regPage) {
            return <>
                <div onClick={this.reload} style={{ float: 'left' }}><img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer', transform: 'rotate(180deg)' }}></img>{' '}<label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Back</label></div>
                <div onClick={this.handleSingUP} style={{ float: 'right' }}><label style={{ color: '#8b0304', 'cursor': 'pointer' }}>Continue</label>{' '}<img src={rightA} style={{ width: '35px', height: '35px', 'cursor': 'pointer' }}></img></div>
            </>
        }
        else if (this.state.login) {
            return <>
                <div style={{ marginLeft: '60px', float: 'left' }}>
                    <Button size="md" className="float-right" style={{ marginTop: 20, minWidth: 110, backgroundColor: '#8b0304 !important', marginLeft: '10px', cursor: 'pointer' }} active onClick={() => this.setState({ loginPage: false, login: false, regPage: true, displayText: true })}>
                        Register
                    </Button>
                    <Button size="md" className="float-right" style={{ marginTop: 20, minWidth: 110, backgroundColor: '#8b0304 !important', cursor: 'pointer' }} active onClick={() => this.setState({ loginPage: true, regPage: false, login: false, displayText: true })}>
                        Log In
                    </Button>
                </div>
            </>
        }
    }

    render() {
        
        let hearder1 = '';
        let hearder2 = '';
        let text1 = '';
        let text2 = '';
        if (this.state.action) {
            hearder1 = "Your email ID is now verified. "
            hearder2 = "Please login to complete your profile and initiate your money transfer."
        }
        console.log(this.state);
        if (this.state.loggedIn == true) {
            window.location = "/"
            return (<></>)
        }
        if (this.state.displayText) {
            text1 = "Money Transfer"
            text2 = "Made Easy!"
        }
        return (
            <div>
                <Spacer height={10} />
                <Row>
                    <Col className="pr-1" md="12"  >
                    </Col>
                </Row>
                <Spacer height={50} />
                <Row>
                    <Col className="pr-1" md="1"></Col>
                    <Col className="px-1" md="5" style={{ left: '90px' }}>
                        <Spacer height={30} />
                        <img src={grouplogo2} width="100px" height="auto" />
                    </Col>
                    <Col className="pl-1" md="5">
                        <br />
                        <h5 style={{ fontWeight: 'bold', fontFamily: 'Karla', fontSize: '45px', letterSpacing: '-0.18px', color: '#000000', lineHeight: '1.51' }}>
                            MoneyWorld Remit
                        </h5>
                        <Spacer height={50} />
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="1"></Col>
                    <Col className="px-1" md="5" >
                        <ExchangeRateCalculator 
                            background={
                                <div className="containerlogo">
                                    <img src={grouplogo} width="650px" height="370px" />
                                    <span className="logoImgText"><b>{text1}</b></span>
                                    <div className="logoImgText2"><b>{text2}</b></div>
                                </div>
                            }/>
                        
                    </Col>
                    <Col className="px-1" md="5">
                        <Row>
                            <h4 style={{ color: '#8b0304' }}>{hearder1}</h4>
                            <h4 style={{ color: '#8b0304' }}>{hearder2}</h4>
                        </Row>
                        <Row>
                            <Col id="conButton" className="pl-1" md="12">
                                {this.getVerificationContent()}
                                <Spacer height={100} />
                                {this.getInputAreaContent()}
                                {this.getButtons()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default Login;