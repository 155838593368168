import React  from "react";
import { Button, Modal, ModalHeader, ModalBody  } from "reactstrap";


class BeneficiarySubmitModal extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className} backdrop="static">
          <ModalHeader  style={{borderBottom:'transparent'}} toggle={this.toggle}>
            <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">{this.props.modalTitle.message}</p> 
            </ModalHeader>
          <ModalBody>
             <center>
              <Button color="primary" style={{marginRight:'15px',fontSize:'1rem',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}} onClick={()=>(this.props.modalTitle.status==200)?(window.location="/beneficiary/list"):this.toggle()}>I'll do it later</Button>{' '}
              <Button color="primary" style={{marginRight:'15px',fontSize:'1rem',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}} hidden={this.props.modalTitle.status==200?false:true} disabled={this.props.modalTitle.status==200?false:true} onClick={()=>(this.props.modalTitle.status==200)?(window.location="/transaction/add"):"javascript:void(0)"}>Proceed</Button>
            </center>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default BeneficiarySubmitModal;