class UserProfileModel {

    constructor(document) {
        this.customerId         =document.customerId;
        this.documentId         =document.documentId;
        this.documentName       =document.documentName;
        this.documentStatus     =document.documentStatus;
        this.documentType       =document.documentType;
        this.originalFileName   =document.originalFileName;
        this.fileName           =document.fileName;
    }
    updateValues = (documents) =>{
        var documentsList=documents;
        return documentsList;
    }
}

export default UserProfileModel;
