import AddTransaction from "views/transaction/addTransaction";
import ListTransaction from "views/transaction/listTransaction";

var transactionRoutes = [
    {
      path: "/transaction/add",
      name: "Add Transaction",
      component: AddTransaction
    },
    {
      path: "/transaction/list",
      name: "View transactions",
      component: ListTransaction
    },
    { redirect: true, path: "/transaction", pathTo: "/transaction/list", name: "View transactions" }
];
export default transactionRoutes;