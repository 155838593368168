import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip, Container, Row, Col,Breadcrumb, BreadcrumbItem, Input,Button,FormGroup, Label, Form } from 'reactstrap';
import swapArrow from "assets/img/swapArrow.svg";
import currencyHome from "variables/currencyHome.jsx";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import mime from "mime-types";
import { Storage } from 'aws-amplify';
import uuid from "uuid";
import moment from "moment";
import axios from 'axios';
import ConfirmationModal from "components/modals/confirmationModal.jsx";
import info from "assets/img/info.svg";
import Notification from "components/modals/Notification";

class AddTransaction extends React.Component{
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
          editingEnabled:true,
          otpDisabled:true,
          isOtpSent:false,
          redirect:false,
          paymentId:"",
          rate:"",
          amountInSGD:"0",
          currency:"",
          loaderMessage:"",
          isSaved:false,
          feeChargedinSGD:"",
          currency:"",feeChargedinSGD:"",
          amountToTransferDisabled:false,
          amountPaidinSGDDisabled:false,
          processingFeeSelect:true, 
          currencySelect:true,
          fileNames:"", 
          messageModel:"",
          precisionDigits: 4,
          redirectTo:""
          }
        this.beneficiaryList          = []
        this.currencyExchangeFeeModal = []
        this.getBeneficiaries();
        this.modalRef                 = React.createRef();
        this.errorModel               = React.createRef();
        this.onSubmit                 = this.onSubmit.bind(this);
        this.actionOtp                = this.actionOtp.bind(this);
        this.onSaveLater              = this.onSaveLater.bind(this);
        this.saveNextCheck            = this.saveNextCheck.bind(this);
        this.sendOtp                  = this.sendOtp.bind(this);
        this.findRate                 = this.findRate.bind(this);
        this.getValidDates            = this.getValidDates.bind(this);
        this.checkHomePayment         = this.checkHomePayment.bind(this);
        this.significantDigitFunction = this.significantDigitFunction.bind(this);
        this.fileLabel                = React.createRef();
        this.fileField                = React.createRef();
        this.latestConversionRates       = [];
        this.uploadedFiles            = [];
        this.currencies               = [];
        this.amountToTransfer         = '';
        this.currency                 = '';
        this.paymentType              = '';  
        this.feeChargedinSGD          = '';
        this.amountPaidinSGD          = '';
        this.tom                      = false;
        this.today                    = false;
        this.singaporeHolidaysList    = [];
        this.data                     = {
                  beneficiaryDetails:"",
                  beneficiaryId:"",
                  beneficiaryName:"",
                  bankName:"",
                  // branchCode:"",
                  accountNumber:"",
                  amountToTransfer:"",
                  currency:"",
                  executionDate:"",
                  purpose:"",
                  speed:"express",
                  internalReference:"",
                  notifyBeneficiary:false,
                  beneficiaryNotification:"",
                  invoiceFileName:"",
                  amountPaidinSGD:"",
                  feeChargedinSGD:"",
                  customerTransactionId:"",
                  exchangeRateToCurrencyToSGD:"",
                  paymentType:"",
                  invoiceFileNames:[],
                }
      }

      checkHomePayment(){
        if(this.props.location.param_home && this.props.location.param_home.redirect){
            this.data.beneficiaryId               = this.props.location.param_home.beneficiary.beneficiaryId;
            this.data.amountPaidinSGD             = this.props.location.param_home.amountPaidinSGD;
            this.data.amountToTransfer            = this.props.location.param_home.amountToTransfer;
            this.data.currency                    = this.props.location.param_home.currency;
            if(this.data.currency == "IDR" || this.data.currency == "MYR"){
              this.setState({precisionDigits: 4})
            }
            this.data.paymentType                 = this.props.location.param_home.paymentType;
            this.data.exchangeRateToCurrencyToSGD = this.props.location.param_home.exchangeRateToCurrencyToSGD;
            this.data.feeChargedinSGD             = this.props.location.param_home.feeChargedinSGD;
            this.currency                         = this.props.location.param_home.currency;
            this.amountPaidinSGD                  = this.props.location.param_home.amountPaidinSGD;
            this.amountToTransfer                 = this.props.location.param_home.amountToTransfer;
            this.feeChargedinSGD                  = this.props.location.param_home.feeChargedinSGD;
            this.paymentType                      = this.props.location.param_home.paymentType;

            this.data.beneficiaryDetails          = this.props.location.param_home.beneficiary.name+" ("+this.props.location.param_home.beneficiary.bankName+") "+" ("+this.props.location.param_home.beneficiary.accountNumber+") ";
            this.data.beneficiaryName             = this.props.location.param_home.beneficiary.name;
            this.data.bankName                    = this.props.location.param_home.beneficiary.bankName;
            // this.data.branchCode                  = this.props.location.param_home.beneficiary.branchCode;
            this.data.accountNumber               = this.props.location.param_home.beneficiary.accountNumber;
            this.data.beneficiaryNotification     = this.props.location.param_home.beneficiary.emailId;
            this.currencies                       = this.props.location.param_home.beneficiary.currencies;
          }
        }

      checkPayment(){
        if(this.props.location.param && this.props.location.param.redirect){
            this.data.beneficiaryId               = this.props.location.param.beneficiary.beneficiaryId;              
            this.data.beneficiaryDetails          = this.props.location.param.beneficiary.name+" ("+this.props.location.param.beneficiary.bankName+") "+" ("+this.props.location.param.beneficiary.accountNumber+") ";
            this.data.beneficiaryName             = this.props.location.param.beneficiary.name;
            this.data.bankName                    = this.props.location.param.beneficiary.bankName;
            // this.data.branchCode                  = this.props.location.param.beneficiary.branchCode;
            this.data.accountNumber               = this.props.location.param.beneficiary.accountNumber;
            this.data.beneficiaryNotification     = this.props.location.param.beneficiary.emailId;
            this.currencies                       = this.props.location.param.beneficiary.currencies;
          }
      }
      
      getBeneficiaries=()=>{
        const url= (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/beneficiary";
        axios.get(url).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          if(res.data.status === 200){
            this.singaporeHolidaysList = res.data.singaporeHolidaysList
          }
          if(this.props.location.param && this.props.location.param.redirect){
            this.data.beneficiaryId           = this.props.location.param.beneficiary.beneficiaryId
            this.data.beneficiaryDetails      = this.props.location.param.beneficiary.name+" ("+this.props.location.param.beneficiary.bankName+") "+" ("+this.props.location.param.beneficiary.accountNumber+") ";
            this.data.beneficiaryName         = this.props.location.param.beneficiary.name;
            this.data.beneficiaryNotification = this.props.location.param.beneficiary.emailId;
            this.data.bankName                = this.props.location.param.beneficiary.bankName;
            // this.data.branchCode              = this.props.location.param.beneficiary.branchCode;
            this.data.accountNumber           = this.props.location.param.beneficiary.accountNumber;
          }
          res.data.beneficiaries.forEach((beneficiary) => {
            if(beneficiary.beneficiaryStatus !== "Rejected" && beneficiary.currencies[0] !== "SGD"){ 
                this.beneficiaryList.push(beneficiary)
            }
          });
          this.forceUpdate();
          }).catch(function (error) {
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
          })
        const currencyDataUrl= (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/fee";
        axios.get(currencyDataUrl).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          if(res.status==200){
            this.currencyExchangeFeeModal = res.data.currencyExchangeModelList
            this.checkHomePayment();
            this.checkPayment();
            this.forceUpdate();
          }
        }).catch(function (error) {
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
        })
      }

      onFocus(event){
        if(event.target.disabled)return
        event.target.nextElementSibling.classList.remove('spanMiddle')
        event.target.nextElementSibling.classList.add('spanTop')
        if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
      }

      onBlur(event){
        event.target.classList.remove('activeFocus','invalid','valid')
        event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
        if(event.target.value==""){
          event.target.classList.remove('activeFocus','invalid','valid')
          event.target.nextElementSibling.classList.remove('spanTop')
          event.target.nextElementSibling.classList.add('spanMiddle')
          }
          if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
      }
     
    
      findRate(currency,amount){
        let selecter = "lessThan1k"
        if(parseFloat(amount)<1000){
          selecter = "lessThan1k"
        }
        if(parseFloat(amount)>=1000 && parseFloat(amount)<=4999){
          selecter = "lessThan5k";
        }
        if(parseFloat(amount)>=5000 && parseFloat(amount)<=9999){
          selecter = "lessThan10k";
        }
        if(parseFloat(amount)>=10000 && parseFloat(amount)<=14999){
          selecter = "lessThan15k";
        }
        if(parseFloat(amount)>=15000 && parseFloat(amount)<=19999){
          selecter = "lessThan20k";
        }
        if(parseFloat(amount)>=20000 && parseFloat(amount)<=24999){
          selecter = "lessThan25k";
        }
        if(parseFloat(amount)>=25000 && parseFloat(amount)<=29999){
          selecter = "lessThan30k";
        }
        if(parseFloat(amount)>=30000 && parseFloat(amount)<=34999){
          selecter = "lessThan35k";
        }
        if(parseFloat(amount)>=35000 && parseFloat(amount)<=39999){
          selecter = "lessThan40k";
        }
        if(parseFloat(amount)>=40000 && parseFloat(amount)<=44999){
          selecter = "lessThan45k";
        }
        if(parseFloat(amount)>=45000 && parseFloat(amount)<=49999){
          selecter = "lessThan50k";
        }
        if(parseFloat(amount)-50000>=0){
          selecter = "moreThan50k";
        }
        return this.currencyExchangeFeeModal[currency][selecter]
      }

      significantDigitFunction(number){
        let x = Math.abs(parseFloat(number))
        let floorValue = Math.floor(x)
        let diff = x - floorValue
        if (diff == 0){
          return x.toFixed(2)
        }
        let i = 0
        while ((diff * Math.pow(10, i + 1)) < 1) {
          i += 1
          if (i > 10) break
        }
        return x.toFixed(Math.min(i + 4, 10))
      }
      

      currencyToSGDConverter(amountToTransfer){
        let currencyString = this.currency+"SIN";
        let currencyRate   = this.latestConversionRates[currencyString];
        let mwaRate        = this.findRate(this.currency, (amountToTransfer*currencyRate))
        let netRate        = this.significantDigitFunction(currencyRate*(1+(mwaRate/100)))
        let finalAmount    = (amountToTransfer*netRate).toFixed(2);      
        this.setState({amountPaidinSGD:finalAmount,exchangeRateToCurrencyToSGD:netRate});
        this.data.amountPaidinSGD             = finalAmount
        this.data.exchangeRateToCurrencyToSGD = netRate
      }
  
      SGDToCurrencyConverter(amountPaidinSGD){
        let mwaRate        = this.findRate(this.currency, amountPaidinSGD)
        let currencyString = this.currency+"SIN";
        let currencyRate   = this.latestConversionRates[currencyString];
        let netRate        = this.significantDigitFunction(currencyRate*(1+(mwaRate/100)))
        let finalAmount    = (amountPaidinSGD/netRate).toFixed(2);
        this.setState({amountToTransfer:finalAmount,exchangeRateToCurrencyToSGD:netRate});
        this.data.amountToTransfer            = finalAmount
        this.data.exchangeRateToCurrencyToSGD = netRate
        
      }

      renderOptions(){
        if(this.currency!=""){
          let optionsSHA = <option value="SHA">SHA</option>
          let optionsSHAOUR = <><option value="SHA">SHA</option><option value="OUR">OUR</option></>
          if(this.currencyExchangeFeeModal[this.currency].totalCharges!=0){
            return optionsSHAOUR
          }
          else{
            return optionsSHA
          }
        }
      }
  
      refreshValues(){
        this.setState({currency:"", amountPaidinSGDDisabled:false, amountToTransferDisabled:false, amountToTransfer:"", amountPaidinSGD:"", feeChargedinSGD:"", paymentType:"", processingFeeSelect:true, currencySelect:true})
        this.amountToTransfer = ""
        this.amountPaidinSGD  = ""
        this.currency         = ""
      }
    
      handleChange = (event) => {
        if(event.target.type=="text"){
          event.target.classList.remove('valid','invalid')
          event.target.classList.add('activeFocus')
        }

        this.data[event.target.name]=event.target.value

        if(event.target.name==="currency"){
          this.refreshValues()
          var precisionValue = 4;
          this.setState({[event.target.name]: event.target.value,processingFeeSelect:true, currencySelect:false,feeChargedinSGD:"",precisionDigits: parseInt(precisionValue)})
          const url=(process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/conversion-rates";
          axios.get(url).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          if(res.data.status == 200){
          this.latestConversionRates = res.data.latestConversionRates;
          this.currency = this.state.currency?this.state.currency:"";
          this.forceUpdate();
        }
        }).catch(function (error) {
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
        })
        }

        if(event.target.name==="amountToTransfer"){
          if(event.target.value==""){
            this.amountToTransfer = 0
            this.setState({[event.target.name]: 0,amountPaidinSGDDisabled:true})
            this.currencyToSGDConverter(0);
            return
          }
          var regex=/^[0-9,.]+$/g;
          if (!event.target.value.match(regex))
          {
            event.target.value=0;
            return
          }
          if(event.target.value.toString().split(".").length>2){
            event.target.value=0;
            return
          }
          this.amountToTransfer        = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.data[event.target.name] = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.setState({[event.target.name]: event.target.value,amountPaidinSGDDisabled:true})
          this.currencyToSGDConverter(Number(event.target.value.toString().replace(/,/g,"")).toFixed(2).toString())
        }
        if(event.target.name==="amountPaidinSGD"){
          if(event.target.value==""){
            this.amountPaidinSGD = 0
            this.setState({[event.target.name]: 0,amountToTransferDisabled:true})
            this.SGDToCurrencyConverter(0);
            return
          }
          var regex=/^[0-9,.]+$/g;
          if (!event.target.value.match(regex))
          {
            event.target.value=0;
              return
          }
          if(event.target.value.toString().split(".").length>2){
            event.target.value=0;
            return
          }
          this.amountPaidinSGD         = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.data[event.target.name] = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.setState({[event.target.name]: event.target.value,amountToTransferDisabled:true})
          this.SGDToCurrencyConverter(Number(event.target.value.toString().replace(/,/g,"")).toFixed(2).toString())
        }
        if(event.target.name==="paymentType"){
          this.data.feeChargedinSGD = this.currencyExchangeFeeModal[this.currency][event.target.value=="SHA"?"sharedCharges":"totalCharges"]
          this.setState({[event.target.name]: event.target.value,processingFeeSelect:false, feeChargedinSGD:this.currencyExchangeFeeModal[this.currency][event.target.value=="SHA"?"sharedCharges":"totalCharges"]})
        }
        
        if(event.target.name=="notifyBeneficiary"){
          this.data[event.target.name] = event.target.checked;
          this.forceUpdate()
        }
        
        if(event.target.name==="beneficiaryId"){
          {this.beneficiaryList.forEach((beneficiary)=>{
            if(beneficiary.beneficiaryId===this.data.beneficiaryId){
              this.data.beneficiaryDetails      = beneficiary.name+" ( "+beneficiary.bankName+" ) "+" ( "+beneficiary.accountNumber+" ) ";
              this.data.beneficiaryName         = beneficiary.name;
              this.data.bankName                = beneficiary.bankName;
              // this.data.branchCode              = beneficiary.branchCode;
              this.data.accountNumber           = beneficiary.accountNumber;
              this.data.beneficiaryNotification = beneficiary.emailId;
              this.currencies                   = beneficiary.currencies;
            }
          })}
          this.forceUpdate();
        }
      }

      
    
      onFileChange=(e)=>{
      let file = e.target.files;
      if(file.length>0){
          this.uploadDoc(file,0);
      }      
      }

      uploadDoc = (files,pageNum) => {
        var filename = (this.state.fileNames.length > 0)? " || "+ files[pageNum].name :files[pageNum].name;
        // this.setState({fileNames: (this.state.fileNames + files[pageNum].name) + " || "})
        this.setState({fileNames: (this.state.fileNames + filename)})
        let fileName = uuid.v4()+'.'+files[pageNum].name.slice((files[pageNum].name.lastIndexOf(".") - 1 >>> 0) + 2);
        this.setState({loading:true,loaderMessage:"Uploading File"})
        Storage.put(fileName,files[pageNum], {
          level: 'private',
          contentType: mime.contentType(files[pageNum].name)
      })
        .then (result => {
          this.uploadedFiles.push(result.key)
          this.setState({loading:false,loaderMessage:"File Uploaded"})
          if(pageNum < files.length -1 )  {
            this.uploadDoc(files,pageNum+1)
          }
        }).catch();   
      }

      savePayment(){
          this.data.invoiceFileNames = this.uploadedFiles
          const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment";
          axios.post(url,this.data).then(res =>{
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
            if(res.data.status==200){
              if(this.state.redirect){
                window.location="/payment/list"
              }
              else{
                this.setState({loaderMessage:"Payment Saved. Sending OTP...",paymentId:res.data.message,isSaved:true})
                this.sendOtp()
              }
            }
            else if(res.data.status==403){
              this.setState({loading:false,messageModel:"All fields are mandatory"})
              this.errorModel.current.toggle();
              return res.data.status;
            }
            else if(res.data.status==401){
              this.setState({loading:false,messageModel:"Date not in proper format"})
              this.errorModel.current.toggle();
              return res.data.status;
            }
            //  else{
            //   this.setState({loading:false,messageModel:res.data.message,redirectTo:"transaction/list"})
            //   this.errorModel.current.toggle();
            //   return res.data.status;
            // }
            }).catch(function (error) {if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}})
      }
    
      sendOtp(){
        this.setState({loading:true,loaderMessage:"Payment Saved. Sending OTP..."})
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/otp";
        axios.post(url).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          if(res.data.status==200){
            this.setState({isOtpSent:true,loading:false,loaderMessage:"",messageModel:"Otp sent successfully"})
          }
          else{
            this.setState({loading:false,messageModel:"There was an error in sending Otp."})
            this.errorModel.current.toggle();
          }
        }).catch(function (error){if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}})
      }
      verifyOtp(otp){
        this.setState({loading:true,loaderMessage:"Verifying OTP"})
        let data = {
          "otp" : otp,
          "paymentId" : this.state.paymentId
        }
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/verify";
        axios.post(url,data).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          if(res.data.status==200){
            // this.setState({loading:false,loaderMessage:"",redirectTo:"transaction/list",messageModel:res.data.message})
            // this.errorModel.current.toggle();
            this.setState({loading:false,loaderMessage:""})
            this.modalRef.current.toggle();
          }
          else if(res.data.status==201){
            this.setState({loading:false,loaderMessage:"",redirectTo:"/#transaction/list",messageModel:res.data.message})
            this.errorModel.current.toggle();
          }
          else{
            this.setState({loading:false,loaderMessage:"",messageModel:"There was an error in verifying Otp."})
            this.errorModel.current.toggle();
          }
        }).catch(function (error){ if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} })
      }
    
      onSubmit(e){
       e.preventDefault();
       this.savePayment();
       this.setState({loading:true,loaderMessage:"Saving Payment"});
      }

      onSaveLater(e){
        e.preventDefault();
        this.setState({redirect:true,loading:true,loaderMessage:"Saving Payment"});
        this.savePayment();
       }
      actionOtp(e){
        if(e.target.value.length==4){
          this.verifyOtp(e.target.value)
        }
      }
    
      saveNextCheck(){
        if(this.data.beneficiaryDetails==""){
          this.setState({messageModel:"Please Select Beneficiary"})
          this.errorModel.current.toggle();
         return
        }
        if(this.data.currency==""){
          this.setState({messageModel:"Please Select Currency"})
          this.errorModel.current.toggle();
          return
        }
        if(this.data.amountToTransfer=="" || this.data.amountPaidinSGD==""){
          this.setState({messageModel:"Please Enter Amount"})
          this.errorModel.current.toggle();
          return
        }
        if(this.data.paymentType==""){
          this.setState({messageModel:"Please Select Payment Type"})
          this.errorModel.current.toggle();
          return
        }
        if(this.data.executionDate==""){
          this.setState({messageModel:"Please Select Execution Date"})
          this.errorModel.current.toggle();
          return
        }
        if(this.data.purpose==""){
          this.setState({messageModel:"Please select purpose"})
          this.errorModel.current.toggle();
          return
        }
        if(this.data.notifyBeneficiary==true && (this.data.beneficiaryNotification=="" || this.data.beneficiaryNotification==undefined)){
          this.setState({messageModel:"Please enter beneficiary EmailID to get email notifications"})
          this.errorModel.current.toggle();
          return
        }
        if(!(this.data.beneficiaryNotification=="" || this.data.beneficiaryNotification==undefined)){
          var regex=/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]+$/g;
          if (!this.data.beneficiaryNotification.match(regex))
          {
            this.data.beneficiaryNotification=""
            this.setState({messageModel:"Please enter the correct beneficiary email ID."})
            this.errorModel.current.toggle();
            return
          }
        } 
        // if(this.uploadedFiles.length==0){
        //   this.setState({messageModel:"Please attach invoice(s)"})
        //   this.errorModel.current.toggle();
        //   return
        // }
        if((parseFloat(this.data.amountToTransfer)>parseFloat(this.currency==""?0:currencyHome[this.currency].max) || parseFloat(this.data.amountToTransfer)<parseFloat(this.currency==""?0:currencyHome[this.currency].min))){
          this.setState({messageModel:"Amount should be between "+currencyHome[this.currency].min.toLocaleString()+" and "+currencyHome[this.currency].max.toLocaleString()+" for "+this.currency})
          this.errorModel.current.toggle();
          return
        }
        this.setState({editingEnabled:false})
      }
    
      onKeyPress(event) {
        if (event.which === 13) {
          event.preventDefault();
        }
      }
      getValidDates(){
        var today = new Date()
        var tom   = new Date() 
        var firstDay;
        var nextDay;
        tom = new Date(tom.setDate(tom.getDate() + 1));
        var todayHoliday = this.singaporeHolidaysList.filter((d) => d === moment(today).format("YYYY-MM-DD"));
        var tomHoliday   = this.singaporeHolidaysList.filter((d) => d === moment(tom).format("YYYY-MM-DD"));
        if(todayHoliday.length > 0){
           today      = new Date(today.setDate(today.getDate() + 1))
           this.today = true;
        }
        if(tomHoliday.length > 0){
          tom = new Date(tom.setDate(tom.getDate() + 1))
        }
        if(today.getHours() >= 15 && this.today === false){
          today = new Date(today.setDate(today.getDate() + 1));
           if(tomHoliday.length > 0){
            today = new Date(today.setDate(today.getDate() + 1));
            tom   = new Date(tom.setDate(tom.getDate() + 3));
           }else{
            tom   = new Date(tom.setDate(tom.getDate() + 1));
           }    
           this.tom = true;
        }

        if(today.getDay() == 0 ){
          firstDay = today.setDate(today.getDate() + 1);
          nextDay  = tom.setDate(tom.getDate() + 1);
        }else if(today.getDay() == 5){
          firstDay = today.setDate(today.getDate());
        }else if(today.getDay() == 6){
          firstDay = today.setDate(today.getDate() + 2);
        }else{
          firstDay = today.setDate(today.getDate());
        }

        if(tom.getDay() == 0){
          nextDay  = tom.setDate(tom.getDate() + 2);
        }else if(today.getDay() == 5  && this.tom === true){
          nextDay  = tom.setDate(tom.getDate() + 2);
        }else if(today.getDay() == 5 && this.tom !== true){
          nextDay  = tom.setDate(tom.getDate() + 2);
        }else if(tom.getDay() == 6 && this.tom === true){
          nextDay  = tom.setDate(tom.getDate() + 1);
        }else if(tom.getDay() == 6 && this.tom !== true){
          nextDay  = tom.setDate(tom.getDate() + 2);
        }
        else{
          nextDay  = tom.setDate(tom.getDate());
        }
        return <><option>{moment(firstDay).format("YYYY-MM-DD")}</option><option>{moment(nextDay).format("YYYY-MM-DD")}</option></>
      }

    currencyFormatter = (amount) => {
      if(String(amount).endsWith(".")){
        return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+"."
      }
      if(String(amount).endsWith(".0")){
        return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".0"
      }
      if(String(amount).endsWith(".00")){
        return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".00"
      }
      if(String(amount).endsWith(".000")){
        return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".000"
      }
      if(String(amount).endsWith(".0000")){
        return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".0000"
      }
      if(String(amount).endsWith(".00000")){
        return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".00000"
      }
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})
    }

    render(){
        return(
            <>
            <Notification ref={this.errorModel} redirectTo={this.state.redirectTo} modalTitle={this.state.messageModel}/>
            <BlockUi tag="div" blocking={this.state.loading} keepInView={true} message={this.state.loaderMessage} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <ConfirmationModal ref={this.modalRef} modalTitle="Payment" payment={this.data} paymentId={this.state.paymentId}/>
            <Form style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className="mainContent" >
            <Row className="breadcrumbContainer" hidden={this.state.isOtpSent}>
                   <Col md="12" className="breadcrumbContainerColumn">
                      <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                        <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                        <BreadcrumbItem className="breadcrumbItem" tag="a" >Transaction</BreadcrumbItem>
                        <BreadcrumbItem className="breadcrumbItem" active tag="span">Initiate Transaction</BreadcrumbItem>
                      </Breadcrumb>
                   </Col>
            </Row>
            <Row className="breadcrumbContainer" hidden={!this.state.isOtpSent}>
                   <Col md="12" className="breadcrumbContainerColumn">
                   <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                     <BreadcrumbItem className="breadcrumbItem initial" tag="a" href="#">Home</BreadcrumbItem>
                     <BreadcrumbItem className="breadcrumbItem" active tag="span">Send OTP</BreadcrumbItem>
                   </Breadcrumb>
                   </Col>
            </Row>
            <Row className="pageHeading" hidden={this.state.isOtpSent}>
              <Col md="6"  className="pageHeadingColumn">
                <h1 style={{marginBottom:"50px"}}>Initiate Transaction</h1>
              </Col>
            </Row>
            <Container className="section" hidden={!this.state.isOtpSent?!(this.state.editingEnabled || this.state.isOtpSent):!(this.state.editingEnabled && this.state.isOtpSent)} onFocus={window.scrollTo(0, 0)}>
            <Row className="inputRow">
            <Col md="12" className="inputField transBeneficiary" hidden={(this.props.location.param && this.props.location.param.redirect) || (this.props.location.param_home && this.props.location.param_home.redirect)}>
                <select name="beneficiaryId" onChange={this.handleChange} required 
                hidden={(this.props.location.param && this.props.location.param.redirect) || (this.props.location.param_home && this.props.location.param_home.redirect)}
                disabled={(this.props.location.param && this.props.location.param.redirect) || (this.props.location.param_home && this.props.location.param_home.redirect)}>
                <option value="" disabled selected>Select</option>
                    {this.beneficiaryList.map((prop) => {
                    return <option value={prop.beneficiaryId}>{prop.name}</option> 
                    })}
                </select>
                <br/>
                <label hidden={(this.props.location.param && this.props.location.param.redirect) || (this.props.location.param_home && this.props.location.param_home.redirect)}>Beneficiary</label>
            </Col>
            <Col md="12" className="inputField" hidden={!(this.props.location.param && this.props.location.param.redirect)}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="beneficiaryId" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Beneficiary Details" disabled autoComplete="off"
                      hidden={!(this.props.location.param && this.props.location.param.redirect)}
                      value={this.props.location.param && ("    "+this.props.location.param.beneficiary.name)}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param && this.props.location.param.redirect)}>Beneficiary Name</span>
                </div>
            </Col>
            <Col md="12" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="beneficiaryId" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Beneficiary Details" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+this.props.location.param_home.beneficiary.name)}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Beneficiary Name</span>
                </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField transCurrency" hidden={this.props.location.param_home && this.props.location.param_home.redirect}>
                 <select name="currency" required onChange={this.handleChange} disabled={(this.currencies == null)?true:false}>  {/*Priya */}
                    <option selected={this.state.currencySelect?true:false}  disabled hidden value=''>Select</option>
                    {((this.currencies!=null)?this.currencies:[]).map((prop) => {
                                        return <option style={{fontSize:'16px !important'}} value={prop}>{prop}</option> 
                        })}
                 </select><br/>
                <label>{this.currency==""?"Currency":currencyHome[this.currency].name}</label>
            </Col>
            <Col md="3" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
                <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="currency" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Currency" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+this.props.location.param_home.currency)}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Currency</span>
                </div>
            </Col>
            <Col md="4" className="inputField" hidden={this.props.location.param_home && this.props.location.param_home.redirect}>
            <div className="inputGroup">
                   <input type="text" id="homeCurrency" className="inputText"
                    name="amountToTransfer" autoComplete="off"
                    // pattern="[^.][0-9.]+" 
                    onChange={this.handleChange} 
                    value={this.state.amountToTransfer?this.currencyFormatter(this.state.amountToTransfer):""}
                    // (this.state.amountToTransfer?parseFloat(this.state.amountToTransfer.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
                    title="Amount To Transfer" disabled={(this.currency==""?true:false)}/>
                   <span className="spanTop" onClick={(e)=>{e.target.previousElementSibling.focus()}}>Amount {this.currency==""?"":(" in "+this.currency)}
                   </span><br/>
            </div>
            </Col>
            <Col md="4" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="amountToTransfer" 
                      title="Amount To Transfer" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+parseFloat(this.props.location.param_home.amountToTransfer).toLocaleString(undefined, {  maximumFractionDigits: 20 }))}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Amount to Transfer</span>
            </div>
            </Col>
            <Col md="1">
                <img src={swapArrow} height="35px" width="35"/>
            </Col>
            <Col md="4" className="inputField" hidden={this.props.location.param_home && this.props.location.param_home.redirect}>
                <div className="inputGroup">
                   <input type="text" id="homeCurrency"  className="inputText"
                    name="amountPaidinSGD" autoComplete="off"
                    // pattern="[^.][0-9.]+" 
                    onChange={this.handleChange} 
                    value={this.state.amountPaidinSGD?this.currencyFormatter(this.state.amountPaidinSGD):""}
                    // (this.state.amountPaidinSGD?parseFloat(this.state.amountPaidinSGD.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
                    title="Amount to be Paid in SGD" disabled={(this.currency==""?true:false)}/>
                   <span className="spanTop" onClick={(e)=>{e.target.previousElementSibling.focus()}}>Amount in SGD
                   </span>
                </div>
            </Col>
            <Col md="4" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="amountPaidinSGD"
                      title="Amount to be Paid in SGD" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+parseFloat(this.props.location.param_home.amountPaidinSGD).toLocaleString(undefined, {  maximumFractionDigits: 20 }))}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Amount to be Paid in SGD</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField paymentType" hidden={this.props.location.param_home && this.props.location.param_home.redirect}>
                    <select name="paymentType"  title="Processing Fee Type" disabled={(this.currency==""?true:false)} onChange={this.handleChange} required>
                      <option value="" disabled hidden selected={this.state.processingFeeSelect?true:false}>Select</option>
                      {this.state.currency?this.renderOptions():""}
                    </select><br/>
                    <label id="processingFeeID" >Processing Fee Type <img src={info} height={15} width={15}  style={{marginLeft:"5px"}}/></label>
                    <UncontrolledTooltip placement="right" target="processingFeeID" style={{fontSize:"10px"}}>
                    <Row>
                      <Col md="12" >
                        <ul style={{textAlign:"left",padding:"0px",marginBottom:"0px"}}>
                          <li>SHA (cover local bank charges)</li>
                          <li>OUR (cover all charges)</li>
                        </ul>
                      </Col>
                    </Row>
                    </UncontrolledTooltip>
            </Col>
            <Col md="3" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="paymentType" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Payment Type" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+this.props.location.param_home.paymentType)}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Payment Type</span>
            </div>
            </Col>
            <Col md="4" className="inputField" hidden={this.props.location.param_home && this.props.location.param_home.redirect}>
            <span style={{color:"#052d99",fontWeight:"bold"}}>Processing Fee <input style={{background:"transparent",border:"none",color:"#052d99"}}
              value={(this.state.feeChargedinSGD?this.state.feeChargedinSGD:"0")+" SGD"} disabled></input></span>
            </Col>
            <Col md="4" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="feeChargedinSGD" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Processing Fee" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+this.props.location.param_home.feeChargedinSGD)}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Processing Fee</span>
            </div>
            </Col>
            <Col md="5" className="inputField" hidden={this.props.location.param_home && this.props.location.param_home.redirect}>
            <span style={{color:"#052d99",fontWeight:"bold"}}>Grand Total <input style={{background:"transparent",border:"none",color:"#052d99"}}
              value={(this.state.feeChargedinSGD?parseFloat(parseFloat(this.state.amountPaidinSGD?(this.state.amountPaidinSGD.toString().replace(/,/g,"")):0)+parseFloat(this.state.feeChargedinSGD)).toLocaleString(undefined, {  maximumFractionDigits: 2 }):0)+" SGD"} disabled/></span>
            </Col>
            <Col md="5" className="inputField" hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="grandTotal" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Grand Total" disabled autoComplete="off"
                      hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}
                      value={this.props.location.param_home && ("    "+parseFloat(this.props.location.param_home.netAmount).toLocaleString(undefined, {  maximumFractionDigits: 2 }))}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    hidden={!(this.props.location.param_home && this.props.location.param_home.redirect)}>Grand Total</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField executionDate">
            <select name="executionDate"  title="Execution Date" onChange={this.handleChange} required>
              <option value="" disabled selected>Select</option>
              {this.getValidDates()}
            </select><br/>
            <label id="executionDateID">Execution Date<img src={info} height={15} width={15}  style={{marginLeft:"5px"}}/></label>
            <UncontrolledTooltip placement="right" target="executionDateID">
            The timeframe within which the customer has to transfer the amount in SGD to MWA Account
            </UncontrolledTooltip>
            </Col>
            <Col md="3" className="inputField paymentType">
                    <select name="purpose"  title="Purpose" onChange={this.handleChange} required>
                      <option value="" disabled hidden selected>Select</option>
                      <option value="Business expense">Business Expense</option>
                      <option value="Dividend">Dividend</option>
                      <option value="Education / Study">Education / Study</option>
                      <option value="Investment securities">Investment Securities</option>
                      <option value="Invoice payment">Invoice Payment</option>
                      <option value="Purchase sale of goods">Purchase Sale of Goods</option>
                      <option value="Intercompany payment">Intercompany Payment</option>
                      <option value="Loan repayment">Loan Repayment</option>
                      <option value="Salary payment">Salary Payment</option>
                      <option value="Family Support">Family Support</option>
                      <option value="Charity Donation">Charity Donation</option>
                      <option value="Property payment">Property Payment</option>
                      <option value="Others">Others</option>
                    </select><br/>
                    <label >Purpose</label>
            </Col>
            <Col md="6" className="inputField">
            <div className="inputGroup">
                    <input type="text" className="inputText" data-optional="true"
                      name="internalReference" onFocus={this.onFocus} onBlur={this.onBlur}
                      onChange={this.handleChange} autoComplete="off" />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Remarks</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="4" className="inputField">
                <FormGroup check inline>
                  <label className="containerCheck" style={{fontSize:"16px",fontWeight:"bold",color:"#042d99",marginTop:"10px"}}>Would you like to notify Beneficiary?
                  <input type="checkbox" name="notifyBeneficiary" 
                  disabled={(this.data.beneficiaryId=="" || this.data.beneficiaryId==undefined)?true:false}
                  onChange={this.handleChange}/><span className="checkmark"></span></label>
                </FormGroup>
            </Col>
            <Col md="8" className="inputField">
            <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="beneficiaryNotification" onFocus={this.onFocus} onBlur={this.onBlur}
                      onChange={this.handleChange} autoComplete="off" 
                      pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]+$"
                      disabled={!this.data.notifyBeneficiary || !(this.data.beneficiaryNotification=="" || this.data.beneficiaryNotification==undefined)} 
                      hidden={!this.data.notifyBeneficiary || !(this.data.beneficiaryNotification=="" || this.data.beneficiaryNotification==undefined)}
                        />
                    <span hidden={!this.data.notifyBeneficiary || !(this.data.beneficiaryNotification=="" || this.data.beneficiaryNotification==undefined)} onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Email</span>
            </div>
            <label hidden={!this.data.notifyBeneficiary || (this.data.beneficiaryNotification=="" || this.data.beneficiaryNotification==undefined)} style={{fontSize:"14px",fontWeight:"bold",marginTop:"10px"}}>We will inform the beneficiary about this payment over email.</label>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md={{size:"2"}} className="inputField">
              <label style={{fontSize:"16px",fontWeight:"bold",color:"#042d99",marginTop:"10px"}}>Upload Invoice(s)</label>
            </Col>
            <Col md="10" className="inputField">
                <div className="input-container" onClick={() => this.fileField.current.click()}>
                <input type="file" name="validId"  accept=".png,.jpeg,.pdf,.jpg" onChange={this.onFileChange} ref={this.fileField} multiple></input>
                        <span className="browse-btn" style={{color:"#042d99"}} >Browse..</span>
                    <span className="file-info">
                    <label  style={{padding:'5px',fontSize:"16px"}}>{this.state.fileNames==""?"Click on browse to upload multiple files":this.state.fileNames}</label>
                    </span>
                  </div>
            </Col>
            </Row>
            </Container>
            <Container className="section" hidden={!this.state.isOtpSent?!(!this.state.editingEnabled || this.state.isOtpSent):!(this.state.editingEnabled && this.state.isOtpSent)} onFocus={window.scrollTo(0, 0)}>
            <Row className="inputRow">
            <Col md="12" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Beneficiary Details </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={this.data.beneficiaryDetails} disabled></input></div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Currency </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={this.data.currency} disabled></input></div>
            </Col>
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Exchange Rate </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={"1 SGD = " + parseFloat(parseFloat(this.data.exchangeRateToCurrencyToSGD).toString().replace(/,/g,"")).toLocaleString(undefined, { maximumFractionDigits: 20 }) + " "+ this.data.currency} disabled></input></div>
            </Col>
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Amount to Transfer </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={parseFloat((parseFloat(this.data.amountToTransfer)).toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }) + " " +this.data.currency + " "} disabled></input></div>
            </Col>
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Amount after Conversion </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={parseFloat(parseFloat(this.data.amountPaidinSGD).toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }) + " SGD"} disabled></input></div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Processing Fee Type </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={this.data.paymentType} disabled></input></div>
            </Col>
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Processing Fee </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={(this.data.feeChargedinSGD?this.data.feeChargedinSGD:"0")+" SGD"} disabled></input></div>
            </Col>
            <Col md="6" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Grand Total </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={(parseFloat((parseFloat(this.data.feeChargedinSGD)+parseFloat(this.data.amountPaidinSGD))).toLocaleString(undefined, {  maximumFractionDigits: 20 }))+" SGD"} disabled/></div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Execution Date </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={this.data.executionDate} disabled></input></div>
            </Col>
            <Col md="3" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Purpose of the Payment </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={this.data.purpose} disabled></input></div>
            </Col>
            <Col md="6" className="inputField">
            <div><span style={{color:"#052d99",fontWeight:"bold"}}>Remarks </span><br/></div><div><input style={{background:"transparent",border:"none",color:"#052d99",width: "100%"}}
              value={this.data.internalReference} disabled></input></div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="4" className="inputField">
                <FormGroup check inline>
                  <label className="containerCheck" style={{fontSize:"16px",fontWeight:"bold",color:"#042d99",marginTop:"10px"}}>Would you like to notify the beneficiary?
                  <input type="checkbox" name="notifyBeneficiary" 
                  disabled checked={this.data.notifyBeneficiary}
                  onChange={this.handleChange}/><span className="checkmark"></span></label>
                </FormGroup>
            </Col>
            <Col md="8" className="inputField">
            <label style={{fontSize:"14px",fontWeight:"bold",marginTop:"10px"}}>{this.data.notifyBeneficiary?("An email will be sent to the beneficiary at "+this.data.beneficiaryNotification+"."):"An email will not be sent to the beneficiary."}</label>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="5"></Col>
            <Col md="2" className="inputField">
            <input type="file" multiple className="form-control"/>
            <label style={{paddingLeft:"15px"}} class="custom-file-upload" disabled>{this.data.invoiceFileName}</label>
            </Col>
            </Row>
            <div hidden={!this.state.isOtpSent}>
                 <Row className="inputRow" >
                   <Col md="6"  className="inputField" style={{display:"flex"}}>
                     <h1>Send {this.data.currency+" "+this.data.amountToTransfer+" ( SGD "+(this.data.amountInSGD+this.data.feeChargedinSGD)+" )"}
                     to <br/>{this.data.beneficiaryName+" (A/C No. "+this.data.accountNumber+" )"}</h1>
                    
                   </Col>
                 </Row>
            <Row>
                      <Col className="pr-1" md={{ size: 8, order: 2, offset: 2 }}>
                        <p style={{textAlign: "center"}}>Please enter the OTP sent to your mobile/email to authenticate the transaction</p>
                      </Col>
            </Row>
            <Row>
                      <Col className="pr-1" md={{ size: 3, order: 2, offset: 4 }}>
                      <Input
                            placeholder="4 digit OTP"
                            type="text"
                            name="otp" autoComplete="off"
                            title="OTP"
                            pattern="[0-9]{4}"
                            onChange={this.actionOtp}
                            
                          /><br/>
                          <a href="" onClick={(e)=>e.preventDefault()}><p style={{textAlign: "center"}} onClick={this.sendOtp}>Did not recieve OTP? Resend</p></a>
                      </Col>
            </Row>
            </div>
            </Container>
            <Container className="section" hidden={!this.state.isOtpSent} onFocus={window.scrollTo(0, 0)}>
                 <Row className="inputRow" >
                   <Col md="12"  className="inputField" style={{display:"flex"}}>
                     <h3 style={{color:"#4a4a4a"}}>Send{" "+this.data.currency+" "+this.data.amountToTransfer+" ( SGD "+(parseFloat(this.data.amountPaidinSGD)+parseFloat(this.data.feeChargedinSGD))+" ) "}
                      to<br/><span style={{color:"#052d99"}}>{this.data.beneficiaryName}</span>{" (Account Number "+this.data.accountNumber+")"}</h3>
                   </Col>
                 </Row>
                 <Row className="inputRow" >
                   <Col md="12"  className="inputField" style={{display:"flex"}}>
                     <h6 style={{color:"#9b9b9b"}}>{this.data.purpose+" | Payment Type: "+this.data.paymentType}</h6>
                   </Col>
                 </Row>
                 <Row className="inputRow" >
                   <Col md="4"  className="inputField" style={{display:"flex"}}>
                    <Label style={{fontSize:"15px",color:"#9f9f9f"}}>Enter OTP</Label>
                   </Col>
                 </Row>
                 <Row className="inputRow" >
                   <Col md="3"  className="inputField" style={{display:"flex"}}>
                   <Input
                            placeholder="4 digit OTP"
                            type="text"
                            className="inputText"
                            name="otp"
                            title="OTP"
                            pattern="[0-9]{4}"
                            onChange={this.actionOtp}
                            
                          />
                   </Col>
                 </Row>
                 <Row className="inputRow" >
                   <Col md="6"  className="inputField" style={{display:"flex"}}>
                    <Label style={{fontSize:"15px",color:"#9f9f9f"}}>OTP was sent to your registered mobile number</Label>
                   </Col>
                 </Row>
                 <Row className="inputRow" >
                   <Col md="12"  className="inputField" style={{display:"flex"}}>
                      <Label style={{fontSize:"15px",color:"#9f9f9f",display:"flex"}}>Did not recieve OTP? <a href="" onClick={(e)=>e.preventDefault()}><p style={{textAlign: "center",color:"#052d99"}} onClick={this.sendOtp}>&nbsp;Resend</p></a></Label>
                   </Col>
                 </Row>
            </Container>
          </Container>
          <div style={{backgroundColor:"#eacccc",padding:"5px",marginLeft:"250px"}} hidden={!this.state.isOtpSent}>
              <p style={{paddingLeft:"30px",margin:"0px",fontFamily: "Karla",color: "#585859"}}>Once confirmed, the payment cannot be reverted back.</p>
            </div>
          <Container className="pageFooter" style={{marginRight:"0px",paddingRight:"0px"}}>
              <Row className="inputRow" hidden={!this.state.isOtpSent?!(this.state.editingEnabled || this.state.isOtpSent):!(this.state.editingEnabled && this.state.isOtpSent)}>
              <Col className="inputField" md="6"></Col>
                <Col className="inputField" md="6">
                  <Link to="/dashboard"><Button className="pageButton secondary" size="sm" style={{margin:"10px"}}>Cancel</Button></Link>
                  <Button className="pageButton primary" size="sm" onMouseEnter={(e)=>e.target.classList.add("activeState")} onMouseLeave={(e)=>e.target.classList.remove("activeState")} onClick={this.saveNextCheck} style={{margin:"10px",width:"150px"}}>Preview</Button>
              </Col>
              </Row>
              <Row className="inputRow" hidden={!this.state.isOtpSent?!(!this.state.editingEnabled || this.state.isOtpSent):!(this.state.editingEnabled && this.state.isOtpSent)}>
              <Col className="inputField" md="4"></Col>
              <Col className="inputField" md="8">
                <Button className="pageButton secondary"  size="sm" hidden={this.state.isSaved} disabled={this.state.isSaved} onClick={()=>this.setState({editingEnabled:true})}>Edit</Button>{' '}
                <Button className="pageButton primary" size="sm" onMouseEnter={(e)=>e.target.classList.add("activeState")} onMouseLeave={(e)=>e.target.classList.remove("activeState")} onClick={this.onSubmit}>Submit</Button>{' '}
                <Button className="pageButton secondary" size="sm" style={{width:"150px"}} onClick={this.onSaveLater}>Save for Later</Button>
              </Col>
              </Row>
          </Container>
          </Form>
          </BlockUi>
          </>
        )
    }
}
export default AddTransaction;