import React from "react";
import axios from 'axios';
import NumberFormat from "react-number-format";
import { InputGroup, Button, Label, Row, Col } from 'reactstrap';
import Spacer from '../../components/Spacer/Spacer';
import swapArrow from '../../assets/img/swapArrow.svg'

// import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";

const baseURL = process.env.REACT_APP_IS_IN_PROD === "true" ? process.env.REACT_APP_PROD_API_BASE_URL : process.env.REACT_APP_DEV_API_BASE_URL;

class ExchangeRateCalculator extends React.Component {
    state = {
        fromCurrency: "SGD",
        toCurrency: undefined,
        fromAmount: undefined,
        toAmount: undefined,
        dropdownOpen: false,
        conversionRate: undefined,
        updateTimeOfRates: undefined,
        leftAmount: 1,
        rightAmount: undefined,
        leftCurrency: "SGD",
        rightCurrency: undefined,
        isRateCalculatorFlipped: false  
    }

    willUpdateState = false;

    componentDidMount() {
        axios.create().get(baseURL + "/payment/conversion-rates").then((res) => {
            if (res.data.status === 200) {
                delete res.data.latestConversionRates['MMKSIN'];
                delete res.data.latestConversionRates['MMKSIN'];
                this.setState({conversionRate: res.data.latestConversionRates, updateTimeOfRates: res.data.lastUpdatedDetails});
            }
        })
    }

    selectCurrency = (event) => {
        this.setState({toCurrency: event.target.value, rightCurrency: event.target.value})
        this.setState((prevState) => ({toAmount: prevState.fromAmount/prevState.conversionRate[prevState.toCurrency + "SIN"]}), () => this.updateConvertedRate())

    }

    updateConvertedRate() {
        this.state.isRateCalculatorFlipped 
        ? this.setState({rightAmount: (1*this.state.conversionRate[this.state.rightCurrency + "SIN"]).toFixed(4)})
        : this.setState({rightAmount: (1/this.state.conversionRate[this.state.rightCurrency + "SIN"]).toFixed(4)})
    }

    flipRate = () => {
        this.setState((prevState) => ({isRateCalculatorFlipped: !prevState.isRateCalculatorFlipped}), () => this.updateConvertedRate())
    }

    render() {
        return (
            this.state.conversionRate 
            ?
            <div className="col-sm-10" style={{fontFamily: "Karla"}}>
                {/* <Spacer height={100} /> */}
                <Row>
                    <Col sm={1}/>
                    <label className="logoImgTextLogin"><b>Exchange Rate Calculator</b></label>
                </Row>
                <Spacer height={40} />
                <InputGroup className="col-sm-12">
                    <Label sm={2} style={{color: "darkred", fontSize: "1em"}}> From</Label>
                    <NumberFormat
                        className="col-sm-7"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={4}
                        value={this.state.fromAmount}
                        onValueChange={(values) => {this.setState({fromAmount: values.floatValue, toAmount: values.floatValue/this.state.conversionRate[this.state.toCurrency + "SIN"]});}}
                    />
                    <Button className="col-sm-3" style={{ textAlign: "left", paddingLeft:"1rem"}} active>{this.state.fromCurrency}</Button>
                </InputGroup>
                <Spacer height={20}/>
                <InputGroup className="col-sm-12">
                    <Label sm={2} style={{color: "darkred", fontSize: "1em"}}> To</Label>
                    <NumberFormat
                        className="col-sm-7"
                        thousandSeparator={true}
                        allowNegative={false}
                        value={this.state.toAmount}
                        decimalScale={4}
                        onValueChange={(values) => {this.setState({toAmount: values.floatValue, fromAmount: values.floatValue*this.state.conversionRate[this.state.toCurrency + "SIN"]});}}
                    />
                    <select style={{ height:"38px", textAlign: "left"}} className="btn btn-secondary active col-sm-3" onChange={this.selectCurrency}>
                        <option selected disabled>SELECT</option>
                        {Object.keys(this.state.conversionRate).sort().map((key) => <option>{key.substring(0,3)}</option>)}
                    </select>
                </InputGroup>
                <Spacer height={40} />
                <Row>
                    <Col sm={2}> </Col> 
                    <Col sm={4} style={{backgroundColor: "#f3f3f3", color: "darkred", fontSize: "1.1em", textAlign: "center", borderRadius: "2em"}}>
                        <Spacer height={40} />
                        <Col className="col-sm-12">
                            <NumberFormat 
                                thousandSeparator={true}
                                value={this.state.leftAmount}
                                displayType="text"
                            />
                        </Col>
                        <Spacer height={15} />
                        <Col className="col-sm-12">
                            <b>{this.state.isRateCalculatorFlipped ? this.state.rightCurrency : this.state.leftCurrency}</b>
                        </Col>
                        <Spacer height={40} />
                    </Col>
                    <Col sm={1} onClick={this.flipRate} style={{margin: "auto", color: "darkred"}}>
                        <img src={swapArrow} />
                    </Col>
                    <Col sm={4} style={{backgroundColor: "#f3f3f3", color: "darkred", fontSize: "1.1em", textAlign: "center", borderRadius: "2em"}}>
                        <Spacer height={40} />
                        <Col className="col-sm-12">
                            <NumberFormat 
                                thousandSeparator={true}
                                value={this.state.rightAmount}
                                displayType="text"
                            />
                        </Col>
                        <Spacer height={15} />
                        <Col className="col-sm-12">
                            <b>{this.state.isRateCalculatorFlipped ? this.state.leftCurrency : this.state.rightCurrency}</b>
                        </Col>
                        <Spacer height={40} />
                    </Col>
                    <Col sm={1}/>
                </Row>
                <Spacer height={30}/>
                <Row style={{textAlign: "center", fontSize: "0.6em"}}>
                    <Col>
                    <strong>{this.state.toCurrency && "Rate is last Updated at: " + new Date(this.state.updateTimeOfRates[this.state.toCurrency + "SIN"]).toLocaleString()}</strong>
                    </Col>
                </Row>
                <Spacer height={10}/>
                <Row style={{textAlign: "center", fontSize: "0.6em"}}>
                    <Col>
                    *Rates are subjected to change on confirmation.
                    </Col>
                </Row>
                <Spacer height={50}/>
            </div>
            : this.props.background
        )
    }
}

export default ExchangeRateCalculator;