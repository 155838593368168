import ListBeneficiary from 'views/beneficiary/listBeneficiary';
import AddBeneficiary from 'views/beneficiary/addBeneficiary';

var beneficiaryRoutes = [
  {
    path: "/beneficiary/add",
    name: "Add Beneficiary",
    component: AddBeneficiary
  },
  {
    path: "/beneficiary/list",
    name: "List Beneficiary",
    component: ListBeneficiary
  },
  { redirect: true, path: "/beneficiary", pathTo: "/beneficiary/list", name: "List Beneficiary" }
]

export default beneficiaryRoutes;
