import React  from "react";
import {Button, Row, Col, Modal, ModalHeader, UncontrolledTooltip, Form, ModalBody, ModalFooter,FormGroup,Input  } from "reactstrap";
import axios from 'axios';
import currency from "variables/currency.js";
import ConfirmationModal from "components/modals/confirmationModal.jsx";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {Redirect, Link } from "react-router-dom";
import moment from "moment";
import info from "assets/img/info.svg";
import swapArrow from "assets/img/swapArrow.svg";
import currencyHome from "variables/currencyHome.jsx";
import Notification from "components/modals/Notification";


class TransactionModal extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
      currency:"",
      feeChargedinSGD:"",
      currency:"",feeChargedinSGD:"",
      amountToTransferDisabled:false,
      amountPaidinSGDDisabled:false,
      processingFeeSelect:true, 
      currencySelect:true ,
      modal: false,
      editingDisabled:true,
      isOtpSent:false,
      loading:false,
      loaderMessage:""
    };
    this.getCurrencyFeeData();
    this.latestConversionRates       = [];
    this.toggle                      = this.toggle.bind(this);
    this.okHandle                    = this.okHandle.bind(this);
    this.saveHandle                  = this.saveHandle.bind(this);
    this.handleChange                = this.handleChange.bind(this);
    this.sendOtp                     = this.sendOtp.bind(this);
    this.verifyOtp                   = this.verifyOtp.bind(this);
    this.actionOtp                   = this.actionOtp.bind(this);
    this.findRate                    = this.findRate.bind(this);
    this.significantDigitFunction    = this.significantDigitFunction.bind(this);
    this.errorModel                  = React.createRef();
    this.modalRef                    = React.createRef();
    this.amountToTransfer            = '';
    this.currency                    = '';
    this.paymentType                 = '';               //Ekansh
    this.feeChargedinSGD             = '';
    this.amountPaidinSGD             = '';
    this.amountPaidinSGD             = '';
    this.exchangeRateToCurrencyToSGD = '';
    this.data = {
      paymentId:"",
      amountToTransfer:"",
      currency:"",
      executionDate:"",
      purpose:"",
      speed:"express",
      internalReference:"",
      amountPaidinSGD:"",
      feeChargedinSGD:"",
      exchangeRateToCurrencyToSGD:"",
      paymentType:"",
    }
    this.currencyExchangeFeeModal = []
  }
  
  getCurrencyFeeData=()=>{
    const currencyDataUrl= (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/fee";
    axios.get(currencyDataUrl).then(res =>{
      if(res.status==200){
        this.currencyExchangeFeeModal = res.data.currencyExchangeModelList
        this.forceUpdate();
      }
    }).catch(function (error) {
    })
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  
  okHandle(){
    if(this.state.editingDisabled){
      this.setState({isOtpSent:false})
    this.toggle()
    }
    else{
      this.setState({editingDisabled:true,isOtpSent:false})
      this.toggle()
    }
  }

  findRate(currency,amount){
    let selecter = "lessThan1k"
    if(parseFloat(amount)<1000){
      selecter = "lessThan1k"
    }
    if(parseFloat(amount)>=1000 && parseFloat(amount)<=4999){
      selecter = "lessThan5k";
    }
    if(parseFloat(amount)>=5000 && parseFloat(amount)<=9999){
      selecter = "lessThan10k";
    }
    if(parseFloat(amount)>=10000 && parseFloat(amount)<=14999){
      selecter = "lessThan15k";
    }
    if(parseFloat(amount)>=15000 && parseFloat(amount)<=19999){
      selecter = "lessThan20k";
    }
    if(parseFloat(amount)>=20000 && parseFloat(amount)<=24999){
      selecter = "lessThan25k";
    }
    if(parseFloat(amount)>=25000 && parseFloat(amount)<=29999){
      selecter = "lessThan30k";
    }
    if(parseFloat(amount)>=30000 && parseFloat(amount)<=34999){
      selecter = "lessThan35k";
    }
    if(parseFloat(amount)>=35000 && parseFloat(amount)<=39999){
      selecter = "lessThan40k";
    }
    if(parseFloat(amount)>=40000 && parseFloat(amount)<=44999){
      selecter = "lessThan45k";
    }
    if(parseFloat(amount)>=45000 && parseFloat(amount)<=49999){
      selecter = "lessThan50k";
    }
    if(parseFloat(amount)-50000>=0){
      selecter = "moreThan50k";
    }
    return this.currencyExchangeFeeModal[currency][selecter]
  }

  

  renderOptions(){
    if(this.currency!=""){
      let optionsSHA = <option value="SHA">SHA</option>
      let optionsSHAOUR = <><option value="SHA">SHA</option><option value="OUR">OUR</option></>
      if(this.currencyExchangeFeeModal[this.currency].totalCharges!=0){
        return optionsSHAOUR
      }
      else{
        return optionsSHA
      }
    }
  }

  refreshValues(){
    this.setState({currency:"", amountPaidinSGDDisabled:false, amountToTransferDisabled:false, amountToTransfer:"", amountPaidinSGD:"", feeChargedinSGD:"", paymentType:"", processingFeeSelect:true, currencySelect:true})
    this.amountToTransfer = ""
    this.amountPaidinSGD = ""
    this.currency = ""
  }


  saveHandle(e){
     e.preventDefault();
    if(this.state.editingDisabled && this.props.payment.paymentStatus==="Draft"){
      this.setState({editingDisabled:false})
    }
    else{
      this.onSave()
    } 
  }

  significantDigitFunction(number){
    let x = Math.abs(parseFloat(number))
    let floorValue = Math.floor(x)
    let diff = x - floorValue
    let i = 0
    while ((diff * Math.pow(10, i + 1)) < 1) {
      i += 1
      if (i > 10) break
    }
    return x.toFixed(Math.min(i + 4, 10))
  }

  currencyToSGDConverter(amountToTransfer){
    let currencyString = this.currency+"SIN";
    let currencyRate   = this.latestConversionRates[currencyString];
    let mwaRate        = this.findRate(this.currency, (amountToTransfer*currencyRate))
    let netRate        = this.significantDigitFunction(currencyRate*(1+(mwaRate/100)))
    let finalAmount    = (amountToTransfer*netRate).toFixed(2);

    this.setState({amountPaidinSGD:finalAmount,exchangeRateToCurrencyToSGD:netRate});
    this.data.amountPaidinSGD        = finalAmount;
    this.data.exchangeRateToCurrencyToSGD = netRate;
    this.amountPaidinSGD             = finalAmount;
    this.exchangeRateToCurrencyToSGD = netRate;
  }

  SGDToCurrencyConverter(amountPaidinSGD){
        let mwaRate        = this.findRate(this.currency, amountPaidinSGD)
        let currencyString = this.currency+"SIN";
        let currencyRate   = this.latestConversionRates[currencyString];
        let netRate        = this.significantDigitFunction(currencyRate*(1+(mwaRate/100)))
        let finalAmount    = (amountPaidinSGD/netRate).toFixed(2);

        this.setState({amountToTransfer:finalAmount,exchangeRateToCurrencyToSGD:netRate});
        
        this.data.amountToTransfer            = finalAmount;
        this.data.exchangeRateToCurrencyToSGD = netRate;
        this.amountToTransfer            = finalAmount;
        this.exchangeRateToCurrencyToSGD = netRate;
  }

  handleChange = (event) => {
    if(event.target.type=="text"){
      event.target.classList.remove('valid','invalid')
      event.target.classList.add('activeFocus')
    }
    this.data[event.target.name]=event.target.value
    if(event.target.name==="currency"){
      this.refreshValues()
      this.setState({[event.target.name]: event.target.value,processingFeeSelect:true, currencySelect:false,feeChargedinSGD:""})
      const url=(process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/conversion-rates";
      axios.get(url).then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      if(res.data.status == 200){
        this.latestConversionRates = res.data.latestConversionRates;
        this.currency = this.state.currency?this.state.currency:"";
        this.forceUpdate();
      }
      }).catch(function (error) {
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
      })
    }
    if(event.target.name==="amountToTransfer"){
      if(event.target.value==""){
        this.amountToTransfer = 0
        this.setState({[event.target.name]: 0,amountPaidinSGDDisabled:true})
        this.currencyToSGDConverter(0);
        return
      }
      var regex=/^[0-9,.]+$/g;
      if (!event.target.value.match(regex))
      {
        event.target.value=0;
        return
      }
      if(event.target.value.toString().split(".").length>2){
        event.target.value=0;
        return
      }
      this.amountToTransfer        = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
      this.data[event.target.name] = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
      this.setState({[event.target.name]: event.target.value,amountPaidinSGDDisabled:true})
      this.currencyToSGDConverter(Number(event.target.value.toString().replace(/,/g,"")).toFixed(2).toString())
    }
    
    if(event.target.name==="amountPaidinSGD"){
      if(event.target.value==""){
        this.amountPaidinSGD = 0
        this.setState({[event.target.name]: 0,amountPaidinSGDDisabled:true})
        this.SGDToCurrencyConverter(0);
        return
      }
     
      var regex=/^[0-9,.]+$/g;
          if (!event.target.value.match(regex))
          {
            event.target.value=0;
              return
          }
          if(event.target.value.toString().split(".").length>2){
            event.target.value=0;
            return
          }
          this.amountPaidinSGD         = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.data[event.target.name] = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.setState({[event.target.name]: event.target.value,amountToTransferDisabled:true})
          this.SGDToCurrencyConverter(Number(event.target.value.toString().replace(/,/g,"")).toFixed(2).toString())
    }
   
    if(event.target.name==="paymentType"){
      this.paymentType     = event.target.value
      this.data.feeChargedinSGD = this.currencyExchangeFeeModal[this.currency][event.target.value=="SHA"?"sharedCharges":"totalCharges"]
      this.setState({[event.target.name]: event.target.value,processingFeeSelect:false, feeChargedinSGD:this.currencyExchangeFeeModal[this.currency][event.target.value=="SHA"?"sharedCharges":"totalCharges"]})
    }
   
  }

  onSave(){
    this.data.paymentId=this.props.payment.paymentId;
    this.data.purpose=this.props.payment.purpose;
    this.data.speed=this.props.payment.speed;
    this.data.executionDate=(this.data.executionDate==""?this.props.payment.executionDate:this.data.executionDate);
    this.data.internalReference=this.props.payment.internalReference?this.props.payment.internalReference:"";
    if(!moment(this.data.executionDate).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))){
      this.setState({messageModel:"Execution Date is expired. Please change it."})
      this.errorModel.current.toggle();
      return
    }
    if(this.data.currency==""||this.data.amountToTransfer==""||this.data.amountPaidinSGD==""||this.data.feeChargedinSGD==""||
          this.data.exchangeRateToCurrencyToSGD==""||this.data.paymentType==""){
          this.setState({messageModel:"Enter all the details"})
          this.errorModel.current.toggle();
          return
        }
    if((parseFloat(this.data.amountToTransfer)>parseFloat(this.currency==""?0:currencyHome[this.currency].max) || parseFloat(this.data.amountToTransfer)<parseFloat(this.currency==""?0:currencyHome[this.currency].min))){
      this.setState({messageModel:"Amount should be between "+currencyHome[this.currency].min.toLocaleString()+" and "+currencyHome[this.currency].max.toLocaleString()+" for "+this.currency})
      this.errorModel.current.toggle();
      return
    }
    this.setState({loading:true,loaderMessage:"Saving..."})
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/editpayment";
      axios.post(url,this.data).then(res =>{
        if(res.data.status==200){
          this.setState({loading:false,loaderMessage:"",editingDisabled:true,messageModel:res.data.message})
          this.errorModel.current.toggle();
          window.location.reload();
        }
        else{
         this.setState({loading:false,loaderMessage:"",editingDisabled:true,messageModel:res.data.message})
         this.errorModel.current.toggle();
        }
        }).catch(function (error) {})
  }
  getValidDates(){
    var today = new Date()
    var firstDay;
    var nextDay;
    if(today.getDay()==0){
      // this.date.setDate(this.date.getDate() + 1);
      firstDay = today.setDate(today.getDate() + 1);
      nextDay  = today.setDate(today.getDate() + 2);
    }else if(today.getDay()==5){
      firstDay = today.setDate(today.getDate());
      nextDay  = today.setDate(today.getDate() + 3);
    }else if(today.getDate()==6){
      firstDay = today.setDate(today.getDate() + 2);
      nextDay  = today.setDate(today.getDate() + 3);
    }else{
      firstDay = today.setDate(today.getDate());
      nextDay  = today.setDate(today.getDate() + 1);
    }
    return <><option>{moment(firstDay).format("YYYY-MM-DD")}</option><option>{moment(nextDay).format("YYYY-MM-DD")}</option></>
  }

  sendOtp(){
    this.setState({loading:true,loaderMessage:"Sending OTP..."})
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/otp";
    axios.post(url).then(res =>{
      if(res.data.status==200){
        this.setState({isOtpSent:true,loading:false,loaderMessage:"",messageModel:"OTP sent Successfully"})
        this.errorModel.current.toggle();
      }
      else{
        this.setState({loading:false,loaderMessage:"",messageModel:"There was an error in sending OTP."})
        this.errorModel.current.toggle();
      }
    }).catch(function (error){})
  }
  verifyOtp(otp){
    this.setState({loading:true,loaderMessage:"Verifying OTP..."})
    let data = {
      "otp" : otp,
      "paymentId" : this.props.payment.paymentId
    }
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/verify";
    axios.post(url,data).then(res =>{
      if(res.data.status==200){
        this.setState({loading:false,loaderMessage:""})
        this.modalRef.current.toggle();
      }
      else{
        this.setState({loading:false,loaderMessage:"",messageModel:"There was an error in verifying OTP."})
        this.errorModel.current.toggle();
      }
    }).catch(function (error){})
  }
  actionOtp(e){
    if(e.target.value.length==4){
      this.verifyOtp(e.target.value)
    }
  }

  currencyFormatter = (amount) => {
    if(String(amount).endsWith(".")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+"."
    }
    if(String(amount).endsWith(".0")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".0"
    }
    if(String(amount).endsWith(".00")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".00"
    }
    if(String(amount).endsWith(".000")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".000"
    }
    if(String(amount).endsWith(".0000")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".0000"
    }
    if(String(amount).endsWith(".00000")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".00000"
    }
    return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})
  }

  render() {
    return (
      <>
        <Notification ref={this.errorModel}  modalTitle={this.state.messageModel}/>
        <ConfirmationModal ref={this.modalRef} modalTitle="Payment Summary" payment={this.props.payment} paymentId={this.props.payment.paymentId}/>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" className={this.props.className} backdrop="static">
          <ModalHeader toggle={this.toggle}>{this.props.modalTitle}<span style={{color:"red",fontSize:"12px"}}>
          {!moment(this.props.payment.executionDate).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD")) && this.props.payment.paymentStatus!="Completed"?" Execution Date is expired.":""}</span>
          <span style={{color:"green",fontWeight:"bold" ,fontSize:"12px"}}>{this.props.payment.paymentStatus=="Completed"?" Success":""}</span>
          </ModalHeader>
          <BlockUi tag="div" blocking={this.state.loading} keepInView={true} message={this.state.loaderMessage}>
          <Form>
          <ModalBody>
          
          <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
          <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="beneficiaryName" 
                      title="Beneficiary Name" disabled autoComplete="off"
                      value={this.props.payment.beneficiaryName} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Name</span>
                </div>
            </Col>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="bankName" 
                      title="Bank Name" disabled autoComplete="off"
                      value={this.props.payment.bankName} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Name</span>
                </div>
            </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="accountNumber" 
                      title="Account Number" disabled autoComplete="off"
                      value={this.props.payment.accountNumber} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Account Number</span>
                </div>
           </Col>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="paymentStatus" 
                      title="Payment Status" disabled autoComplete="off"
                      value={(this.props.payment.paymentStatus==="Completed")?"Success":this.props.payment.paymentStatusPrettyName} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Payment Status</span>
                </div>
         </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="customerTransactionId" 
                      title="Transaction Reference Number" disabled autoComplete="off"
                      value={this.props.payment.customerTransactionId?this.props.payment.customerTransactionId:"Yet to enter Transaction Reference Number"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Transaction Reference Number</span>
                </div>
           </Col>
           <Col md="6" className="inputField" >     
           {/* hidden={!this.state.editingDisabled} */}
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="beneficiaryId" 
                      title="Beneficiary Name" disabled autoComplete="off"
                      value={this.props.payment.beneficiaryName?this.props.payment.beneficiaryName:"Beneficiary Name"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Name</span>
                </div>
           </Col>
          
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
            <Col md="3" className="inputField transCurrency" hidden={this.state.editingDisabled}>
                 <select name="currency" required onChange={this.handleChange}> 
                    <option selected={this.state.currencySelect?true:false}  disabled hidden value=''>Select</option>
                    {(this.props.payment.currencies?this.props.payment.currencies:[]).map((prop, key) => {
                                        return <option style={{fontSize:'16px !important'}} value={prop}>{prop}</option> 
                        })}
                 </select><br/>
                <label>{this.currency==""?"Currency":currencyHome[this.currency].name}</label>
            </Col>
            <Col md="3" className="inputField" hidden={!this.state.editingDisabled}>
                  <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="currency" 
                      title="Currency" disabled autoComplete="off"
                      value={this.props.payment.currency} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Currency</span>
                  </div>
            </Col>
            
            <Col md="4" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                   <input type="text" id="homeCurrency" className="inputText"
                    name="amountToTransfer" autoComplete="off" 
                    pattern="[^.][0-9.]+"
                    onChange={this.handleChange} 
                    value={this.state.amountToTransfer?this.currencyFormatter(this.state.amountToTransfer):""}
                    // {(this.state.amountToTransfer?parseFloat(this.state.amountToTransfer.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
                    title="Amount To Transfer" disabled={(this.currency==""?true:false)}/>
                   <span className="spanTop" onClick={(e)=>{e.target.previousElementSibling.focus()}}>Amount {this.currency==""?"":(" in "+this.currency)}
                   </span><br/>
                </div>
            </Col>
            <Col md="4" className="inputField" hidden={!this.state.editingDisabled}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="amountToTransfer" 
                      title="Amount To Transfer" disabled autoComplete="off"
                      value={Number(this.props.payment.amountToTransfer?this.props.payment.amountToTransfer:0)}
                      />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    >Amount to Transfer</span>
            </div>
            </Col>
            <Col md="1">
                <img src={swapArrow} height="35px" width="35"/>
            </Col>
            
            <Col md="4" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                   <input type="text" id="homeCurrency"  className="inputText"
                    name="amountPaidinSGD" autoComplete="off"
                    pattern="[^.][0-9.]+" 
                    onChange={this.handleChange} 
                    value={this.state.amountPaidinSGD?this.currencyFormatter(this.state.amountPaidinSGD):""}
                    // {(this.state.amountPaidinSGD?parseFloat(this.state.amountPaidinSGD.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
                    title="Amount to be Paid in SGD" disabled={(this.currency==""?true:false)}/>
                   <span className="spanTop" onClick={(e)=>{e.target.previousElementSibling.focus()}}>Amount in SGD
                   </span>
                </div>
            </Col>
            <Col md="4" className="inputField" hidden={!this.state.editingDisabled}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="amountPaidinSGD"
                      title="Amount to be Paid in SGD" disabled autoComplete="off"
                      value={Number(this.props.payment.amountPaidinSGD?this.props.payment.amountPaidinSGD:0)}
                      />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}
                    >Amount to be Paid in SGD</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
            <Col md="3" className="inputField paymentType" hidden={this.state.editingDisabled}>
                    <select name="paymentType"  title="Processing Fee Type" disabled={(this.currency==""?true:false)} onChange={this.handleChange} required>
                      <option value="" disabled hidden selected={this.state.processingFeeSelect?true:false}>Select</option>
                      {this.state.currency?this.renderOptions():""}
                    </select><br/>
                    <label >Processing Fee Type</label>
            </Col>
            <Col md="3" className="inputField" hidden={!this.state.editingDisabled}>
            <div className="inputGroup">
                    <input type="text" id="homeCurrency" className="inputText" 
                      name="paymentType" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Payment Type" disabled autoComplete="off"
                      value={this.props.payment.paymentType}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Payment Type</span>
            </div>
            </Col>
            <Col md="4" className="inputField" hidden={this.state.editingDisabled}>
            <span style={{color:"#052d99",fontWeight:"bold"}}>Processing Fee: <br/><input style={{background:"transparent",border:"none",color:"#052d99"}}
              value={(this.state.feeChargedinSGD?this.state.feeChargedinSGD:"0")+" SGD"} disabled></input></span>
            </Col>
            <Col md="4" className="inputField" hidden={!this.state.editingDisabled}>
            <span style={{color:"#052d99",fontWeight:"bold"}}>Processing Fee: <br/><input style={{background:"transparent",border:"none",color:"#052d99"}}
              value={this.props.payment.feeChargedinSGD+" SGD"} disabled></input></span>
            </Col>
            <Col md="5" className="inputField" hidden={this.state.editingDisabled}>
            <span style={{color:"#052d99",fontWeight:"bold"}}>Grand Total: <br/><input style={{background:"transparent",border:"none",color:"#052d99"}}
              value={this.state.feeChargedinSGD?(parseFloat(this.state.amountPaidinSGD?this.state.amountPaidinSGD:0)+parseFloat(this.state.feeChargedinSGD)):0+" SGD"} disabled/></span>
            </Col>
            <Col md="5" className="inputField" hidden={!this.state.editingDisabled}>
            <span style={{color:"#052d99",fontWeight:"bold"}}>Grand Total: <br/><input style={{background:"transparent",border:"none",color:"#052d99"}}
              value={(parseFloat(this.props.payment.amountPaidinSGD)+parseFloat(this.props.payment.feeChargedinSGD))+" SGD"} disabled/></span>
            </Col>
            </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
            <Col md="6" className="inputField executionDate" hidden={this.state.editingDisabled}>
              <select name="executionDate"  title="Execution Date" onChange={this.handleChange} required>
                <option value="" disabled selected>Select</option>
                {this.getValidDates()}
              </select><br/>
              <label id="executionDateID">Execution Date<img src={info} height={15} width={15}  style={{marginLeft:"5px"}}/></label>
              <UncontrolledTooltip placement="right" target="executionDateID">
              The timeframe within which the customer has to transfer the amount in SGD to MWA Account.
            </UncontrolledTooltip>
            </Col>
            <Col md="6" className="inputField" hidden={!this.state.editingDisabled}>
               <div className="inputGroup">
                       <input type="text" id="homeCurrency" className="inputText" 
                         name="executionDate" 
                         title="Execution Date" disabled autoComplete="off"
                         value={this.props.payment.executionDate}/>
                       <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Execution Date</span>
               </div>
            </Col>
            <Col md="6" className="inputField">
               <div className="inputGroup">
                       <input type="text" id="homeCurrency" className="inputText" 
                         name="internalReference" 
                         title="Internal Reference" disabled autoComplete="off"
                         value={this.props.payment.internalReference}/>
                       <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Remark (Optional)</span>
               </div>
            </Col>
         </Row>
         <Row  className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
         <Col md="12" className="inputField">
               <div className="inputGroup">
                       <input type="text" id="homeCurrency" className="inputText" 
                         name="purpose" 
                         title="Purpose" disabled autoComplete="off"
                         value={this.props.payment.purpose}/>
                       <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Purpose</span>
               </div>
          </Col>
         </Row>
         <div hidden={this.props.payment.paymentStatus==="Completed" || this.props.payment.paymentStatus==="Failed"?false:true}>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                          <input type="text" id="homeCurrency" className="inputText" 
                            name="acknowledgementId" 
                            title="Acknowledgement Reference Number" disabled autoComplete="off"
                            value={this.props.payment.acknowledgementId?this.props.payment.acknowledgementId:"Not Generated yet"}/>
                          <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Acknowledgement Reference Number</span>
                  </div>
             </Col>
              <Col md="6" className="inputField" hidden={this.props.payment.acknowledgementId===""}>
                  <Button
                    className="pageButton secondary" style={{width:"300px",margin:"0px",marginTop:"5px",marginRight:"10px"}}
                    name="customerTransactionId"
                    title="Customer Transaction Id"
                    onClick={()=>window.open(this.props.payment.acknowlegdementFileName)}
                  >
                    Download Acknowledgement Recipt
                  </Button>
              </Col>
         </Row>
         </div>
         <div hidden={!this.state.isOtpSent}>
            <Row>
                      <Col md="12">
                        <hr style={{borderTop:"1px solid"}}/>
                      </Col>
            </Row>
            <Row>
                      <Col  md={{ size: 8, order: 2, offset: 2 }}>
                        <p style={{textAlign: "center"}}>Please enter the OTP sent to your mobile/email to authenticate the transaction</p>
                      </Col>
            </Row>
            <Row>
                      <Col md={{ size: 4, order: 2, offset: 4 }}>
                      <Input type="text" className="inputText"
                            placeholder="4 digit OTP"
                            type="text"
                            name="otp"
                            title="OTP"
                            pattern="[0-9]{4}"
                            onChange={this.actionOtp}
                            
                          /><br/>
                          <a href="" onClick={(e)=>e.preventDefault()}><p style={{textAlign: "center"}} onClick={this.sendOtp}>Did not recieve OTP? Resend</p></a>
                      </Col>
            </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <span hidden={!this.props.edit}>
            <Button className="pageButton secondary"  size="lg" style={{"width": "250px"}} hidden={(((this.props.payment.paymentStatus=="Draft" || this.props.payment.paymentStatus=="Initialised" || this.props.payment.paymentStatus=="OpsApproved" || this.props.payment.paymentStatus=="OpsRejected" || this.props.payment.paymentStatus=="ConfirmationPending") && this.props.payment.transactionType==="OUTWARD")||((this.props.payment.paymentStatus=="Initialised"||this.props.payment.paymentStatus=="BankInstructionGenerated")&& this.props.payment.transactionType==="INWARD")?true:false)} 
                  disabled={(((this.props.payment.paymentStatus=="Draft" || this.props.payment.paymentStatus=="Initialised" || this.props.payment.paymentStatus=="OpsApproved" || this.props.payment.paymentStatus=="OpsRejected" || this.props.payment.paymentStatus=="ConfirmationPending") && this.props.payment.transactionType==="OUTWARD")||((this.props.payment.paymentStatus=="Initialised"||this.props.payment.paymentStatus=="BankInstructionGenerated")&& this.props.payment.transactionType==="INWARD")?true:false)} onClick={(()=>{window.open(this.props.payment.transactionReciptURL, '_blank'); return false;})}>Download Transaction Recipt</Button>
            <Button className="pageButton secondary"  size="sm" hidden={!(this.props.payment.paymentStatus=="Draft" && moment(this.props.payment.executionDate).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))?true:false)} disabled={!(this.props.payment.paymentStatus=="Draft" && moment(this.props.payment.executionDate).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))?true:false)} onClick={this.sendOtp}>Enter OTP</Button>
            <Button className="pageButton secondary"  style={{width:"200px"}} hidden={this.props.payment.paymentStatus!=="Initialised"} disabled={this.props.payment.paymentStatus!=="Initialised"} onClick={()=>this.modalRef.current.toggle()}>Enter Transaction ID</Button>{' '}
            {/* this.props.payment.paymentStatus=="Initialised"?(this.props.payment.isCustAndBenApproved?false:true):(this.props.payment.paymentStatus!=="OpsApproved") */}
            <Button className="pageButton secondary"  size="sm" hidden={!(this.props.payment.paymentStatus=="Draft")} disabled={!(this.props.payment.paymentStatus=="Draft")} onClick={this.saveHandle} type="submit">{this.state.editingDisabled?"Edit":"Save"}</Button>
            {' '}</span>
            <Button className="pageButton secondary"  size="sm" onClick={this.okHandle}>{this.state.editingDisabled?"OK":"Cancel"}</Button>
          </ModalFooter>
        </Form>
        </BlockUi>
        </Modal>
      </>
    );
  }
}

export default TransactionModal;
// || !this.props.payment.isTransactionApprovedByOps
// {!(this.props.payment.paymentStatus=="OpsApproved") || !this.props.payment.isTransactionApprovedByOps}