import React from "react";
import { Container, Row, Col,Breadcrumb, BreadcrumbItem, Button,Form } from 'reactstrap';
import axios from 'axios';
import mime from "mime-types";
import { Storage } from 'aws-amplify';
import uuid from "uuid";
import { currencyG7, currencyAsian } from "variables/currencyHome.jsx";
import BeneficiaryCancelModal from "components/modals/beneficiaryCancelModal";
import BeneficiarySubmitModal from "components/modals/beneficiarySubmitModal";
import BlockUi from 'react-block-ui';
import country from "variables/Country.js";
import 'react-block-ui/style.css';
import Notification from "components/modals/Notification";
import {nationality} from "variables/PhoneCode.js";
import moment from 'moment';
import DatePicker from 'react-date-picker';


class AddBeneficiary extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      loaderMessage:"",
      data:"",
      customer: undefined,
      
    };
    this.beneficiaryCurrencies = [];
    this.fileField             = React.createRef();
    this.fileLabel             = React.createRef();
    this.modalSave             = React.createRef();
    this.accError              = React.createRef(); 
    this.currenciesError       = React.createRef();
    this.uploadFile            = this.uploadFile.bind(this);
    this.onFileChange          = this.onFileChange.bind(this);
    this.onBlur                = this.onBlur.bind(this);
    this.today                 = new Date();
  }

  onFocus(event){
    if(event.target.disabled)return
    event.target.nextElementSibling.classList.remove('spanMiddle')
    event.target.nextElementSibling.classList.add('spanTop')
    if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
  }

  onBlur(event){
    if(event.target.name=="re_accountNumber"){
      this.checkAccountNumber()
    }
    event.target.classList.remove('activeFocus','invalid','valid')
    event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
    if(event.target.value==""){
      event.target.classList.remove('activeFocus','invalid','valid')
      event.target.nextElementSibling.classList.remove('spanTop')
      event.target.nextElementSibling.classList.add('spanMiddle')
      }
      if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
  }

  onFileChange(e){
    this.fileLabel.current.innerHTML=e.target.files[0].name  
  } 

  uploadFile(file, callback, errorCallback) {
    const fileName = uuid.v4()+"."+mime.extension(mime.contentType(file.name))
    Storage.vault.put(fileName, file)
    .then (callback) 
    .catch(errorCallback);    
  }

  handleChange = (event) => {
    if(event.target.type=="text"){
      event.target.classList.remove('valid','invalid')
      event.target.classList.add('activeFocus')
    }
    let eventName = event.target.name
    this.setState({ [event.target.name]:event.target.value },()=>{
      if(this.state.customer && this.state.relation==="SELF" && eventName === "relation"){
        let address = (this.state.customer.block !== undefined && this.state.customer.block !== "")?this.state.customer.block:""
        address += (this.state.customer.street !== undefined && this.state.customer.street !== "")?(", "+this.state.customer.street):""
        address += (this.state.customer.street2 !== undefined && this.state.customer.street2 !== "")?(", "+this.state.customer.street2):""
        address += (this.state.customer.building !== undefined && this.state.customer.building !== "")?(", "+this.state.customer.building):""
        address += (this.state.customer.floor !== undefined && this.state.customer.floor !== "")?(", "+this.state.customer.floor):""
        address += (this.state.customer.apartmentNumber !== undefined && this.state.customer.apartmentNumber !== "")?((this.state.customer.floor!=undefined?"- ":"")+ this.state.customer.apartmentNumber):""
        address += (this.state.customer.country !== undefined && this.state.customer.country !== "")?(", "+this.state.customer.country):""
        address += (this.state.customer.postal !== undefined && this.state.customer.postal !== "")?(", "+this.state.customer.postal):""
        this.setState({
            name:this.state.customer.profileType === "Individual"?this.state.customer.name:this.state.customer.registeredBusinessName,
            beneficiaryIdentificationNumber:this.state.customer.profileType !== "Individual"?this.state.customer.busiessRegistrationNumber:(this.state.customer.myInfoNRIC == undefined?this.state.customer.idNumber:this.state.customer.myInfoNRIC),
            contactNumber: this.state.customer.profileType === "Individual"?this.state.customer.phoneNumber.toString():this.state.customer.contactNumber.toString(),
            emailId: this.state.customer.email,
            address: address,
            nationality: this.state.customer.nationality
        })
    }
    });
  }
    
  fileName = (name) => {
    return this.state === null || this.state.name === null ? "Upload file" : this.state[name];
  }

  checkAccountNumber=()=>{
    if(this.state.accountNumber && this.state.re_accountNumber && this.state.accountNumber!=this.state.re_accountNumber){
      this.accError.current.toggle();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0,0);
    this.setState({ loading:true,loaderMessage:"Creating Beneficiary..."})
    if(this.state.accountNumber && this.state.re_accountNumber && this.state.accountNumber!=this.state.re_accountNumber && this.beneficiaryCurrencies==[]){
      this.accError.current.toggle();
      this.setState({ loading:false,loaderMessage:""})
      return
    }

    if(this.beneficiaryCurrencies.length == 0){
      this.currenciesError.current.toggle();
      this.setState({ loading:false,loaderMessage:""})
      return
    }

    if(this.fileField.current.files.length != 0) {
      this.uploadFile(this.fileField.current.files[0], (result) => {
        this.setState({beneficiaryIdFilename:result.key})
        this.saveBeneficiary();
      })
    }
    else{
      this.saveBeneficiary();
    }
  }

  saveBeneficiary(){
    let data = {
      "bankName": this.state.bankName,
      "swiftBIC": this.state.swiftBIC,
      "accountNumber": this.state.accountNumber,
      // "branchCode": this.state.branchCode,
      "address": this.state.address,
      "country":this.state.country,
      "countryCode":this.state.countryCode,
      "name": this.state.name,
      "nickName": "",
      "contactNumber":this.state.contactNumber,
      "emailId": this.state.emailId?this.state.emailId:"",
      "type": this.state.type,
      "relation": this.state.relation,
      "beneficiaryIdFilename": this.state.beneficiaryIdFilename?this.state.beneficiaryIdFilename:"",
      "bankAddressLine1":this.state.bankAddressLine1,
      "bankAddressLine2":this.state.bankAddressLine2,
      "BSBCode":this.state.BSBCode,
      "ABACode":this.state.ABACode,
      "sortCode":this.state.sortCode,
      "IBANCode":this.state.IBANCode,
      "idNumber":this.state.beneficiaryIdentificationNumber,
      "contactPerson":this.state.beneficiaryContactPerson,
      "currencies":this.beneficiaryCurrencies,
      "nationality":this.state.nationality,
      "dateOfBirth":this.state.dateOfBirth

    }
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/beneficiary";
    axios.post(url,data).then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      if(res.data.status==200){
        this.setState({data:res.data})
        this.modalSave.current.toggle()
        this.setState({ loading:false,loaderMessage:""})
      }
      else if(res.data.status==403){
        this.setState({data:res.data});
        this.modalSave.current.toggle();
        this.setState({ loading:false,loaderMessage:""})
      }
      else{
        this.setState({data:res.data});
        this.modalSave.current.toggle()
        this.setState({ loading:false,loaderMessage:""})
      }
      this.setState({ loading:false})
      }).catch(function (error) {
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
      })
  }
  
  toggleCurrencies = (event) => {
    this.beneficiaryCurrencies = []
    var checkBoxes = document.getElementById("beneficiaryCurrencies").getElementsByTagName("INPUT")
    for (var i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].checked = false
        // if (checkBoxes[i].checked){
        //     this.beneficiaryCurrencies.push(checkBoxes[i].value)
        // }
    }
    event.target.checked = true
    this.beneficiaryCurrencies.push(event.target.value)
    this.forceUpdate()
  //  setTimeout(() => {
  //   this.beneficiaryCurrencies = []
  //   var checkBoxes = document.getElementById("beneficiaryCurrencies").getElementsByTagName("INPUT")
  //   for (var i = 0; i < checkBoxes.length; i++) {
  //     if (checkBoxes[i].checked) {
  //         this.beneficiaryCurrencies.push(checkBoxes[i].value)
  //     }
  //   }
  //   this.forceUpdate()
  //   console.log(this.beneficiaryCurrencies)
  //  },100)
  }

  componentDidMount(){
    axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/profile')
    .then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      this.setState({customer:res.data.customer})
      
    }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} }) 
  }

  render(){
    return(
            <>
            <BlockUi tag="div" blocking={this.state.loading} keepInView={true} message={this.state.loaderMessage} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Form onSubmit={this.handleSubmit} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className="mainContent" >
            <Notification ref={this.accError}  modalTitle="Account Number not Matched"/>
            <Notification ref={this.currenciesError}  modalTitle="Select at least one currency."/>
            <Row className="breadcrumbContainer">
               <Col md="12" className="breadcrumbContainerColumn">
               <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                 <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                 <BreadcrumbItem className="breadcrumbItem" tag="a" >Beneficiary</BreadcrumbItem>
                 <BreadcrumbItem className="breadcrumbItem" active tag="span">Add Beneficiary</BreadcrumbItem>
               </Breadcrumb>
               </Col>
            </Row>
            <Row className="pageHeading">
              <Col md="6"  className="pageHeadingColumn">
                <h1>Add a Beneficiary</h1>
              </Col>
            </Row>
            <Container className="section">
            <Row>
                <Col md="12" className="sectionHeading">
                  <h2>Select Relation</h2>
                </Col>
            </Row>
            <Row className="inputRow">
              <Col md="3" className="inputField genderSelect" >
                <label>Relation </label><br/>
                <select required name="relation" onChange={this.handleChange} >
                  <option selected disabled hidden value=''>Select</option>
                  <option value="PARENT">Parent</option>
                  <option value="SPOUSE">Spouse</option>
                  <option value="CHILD">Child</option>
                  <option value="SELF">Self</option>
                  <option value="OTHER">Other</option>
                </select>
              </Col>
            </Row>
            <Row>
                <Col md="12" className="sectionHeading">
                  <h2>Select Currencies</h2>
                </Col>
            </Row>
            <Row className="inputRow">
                <Col md="12" className="inputField" id="beneficiaryCurrencies" style={{marginBottom:"0px"}}>
                    <div style={{padding:"10px"}}>
                      <span style={{display:"inline-block",fontWeight:"bold",width:"150px"}}>G7 Currencies</span>
                    {
                      currencyG7.map((prop,key) => {
                        return <div class="selectContainer">
                                 <input type="checkbox" value={prop} id={key} onClick={this.toggleCurrencies} />
                                 <label for={key}>{prop}</label> {/* onClick={this.toggleCurrencies} */}
                               </div>
                      })
                      
                    }
                    </div>
                    <div style={{padding:"10px"}}>
                    <span style={{display:"inline-block",fontWeight:"bold",width:"150px"}}>Asian Currencies</span>
                    {
                      currencyAsian.map((prop,key) => {
                        return <div class="selectContainer">
                                 <input type="checkbox" value={prop} id={key+currencyG7.length} onClick={this.toggleCurrencies} />
                                 <label for={key+currencyG7.length}>{prop}</label> {/* onClick={this.toggleCurrencies} */}
                               </div>
                      })
                    }
                    </div>
                </Col>
            </Row>
            <Row>
              <Col md="12" className="sectionHeading">
                <h2>Beneficiary Personal Details</h2>
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="name" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[a-zA-Z][a-z\-A-Z\s,.;]+" 
                autoComplete="off"
                title="Name should be alphanumeric"
                value={this.state.name}
                fieldCheck={this.state.name!==undefined?"filled":"unfilled"}
                onChange={this.handleChange}
                required/>
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Name (as per bank account)</span>
            </div>
            </Col>
            <Col md="6" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="beneficiaryIdentificationNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                autoComplete="off" title="Name should be alphanumeric" 
                data-optional={(this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD"))?"false":"true"}
                onChange={this.handleChange}
                value={this.state.beneficiaryIdentificationNumber} 
                disabled={this.state.relation === "SELF"}
                fieldCheck={this.state.beneficiaryIdentificationNumber!==undefined?"filled":"unfilled"}
                required={this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD")}/>
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Identification Number</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="8" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="beneficiaryContactPerson" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[a-zA-Z][a-z\-A-Z\s,.;]+" 
                autoComplete="off"
                title="Name should be alphanumeric"
                onChange={this.handleChange} data-optional={(this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD"))?"false":"true"}
                required={this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD")}/>
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Contact Person</span>
            </div>
            </Col>
            <Col md="4" className="inputField">
                <div className="inputGroup">
                   <input type="text" className="inputText" id="name"
                    name="contactNumber" autoComplete="off" data-optional={(this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD"))?"false":"true"}
                    pattern="[\+\-0-9]+" 
                    onFocus={this.onFocus} onBlur={this.onBlur}
                    onChange={this.handleChange}
                    value={this.state.contactNumber} 
                    disabled={this.state.relation === "SELF"}
                    fieldCheck={this.state.contactNumber!==undefined?"filled":"unfilled"}
                    title="Contact Number" required={this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD")}/>
                   <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Contact No. </span>
                </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="12" className="inputField">
                  <div className="inputGroup">
                      <input type="text" className="inputText" id="emailId"
                         name="emailId" autoComplete="off" data-optional="true"
                         pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]+$"
                         title="Email should of format ekansh.bajpai712@gmail.com"
                         value={this.state.emailId} 
                         disabled={this.state.relation === "SELF"}
                         fieldCheck={this.state.emailId!==undefined?"filled":"unfilled"}
                         onChange={this.handleChange} onFocus={this.onFocus} onBlur={this.onBlur}
                         title="Email ID" />
                      <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Email ID </span>
                  </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="12" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" id="address" autoComplete="off"
                   name="address" onFocus={this.onFocus} onBlur={this.onBlur} 
                   data-optional={(this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD"))?"false":"true"}
                   onChange={this.handleChange}  
                   value={this.state.address} 
                   disabled={this.state.relation === "SELF"}
                   fieldCheck={this.state.address!==undefined?"filled":"unfilled"}
                   required={this.beneficiaryCurrencies.includes("HKD") || this.beneficiaryCurrencies.includes("TWD")}/>
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Address</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
              <Col md="3" className="inputField nounflag" >
                  <select name="nationality" required   onChange={this.handleChange} 
                  required>
                  <option selected disabled hidden value=''>Select</option>
                  {nationality.map((prop, key) => {
                        return <option  value={prop} key={key} selected={prop === this.state.nationality && this.state.relation === "SELF"}>{prop}</option> 
                    })}
                  </select>
                  <label>Nationality</label>
              </Col>
              <Col md="3" className="inputField" style={{marginBottom:"0px"}}>
								<DatePicker 
									dayPlaceholder="Day"
									monthPlaceholder="Month"
									yearPlaceholder="Year"
									value={this.state.dateOfBirth?new Date(this.state.dateOfBirth.split('-')[0],parseInt(this.state.dateOfBirth.split('-')[1])-1,this.state.dateOfBirth.split('-')[2]):null}
									clearIcon={null}
									onChange={(date)=>{this.setState({dateOfBirth: moment(date).format("YYYY-MM-DD")})}}
								/>
								<label style={{color:'#4a4a4a',display:'block'}}>Date of Birth</label>
              </Col>
            </Row>
            </Container>
            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h2>Beneficiary Bank Details</h2>
              </Col>
            </Row>
            <Row className="inputRow">
              {
                !this.beneficiaryCurrencies.includes("MMK") &&
                <Col md="5" className="inputField">
                      <div className="inputGroup">
                          <input type="text" className="inputText" id="bankName" autoComplete="off"
                            name="bankName" onFocus={this.onFocus} onBlur={this.onBlur}
                            onChange={this.handleChange} required/>
                          <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Name</span>
                      </div>
                </Col>
              }
              {
                this.beneficiaryCurrencies.includes("MMK") &&
                <Col md="5" className="inputField countrySelect" >
                    <select name="bankName" required onChange={this.handleChange}>  {/*Priya */}
                      <option selected disabled hidden value=''>Select</option>
                      <option  value="Ayeyarwady Bank (AYA Bank)">Ayeyarwady Bank (AYA Bank)</option> 
                      <option  value="Kanbawza Bank (KBZ Bank)">Kanbawza Bank (KBZ Bank)</option> 
                      <option  value="Co-operative Bank (CB Bank)">Co-operative Bank (CB Bank)</option>
                    </select>
                    <label style={{display:"block"}}>Bank Name</label>
                </Col>
              }
            <Col md="3" className="inputField">
                  <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName" autoComplete="off"
                      name="swiftBIC" onFocus={this.onFocus} onBlur={this.onBlur} data-optional="true"
                      onChange={this.handleChange} pattern="^([a-zA-Z0-9]{8}|[a-zA-Z0-9]{11})$" title="Can contain alphanumeric value of length 8 or 11"
                      data-optional={(this.beneficiaryCurrencies.includes("GBP") || this.beneficiaryCurrencies.includes("EUR") || 
                                      this.beneficiaryCurrencies.includes("AUD") || this.beneficiaryCurrencies.includes("CAD") || 
                                      this.beneficiaryCurrencies.includes("NZD") || this.beneficiaryCurrencies.includes("USD") || 
                                      this.beneficiaryCurrencies.includes("CHF") || this.beneficiaryCurrencies.includes("JPY"))?"false":"true"} 
                      required={(this.beneficiaryCurrencies.includes("GBP") || this.beneficiaryCurrencies.includes("EUR") || 
                                 this.beneficiaryCurrencies.includes("AUD") || this.beneficiaryCurrencies.includes("CAD") || 
                                 this.beneficiaryCurrencies.includes("NZD") || this.beneficiaryCurrencies.includes("USD") || 
                                 this.beneficiaryCurrencies.includes("CHF") || this.beneficiaryCurrencies.includes("JPY"))}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>SWIFT Bank Code</span>
                  </div>
            </Col>
            <Col md="4" className="inputField">
                    <div className="inputGroup">
                      <input type="text" className="inputText" id="bankName" autoComplete="off"
                        name="ABACode" onFocus={this.onFocus} onBlur={this.onBlur}
                        data-optional="true" onChange={this.handleChange} title="ABA/Routing No."/>
                      <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>ABA/Routing No.</span>
                    </div>
              </Col>
            </Row>
            <Row className="inputRow">
              <Col md="4" className="inputField">
                    <div className="inputGroup">
                        <input type="text" className="inputText" id="bankName" autoComplete="off"
                          name="BSBCode" onFocus={this.onFocus} onBlur={this.onBlur}
                          data-optional={(this.beneficiaryCurrencies.includes("AUD"))?"false":"true"}
                          onChange={this.handleChange} required={(this.beneficiaryCurrencies.includes("AUD"))}
                          />
                        <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>BSB Code</span>
                    </div>
              </Col>
              <Col md="4" className="inputField">
                    <div className="inputGroup">
                      <input type="text" className="inputText" id="bankName" autoComplete="off"
                        name="sortCode" onFocus={this.onFocus} onBlur={this.onBlur}
                        data-optional="true" onChange={this.handleChange} title="Sort Code"/>
                      <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Sort Code</span>
                    </div>
              </Col>
              <Col md="4" className="inputField">
                    <div className="inputGroup">
                      <input type="text" className="inputText" id="bankName"
                        name="IBANCode"  title="IBAN Code" autoComplete="off"
                        data-optional={(this.beneficiaryCurrencies.includes("GBP") || this.beneficiaryCurrencies.includes("EUR"))?"false":"true"}
                        onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.handleChange} 
                        required={(this.beneficiaryCurrencies.includes("GBP") || this.beneficiaryCurrencies.includes("EUR"))}/>
                      <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>IBAN Code</span>
                    </div>
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="bankAddressLine1" onFocus={this.onFocus} onBlur={this.onBlur}
                      autoComplete="off"
                      title="Bank Address (Line 1)"
                      onChange={this.handleChange} required/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Address (Line 1)</span>
                  </div>
            </Col>
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="bankAddressLine2" autoComplete="off"
                      onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Bank Address (Line 2)" data-optional="true"
                      onChange={this.handleChange}/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Address (Line 2)</span>
                  </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="accountNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                      pattern="[0-9a-zA-Z]+" autoComplete="off"
                      title="Account Number"
                      onChange={this.handleChange} required/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Account Number</span>
                  </div>
            </Col>
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="re_accountNumber" autoComplete="off"
                      pattern="[0-9a-zA-Z]+" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Account Number"
                      onChange={this.handleChange} required/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Re-Enter Account Number</span>
                  </div>
            </Col>
            </Row>
            <Row className="inputRow">
                     <Col md="6" className="inputField">
                        <div className="input-container" style={{maxWidth: "100%",borderColor:"#4a4a4a"}} onClick={() => this.fileField.current.click()}>
                            <input type="file" name="validId"  accept=".png,.jpeg,.pdf,.jpg" onChange={this.onFileChange} ref={this.fileField}></input>
                            <span className="browse-btn" style={{color:"#042d99"}} >Browse..</span>
                            <span className="file-info">
                              <label  style={{padding:'5px',fontSize:"16px"}} ref={this.fileLabel}>Upload ID (Optional)</label>
                            </span>
                        </div>
                    </Col>
                    <Col md="3" className="inputField">
                        <label>Transaction Type</label><br/>
                        <select required name="type" onChange={this.handleChange} >
                          <option selected disabled hidden value=''>Type</option>
                          <option value="INDIVIDUAL">Individual</option>
                          <option value="COMPANY">Company</option>
                        </select>
                    </Col>
                    <Col md="3" className="inputField countrySelect" >
                        <select name="country" required onChange={this.handleChange}>  {/*Priya */}
                        <option selected disabled hidden value=''>Select</option>
                        {country.map((country, key) => {
                              return <option  value={country}  key={key} >{country}</option> 
                          })}
                        </select>
                        <label>Bank Country</label>
                    </Col>
            </Row>
            </Container>
            
          </Container>
          <Container className="pageFooter" style={{marginRight:"50px"}} hidden={this.state.loading}>
              <Row className="inputRow">
                <Col md="6"></Col>
                <Col md="6">
                  <BeneficiarySubmitModal ref={this.modalSave} modalTitle={this.state.data}/>
                  <Button className="pageButton primary" size="sm" onMouseEnter={(e)=>e.target.classList.add("activeState")} onMouseLeave={(e)=>e.target.classList.remove("activeState")} type="submit" style={{float:"right"}}>Save</Button>
                  <BeneficiaryCancelModal buttonLabel="Cancel"  modalTitle="Are you Sure?" />
                </Col>
              </Row>
          </Container>
          </Form>
          </BlockUi>
          </>
        )
    }
}
export default AddBeneficiary;