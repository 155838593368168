import React from "react";
import { Redirect,Link } from "react-router-dom";
import { Container, Row, Col,Breadcrumb,UncontrolledTooltip,BreadcrumbItem } from 'reactstrap';
import send from "assets/img/send.svg";
import swapArrow from "assets/img/swapArrow.svg";
import addHome from "assets/img/addHome.png";
import Spacer from '../../components/Spacer/Spacer';
import UserProfile from "views/User/UserVerify.jsx";
import currencyHome from "variables/currencyHome.jsx";
import axios from 'axios';
import info from "assets/img/info.svg";
import { FiPlus } from "react-icons/fi";
import moment from 'moment';
import BlockUi from 'react-block-ui';
import Notification from "components/modals/Notification";
import queryString from 'query-string';
  

class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {modal:false, callmodal:false, beneficiary:'', transactions:[], enableLink:true, invalid:false,
        invalidCurreny:false, homePageLoad:true, rate:"", amountInSGD:"0", currency:"", feeChargedinSGD:"", remitterText:'(OUR- remitter pays 20 SGD)', userPageLoad:true,
        amountToTransferDisabled:false, amountPaidinSGDDisabled:false, processingFeeSelect:true, currencySelect:true, redirect:false 
    }
         this.documentModel               = [];
         this.currencies                  = [];
         this.latestConversionRates       = [];
         this.errorModel                  = React.createRef();
         this.getCustomer                 = this.getCustomer.bind(this);
         this.docName                     = '';
         this.displayShowall              = this.displayShowall.bind(this);
         this.displayShowallTrans         = this.displayShowallTrans.bind(this);
         this.payment                     = React.createRef();
         this.handleChange                = this.handleChange.bind(this);
         this.amountToTransfer            = '';
         this.currency                    = '';
         this.paymentType                 = '';             
         this.feeChargedinSGD             = '';
         this.amountPaidinSGD             = '';
         this.beneficiary                 = '';
         this.exchangeRateToCurrencyToSGD = '';
         this.currencyExchangeFeeModal    = [];
         this.amountError                 = React.createRef()
         this.renderIcon                  = this.renderIcon.bind(this);
         this.checkRange                  = this.checkRange.bind(this);
         this.refreshValues               = this.refreshValues.bind(this)
         this.currencyToSGDConverter      = this.currencyToSGDConverter.bind(this);
         this.significantDigitFunction    = this.significantDigitFunction.bind(this);
         this.redirectParameter           = localStorage.getItem("redirect")
         localStorage.removeItem("redirect")
         console.log(window.location)
        //  queryString.parse("?"+window.location.hash.split("?")[1]).redirect
         console.log(this.redirectParameter)
         this.getCustomer();  
         
    }

    getCustomer(){

      axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/customer')
      .then(res =>{
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          let completedPayments = res.data.customer.paymentData.filter((d) => d.paymentStatus==="Completed")
          let completedBeneficiay = res.data.customer.beneficiayData.filter((d) => d.beneficiaryStatus !=="Rejected" && d.currencies[0] !== "SGD")//d.beneficiaryStatus ==="Approved" || d.beneficiaryStatus ==="AutoApproved"
          this.setState({name:res.data.customer.name,beneficiary:completedBeneficiay,transactions:completedPayments,homePageLoad:false,userPageLoad:false})                
      }).catch(function (error) {
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
      })

      const currencyDataUrl= (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/fee";
      axios.get(currencyDataUrl).then(res =>{
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
        if(res.status==200){
          this.currencyExchangeFeeModal = res.data.currencyExchangeModelList;
          this.forceUpdate();
        }
      }).catch(function (error) {
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
      })
    }

    findRate(currency,amount){
      let selecter = "lessThan1k"
      if(parseFloat(amount)<1000){
        selecter = "lessThan1k"
      }
      if(parseFloat(amount)>=1000 && parseFloat(amount)<=4999){
        selecter = "lessThan5k";
      }
      if(parseFloat(amount)>=5000 && parseFloat(amount)<=9999){
        selecter = "lessThan10k";
      }
      if(parseFloat(amount)>=10000 && parseFloat(amount)<=14999){
        selecter = "lessThan15k";
      }
      if(parseFloat(amount)>=15000 && parseFloat(amount)<=19999){
        selecter = "lessThan20k";
      }
      if(parseFloat(amount)>=20000 && parseFloat(amount)<=24999){
        selecter = "lessThan25k";
      }
      if(parseFloat(amount)>=25000 && parseFloat(amount)<=29999){
        selecter = "lessThan30k";
      }
      if(parseFloat(amount)>=30000 && parseFloat(amount)<=34999){
        selecter = "lessThan35k";
      }
      if(parseFloat(amount)>=35000 && parseFloat(amount)<=39999){
        selecter = "lessThan40k";
      }
      if(parseFloat(amount)>=40000 && parseFloat(amount)<=44999){
        selecter = "lessThan45k";
      }
      if(parseFloat(amount)>=45000 && parseFloat(amount)<=49999){
        selecter = "lessThan50k";
      }
      if(parseFloat(amount)-50000>=0){
        selecter = "moreThan50k";
      }
      return this.currencyExchangeFeeModal[currency][selecter]
    }

    display_content = () => {
      const no_list_content=<Row className="inputField">
      <Col md="10" className="inputField">
              <span>Currently there are no beneficiaries added.Add a benefiicary to initiate payment process</span>
      </Col>
    </Row>
      ;
    
    if(this.state.beneficiary.length>0 && this.state.beneficiary){
      let content = this.createTable();
      return content
    }
    return no_list_content;
    }
     
    checkValue(){
      if(this.state.invalid){
        return
      }
    }

    significantDigitFunction(number){
      let x = Math.abs(parseFloat(number))
      let floorValue = Math.floor(x)
      let diff = x - floorValue
      let i = 0
      while ((diff * Math.pow(10, i + 1)) < 1) {
        i += 1
        if (i > 10) break
      }
      return x.toFixed(Math.min(i + 4, 10))
    }

    currencyToSGDConverter(amountToTransfer){
      let currencyString = this.currency+"SIN";
      let currencyRate   = this.latestConversionRates[currencyString];
      let mwaRate        = this.findRate(this.currency, (amountToTransfer*currencyRate))
      let netRate        = this.significantDigitFunction(currencyRate*(1+(mwaRate/100)))
      let finalAmount    = (amountToTransfer*netRate).toFixed(2);

      this.setState({amountPaidinSGD:finalAmount,exchangeRateToCurrencyToSGD:netRate});

      this.amountPaidinSGD             = finalAmount;
      this.exchangeRateToCurrencyToSGD = netRate;
    }

    SGDToCurrencyConverter(amountPaidinSGD){
          let mwaRate        = this.findRate(this.currency, amountPaidinSGD)
          let currencyString = this.currency+"SIN";
          let currencyRate   = this.latestConversionRates[currencyString];
          let netRate        = this.significantDigitFunction(currencyRate*(1+(mwaRate/100)))
          let finalAmount    = (amountPaidinSGD/netRate).toFixed(2);

          this.setState({amountToTransfer:finalAmount,exchangeRateToCurrencyToSGD:netRate});
           
          this.amountToTransfer            = finalAmount;
          this.exchangeRateToCurrencyToSGD = netRate;
    }

    handleChange=(event)=>{
      if(event.target.type=="text"){
        event.target.classList.remove('valid','invalid')
        event.target.classList.add('activeFocus')
      }
      if(event.target.name === "beneficiaryId"){
        {this.state.beneficiary.forEach((beneficiary)=>{
          if(beneficiary.beneficiaryId === event.target.value){
            this.currencies      = beneficiary.currencies;
            this.beneficiary     = beneficiary;
            this.setState({[event.target.name]: event.target.value})
          }
        })}
        this.forceUpdate();
      }
      
      if(event.target.name==="currency"){
        this.refreshValues()
        this.setState({[event.target.name]: event.target.value,processingFeeSelect:true, currencySelect:false,feeChargedinSGD:""})
        const url=(process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/conversion-rates";
        axios.get(url).then(res =>{
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
        if(res.data.status == 200){
          this.latestConversionRates = res.data.latestConversionRates;
          this.currency = this.state.currency?this.state.currency:"";
          this.forceUpdate();
        }
        }).catch(function (error) {
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
        })
      }

      if(event.target.name === "amountToTransfer"){
        if(event.target.value==""){
          this.amountToTransfer = 0
          this.setState({[event.target.name]: 0,amountPaidinSGDDisabled:true})
          this.currencyToSGDConverter(0);
          return
        }
        var regex=/^[0-9,.]+$/g;
          if (!event.target.value.match(regex))
          {
            event.target.value=0;
              return
          }
          if(event.target.value.toString().split(".").length>2){
            event.target.value=0;
            return
          }
          this.amountToTransfer        = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
          this.setState({[event.target.name]: event.target.value,amountPaidinSGDDisabled:true})
          this.currencyToSGDConverter(Number(event.target.value.toString().replace(/,/g,"")).toFixed(2).toString())
      }
      if(event.target.name==="amountPaidinSGD"){
        if(event.target.value==""){
          this.amountPaidinSGD = 0
          this.setState({[event.target.name]: 0,amountPaidinSGDDisabled:true})
          this.SGDToCurrencyConverter(0);
          return
        }
        var regex=/^[0-9,.]+$/g;
          if (!event.target.value.match(regex))
          {
            event.target.value=0;
              return
          }
          if(event.target.value.toString().split(".").length>2){
            event.target.value=0;
            return
          }
        this.amountPaidinSGD         = Number(event.target.value.toString().replace(/,/g,"")).toFixed(2)
        this.setState({[event.target.name]: event.target.value,amountToTransferDisabled:true})
        this.SGDToCurrencyConverter(Number(event.target.value.toString().replace(/,/g,"")).toFixed(2).toString())
      }
      if(event.target.name === "paymentType"){
        this.paymentType     = event.target.value
        this.feeChargedinSGD = this.currencyExchangeFeeModal[this.currency][event.target.value=="SHA"?"sharedCharges":"totalCharges"]
        this.setState({[event.target.name]: event.target.value,processingFeeSelect:false, feeChargedinSGD:this.currencyExchangeFeeModal[this.currency][event.target.value=="SHA"?"sharedCharges":"totalCharges"]})
      }
    }
  
    createTable = () => {
        let table = this.state.beneficiary.slice(0, 3).map((beneficiary, key) => {
         return<>
         <Row className="inputRow homeTable" style={{paddingRight: '0px' ,paddingLeft:'5px',marginRight: '0px'}}>
          <Col md="10" className="inputField" style={{paddingLeft:'25px !important',paddingTop:'15px',marginBottom: '0px'}}>
            <p style={{'fontSize':'16px',marginBottom:'10px'}}>{beneficiary.name}</p>
            <p style={{'fontSize':'12px'}}>{beneficiary.bankName} | {beneficiary.accountNumber}</p>
          </Col>
          <Col className="inputField" md="2" className="homeButtonDisplay" style={{fontSize:'20px',paddingTop:'20px',marginBottom:'0px'}}>
              <Link to={{ pathname: '/transaction/add', param: { redirect:true, beneficiary:beneficiary} }}><img  style={{width:'30px',height:'30px'}} src={send}/></Link>
          </Col>
          </Row>
          <Row className="inputRow " style={{paddingRight: '0px' ,paddingLeft:'5px',marginRight: '0px'}}>
            {(key <this.state.beneficiary.slice(0,3).length-1)?<h2 style={{'width':'98%', 'text-align':'center', 'border-bottom': '1px solid #000','line-height':'0.1em', 'margin':'10px 0 20px'}}/>:""}
            </Row>
          </>
        })
      return table
    }
  
    display_contentTran = () => {
      const no_list_content=<Row>
      <Col md="8" className="inputField">
              <span>Currently there are no Transactions.</span>
      </Col>
    </Row>
      ;
    
    if(this.state.transactions.length>0){
      let content = this.createTableTrans();
      return content
    }
    return no_list_content;
    }
  
    displayShowall(){
      if(this.state.beneficiary.length>0){

        return(<Row className="inputRow">
          <Col className="inputField" style={{paddingLeft:'0px !important'}} md="1" ><label style={{width:'50px',fontSize:'14px'}} >{this.state.beneficiary.length > 3? 3 : this.state.beneficiary.length}{' '}of {''}{this.state.beneficiary.length}</label></Col>
          <Col className="inputField" md="9">
           <h2 style={{'width':'304px', 'text-align':'center', 'border-bottom': '1px solid #000','line-height':'0.1em', 'margin':'10px 0 20px'}}/>
          </Col><Link to="/beneficiary/list">Show all</Link></Row>);
      }
    }
  
    displayShowallTrans(){
      if(this.state.transactions.length>0){
        
        return(<Row className="inputRow ">
           <Col md="1" className="inputField"><label style={{width:'50px',fontSize:'14px'}} >{this.state.transactions.length > 3? 3 : this.state.transactions.length}{' '}of {''}{this.state.transactions.length}</label></Col>
          <Col md="9" className="inputField">
        <h2 style={{'width':'305px', 'text-align':'center', 'border-bottom': '1px solid #000','line-height':'0.1em', 'margin':'10px 0 20px'}}/>
       </Col><Link to="/transaction/list">Show all</Link></Row>
       );
      }
    }
  
    createTableTrans = () => {
        let table = this.state.transactions.slice(0, 3).map((transactions, key) => {
          return (<Row className="inputRow homeRow" style={{'marginLeft':'0px'}}>
          <Col md="8">
            <p className="inputRow" style={{'fontSize':'16px',marginBottom:'10px'}}>{parseInt(transactions.amountToTransfer.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 })} {transactions.currency}</p>
            <p style={{'fontSize':'14px'}}>{transactions.beneficiaryName}{' '} |{' '} <b>A/c no:</b> {transactions.accountNumber}</p>
          </Col>
          <Col className="inputField" md="4" style={{'fontSize':'14px'}}>{moment(transactions.executionDate).format('Do MMM[,]YYYY')}</Col>
          {(key <this.state.transactions.slice(0,3).length-1)?<h2 style={{'width':'412px', 'text-align':'center', 'border-bottom': '1px solid #000','line-height':'0.1em', 'margin':'10px 0 20px'}}/>:""}
         </Row>
         );
          })
   
      return table
    }

    renderIcon=()=>{
      return <FiPlus/>
    }

    renderOptions(){
      if(this.currency!=""){
        let optionsSHA = <option value="SHA">SHA</option>
        let optionsSHAOUR = <><option value="SHA">SHA</option><option value="OUR">OUR</option></>
        if(this.currencyExchangeFeeModal[this.currency].totalCharges!=0){
          return optionsSHAOUR
        }
        else{
          return optionsSHA
        }
      }
    }

    refreshValues(){
      this.setState({currency:"", amountPaidinSGDDisabled:false, amountToTransferDisabled:false, amountToTransfer:"", amountPaidinSGD:"", feeChargedinSGD:"", paymentType:"", processingFeeSelect:true, currencySelect:true})
      this.amountToTransfer = ""
      this.amountPaidinSGD = ""
      this.currency = ""
    }

    checkRange(){
      if((parseFloat(this.amountToTransfer) > parseFloat(this.currency == "" ? 0 : currencyHome[this.currency].max) || parseFloat(this.amountToTransfer) < parseFloat(this.currency == ""? 0 : currencyHome[this.currency].min))){
        this.setState({loading:false,messageModel:"Amount should be between "+currencyHome[this.currency].min.toLocaleString()+" and "+currencyHome[this.currency].max.toLocaleString()+" for "+this.currency})
        this.errorModel.current.toggle();
        return
      }
      this.setState({ redirect: true })
    }
    renderRedirect(){
      if (this.state.redirect) {
      return <Redirect to={{ pathname: '/transaction/add', param_home: { redirect : true, beneficiary:this.beneficiary, currency:this.currency, amountToTransfer:this.amountToTransfer, amountPaidinSGD:this.amountPaidinSGD, paymentType:this.paymentType, exchangeRateToCurrencyToSGD:this.exchangeRateToCurrencyToSGD, feeChargedinSGD:this.state.feeChargedinSGD, netAmount:(parseFloat(this.amountPaidinSGD)+parseFloat(this.feeChargedinSGD))} }}/>
    }
  }

  currencyFormatter = (amount) => {
    if(String(amount).endsWith(".")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+"."
    }
    if(String(amount).endsWith(".0")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".0"
    }
    if(String(amount).endsWith(".00")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".00"
    }
    if(String(amount).endsWith(".000")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".000"
    }
    if(String(amount).endsWith(".0000")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".0000"
    }
    if(String(amount).endsWith(".00000")){
      return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})+".00000"
    }
    return  Number(amount.split(",").join("")).toLocaleString(undefined,{ maximumFractionDigits:20})
  }


    render(){
      let name=this.state.name;
      console.log(this.redirectParameter)
      if(this.redirectParameter !== null && this.redirectParameter === "true"){
        return <Redirect to="/beneficiary/add"/>
      }
      if(this.props.user && (this.props.user.attributes.profile === "Rejected") || this.props.user && (this.props.user.attributes.profile === "AutoReject")){
        return <UserProfile user={this.props.user}/>
      }
      if(((this.props.user &&  this.props.user.attributes.profile === "Approved")|| (this.props.user && this.props.user.attributes.profile === "AutoApproveWith2Year") || (this.props.user && this.props.user.attributes.profile === "AutoApproveWith3Year")) && !this.props.user.attributes["custom:verificationComplete"]) {
        return <UserProfile user={this.props.user}/>
      }

        return(
            <>
            <Notification ref={this.errorModel}  modalTitle={this.state.messageModel}/>
             <BlockUi tag="div" blocking={this.state.userPageLoad} keepInView={true} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className="mainContent" >
            <Row className="breadcrumbContainer">
               <Col md="12" className="breadcrumbContainerColumn">
               <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                 <BreadcrumbItem className="breadcrumbItem initial">Home</BreadcrumbItem>
               </Breadcrumb>
               </Col>
            </Row>
            <Row className="pageHeading">
              <Col md="6"  className="pageHeadingColumn">
                <label>Welcome <label style={{ fontWeight:'bold'}}><small> <b>{name}</b></small></label></label><br/>
                <label style={{fontSize:'30px',color: '#9f9f9f'}}>Send your money in easy steps</label>
              </Col>
            </Row>
            <Spacer height={25}/>
            <Row className="pageHeading">
              <Col md="6"  className="pageHeadingColumn" >
               <ul>
                 <li>Ensure the beneficiary is added</li>
                 <li>Select foreign currency</li>
                 <li>Enter amount in foreign currency or Enter amount in SGD</li>
                 <li>Select processing fee type</li>
                 <li>Click on <img src={send} style={{height:"15px",width:"15px",margin:"0px 5px"}}/></li>
               </ul>
              </Col>
            </Row>
            <Container className="section">
            <Spacer height={50}/>
            <Row className="inputRow">
            <Col md="3" className="inputField transBeneficiary">
              <select name="beneficiaryId" onChange={this.handleChange} required>
                <option value="" disabled selected>Select</option>
                    {(this.state.beneficiary?this.state.beneficiary:[]).map((prop) => {
                    return <option value={prop.beneficiaryId}>{prop.name}</option> 
                    })}
              </select><br/>
              <label>Beneficiary</label>
            </Col>
            <Col md="2" className="inputField transCurrency" >
                 <select name="currency" required onChange={this.handleChange} disabled={(this.currencies == null)?true:false}> 
                    <option selected={this.state.currencySelect?true:false}  disabled hidden value=''>Select</option>
                    {((this.currencies!=null)?this.currencies:[]).map((prop) => {
                                        return <option style={{fontSize:'16px !important'}} value={prop}>{prop}</option> 
                        })}
                 </select><br/>
                <label>{this.currency==""?"Currency":currencyHome[this.currency].name}</label>
            </Col>
            <Col md="3" className="inputField">
            <div className="inputGroup">
                   <input type="text" id="homeCurrency" className="inputText"
                    name="amountToTransfer" autoComplete="off" 
                    onChange={this.handleChange} 
                    value={this.state.amountToTransfer?this.currencyFormatter(this.state.amountToTransfer):""}
                    // {(this.state.amountToTransfer?parseFloat(this.state.amountToTransfer.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
                    title="Amount To Transfer" disabled={(this.currency==""?true:false)}/>
                   <span className="spanTop" onClick={(e)=>{e.target.previousElementSibling.focus()}}>Amount {this.currency==""?"":(" in "+this.currency)}
                   </span><br/>
                </div>
            </Col>
            <Col md="1">
                <img src={swapArrow} height="35px" width="35"/>
            </Col>
            <Col md="3" className="inputField">
                <div className="inputGroup">
                   <input type="text" id="homeCurrency"  className="inputText"
                    name="amountPaidinSGD" autoComplete="off"
                    onChange={this.handleChange} 
                    value={this.state.amountPaidinSGD?this.currencyFormatter(this.state.amountPaidinSGD):""}
                    // {(this.state.amountPaidinSGD?parseFloat(this.state.amountPaidinSGD.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
                    title="Amount to be Paid in SGD" disabled={(this.currency==""?true:false)}/>
                   <span className="spanTop" onClick={(e)=>{e.target.previousElementSibling.focus()}}>Amount in SGD
                   </span>
                </div>
            </Col>
            </Row>
            <Row className="inputRow">
                <Col md="3" className="inputField paymentType">
                    <select name="paymentType"  title="Processing Fee Type" disabled={(this.currency==""?true:false)} onChange={this.handleChange} required>
                      <option value="" disabled hidden selected={this.state.processingFeeSelect?true:false}>Select</option>
                      {this.state.currency?this.renderOptions():""}
                    </select><br/>
                    <label id="processingFeeID">Processing Fee Type <img src={info} height={15} width={15}  style={{marginLeft:"5px"}}/></label>
                    <UncontrolledTooltip placement="right" target="processingFeeID" style={{fontSize:"10px"}}>
                    <Row>
                      <Col md="12" >
                        <ul style={{textAlign:"left",padding:"0px",marginBottom:"0px"}}>
                          <li>SHA (cover local bank charges)</li>
                          <li>OUR (cover all charges)</li>
                        </ul>
                      </Col>
                    </Row>
                    </UncontrolledTooltip>
                </Col>
                <Col md="1">
                  {this.renderRedirect()}
                  <a href="" onClick={(e)=>e.preventDefault()} disabled={(this.state.feeChargedinSGD==""?true:false)||(this.state.amountPaidinSGD==""?true:false)}><img src={send} onClick={this.checkRange} height="35px" width="35"/></a>
                </Col>
                <Col md="4">
                  <label style={{color:'#8b0304',fontSize:'20px',marginTop:"5px"}}>Pay{' '}<span>S${" "}{this.state.feeChargedinSGD?Number(parseFloat(parseFloat(this.state.amountPaidinSGD?this.state.amountPaidinSGD:0)+parseFloat(this.state.feeChargedinSGD))).toLocaleString(undefined, {  maximumFractionDigits: 2 }):0}</span></label> 
                </Col>
                </Row>
                <Spacer height={50}/>
                <Row className="inputRow">
                <Col className="inputField" md="6">
                <Row className="inputRow" style={{height: '40px',marginBottom:'25px'}}>
                <Col md="3" className="inputField" style={{paddingLeft:'0px'}} className="inputField">
                  <label style={{'fontSize':'15px','color':'#4a4a4a'}}>Transactions</label>
                  <Spacer height={7}/>
                </Col> 
                <Col md="3" className="inputField" style={{paddingLeft:'0px',paddingTop:'2px'}}><h2 style={{'width':'310px', 'textAlign':'left', 'border-bottom': '1px solid #000','line-height':'0.1em', 'margin':'10px 0 20px'}}/></Col> 
                
                </Row>
                {this.display_contentTran()}
                {this.displayShowallTrans()}
                
               </Col>
               <Col  md="6" className="inputField">
               <Row className="inputRow" style={{height: '40px'}}>
                <Col md="3" className="inputField">
                  <label style={{'fontSize':'15px','color':'#4a4a4a'}}>Beneficiaries</label>
                  <Spacer height={7}/>
                </Col>
                <Col md="2" className="inputField" style={{paddingLeft:'0px',paddingTop:'2px'}}><h2 style={{'width':'268px', 'text-align':'left', 'border-bottom': '1px solid #000','line-height':'0.1em', 'margin':'10px 0 20px'}}/></Col>
                <Col className="inputField" md="1" style={{'padding-right': '0px',marginLeft:'205px'}}>
                <Link to="/beneficiary/add" ><img src={addHome} style={{width:'25px',height:'25px'}}></img></Link>
                </Col>
                </Row>
                {this.display_content()}
                {this.displayShowall()}
              </Col>
                </Row>
            </Container>
          </Container>
          </BlockUi>
            </>
        )
    }
}
export default Home;