import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import userRoutes from "routes/user.jsx";
import UserModel from "views/User/UserModel.jsx";

class User extends React.Component {
    constructor(props){
        super(props)
        var urlParameters = window.location.hash
        this.pathHash = urlParameters.split("?")[0]
    }
    render() {
        return (
            <>
            <Switch>
                {userRoutes.map((prop, key) => { 
                if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo}  key={key} />;
                } 
                return (
                    <Route path={prop.path} component={(props) => <prop.component {...this.props} key={key} user={this.props.user}  list={UserModel} />}   key={key} exact/>
                );
                })}
            </Switch>
            {this.props.user.attributes.profile === "ProfileSetup" && this.props.user.attributes["custom:profileType"] === "Individual" &&  this.pathHash !== "#callback" && this.pathHash !== "#user" && this.pathHash !=="#user/page2" && this.pathHash !== "#user/document"  && <Redirect to='/user' />}
            {this.props.user.attributes.profile === "PendingUploadDocument" && this.props.user.attributes["custom:profileType"] === "Individual" && this.pathHash !== "#user" && this.pathHash !== "#user/page2" && <Redirect to='/user/document' />}
            {this.props.user.attributes.profile === "PendingClarification" && this.props.user.attributes["custom:profileType"] === "Individual" && <Redirect to='/user/document' />}
            {this.props.user.attributes.profile === "ProfileSetup"  && this.props.user.attributes["custom:profileType"] === "Corporate" && this.pathHash !== "#callback" && this.pathHash !== "#corpUser" && this.pathHash !=="#corpDocument" && this.pathHash !== "#corpDocument"  && <Redirect to='/corpUser' />}
            {this.props.user.attributes.profile === "PendingUploadDocument" && this.props.user.attributes["custom:profileType"] === "Corporate" && this.pathHash !== "#scorpUser" && <Redirect to='/corpDocument' />}
            {this.props.user.attributes.profile === "PendingClarification" && this.props.user.attributes["custom:profileType"] === "Corporate" && <Redirect to='/corpDocument' />}
            </>
        );
    }
}

export default User;