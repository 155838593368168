import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col,Breadcrumb, BreadcrumbItem, 
  Input,Table,Modal, ModalHeader, ModalBody, Button, Form,  } from 'reactstrap';
import send from 'assets/img/send.svg';
import edit from 'assets/img/edit.svg';
import trash from 'assets/img/trash.svg';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import BeneficiaryModal from 'components/modals/beneficiaryModal';
import Notification from "components/modals/Notification";

class ListBeneficiary extends React.Component{
    constructor(props) {
        super(props);
        this.state={
          modal: false,
          modalEdit: false,
          disable:"true",
          beneficiaryToDelete: "",
          currentBeneficiary:"",
        };
        this.flag         = 0
        this.modalRef     = React.createRef();
        this.deleteError  = React.createRef();
        this.state.length = 0;

        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/beneficiary";
        axios.get(url).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          this.setState({beneficiary: res.data.beneficiaries});
          }).catch(function (error) {
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
          })

        this.toggle            = this.toggle.bind(this);
        this.deleteBeneficiary = this.deleteBeneficiary.bind(this)
        this.onKeyPress        = this.onKeyPress.bind(this)
      }

      toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }
      
      handleChange = (event) => {
        this.setState({ [event.target.name]:event.target.value });
        this.flag = 1
      }

      modalEditBeneficiary=(i)=>{
        this.setState({currentBeneficiary:this.state.beneficiary[i]})
        this.modalRef.current.toggle();
        this.modalRef.current.setState({editingDisabled:false})
      }
    
      modalViewBeneficiary=(i)=>{
        this.setState({currentBeneficiary:this.state.beneficiary[i]})
        this.modalRef.current.toggle();
      }
    
      modalDeleteBeneficiary=(beneficiaryId)=>{
        this.toggle()
        this.setState({ beneficiaryToDelete:beneficiaryId})
      }
    
      deleteBeneficiary=()=> {
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/beneficiary/"+this.state.beneficiaryToDelete;
        axios.delete(url).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          this.setState({ beneficiaryToDelete:""})
          if(res.data.status==200){
           window.location.reload();
          }
          else{
            this.deleteError.current.toggle();
          } 
          }).catch(function (error) {
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
          this.setState({ beneficiaryToDelete:""})
          })
          this.toggle()
      }

      checkSend(i){
        if(this.state.beneficiary[i].beneficiaryStatus=="Approved" || this.state.beneficiary[i].beneficiaryStatus=="AutoApproved"){
         return <Link to={{ pathname: '/transaction/add', param: { redirect:true, beneficiary:this.state.beneficiary[i]} }}><span><img title="Send" src={send}/></span></Link>
        }
      }

      checkEdit(i){
        if(this.state.beneficiary[i].beneficiaryStatus=="ApprovalPending"){
         return <span onClick={() =>this.modalEditBeneficiary(i)}><img title="Edit" src={edit}/></span>
        }
        return <span></span>
      }
    
      display_content = () => {
      const no_list_content=<Row>
          <Col md="6">
          {this.state.searchCriteria?"No Results found": (this.flag==0?<b>Currently there are no beneficiaries added to this list. <Link to="/beneficiary/add">Go Ahead and Create One</Link></b>:"No Results Found. Press Enter to continue")}
          </Col>
          </Row>;
      
      if(this.state.beneficiary.length>0){
        return <><Table striped>
        <thead className="tableHeading">
            <tr>
            <th>#</th>
            <th>Name</th>
            <th>Bank Name</th>
            <th>SWIFT Code</th>
            <th>Account Number</th>
            <th>Status</th>
            <th colSpan="3"><Link to="/beneficiary/add"><span>Add New</span></Link></th>
            </tr>
        </thead>
        <tbody className="tableContent">
            {this.createTable()}
        </tbody>
        </Table>
      </>;
      }
      if(this.state.beneficiary.length==0)
      return no_list_content;
      }
      
      createTable = () => {
        let table = []
        for (let i = 0; i < this.state.beneficiary.length; i++) {
          let children = []
          children.push(<th scope="row">{`${i + 1}`}</th>)
          children.push(<td onClick={() =>this.modalViewBeneficiary(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.state.beneficiary[i].name}</a></td>)
          children.push(<td onClick={() =>this.modalViewBeneficiary(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.state.beneficiary[i].bankName}</a></td>)
          children.push(<td onClick={() =>this.modalViewBeneficiary(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.state.beneficiary[i].swiftBIC}</a></td>)
          children.push(<td onClick={() =>this.modalViewBeneficiary(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.state.beneficiary[i].accountNumber}</a></td>)
          children.push(<td onClick={() =>this.modalViewBeneficiary(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.state.beneficiary[i].beneficiaryStatus}</a></td>)
          children.push(<td style={{padding:"12px 0px"}}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.checkEdit(i)}</a></td>)
          children.push(<td style={{padding:"12px 0px"}}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}><span onClick={() =>this.modalDeleteBeneficiary(this.state.beneficiary[i].beneficiaryId)}>
          <img title="Delete" src={trash}/></span></a></td>)
          children.push(<td style={{padding:"12px 0px"}}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
          style={{underline:"none",color:"#000"}}>{this.checkSend(i)}</a></td>)
          table.push(<tr>{children}</tr>)
        }
        return table
      }

      onKeyPress(event) {
        if (event.which === 13) {
          event.preventDefault();
          const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/beneficiary?searchCriteria="+this.state.searchCriteria;
          axios.get(url).then(res =>{
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
            this.setState({beneficiary: res.data.beneficiaries});
            }).catch(function (error) {
              if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
            })
        }
      }

    render(){
        while(this.state.beneficiary==undefined){
            return <div className="loader">
            <Loader type="Oval" color="#000" height="80"  width="80" />
            </div>
          }
        return(
            <Container fluid className="mainContent" >
                  <BeneficiaryModal ref={this.modalRef} action={this.hello} disabled={this.state.disable} modalTitle="Beneficiary Details" beneficiary={this.state.currentBeneficiary}/>
                  <Notification ref={this.deleteError}  modalTitle="Beneficiary cannot be deleted" />

                  <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className}>
                    <ModalHeader style={{borderBottom:'transparent'}} toggle={this.toggle}>
                    <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">The beneficiary will be deleted from list. Are you sure?</p></ModalHeader>
                    <ModalBody>
                      <center>
                        <Button color="primary" onClick={this.deleteBeneficiary} style={{marginRight:'15px',backgroundColor:'transparent',color:'#8c0204',borderColor:'transparent'}} >Yes</Button>{' '}
                        <Button color="secondary" onClick={this.toggle} style={{marginRight:'15px',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}}>No</Button>
                      </center>
                    </ModalBody>
                    
                  </Modal> 

                  <Row className="breadcrumbContainer">
                   <Col md="12" className="breadcrumbContainerColumn">
                   <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                     <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                     <BreadcrumbItem className="breadcrumbItem" tag="a">Beneficiary</BreadcrumbItem>
                     <BreadcrumbItem className="breadcrumbItem" active tag="span">Beneficiary List</BreadcrumbItem>
                   </Breadcrumb>
                   </Col>
                  </Row>
                  <Row className="pageHeading">
                   <Col md="6"  className="pageHeadingColumn">
                     <h1>Your Beneficiaries</h1>
                   </Col>
                  </Row>
                  <Container className="section">
                  <Row className="inputRow">
                   <Col md="6" className="inputField">
                    <Form onSubmit={(e)=>e.preventDefault()}>
                    <span>
                    <Input type="text" className="inputText" id="search" name="searchCriteria" pattern="^[a-zA-Z0-9][a-zA-Z0-9\-\S]+" placeholder="Search by Beneficiary"
                     onChange={this.handleChange} onKeyPress={this.onKeyPress}/>
                    </span>
                    </Form>
                   </Col>
                  </Row>
                  </Container>
              <Container className="section">
              <Row className="inputRow">
              <Col md="12" className="inputField">
              {this.display_content()}
              </Col>
              </Row>
              </Container>
            </Container>
        )
    }
}
export default ListBeneficiary;