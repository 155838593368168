import React from "react";
import { Button, Modal, ModalHeader, ModalBody} from "reactstrap";
import axios from 'axios';
import logo from "assets/img/logo.svg";
import bell from "assets/img/bell.svg";

class Navbar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          modal:false,
          notificationList: [],
          areNotificationsSeen: false,
          unreadCount:0
        };
        console.log(this.state)
        this.fetchNotifications()
        document.addEventListener("click",function(event){
          if (event.target.id !== "notificationDropdown" && !event.target.classList.contains("notificationItem")){
            document.getElementById("notificationDropdown").classList.add("displayNone")
          }
        })
        setInterval(()=>this.fetchNotifications(),5000);
    }

    redirectToHome = () => {
      window.location="/user";
    } 

    toggleLogout = () => {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    notificationsOpened = () => {
      document.getElementById("notificationDropdown").classList.toggle("displayNone");
      if(!this.state.areNotificationsSeen){
        document.getElementById("notificationCount").classList.add("displayNone")
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/notifications?receiverType=Customer";
        axios.get(url).then((response)=>{  
          console.log(response.data)
        })
      }
    }

    fetchNotifications = () => {
      var data = {
        receiverType:"Customer"
      }
      const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/notifications";
      axios.post(url,data).then((repsonse)=>{
        this.setState({notificationList:repsonse.data.notifications,unreadCount:repsonse.data.unreadCount})
        if(this.state.unreadCount>0){
          document.getElementById("notificationCount").classList.remove("displayNone");
        }
      })
    }

    

    render(){
        return(
          <>
          <Modal isOpen={this.state.modal} toggle={this.toggleLogout} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className}>
            <ModalHeader  style={{borderBottom:'transparent'}} toggle={this.toggleLogout}>
            <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">Are you sure you want to log out?</p> 
              </ModalHeader>
            <ModalBody>
              <center>
            <Button color="primary" onClick={this.toggleLogout} style={{marginRight:'15px',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}}>Cancel</Button>
            <Button color="primary" onClick={this.props.logout} style={{marginRight:'15px',backgroundColor:'transparent',color:'#8c0204',borderColor:'transparent'}} >Log out</Button>{' '}
              </center>
            </ModalBody>
        </Modal>
            <div className="navbar">
            <nav className="navBarNavigation">
              <div className="toggleButtonDiv">
                <button className="toggleButton" onClick={this.props.toggle}>
                  <div className="toggleButtonLine"></div>
                  <div className="toggleButtonLine"></div>
                  <div className="toggleButtonLine"></div>
                </button>
              </div>
              <div className="navBrand"><span><img src={logo} className="navLogo"/></span></div>
              <div className="seperater"/>
              <div style={{paddingLeft:"25px", paddingRight:"25px", position:"relative"}}>
                <span onClick={()=> this.notificationsOpened()} style={{cursor:"pointer"}}><img src={bell} style={{height:"25px", width:"25px"}}/></span>
                <span id="notificationCount" className="notificationBadge displayNone">{this.state.unreadCount}</span>
                <ul id="notificationDropdown" className="customDropdown displayNone">
                  {
                    this.state.notificationList.map((item)=>{
                    return <li className="notificationItem"><span className={item.readStatus?"displayNone":""}>New</span>{item.notification}</li>
                    })
                  }
                  {
                    this.state.notificationList.length>0?"":<li style={{border:"none"}}>No notifications found.</li>
                  }
                </ul>
              </div>
              <span style={{fontSize: '17px',marginRight: '5px',cursor: 'pointer', borderBottom: '#052d99 solid 1px',color:'#052d99'}} onClick={this.redirectToHome}  className="d-md-block logoutColor">{this.props.user && this.props.user.attributes.name}</span>{' '}| {' '}
              <span onClick={this.toggleLogout} style={{cursor: 'pointer', fontSize: '17px',marginLeft:'3px'}}  className="d-md-block logoutColor">{' '}{' '}Log Out</span>
            </nav>
            </div> 
          </> 
        )
    }
}
export default Navbar;