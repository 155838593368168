import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Navbar from "components/navbar/navbar.jsx"
import Sidebar from "components/sidebar/sidebar.jsx"
import dashRoutes from "../routes/dashboard.jsx"
import dashRoutesCorp from "../routes/dashboardCorp.jsx"
import { Auth } from "aws-amplify";


class DashboardLayout extends React.Component {
    constructor(props){
      super(props);
      this.sidebarRef         = React.createRef();
      this.collapseController = this.collapseController.bind(this);
      this.logout             = this.logout.bind(this)
    
      Auth.currentAuthenticatedUser({bypassCache: true})
          .then((data) => {
            if(data === undefined)
              this.setState({loggedOut: true})
  
          })
          .catch(() =>  {
            this.setState({loggedOut: true})
          })
    }

    handleActiveClick = (color) => {
      this.setState({ activeColor: color });
    }
    handleBgClick = (color) => {
      this.setState({ backgroundColor: color });
    }
  
    logout() {
      Auth.signOut()
      .then(() => {
        localStorage.removeItem("timestamp")
        window.location="/"
      })
      .catch();
    }


    collapseController(){
      this.sidebarRef.current.toggle();
    }

    render() {
      if(this.props.user.attributes["custom:profileType"] === "Individual"){
        return (
          <div style={{overflowX:"hidden",backgroundColor:"#f7f7f7"}} id="main-wrapper">
              <Navbar toggle={this.collapseController} {...this.props} logout={this.logout}/>
              <div id="wrapper">
                  <Sidebar
                     ref={this.sidebarRef}
                     {...this.props}
                     routes={dashRoutes}
                     bgColor={false}
                     disabled={(this.props.user.attributes.profile==="Rejected" || this.props.user.attributes.profile === "AutoReject")?true:false} //this.props.user.attributes.profile==="PendingApproval"||
                  />
  
                  <div id="pageContentWrapper" style={{height:"100vh",position:"relative",maxHeight:"calc(100vh - 70px)",display:"flex",flexFlow:"column"}}>
                  <Switch>
                    {dashRoutes.map((prop, key) => {
                      if (prop.redirect) {
                        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                      }
                      return (
                        <Route  path={prop.path} ref="mainPanel" component={() => <prop.component {...this.props} />} key={key} />
                      );
                    })}
                  </Switch>
                  </div>
              </div>
            </div>
        );
      }else{
      return (
       
        <div style={{overflowX:"hidden",backgroundColor:"#f7f7f7"}} id="main-wrapper">
            <Navbar toggle={this.collapseController} {...this.props} logout={this.logout}/>
            <div id="wrapper">
                <Sidebar
                   ref={this.sidebarRef}
                   {...this.props}
                   routes   = {dashRoutesCorp}
                   bgColor  = {false}
                   disabled = {(this.props.user.attributes.profile ==="Rejected" || this.props.user.attributes.profile === "AutoReject")?true:false}
                />
                <div id="pageContentWrapper" style={{height:"100vh",position:"relative",maxHeight:"calc(100vh - 70px)",display:"flex",flexFlow:"column"}}>
                <Switch>
                  {dashRoutesCorp.map((prop, key) => {
                    if (prop.redirect) {
                      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    }
                    return (
                      <Route  path={prop.path} ref="mainPanel" component={() => <prop.component {...this.props} />} key={key} />
                    );
                  })}
                </Switch>
                </div>
            </div>
          </div>
      );
      }
    }
  }
  export default DashboardLayout;
  