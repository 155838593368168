import React  from "react";
import { Button, Modal, ModalHeader, ModalBody  } from "reactstrap";


class BeneficiaryCancelModal extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return (
      <>
        <Button className="pageButton secondary"  size="sm" id="back" onClick={this.toggle} style={{float:"right"}}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}  backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className} backdrop="static">
          <ModalHeader style={{borderBottom:'transparent'}} toggle={this.toggle}>
          <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">{this.props.modalTitle}</p></ModalHeader>
          <ModalBody>
          <center>
              <Button className=" primary" size="sm"  onMouseLeave={(e)=>e.target.classList.remove("activeState")} onClick={()=>window.location="/beneficiary/list"} style={{marginRight:'15px',backgroundColor:'transparent',color:'#052d99',fontSize:'1rem',borderColor:'transparent'}}>Yes</Button>{' '}
              <Button className=" secondary" size="sm"  onClick={this.toggle} style={{marginRight:'15px',backgroundColor:'transparent',fontSize:'1rem',color:'#8c0204',borderColor:'transparent'}}>No</Button>
          </center>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default BeneficiaryCancelModal;