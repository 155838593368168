import React  from "react";
import {Button, Row, Col, Modal, ModalHeader, Form, ModalBody, ModalFooter  } from "reactstrap";
import axios from 'axios';
import { FiSend, FiCalendar, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import mime from "mime-types";
import { Storage } from 'aws-amplify';
import uuid from "uuid";
import qrCode from "assets/img/qrCode.png";
import moment from "moment";
import Notification from "components/modals/Notification";

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
      modal: false,
      editingDisabled:true,
      loading:false,
      loaderMessage:"",
      messageModel:'',
      transferMethod:''
    };
    this.fileLabel      = React.createRef();
    this.errorModel     = React.createRef();
    this.fileField      = React.createRef();
    this.submitModal    = React.createRef();
    this.toggle         = this.toggle.bind(this);
    this.toggleSubmit  = this.toggleSubmit.bind(this);
    this.handleChange   = this.handleChange.bind(this);
    this.onSave         = this.onSave.bind(this);
    this.onFileChange   = this.onFileChange.bind(this);
  }

  onFocus(event){
    if(event.target.disabled)return
    event.target.nextElementSibling.classList.remove('spanMiddle')
    event.target.nextElementSibling.classList.add('spanTop')
    if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
  }

  onBlur(event){
    event.target.classList.remove('activeFocus','invalid','valid')
    event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
    if(event.target.value==""){
      event.target.classList.remove('activeFocus','invalid','valid')
      event.target.nextElementSibling.classList.remove('spanTop')
      event.target.nextElementSibling.classList.add('spanMiddle')
      }
      if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleSubmit() {
    this.setState(prevState => ({
      submitModal: !prevState.submitModal
    }));
  }
  
  handleChange = (event) => {
   this.setState({ [event.target.name]:event.target.value });
  }

  onFileChange(e){
    this.fileLabel.current.innerHTML=e.target.files[0].name
    this.setState({customerTransactionSupportDocument: e.target.files[0].name})
  }

  uploadFile(file, callback, errorCallback) {
    const fileName = uuid.v4()+"."+mime.extension(mime.contentType(file.name))
    Storage.vault.put(fileName, file)
    .then (callback) 
    .catch(errorCallback);    
 }

  onSave(){
    if(!moment(this.props.payment.executionDate).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))){
      this.setState({messageModel:"Execution Date has expired"})
      this.errorModel.current.toggle();
      window.location.reload();
      return
    }

    if(!this.state.transferMethod){
      this.setState({messageModel:"Please select transfer method"})
      this.errorModel.current.toggle();
      return
    }

    if(!this.state.customerTransactionId){
      this.setState({messageModel:"Please enter Transaction Id"})
      this.errorModel.current.toggle();
      return
    }

    if(!this.state.customerTransactionSupportDocument){
      this.setState({messageModel:"Please upload Transaction Support Document"})
      this.errorModel.current.toggle();
      return
    }


    this.setState({loading:true,loaderMessage:"Saving Transaction Id..."})
    this.uploadFile(this.fileField.current.files[0], (result) => {
      this.setState({customerTransactionSupportDocument:result.key})
      let data={
        "paymentId":this.props.paymentId,
        "currency":this.props.payment.currency,
        "amountToTransfer":this.props.payment.amountToTransfer,
        "purpose":this.props.payment.purpose,
        "speed":this.props.payment.speed,
        "executionDate":this.props.payment.executionDate,
        "internalReference":this.props.payment.internalReference,
        "amountPaidinSGD":this.props.payment.amountPaidinSGD,
        "feeChargedinSGD":this.props.payment.feeChargedinSGD,
        "customerTransactionId":this.state.customerTransactionId,
        "customerTransactionSupportDocument":this.state.customerTransactionSupportDocument,
        "exchangeRateToCurrencyToSGD": this.state.exchangeRateToCurrencyToSGD?this.state.exchangeRateToCurrencyToSGD:this.props.payment.exchangeRateToCurrencyToSGD,
        "paymentType":this.state.paymentType?this.state.paymentType:this.props.payment.paymentType,
        "transferMethod":this.state.transferMethod
      }
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/editpayment";
        axios.post(url,data).then(res =>{
          if(res.data.status==200){
            this.setState({loading:false,loaderMessage:"",messageModel:res.data.message})
            this.setState({submitModal:true})
          }
          else{
            this.setState({loading:false,loaderMessage:"",messageModel:res.data.message})
            this.errorModel.current.toggle();
          }
          }).catch(function (error) {})
    })
    
        
  }

  render() {
    return (
      // this.state.modal
      <>
      <Notification ref={this.errorModel}  modalTitle={this.state.messageModel}/>
      <Modal ref={this.submitModal}  isOpen={this.state.submitModal} toggle={this.toggleSubmit} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className}>
            <ModalHeader style={{borderBottom:'transparent'}} toggle={this.toggleSubmit}>
            <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">Thank you. You will receive an mail once transaction is completed.</p>
            </ModalHeader>
             <ModalBody>
               <center>
                <Button color="primary" onClick={() => window.location="/transaction/list"} style={{marginRight:'15px',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}}>OK</Button>
                </center>
             </ModalBody>
          </Modal>
      <Notification ref={this.errorModel}  modalTitle={this.state.messageModel}/>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" backdrop="static" className={this.props.className} onClosed={()=>window.location="/transaction/list"}>
          <ModalHeader><b>{this.props.modalTitle}</b></ModalHeader>
          <BlockUi tag="div" blocking={this.state.loading} keepInView={true} message={this.state.loaderMessage}>
          <Form>
          <ModalBody>
            <div style={{fontSize:"12px"}}>
            <Row>
           <Col md="6" style={{textAlign:"center"}}><h4><b>Beneficiary Details</b></h4></Col>
           <Col md="6" style={{textAlign:"center"}}><h4><b>Transaction Details</b></h4></Col>
         </Row>
          <Row>
           <Col md="3" style={{textAlign:"left"}}><b>Beneficiary Name</b></Col>
           <Col md="1"><b><FiSend /></b></Col>
           <Col md="3" style={{textAlign:"left"}}>
           {this.props.payment.beneficiaryName}
           </Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}><b>Amount to Transfer</b></Col>
           <Col md="1" ><b><FiSend /></b></Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}>
           <b>{this.props.payment.currency}</b>{" "+(this.props.payment.amountToTransfer?parseFloat(this.props.payment.amountToTransfer.toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 }):"")}
           </Col>
         </Row>
         <Row>
           <Col md="3" style={{textAlign:"left"}}><b>Account Number</b></Col>
           <Col md="1"><b><FiUser /></b></Col>
           <Col md="3" style={{textAlign:"left"}}>
           {this.props.payment.accountNumber}
           </Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}><b>Transfer Rate</b></Col>
           <Col md="1" ><b><FiSend /></b></Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}>
           {"1 "+this.props.payment.currency+" = "+Number(this.props.payment.exchangeRateToCurrencyToSGD?this.props.payment.exchangeRateToCurrencyToSGD:0)+" SGD"}
           </Col>
         </Row>
         <Row>
           <Col md="3" style={{textAlign:"left"}}><b>Bank Name</b></Col>
           <Col md="1"><b><FiCalendar /></b></Col>
           <Col md="3" style={{textAlign:"left"}}>
           {this.props.payment.bankName}
           </Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}><b>Amount after Conversion</b></Col>
           <Col md="1"><b><FiSend /></b></Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}>
           {parseFloat((this.props.payment.amountPaidinSGD?this.props.payment.amountPaidinSGD:0).toString().replace(/,/g,"")).toLocaleString(undefined, {  maximumFractionDigits: 20 })+" SGD"}
           </Col>
         </Row>
         <Row>
           <Col md="3" style={{textAlign:"left"}}><b>Execution Date</b></Col>
           <Col md="1"><b><FiCalendar /></b></Col>
           <Col md="3" style={{textAlign:"left"}}>
           {this.props.payment.executionDate}
           </Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}><b>Processing Fee</b></Col>
           <Col md="1"><b><FiSend /></b></Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}>
           {Number(this.props.payment.feeChargedinSGD?this.props.payment.feeChargedinSGD:0)+" SGD ("+this.props.payment.paymentType+")"}
           </Col>
         </Row>
         <Row>
           <Col md="7"></Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}><b>Total Amount</b></Col>
           <Col md="1"><b><FiSend /></b></Col>
           <Col md="2" style={{textAlign:"left",padding:"0px"}}>
           {parseFloat((parseFloat(this.props.payment.amountPaidinSGD)+parseFloat(this.props.payment.feeChargedinSGD))).toLocaleString(undefined, {  maximumFractionDigits: 20 })+" SGD"}
           </Col>
         </Row>
         </div>
         <Row>
            
           <Col md="12"><hr/></Col>
           
         </Row>
         
         <Row className="inputRow" style={{width:"100%",paddingLeft:"20px",paddingRight:"20px"}}>
           <Col md="12">
            <p style={{textAlign:"center"}}>Kindly transfer the total amount S$&nbsp;<b>{parseFloat((parseFloat(this.props.payment.amountPaidinSGD)+parseFloat(this.props.payment.feeChargedinSGD))).toLocaleString(undefined, {  maximumFractionDigits: 20 })}</b>&nbsp;to Money World Asia Pte Ltd by&nbsp;<b>{this.props.payment.executionDate}</b>.</p>
           </Col>
         </Row> 
         <Row className="inputRow" style={{width:"100%",paddingLeft:"20px",paddingRight:"20px"}}>
           <Col md={{ size: 4, offset: 5 }} className="inputField  transferMethod">
                <select name="transferMethod" onChange={this.handleChange} required>
                  <option value="" disabled hidden selected>Select</option>
                  <option value="qrCode">QR Code</option>
                  <option value="bankTransfer">Bank Transfer</option>
                </select>
                <br/>
                <label>Payment Method</label>
            </Col>
         </Row>
         <Row className="inputRow" style={{width:"100%",paddingLeft:"20px",paddingRight:"20px"}}>
           <Col md={{ size: 4, offset: 5 }} className="inputField" hidden={this.state.transferMethod!="qrCode"}>
             <img src={qrCode} height={140} width={140} style={{alignContent:"right"}}/>
           </Col>
           <Col md="12" style={{textAlign:"center",fontSize:"10px"}} hidden={this.state.transferMethod!="qrCode"}>
           <p>Only for transactions below S$5,000, or subject to your e-wallet daily limit, whichever lower</p>
           </Col>
            <Col md={{ size: 6, offset: 4 }} className="inputField" hidden={this.state.transferMethod!="bankTransfer"}>
           <p style={{textAlign:"center"}}><b>Account Details for Transferring the Amount</b><br/>
           MoneyWorld Asia Pte Ltd <br/>
           DBS Bank<br/>
           Account Number: 0019005787</p>
           </Col>
         </Row>
         <Row className="inputRow" style={{width:"100%",paddingLeft:"20px",paddingRight:"20px"}}>
           <Col md="12" className="inputField" style={{marginBottom:"0px"}}>
             <Row className="inputRow" style={{width:"100%",paddingLeft:"20px",paddingRight:"20px"}}>
               <Col md={{ size: 5, offset: 2 }} className="inputField" style={{textAlign:"center", marginBottom:"0px"}}>
                  <div className="inputGroup">
                      <input type="text" className="inputText" id="otherDoc"
                        name="customerTransactionId" onFocus={this.onFocus} onBlur={this.onBlur}
                        onChange={this.handleChange} autoComplete="off" required/>
                      <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Transfer Reference No.</span>
                  </div>
               </Col>
               <Col md={{ size: 5 }} style={{textAlign:"center", marginBottom:"0px"}}>
               <div className="custom-file" style={{border:"none"}}>
               <div class="input-container" onClick={() => this.fileField.current.click()} >
                <input type="file" required name="customerTransactionSupportDocument"  accept=".png,.jpeg,.pdf,.jpg" ref={this.fileField} onChange={this.onFileChange} ></input>
                        <button class="browse-btn" onClick={(e)=>e.preventDefault()}>Browse..</button>
                    <span class="file-info">
                    <label  style={{padding:'5px',fontSize:"16px"}} ref={this.fileLabel}>Upload Transfer Receipt</label></span>
               </div>
                 {/* <input type="file" className="form-control" name="customerTransactionSupportDocument" onChange={this.onFileChange} style={{margin:"0px",padding:"0px"}}ref={this.fileField}/>
                 <label for="file-upload" style={{paddingLeft:"15px",paddingTop:"10px",backgroundPositionY:"10px"}} onClick={(e)=>e.target.previousElementSibling.click()} className="custom-file-upload" ref={this.fileLabel}>Upload Payment Recipt</label> */}
               </div>
               </Col>
             </Row>
           </Col>
          
           
         </Row>
         <Row className="inputRow" style={{width:"100%", paddingLeft:"20px", paddingRight:"20px"}}>
                      <Col className="inputField" md={{ size: 6, offset: 4 }}>
                        <Link to="payment/list"><Button className="pageButton secondary"  size="sm"  style={{width:"150px"}}>Save for Later</Button></Link>{' '}
                        <Button className="pageButton primary" size="sm" onMouseEnter={(e)=>e.target.classList.add("activeState")} onMouseLeave={(e)=>e.target.classList.remove("activeState")}onClick={this.onSave}>Submit</Button>
                      </Col>                      
         </Row>
          </ModalBody>
          <ModalFooter>
          <Col md="12">
          <p style={{textAlign:"center"}}><b style={{textAlign:"center"}}>Important Notice </b>
          <ul style={{textAlign:"left"}}>
          <li style={{fontSize:"16px"}}>Transfer reference number is the transaction number that is generated after you have authorised a transfer from your remitting bank to Money World Asia account.</li>
          <li style={{fontSize:"16px"}}>Transfer receipt is the acknowledgement document given by your remitting bank after you have authorised a transfer from your remitting bank to Money World Asia account.</li>
          </ul> 
          </p>
           </Col>
          </ModalFooter>
        </Form>
        </BlockUi>
        </Modal>
      </>
    );
  }
}

export default ConfirmationModal;