import React from "react";

class PrivacyPolicy extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        return (
            <>
            <p style={{textAlign:"center", padding:"20px", fontSize: "2rem"}}><strong>PRIVACY POLICY</strong></p>
            <div style={{padding:"0 15vw", textAlign:"left"}} className="pp_div">
              <ol type="I" style={{padding:"0px"}}>
              <li><strong><strong>LEGAL INFORMATION</strong></strong></li>
              </ol>
              
              <p><span>This privacy policy (&ldquo;</span><strong>Privacy Policy</strong><span>&rdquo;) governs how Money World Asia Pte. Ltd. (&ldquo;</span><strong>MWA</strong><span>&rdquo;</span> <span>&ldquo;</span><strong>we</strong><span>&rdquo;, &ldquo;</span><strong>our</strong><span>&rdquo; or &ldquo;</span><strong>us</strong><span>&rdquo;) use the personal data collected from you, or that you provide to MWA in connection with the access and use of our website (</span><span>http://moneyworld.com.sg/</span><span>) (the &ldquo;</span><strong>Website</strong><span>&rdquo;) and/or the remittance services (the &ldquo;</span><strong>Services</strong><span>&rdquo;) provided by MWA. </span><span>By visiting the Website and/or using the Services, you are deemed to agree to the terms of this Privacy Policy. Please review this Privacy Policy carefully prior to using the Services or otherwise providing any personal data</span><span>. This Privacy Policy forms part of our Terms &amp; Conditions (</span><a href="http://moneyworld.com.sg/terms-conditions/"><span>http://moneyworld.com.sg/terms-conditions/</span></a><span>).</span></p>
              
              
              <ol type="I" start="2" style={{padding:"0px"}}>
              <li><strong><strong>COLLECTION OF PERSONAL DATA&nbsp;</strong></strong></li>
              </ol>
              
              <p><span>Personal data refers to data about an individual who can be identified from either that particular data, or from that data and other information which MWA has or is likely to have access to.</span> <span>Such personal data shall also refer to the data which is already in our possession or the data to be collected by us. Commonly collected personal data of our customers typically include, amongst other things, names, addresses, telephone (landline and mobile) numbers, e-mail addresses, identification numbers, nationalities, dates of birth, specimen signature(s) and banking information.</span></p>
              <p style={{textDecoration:"underline"}}><strong>Collection of personal data</strong></p>
              <p><span>Wherever possible, we only collect personal data directly from you. Without limitation, this can occur during or before you</span></p>
              <ol>
              <li><span>register for an account with MWA;&nbsp;</span></li>
              <li><span>submit an application or request for the use of Services; and/or</span></li>
              <li><span>use the Services.</span></li>
              </ol>
              <p style={{textDecoration:"underline"}}><strong>Use of cookies</strong></p>
              <p><span>The Website may use data collection devices such as &ldquo;cookies&rdquo; and other technologies such as pixel tags and web beacons to personalise your online experience, help analyze the Website flow and measure promotional effectiveness. &ldquo;Cookies&rdquo; are small files placed on your hard drive that assist us in providing the Services. We may offer features that are only available through the use of a &ldquo;cookie&rdquo;. We also use cookies to reduce the number of times you need to enter your password. Cookies may also help us provide to you information targeted in accordance to your interests. Most browsers automatically accept cookies but you can modify your browser to decline cookies (if your browser permits you to do so), although in such a case you may not be able to access certain features on the Website or some interactive features offered on the Website may be restricted or rendered inoperable.&nbsp;</span></p>
              <p><span>The Website may allow third parties (for example, analytics firms and business partners) to download cookies to your device. These third parties may use cookies and other technologies to collect non-personal data about your online activities while you are using the Website. We have no access to, or control over these cookies, or other features these third parties use, and the information practices of these third parties are not covered by this Privacy Policy.&nbsp;</span></p>
              
              
              <ol type="I" start="3" style={{padding:"0px"}}>
              <li><strong><strong>USE OF PERSONAL DATA</strong></strong></li>
              </ol>
              
              <p style={{textDecoration:"underline"}}><strong>Using your personal data</strong></p>
              <p><span>We collect personal data from our existing and prospective customers and employees for various reasons. Without limitation, these purposes include:-</span></p>
              <ol>
              <li><span>fulfilling any of our obligations or exercising any of our rights under the Terms &amp; Conditions;</span></li>
              <li><span>providing and improving our Services and your experience on the </span><span>Website</span><span>;&nbsp;</span></li>
              <li><span>reviewing, processing and confirming any request or application submitted by you in connection with the use of the Services</span></li>
              </ol>
              <p><span>Personal data will be held for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We shall cease to retain personal data, or remove the means by which the personal data can be associated with particular individuals, as soon as it is reasonable to assume that the purpose for which that personal data was collected is no longer being served by retention of the personal data and retention is no longer necessary for legal or business purposes.</span></p>
              <p style={{textDecoration:"underline"}}><strong>Sharing your personal data</strong></p>
              <p><span>We may share your personal information with trusted third parties. Such parties may either be in Singapore or abroad and may include:&nbsp;</span></p>
              <ol>
              <li><span>our advertising, marketing and promotional agencies to help us deliver and analyze the effectiveness of our advertising campaigns and promotions;&nbsp;</span></li>
              <li><span>such third parties as may be required or desirable (as determined by us) to process or deliver Services to you, or otherwise involved in the provision of Services to you, including but not limited to MWA&rsquo;s agents, or any financial institution or payment intermediaries;&nbsp;</span></li>
              <li><span>law enforcement or government authorities where they have followed due legal process to request us to disclose the information;&nbsp;</span></li>
              <li><span>third party providers of services, such as data processing; and/or</span></li>
              <li><span>web analytics tool providers, such as Google.&nbsp;</span></li>
              </ol>
              <p><span>We share your personal data for the following reasons: -&nbsp;</span></p>
              <ol>
              <li><span>reasons provided under &lsquo;using your personal data&rsquo;;</span></li>
              <li><span>verification of your identity;&nbsp;</span></li>
              <li><span>processing transactions for the use of our Services;&nbsp;</span></li>
              <li><span>to protect the rights or property of MWA (including but not limited to any intellectual property rights);&nbsp;</span></li>
              <li><span>to respond to claims that you violated the rights of third-parties;&nbsp;</span></li>
              <li><span>conduct investigations into possible breaches of applicable laws; and/or</span></li>
              <li><span>to comply with a court order or other legal or regulatory requirements in the jurisdictions we operate.&nbsp;</span></li>
              </ol>
              <p><span>In situations where a third party under contract with MWA collects personal data about you, we use commercially reasonable efforts to require the third party to exercise reasonable care in protecting your information.&nbsp;</span></p>
              
              
              <ol type="I" start="4" style={{padding:"0px"}}>
              <li><strong><strong>AMENDMENTS TO PERSONAL DATA / OPT OUT</strong></strong></li>
              </ol>
              
              <p><span>You have a right to access, change and remove the personal data held by MWA. If you wish to do so, please contact our Data Protection Officer at </span><span>admin@moneyworld.com.sg</span><span>. You may also decline to receive e-mails by clicking on &lsquo;unsubscribe&rsquo; in any marketing e-mail you receive.</span></p>
              <p><span>To the extent not required by MWA in connection with providing the Services, if you do not want your personal data to be shared among affiliates, business partners or unrelated third parties, you may opt-out of such information sharing by sending us an email at </span><a href="mailto:admin@moneyworld.com.sg"><span>admin@moneyworld.com.sg</span></a><span>.&nbsp;</span></p>
              
              
              <ol type="I" start="5" style={{padding:"0px"}}>
              <li><strong><strong>SECURITY&nbsp;</strong></strong></li>
              </ol>
              
              <p><span>MWA uses reasonable measures to protect your personal data and to store it securely. Where personal information is entered on the Website, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) technology. However, due to the inherent nature of the Internet as an open global communications vehicle, no method of transmission over the Internet or method of electronic storage is 100% secure. While security cannot be guaranteed, we strive to protect your personal data and are constantly reviewing and enhancing our security protocols to ensure that your personal data is not subject to unnecessary risks.&nbsp;</span></p>
              
              
              <ol type="I" start="6" style={{padding:"0px"}}>
              <li><strong><strong>TRANSFER OF PERSONAL DATA</strong></strong></li>
              </ol>
              
              <p><span>We may transfer, store, process and/or deal with your personal data outside Singapore. In doing so, we will comply with the Singapore Personal Data Protection Act 2012 (&ldquo;</span><strong>PDPA</strong><span>&rdquo;) and other applicable data protection and privacy laws.&nbsp;</span></p>
              <p><span>In the event that MWA, or substantially all of its assets are acquired by one or more third parties as a result of an acquisition, merger, sale, reorganization, consolidation or liquidation, such data bases containing customer personal information may be one of the transferred assets. In using the Services, you consent to such transfer of your personal data.&nbsp;</span></p>
              
              
              <ol type="I" start="7" style={{padding:"0px"}}>
              <li><strong><strong>RIGHTS TO AMEND THIS PRIVACY POLICY&nbsp;</strong></strong></li>
              </ol>
              
              <p><span>We reserve the right to amend this Privacy Policy at any time. If material changes are made to this Privacy Policy, they will be posted on the Website and date stamped. We encourage you to review the Privacy Policy on the Website periodically in order for you to stay notified of any changes.</span></p>
              <p><span>Your continued access and use of the </span><span>Website</span><span> and/or acceptance of our Services after any changes to this Privacy Policy constitutes your consent to any such changes, to the extent such consent is not otherwise provided.</span></p>
              <p><br /><br /><br /><br /><br /></p>
            </div>
            </>
        )
    }
}

export default PrivacyPolicy;