import React from "react";

class TermsAndConditions extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        return (
            <>
              <p style={{textAlign:"center", padding:"20px", fontSize: "2rem"}}><strong>TERMS &amp; CONDITIONS</strong></p> {/*(&ldquo;TERMS&rdquo;)*/}
              <div style={{padding:"0 15vw", textAlign:"left"}} className="tnc_div">
              <ol className="tnc_list">
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MoneyWorld Asia Pte. Ltd (&ldquo;MWA&rdquo;) is licensed under the Payment Services Act (&rdquo;PS Act&rdquo;) of Singapore to operate a remittance business.&nbsp;</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>All charges incurred outside of Singapore are payable by the Applicant unless otherwise specified.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MWA reserves the right to revise all charges for its services from time to time without prior notice.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>Encashment of the remittance is subject to the rules, regulations and market practices of the country where or through which payment is to be made.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MWA may instruct any of its affiliates, another bank, a clearing house, a payment, clearing or settlement system or other payment intermediary (each a "payment intermediary") in the execution of the remittance. Each payment intermediary may rely on the Applicant&rsquo;s remittance instructions as if directed to it. MWA and/or its payment intermediaries further reserves the right to use any means of transfer in connection with the execution of the remittance, including telegraphic transfer, currency swaps or any other means or methods as may be determined by MWA in its sole and absolute discretion.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MWA and its payment intermediaries are subject to the laws, orders, requests and guidelines (each a "regulation") of various governmental, quasi-governmental and self-regulatory authorities (each an "authority") and also to the respective policies and procedures (each a "policy") adopted by MWA and its payment intermediaries relating to, without limitation: anti-money laundering; counter-terrorism financing; security, diplomatic, trade, financial, economic and other embargoes and sanctions; court orders; investigations; taxes; transaction review and monitoring; and reporting requirements. In seeking to comply with such regulations and policies, MWA and/or its payment intermediaries may (a) suspend, reject or block the remittance; (b) restrain, deduct, impound and/or turn over to an authority, Applicant's funds in respect of the remittance, including from Applicant's accounts; (c) report the remittance, its details and those of Applicant to one or more authorities; and (d) take or forbear from taking any other action.&nbsp; Applicant shall bear the risk of any delay and losses relating to any and all actions or forbearance from action. MWA and its payment intermediaries may not be permitted to disclose information relating to actions taken in respect of regulations or their policies, immediately or at any time.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MWA may suspend or terminate the execution of the remittance as a result of any force majeure event, including, without limitation: (a) change in any regulation or in the interpretation or enforcement of any regulation; (b) act, order or request of any authority; (c) restriction or impending restriction on the availability, convertibility, credit or transferability of any currency; (d) failure or default of any payment intermediary; (e) malfunction of a computer system; (f) failure or default of a service provider or vendor to MWA; (g) any failure in the transmission of, or miscommunication with respect to, the remittance instruction; or (h) any other event beyond MWA&rsquo;s reasonable control. The Applicant shall bear the risk of any delay and losses relating to a force majeure event.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>The Applicant consents to MWA disclosing to its affiliates, to its and their respective service providers, insurers and professional advisors and to payment intermediaries and authorities, and as required by regulation, the information that the Applicant furnished in the application. The Applicant also consents to the data privacy policy contained on MWA&rsquo;s website, as may be amended by MWA from time to time. A physical copy of the privacy policy is kept at the offices of MWA and may be requested by the Applicant for viewing during the regular business hours of MWA.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MWA and its affiliates shall not be liable to the Applicant for any loss, damage or expense suffered or incurred by the Applicant or any other person however arising or described and whatever the legal basis of the liability in connection with the remittance, the execution of the remittance by MWA&rsquo;s payment intermediaries, and any delay or inability to effect the remittance owing to any event beyond the MWA&rsquo;s control or through no fault of the MWA, save where such loss or damage is directly caused by fraud or willful misconduct of the MWA or its employees. MWA shall not be liable for any indirect or consequential loss or loss of profit whether or not they were foreseeable. MWA shall also not be responsible for any loss the Applicant may suffer or incur in connection with any act or omission of a payment intermediary.&nbsp;</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>The Applicant shall indemnify MWA and its affiliates on demand against, and hold from, any and all losses, damages, costs, claims, expenses and liabilities, whether or not reasonably foreseeable, sustained or incurred by MWA and/or its affiliates as a result of or in connection with MWA acting on the Applicant&rsquo;s instructions herein, effecting the transfer of funds (except where due solely to the gross negligence, willful default or fraud of MWA), any breach of these Terms, or otherwise in connection with the enforcement by MWA of any of its rights hereunder. The Applicant agrees that this indemnification obligation hereunder is an unconditional and independent obligation and shall not be affected, prejudiced or limited by the effectiveness or enforceability of any agreement.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>MWA reserves the right not to accept any application without having to assign any reason for doing so.</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>These Terms are subject to the terms and conditions contained on MWA&rsquo;s website (http://moneyworld.com.sg/terms-conditions/). In the event of any inconsistencies, the terms and conditions on the website shall prevail. MWA reserves the right to add, alter, vary and modify any of these terms and conditions, including those contained on the website, at any time at its discretion without any prior notice.&nbsp;</span></li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>For any enquiries, please call our general enquiry number at (65) 6221 1755. The operating hours are as follows:&nbsp;</span>
              <p><span style={{fontWeight: "400"}}>Mon &ndash; Fri: 8.30 am to 6.00 pm;</span></p>
              <p><span style={{fontWeight: "400"}}>Sat, Sun and Public Holidays: Closed.</span></p>
              </li>
              <li style={{fontWeight: "400",margin:"10px 0px"}}><span style={{fontWeight: "400"}}>These Terms are governed by the laws of Singapore. The parties submit to the non-exclusive jurisdiction of the courts of Singapore.</span></li>
              </ol>
              <p><br /><br /></p>
              </div>
            </>
        )
    }
}

export default TermsAndConditions;