import React from "react";
import { Button, Row, Col, Container } from "reactstrap";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Spacer from "../../components/Spacer/Spacer";

class UserVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modal: false,callmodal:true}
    this.docName           ='';
    this.getCustomer      = this.getCustomer.bind(this);
    this.getCustomer();
    
  }
  state = {
    visible: true
  };
  
  getCustomer(){
      axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/profile')
      .then(res =>{
        this.setState({name:res.data.customer.name});
      }).catch(function (error) {}) 
  }
  
  updateSeenAttrib() {
    Auth.currentAuthenticatedUser()
    .then((data) => {
      Auth.updateUserAttributes(data, {
        "custom:verificationComplete": "true"
      }).then((data) => {window.location = "/home"}).catch((err) =>  {})
    })
    .catch((err) =>  {
      this.setState({user: undefined})
    })
    
  }


  render() {
    let message;
    let headerMessage;
    let button;
    let name=this.state.name;
    if(this.props.user.attributes.profile === "PendingApproval" || this.props.user.attributes.profile ==="PendingClarification"){
      headerMessage="Verifying Profile"
      message = "Hello! We are verifying your profile and will notify you over email as soon as the review is complete."
      button=''
    }
    else if(this.props.user.attributes.profile === "Rejected" || this.props.user.attributes.profile === "AutoReject"){
      headerMessage="Sorry!!"
      message = "Your profile was not approved. For clarification, please call Customer Service."
      button=''
    }
    else if(this.props.user.attributes.profile === "Approved" || this.props.user.attributes.profile ==="AutoApproveWith2Year" || this.props.user.attributes.profile ==="AutoApproveWith3Year" && this.props.user.attributes["custom:verificationComplete"] !== "true") {
      headerMessage="Congratulations!!"
      message = "Your profile is approved is approved by our MWA team. You can continue with you transactions." // You can go on to add beneficiaries and make payments.
      this.updateSeenAttrib() 
      button = <a href="/beneficiary/add"><Button style={{textAlign:"right",backgroundColor:'#8b0305'}}>Continue to Add Beneficiaries</Button></a>;  //Proceed to Add Beneficiary
      
  }
  
  return (
      <>
       <Container fluid className="mainContent">
       <Container className="section">
        <Row className="inputRow">
        <Col md="12">
        <Spacer height={10}/>
              <label style={{fontSize:'80px !important',marginRight:'6px'}} className="inputRowUserVerify" >Hi{' '}</label>
              <label style={{color:'#4a4a4a',fontSize:'20px',fontWeight:'bold'}} className="inputRowUserVerify">{name},</label>
              <br/>
          </Col>
        </Row>
        <Row className="inputRow">
          <Col>
          <div >
          <Spacer height={55}/>
                    <label  className="inputRowUserVerifyBody" style={{fontSize:'30px !important',color:'#4a4a4a !important'}}>{message}</label>
          </div> 
          </Col>
        </Row>
        <Row className="inputRow">
        <Col md="6"></Col>
          <Col md="4">
          <Spacer height={17}/>
          {button}
          </Col>
        </Row>
        </Container>
        </Container>
      </>
    );
  }
}

export default UserVerify;
