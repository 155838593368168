var UserModel = {
        'name':"",
        'alias':'',
        'gender':'',
        'dateOfBirth':'',
        'residentialStatus':'',
        'nationality':'',
        'idType':'',
        'idNumber':'',
        'idExpiryDate':'',
        'politicallyExposed':'',
        'email':'',
        'occupation':'',
        'employer':'',
        'callingCode':'',
        'phoneNumber':'',
        'mobOTP':'',
        'homeTelephoneNumber':'',
        'faxNumber':'',
        'floor':'',
        'apartmentNumber':'',
        'street':'',
        'street2':'',
        'streetNumber':'',
        'block':'',
        'source':'',
        'postal':'',
        'allValues':'',
        'fieldsNotFromMyInfo':'',
        'monthlyIncome':'' ,
        'building':'',
        'assessableIncome':'',
        'country':'',
        'customerState':'',
        'profileImageFileName':'',
        'profileType':'',
        'validationFlag':'',
        'sourceOfIncomeOther':'',
        'purposeOther':'',
        'indicativeTransactionLimit':'',
        'sourceOfIncome':[],
        'purpose':[],
        'chineseName':'',
        'altemail':'',
        'myInfoNRIC ':'',
        'yearofAssessment':'',
        'registeredBusinessName':'',
        'princpalBusinessActivites':'',
        'busiessRegistrationNumber':'',
        'companyLogo':'',
        'dateOfRegistration':'',
        'typeOfRegistration':'',
        'registeredBusinessAdderss':'',
        'registeredBusinessAdderss2':'',
        'contactNumber':'',
        'personnelEmailId':'',
        'jobTile':'',
        'taxresident':'',
        'mobileNumberVerified':''

}

export default UserModel;