import home from '../assets/img/home.svg';
import contact from '../assets/img/contact.svg';
import beneficiary from '../assets/img/beneficiary.svg';
import user from '../assets/img/user.svg';
import transactions from '../assets/img/transactions.svg';
import documents from '../assets/img/documents.svg';
import Home from '../views/home/home.jsx';
import Beneficiary from 'views/beneficiary/beneficiary.jsx';
import Transaction from 'views/transaction/transaction.jsx';
import UserDetails from 'views/userProfileDisplay/UserDetailsDisplay.jsx';
import doc from "views/User/userDocument.jsx";
import ContactUs from 'views/ContactUs/ContactUs.jsx';


var dashRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: home ,
    component: Home
  },
  {
    path: "/transaction",
    name: "Transactions",
    icon: transactions,
    component: Transaction
  },
  {
    path: "/beneficiary",
    name: "Beneficiaries",
    icon: beneficiary,
    component: Beneficiary
  },
  {
    path: "/user",
    name: "Profile",
    icon: user,
    component: UserDetails
  },
  {
    path: "/documents",
    name: "Documents",
    icon: documents,
    component: doc
  },
  {
    path: "/contact",
    name: "Support",
    icon: contact,
    component: ContactUs
  },

  { redirect: true, path: "/", pathTo: "/home", name: "Home" }
];
export default dashRoutes;
