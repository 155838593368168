import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import beneficiaryRoutes from "routes/beneficiary.jsx";

class Beneficiary extends React.Component {
    render() {
        return (
            <>
            <Switch>
                {beneficiaryRoutes.map((prop, key) => { 
                if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                }
                return (
                    <Route path={prop.path} component={prop.component} key={key} exact/>
                );
                })}
            </Switch>
            </>
        );
    }
}

export default Beneficiary;