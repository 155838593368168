import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import transactionRoutes from "routes/transaction.jsx";

class Transaction extends React.Component {
    render() {
        
        return (
            <>
            <Switch>
                {transactionRoutes.map((prop, key) => { 
                if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                }
                return (
                    <Route path={prop.path} component={prop.component} key={key} />
                );
                })}
            </Switch>
            </>
        );
    }
}

export default Transaction;