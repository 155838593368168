function isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
}

function passwordValidationError(password) {
        if (!password.match(/[a-z]/g))
                return "Password must contain a lowercase letter"
        if (!password.match(/[A-Z]/g))
                return "Password must contain an uppercase letter"
        if (!password.match(/[0-9]/g))
                return "Password must contain a number"
        if (password.length < 8 || password.length > 64)
                return "Password must be 8 to 64 characters long"
        return null        
}

function passwordValidationLoginError(password) {
        if (!password.match(/[a-z]/g))
                // return "Password must contain a lowercase letter"
                return "Invalid password"
        if (!password.match(/[A-Z]/g))
                // return "Password must contain an uppercase letter"
                return "Invalid password"
        if (!password.match(/[0-9]/g))
                // return "Password must contain a number"
                return "Invalid password"
        if (password.length < 8 || password.length > 64)
                // return "Password must be 8 to 64 characters long"
                return "Invalid password"
        return null        
}

function nameValidationError(name) {
        const re=/^[_ a-zA-Z0-9-.,;]+$/
                return re.test(String(name));   
}

export { isValidEmail, passwordValidationError ,passwordValidationLoginError,nameValidationError}