import React from "react";
import { Link } from "react-router-dom";
import check from "assets/img/check.svg";
import {
  Collapse,
  ListGroup, ListGroupItem, } from 'reactstrap';

class Sidebar extends React.Component{
    constructor(props){
        super(props)
        this.state  = {isOpen:false}
        this.toggle = this.toggle.bind(this); 
    }
    
    toggle(){
      this.setState({isOpen: !this.state.isOpen});
    }
    activeClassCheck(pathName){
      return window.location.href.indexOf(pathName)>-1? "active" : "";
    }
    
    render(){
        return(
          <>
          <div className="sidebarWrapper" style={{backgroundColor:(this.props.bgColor?"#fbfbfb":"#f7f7f7")}}>
          <ul className="sidebarNav">
            { this.props.routes.map((prop)=>{
                if (prop.redirect) return null;
                return (
                  <>
                  <li className={"sidebarNav " + this.activeClassCheck(prop.path)}>
                  <Link to={(this.props.disabled && (prop.path === "/transaction" || prop.path === "/beneficiary"))?"/home":prop.path}><span className="navIcon"><img src={prop.icon}/></span><a>{prop.name}</a></Link>{/*prop.path === "/beneficiary" || */}
                  </li>
                  <div className="spacer"></div>
                  </>
                );
              })     
            }
          </ul>
        </div>
        <Collapse isOpen={this.state.isOpen} className="collapsible">
        <ListGroup>
        { this.props.routes.map((prop)=>{
                if (prop.redirect) return null;
                return (
                  <>
                  <ListGroupItem><Link to={this.props.disabled?"/home":prop.path}><span className="navIcon"><img src={prop.icon}/></span><a style={{marginLeft:"20px"}}>{prop.name}</a></Link></ListGroupItem>
                  </>
                );
              })     
            }
        <ListGroupItem><span className="navIcon"><img src={check}/></span><a style={{marginLeft:"20px"}}>LOGOUT</a></ListGroupItem>
        </ListGroup>  
        </Collapse>
        </>
        )
    }
}
export default Sidebar;