export const occupation=["Accountant (excluding tax accountant)",
"Accounting associate professional (e.g. assistant accountant, audit (accounting) executive)",
"Acrobat",
"Actor",
"Actuary",
"Administration manager",
"ADMINISTRATION PROFESSIONALS",
"ADMINISTRATIVE AND COMMERCIAL MANAGERS",
"Advertising and Marketing Professionals",
"Advertising copywriter",
"Advertising salesman",
"Advertising/Public relations manager",
"Advocate/Solicitor (practising)",
"Aerialist",
"Aeronautical engineer",
"Aeronautical engineering technician",
"After sales adviser/Client account service executive",
"Aged care services manager",
"Agricultural and fishery labourer and related worker n.e.c. (e.g. farm labourer)",
"Agricultural worker n.e.c.",
"Agronomist",
"Air cargo officer",
"Air traffic controller",
"Air transport clerk",
"Air transport equipment project executive",
"Air transport service supervisor",
"Air-conditioning/Refrigeration engineer",
"Air-conditioning/Refrigeration engineering technician",
"Air-conditioning/Refrigeration equipment mechanics",
"Air-conditioning/Refrigeration plant installer",
"Aircraft cleaner",
"Aircraft engine fitter",
"Aircraft engine mechanic",
"Aircraft loader (e.g. airport baggage/cargo handler)",
"Aircraft pilot (except commercial airline and air force)",
"Airport mobile equipment driver",
"Airport/Harbour receptionist/clerk (e.g. check-in agent)",
"Airworthiness surveyor",
"Ambulance driver",
"Amusement/Theme park manager",
"Anaesthesiologist",
"Anatomist",
"Animal scientist",
"Animator",
"Anodiser",
"Anthropologist",
"Applications/Systems programmer",
"Appraiser/Valuer (excluding intangible asset valuer)",
"Aquaculturist",
"Aquarium fish farm worker",
"Arbitrator and Mediator",
"Architectural draughtsman",
"Archivist",
"Art and craft instructor (extracurriculum)",
"ARTISTIC AND CULTURAL ASSOCIATE PROFESSIONALS",
"Arts and cultural centre manager (including gallery and museummanager)",
"Asbestos cement product making machine operator",
"Assessor",
"Assistant chemical engineer",
"Assistant civil and structural engineer",
"Assistant electrical engineer",
"Assistant electronics engineer",
"Assistant manufacturing engineer",
"Assistant mechanical engineer",
"Astrologer/Fortune teller and related worker",
"Athlete/Sportsman",
"Auctioneer",
"Audio and video equipment engineer",
"Audio and video equipment technician",
"Audiologist",
"Audit clerk",
"Auditor (accounting)",
"Author",
"Automated assembly line/Industrial robot operator",
"Automation designer",
"Automation engineer (including robotics engineer)",
"Automation technician",
"Automotive engineer",
"Automotive engineering technician",
"Auxiliary police officer",
"Babysitter/Infant care-giver",
"Baker (general)",
"Bank operations clerk",
"Bank teller",
"Bar/Lounge hostess",
"Barber",
"Barista",
"Bartender/Mixologist",
"Battery assembler",
"Bean curd making machine operator",
"Beautician",
"Bell captain",
"Bellboy/Hotel porter",
"Bill collector",
"Billing clerk",
"Biochemist",
"Biological technician",
"Biologist (general)",
"Biomedical engineer",
"Biscuit making machine operator",
"Blacksmith (general)",
"Boiler fireman",
"Boiler/Pipe insulator",
"Boilermaker",
"Book editor",
"Book embossing machine operator",
"Bookbinding machine operator",
"Bookkeeper",
"Bookmaker/Croupier/Casino dealer and related gaming worker",
"Boring/Drilling machine setter-operator",
"Botanist",
"Bread baker",
"Brewer",
"Brick and tile moulder",
"Bricklayer/Blocklayer",
"Broadcasting operations technician",
"Budgeting and financial accounting manager (including financial controller)",
"Buffing/Polishing machine operator",
"Building and construction project manager",
"Building architect",
"Building caretaker/Watchman",
"Building construction engineer",
"Building finisher and related trades worker n.e.c.",
"Building insulator",
"Building maintenance worker",
"Building painter",
"Building security manager",
"Building structure cleaner and related worker",
"Building technician",
"Bulldozer operator",
"Bus attendant",
"Bus driver",
"Business consultant",
"Business development executive",
"Business development manager",
"BUSINESS SERVICES AND ADMINISTRATION MANAGERS",
"Butler (hotels and other establishments)",
"Butter making machine operator",
"Buyer",
"Cabin attendant/steward",
"Cabinet and furniture maker",
"Cage/Count supervisor",
"Call centre manager",
"Captain waiter/Waiter supervisor",
"Car park attendant",
"Cardiologist",
"Cardiothoracic surgeon",
"Career coach (counselling)",
"Carpenter",
"Cartographer",
"Carton/Paper box making machine operator",
"Cartoonist",
"Cashier (general)",
"casino",
"Casino operations manager",
"Catering and events manager",
"Cement finisher/Screeder",
"Cementer (petroleum and gas wells)",
"Centrifugal casting machine operator",
"Ceramics/Glass technologist",
"Chauffeur",
"Chef",
"Chemical engineer (general)",
"Chemical engineer (petrochemicals)",
"Chemical engineer (petroleum)",
"Chemical engineering technician (general)",
"Chemical engineering technician (petrochemicals)",
"Chemical engineering technician (petroleum and natural gas)",
"Chemical filtering and separating equipment operator",
"Chemical heat treating plant operator",
"Chemical processing, chemical and photographic products plant and machine operator n.e.c.",
"Chemical still/reactor operator",
"Chemist",
"Chemistry technician",
"Chief concierge (hotel)",
"Chief engineer / Second engineer (ship)",
"Chief information officer/Chief technology officer/Chief security officer",
"Chief operating officer/General Manager",
"Chief steward",
"Child /After school care centre worker",
"Child/Youth residential care assistant",
"Chiropractor",
"Chocolate making machine operator",
"Choreographer",
"Cigarette making machine operator",
"Cinema manager",
"Cinema projectionist",
"Civil engineer (general)",
"Civil engineering technician",
"Civil engineering/Building construction labourer",
"Civil/Structural engineering draughtsman",
"Cladding/Curtain wall erector",
"Cleaner in open areas (e.g. bus stops, drains, waterways, overhead bridges, roads, expressways, parks, beaches)",
"Cleaner in other establishments (e.g. shopping malls, schools, hospitals, places of worship)",
"Cleaning supervisor",
"Clearing and forwarding agent",
"Client information clerk n.e.c.",
"Clinical research coordinator",
"Clinical research professionals",
"Clown",
"Coastal waters/Deep sea fishery worker",
"Cobbler",
"Coffee and tea taster",
"Coffee/Cocoa bean roasting and grinding machine operator",
"Coffee/Tea blending machine operator",
"Commercial airline pilot",
"Commercial artist",
"Commodities and freight derivatives broker",
"Community health worker",
"Community, partnership and relations manager",
"Community/Country club manager",
"Company director",
"Compliance officer/Risk analyst (financial)",
"Composer/Orchestrator",
"Compositor/Typesetter",
"Compressor operator",
"Computer and related electronic equipment mechanic",
"Computer engineer",
"Computer systems operator",
"Computer technician (including IT user helpdesk technician)",
"Concierge (hotel)",
"Concrete form worker",
"Concrete mix truck driver",
"Concrete mixing plant operator (including concrete mixer operator)",
"Concrete pump operator",
"Conductor/Director of orchestra/band/choir",
"Confectionery making machine operator",
"Confinement nanny",
"Conservator (art works)",
"Consumer audio and video equipment mechanic",
"Contact centre information clerk",
"Continuity and script editor",
"Continuous rod casting machine operator (non-ferrous metal)",
"Cook (e.g. commis cook 2/3)",
"Coremaker",
"Counsellor (drugs and alcohol)",
"Counsellor (family)",
"Counsellor (rehabilitation)",
"Crane operator (port)",
"Crane/Hoist operator (general)",
"Creative advertising professional",
"Credit and loans officer",
"Crewing executive (ship)",
"Crocodile farm worker",
"cryptocurrency",
"Crushing/Grinding/Chemical mixing equipment operator",
"Curator (art gallery and museum)",
"Customer service manager",
"Customs/Immigration officer",
"Cylinder filler/tester (compressed and liquefied gases)",
"Dairy product pasteurising machine operator",
"Dairy product processing machine operator (general)",
"Dance instructor (extracurriculum)",
"Dancer",
"Data entry clerk",
"Data processing control clerk",
"Data scientist",
"Database administrator",
"Database and network professional n.e.c.",
"Database architect",
"Database Designers and Administrators",
"Deckhand (including lighterman)",
"Decorator and related worker (e.g. window dresser and set designer)",
"Deep-boring machine operator",
"Delivery man using motorised personal mobility devices",
"Demolition worker",
"Dental assistant",
"Dental technician",
"Dentist (general)",
"Dermatologist",
"Despatch worker (mails)",
"Diagnostic radiologist",
"Die casting machine operator",
"Dietitian",
"Digital marketing professional (e.g. online, social media, e-commerce marketing professional)",
"Disc jockey",
"Discotheque/Karaoke/Nightclub manager",
"Dish washer/Plate collector/Table-top cleaner",
"Dog trainer",
"Domestic helper (general)",
"Doorman",
"Draughtsman (general)",
"Dredge operator",
"Driving instructor/tester",
"Dubbing artiste",
"Dyeing machine operator",
"Early intervention teacher for infants and children",
"Economist",
"Editor (news and periodicals)",
"Editor (radio, television and video)",
"Educarer",
"Education methods adviser",
"Electric cable jointer",
"Electrical engineer (general)",
"Electrical engineering technician (general)",
"Electrical engineering technician (high voltage)",
"Electrical equipment/component assembler",
"Electrical fitter (general)",
"Electrical household appliance repairer",
"Electrical lift, escalator and related equipment fitter",
"Electrical line installer",
"Electrical/Electronic products quality checker and tester",
"Electrical/Electronics draughtsman",
"Electrician",
"Electro-plater",
"Electronic equipment/component assembler",
"Electronic pre-press technician",
"Electronics engineer (general)",
"Electronics engineering technician (general)",
"Electronics fitter (general)",
"Electrotyper",
"Embalmer",
"Embedded systems engineer (including port systems engineer)",
"Emergency physician",
"Employment agent/Labour contractor",
"Energy manager",
"Enrolled/Assistant nurse (excluding registered nurse)",
"Enterprise/Solution architect",
"Enumerator/Market research interviewer",
"Environment research scientist (e.g. ecologist)",
"Environmental engineer",
"Environmental inspector (environmental public health)",
"Environmental officer (environmental protection)",
"Environmental officer (public health)",
"Excavating/Trench digging machine operator",
"Executive search consultant",
"Exhibition/Conference/Event planner (including wedding planner/coordinator)",
"Extruder operator (metal)",
"Farming technician (animal production)",
"Fashion model",
"Fashion/Garment designer (including jewellery designer)",
"Fast food preparer",
"Feed mixing machine operator",
"Fermenting-room man",
"Fibreglass maker",
"Financial analyst (e.g. equities analyst, credit analyst, investment research analyst)",
"Financial derivatives dealer/broker",
"Financial/Insurance services manager (e.g. financial institution branch  manager)",
"Financial/Investment adviser (e.g. relationship manager)",
"Fire and safety inspector",
"Fire-engine driver",
"Fire-fighting and rescue officer",
"Fish feed making machine operator",
"Fish/Prawn farm worker",
"Flame cutter",
"Flight engineer",
"Flight navigator",
"Flight operations officer",
"Flight radio operator",
"Floor/Wall tiler",
"Floriculturist/Orchidologist",
"Flower/Orchid farm worker",
"Flying instructor (except air force)",
"Food & beverage operations manager",
"Food and beverage establishment cleaner (e.g. restaurants, food courts, hawker centres)",
"Food and drink technologist",
"Food processing and related trades worker n.e.c. (e.g. fruit pickler)",
"Food science technician",
"Food service counter attendant",
"Food/Drink stall assistant",
"Foreign armed forces personnel",
"Foreign diplomatic personnel",
"Foreign exchange dealer/broker",
"Forging-press operator",
"Fork lift truck operator",
"Freight inspector",
"Fruit-press operator",
"Fumigators/Pest and weed controllers",
"Fund/Portfolio manager (including asset allocator)",
"Furniture upholsterer",
"Gallery, museum and related technician",
"Galvaniser",
"Gaming supervisor (e.g. pit manager/supervisor, slot manager/supervisor and casino shift manager/supervisor)",
"Garden labourer",
"Gardeners and horticultural workers (e.g. landscape technician)",
"Garment and related trades worker n.e.c. (e.g. fellmonger, tent sewer, umbrella maker)",
"Garment cutter",
"Garment marker",
"Garment pattern-maker",
"Gastroenterologist",
"Gem setter/cutter and polisher",
"General practitioner/physician",
"General surgeon",
"General waste and recyclables collection worker",
"Geologist",
"Geophysicist",
"Germination worker (malting)",
"Glass and ceramic painter/decorator",
"Glass blower",
"Glass engraver/etcher",
"Glass lens moulder",
"Glass/Ceramics kiln machine operator",
"Glazier",
"Goldsmith/Silversmith",
"Government licensing official",
"Government social benefits official",
"Government tax and excise official",
"Grain/Spice milling machine operator",
"Graphic designer",
"Grass cutter",
"Greasy waste collection worker",
"Hair stylist/Hairdresser",
"Hand launderer/presser (non-household)",
"Hand packer",
"Hand surgeon",
"Hand/Pedal vehicle driver",
"Harbour pilot",
"Hawker/Stall holder (excluding prepared food or drinks)",
"Hawker/Stall holder (prepared food or drinks)",
"Head butler (hotels and other establishments)",
"Health services manager",
"Healthcare assistant (e.g. therapy aide/assistant)",
"Historian",
"Hoisting equipment rigger (construction)",
"Hoisting equipment rigger (general)",
"Horse trainer",
"Horticultural technician (including irrigation specialist)",
"Horticulturist/Arborist",
"Hospital/Clinic attendant",
"Hot-dip plater",
"Hotel cleaner",
"Hotel executive (including hotel reservation and front desk executive)",
"Hotel operations/Lodging services manager",
"Hotel receptionist (including hotel reservation and front desk clerk)",
"House steward",
"Housekeeper (hotels and other establishments)",
"Housekeeping matron",
"Housekeeping/Linen room attendant",
"Human resource consultant (excluding executive search consultant)",
"Hydrogenation operator (oils and fats)",
"Hydrographic surveyor",
"Hydroponics farm worker",
"Ice making machine operator",
"Ice-cream making machine operator",
"ICT sales and services professionals",
"In-house legal counsel (except judiciary, ministries and statutory boards)",
"Industrial establishment cleaner",
"Industrial investigator",
"Industrial machinery and tools engineer",
"Industrial relations manager",
"Industrial relations officer",
"Industrial safety engineer",
"Industrial/Office machinery mechanic",
"Information technology auditor",
"Information technology project manager",
"Information technology security specialist",
"Information technology testing/quality assurance specialist",
"Information technology trainer (extracurriculum)",
"Instrumentalist",
"Instrumentation engineer",
"Instrumentation technician",
"Insurance investigator",
"Insurance sales agent/broker (including independent financial planner)",
"Insurance underwriter",
"Insurance/Underwriting clerk",
"Intangible asset valuer (e.g. Intellectual property valuation analyst)",
"Intellectual property brokers and transaction specialists",
"Interaction/User experience designer",
"Interior designer",
"Internal medicine physician",
"International market agent/representative (e.g. junket operator)",
"Interpreter",
"IT business process consultant/business analyst",
"IT service manager",
"Jewellery engraver",
"Jewellery worker (general)",
"Jig and gauge maker",
"Joiner",
"Journalist",
"Judge (including registrar in judiciary)",
"Junior college and pre-university teacher (including integrated programme year 5-6 teacher)",
"Keeper/Trainer in crocodile farm",
"Keeper/Trainer in zoological, bird and aquatic parks",
"Kiln drier",
"Kilnman (malting)",
"Kitchen assistant",
"Kitchen operations head/supervisor",
"Knitting machine operator",
"Laboratory attendant",
"Land surveying technician",
"Land surveyor",
"Land transport clerk",
"Landscape architect",
"Landscape designer",
"Landscape operations manager",
"Landscape operations officer",
"Language instructor (extracurriculum)",
"Lashing worker",
"Lathe setter-operator",
"Laundry and dry cleaning worker (machine, non-household)",
"Lawyers",
"Leaflet and newspaper distributor/deliverer",
"Leather goods maker/assembler",
"Ledger and accounts clerk",
"Legal associate professional (e.g. paralegal)",
"Legal clerk",
"Legislator",
"Lens grinding machine operator",
"Lens polishing machine operator",
"Librarian",
"Library clerk",
"Library officer",
"Lifeguard",
"Lift engineer",
"Livestock/Dairy farm worker",
"Load-sheet officer (aircraft)",
"Locksmith",
"Logistics planner",
"Lorry attendant",
"Lorry driver",
"Macaroni/Noodle/Vermicelli making machine operator",
"Machine embroiderer",
"Machinery erector/installer",
"Machinery fitter (general)",
"Machinery mechanic (general)",
"Machining/Tooling technician",
"Magician",
"Make-up artist (stage, film and studio)",
"Management and Business Consultants",
"Management consultant",
"Management executive",
"Managing director/Chief executive officer",
"Managing Directors, Chief Executives and General Managers",
"Manicurist",
"Manufacturing engineer (general)",
"Manufacturing engineering technician (general)",
"Manufacturing Engineering Technicians",
"Manufacturing labourer and related worker n.e.c. (e.g. rubber sheets clipper and sorter)",
"Manufacturing plant/production manager",
"Marine draughtsman",
"Marine engine fitter",
"Marine engineer (shore-based)",
"Marine engineering officer (including third, fourth and fifth engineer)",
"Marine superintendent",
"Marine superintendent (engineer)",
"Marine surveyor (ship and nautical)",
"Market research professional",
"Marketing and sales executive (food & beverage services)",
"Marketing strategy/planning professional",
"Martial arts instructor",
"Massage therapist",
"Masseur (non-medical) (including foot reflexologist)",
"Material planning clerk",
"Materials engineer",
"Mathematician",
"Mattress maker",
"Mechanical draughtsman",
"Mechanical engineer (general)",
"Mechanical engineering technician (general)",
"Mechanical equipment/component assembler",
"Mechanical products quality checker and tester",
"Mechatronics technician",
"Medical and pathology laboratory technician",
"Medical and pharmaceutical products sales professionals",
"Medical device assembler/repairer",
"Medical diagnostic radiographer",
"Medical oncologist",
"Medical scientist",
"Medical social worker",
"Medical X-ray technician",
"Medical/Dental receptionist",
"Merchandising/Category executive",
"Merchandising/Category manager",
"Metal cleaner",
"Metal heat treating plant operator",
"Metal moulder",
"Metal pattern maker",
"Metal rolling mill worker",
"Metal shipwright",
"Metal sprayer",
"Metallurgist",
"Metalworking machine setter-operator (general)",
"Meteorologist",
"Meter reader/Vending-machine collector",
"Milling machine setter-operator",
"Minister of religion",
"Mobile machinery operator n.e.c. (e.g. mobile farm plant operator)",
"Mobile machinery supervisor and general foreman",
"Money changer",
"Moneylender",
"Motor vehicle body builder",
"Motor vehicle cleaner/polisher",
"Motor vehicle mechanic",
"Motor vehicle spray painter",
"Motorcycle delivery man (non-mail items)",
"Motorised sweeper operator",
"Mould lofter",
"Mover",
"Money lenders",
"MRT operations officer",
"MRT service supervisor",
"MRT train operator",
"Multimedia (including games) designer",
"Multimedia (including games) developer",
"Multimedia artist",
"Mushroom farm worker",
"Music instructor (extracurriculum)",
"Musical instrument maker/repairer/tuner",
"Narcotics officer",
"Nature guide (including zoo, birdpark and aquarium)",
"Naval architect",
"Network and communications manager",
"Network, servers and computer systems administrator",
"Network/Infrastructure architect, engineer and specialist",
"Neurosurgeon",
"Newsvendor",
"Non-profit organization",
"Non-ordained religious associate professional",
"Numerical control machine setter-operator",
"Nursery supervisor",
"Nursery worker (e.g. nursery technician)",
"Nursing aide/assistant",
"Nutritionist",
"Obstetrician/Gynaecologist",
"Occupational health and safety inspector",
"Occupational health professional (e.g. occupational hygienists and occupational hygiene officer)",
"Occupational therapist",
"Odd job person",
"Office cashier",
"Office cleaner",
"Office clerk (including filing and copying)",
"Office supervisor",
"Office/Library attendant",
"Oil and gas well drilling rigger",
"Oil pressman (edible oils)",
"Online gaming",
"Online sales channel executive",
"Online sales channel manager",
"Operations officer (except transport operations)",
"Operations research analyst",
"Ophthalmologist",
"Optical and electronic equipment operator n.e.c.",
"Optical engineer (including laser engineer)",
"Optical glass cutter",
"Optical instrument maker/repairer",
"Optician",
"Optometrist",
"Oral health therapist (e.g. dental therapists and dental hygienists)",
"Ore and metal furnace operator (including furnaceman)",
"Orthopaedic footwear maker",
"Orthopaedic surgeon",
"Orthotist/Orthopaedic appliance maker and repairer",
"Osteopath",
"Other accounting clerks (e.g. cost clerk)",
"Other administration professionals n.e.c.",
"Other administrative and related associate professionals n.e.c.",
"Other administrative clerks (e.g. public relations clerk)",
"Other agronomy, horticultural and farming related technicians",
"Other aircraft pilots and related associate professionals",
"Other animal/bird keepers and animal care workers",
"Other aquatic life cultivation workers (e.g. oyster farm worker)",
"Other artistic and cultural associate professionals n.e.c.",
"Other assemblers n.e.c. (e.g. plastic products assembler, rubber products assembler)",
"Other assistant engineers",
"Other associate professionals n.e.c.",
"Other attendants",
"Other authors and related writers",
"Other baked and cereal products machine operators",
"Other bill collectors and related workers",
"Other biologists, botanists, zoologists and related professionals",
"Other blacksmiths, hammersmiths and forging-press workers",
"Other bleaching, dyeing and fabric cleaning machine operators",
"Other brewers and wine and other beverage machine operators",
"Other broadcasting technicians and audio-visual operators (e.g. microphone operator)",
"Other building and fire inspectors",
"Other building frame and related trades workers n.e.c. (e.g. steeplejack)",
"Other business services agents n.e.c. (e.g. sports agent, artiste agent)",
"Other business services and administration managers n.e.c.",
"Other cabinet makers and wood related trades workers",
"Other car and light goods vehicle drivers",
"Other cashiers, ticket and related clerks",
"Other chemical and physical science technicians",
"Other chemical engineering technicians",
"Other chemical engineers",
"Other chemical processing and chemical products plant and machine operators",
"Other civil engineering and related technicians",
"Other civil engineers",
"Other cleaners and helpers in hotels and related establishments",
"Other cleaning workers n.e.c.",
"Other clerical support workers n.e.c.",
"Other commercial and marketing sales executives",
"Other computer operations clerks",
"Other concrete placers, concrete finishers and related workers",
"Other craft and related workers n.e.c.",
"Other crane, hoist and related equipment operators",
"Other creative and performing arts associate professionals",
"Other dairy and confectionery products machine operators",
"Other domestic helpers and cleaners",
"Other draughtsmen (e.g. cartographical draughtsman)",
"Other earth moving and related machinery operators",
"Other education managers (including registrars, deans of education institutions and heads of training institutions)",
"Other education methods specialists",
"Other electrical engineering technicians",
"Other electrical engineers",
"Other electrical line installers and repairers",
"Other electrical mechanics and fitters",
"Other electronics engineering technicians",
"Other electronics engineers",
"Other electronics fitters",
"Other elementary workers n.e.c.",
"Other engineering professionals n.e.c.",
"Other engravers and related decorative painters",
"Other environmental and occupational health and hygiene professionals",
"Other environmental protection and related professionals",
"Other extracurriculum instructors",
"Other farming, forestry and fishery advisers",
"Other film, stage and related directors and producers",
"Other finance and insurance clerks (e.g. credit clerk)",
"Other finance dealers and brokers",
"Other financial analysts and related professionals (e.g. financial product",
"Other food and beverage tasters and graders",
"Other food and related products machine operators n.e.c.",
"Other fruit, vegetable and nut processing machine operators",
"Other furniture makers and related trades workers n.e.c.",
"Other garment and related pattern-makers and cutters",
"Other glass and ceramics plant operators",
"Other glass makers, cutters, grinders and finishers",
"Other government associate professionals n.e.c.",
"Other graphic and multimedia designers and artists",
"Other guides (e.g. museum, historical sites)",
"Other hair care workers (e.g. hair therapist/consultant)",
"Other health associate professionals n.e.c.",
"Other health professionals n.e.c.",
"Other heavy truck and lorry drivers",
"Other horticultural and nursery farm workers",
"Other housekeepers and related workers",
"Other incineration plant, water treatment and related machine operators",
"Other industrial and production engineers",
"Other information professionals",
"Other insulation workers",
"Other insurance representatives and related associate professionals",
"Other jewellery and precious metal workers",
"Other lawyers (e.g. prosecutors, legal counsel in ministries and statutory boards)",
"Other legal professionals n.e.c",
"Other life science technicians",
"Other lifting truck operators",
"Other machine-tool setter-operators",
"Other machinery mechanics and repairers (e.g. agricultural machinery mechanic, bicycle repairer)",
"Other mail distribution workers (e.g. mail sorter)",
"Other manufacturing engineering technicians",
"Other material and freight handling workers",
"Other meat and fish preparers",
"Other meat and fish processing machine operators",
"Other mechanical engineering technicians",
"Other mechanical engineers",
"Other medical imaging, therapeutic equipment technicians and related associate professionals",
"Other metal drawers and extruders",
"Other metal finishing, plating and coating machine operators",
"Other metal melters, casters and rolling mill operators",
"Other metal polishers, grinders and tool sharpeners",
"Other mineral products and processing machine operators",
"Other mining engineers, metallurgists and related professionals",
"Other models (e.g. artist's model)",
"Other musicians, composers and singers",
"Other painters and related workers",
"Other paper and paperboard products machine operators",
"Other park and garden maintenance workers (including landscape worker)",
"Other personal care worker (e.g. old folks' home, nursing home, home care)",
"Other personal service workers n.e.c.",
"Other pharmacists",
"Other pharmacologists and related professionals",
"Other physical and engineering science technicians n.e.c.",
"Other physical science professionals",
"Other physical therapists and related professionals",
"Other physiotherapy technicians and related associated professionals",
"Other potters and related workers",
"Other poultry farm workers (e.g. poultry vaccinator)",
"Other pre-press trades workers",
"Other precision instrument makers and repairers",
"Other printers",
"Other printing, binding and related machine operators",
"Other production and operations managers n.e.c. (e.g. mining manager)",
"Other production clerks",
"Other professional, financial, community and social services managers",
"Other professionals n.e.c. (e.g. patent agent, trademark agent, intellectual property strategist)",
"Other protective services and related workers n.e.c.",
"Other pulp and papermaking plant operators",
"Other quality checkers and testers",
"Other radio and television presenters and related professionals",
"Other railway brakers and related workers",
"Other receptionists and information clerks",
"Other recreation centre managers",
"Other related instructors and associate professionals (e.g. adventuretraining instructor and scuba diving instructor)",
"Other related planners (e.g. traffic planner)",
"Other religious professionals",
"Other riggers and cable splicers",
"Other rubber products machine operators",
"Other services managers n.e.c.",
"Other sheet metal workers",
"Other ships' deck officers and pilots",
"Other ships’ deck crew and related workers",
"Other shoemakers and related workers",
"Other social science professionals (e.g. philosopher)",
"Other social work and counselling professionals",
"Other social work associate professionals",
"Other special education teachers",
"Other specialised goods sales professionals",
"Other specialist medical practitioners (medical) (e.g. endocrinologist, geriatrician, haematologist, infectious ","diseasehysician, neurologist, nuclear medicine physician, rehabilitation physician, renal physician, rheumatologist)",
"Other specialist medical practitioners (other specialisations) (e.g. occupational physician, radiation oncologist)",
"Other specialist medical practitioners (surgical)",
"Other stall sales workers",
"Other statistical clerks",
"Other statistical, mathematical and actuarial professionals",
"Other steam turbine, boiler and engine operators",
"Other stock clerks",
"Other structural metal preparers and erectors",
"Other surveyors and cartographers",
"Other teaching associate professionals n.e.c.",
"Other teaching professionals n.e.c.",
"Other telecommunications equipment operatorsOptical and Electronic Equipment Operators Not Elsewhere Classified",
"Other tellers and counter clerks",
"Other textile, fur and leather products machine operators n.e.c.(e.g. shoemaking and related machine operators)",
"Other tobacco products machine operators",
"Other toiletry products machine operators",
"Other toolmakers and related workers",
"Other transport clerks",
"Other transport controllers and related workers n.e.c.",
"Other transport equipment project executive",
"Other transport operations supervisors",
"Other transport service inspectors and related workers",
"Other university, polytechnic and higher education teachers",
"Other upholsterers and related workers",
"Other vegetable farm workers",
"Other visual artists",
"Other waste and recyclables collection worker",
"Other watchmen and related workers (e.g. lighthouse keeper)",
"Other well drillers and borers and other mining related workers",
"Other wellness related workers n.e.c.",
"Other wood processing plant operators",
"Other wood products machine operators",
"Other wood treaters",
"Other word processors and related operators",
"Otorhinolaryngologist/Ear, nose, throat (ENT) specialist",
"Packing/Bottling/Labelling machine operator",
"Paediatric surgeon",
"Paediatrician",
"Painter",
"Panel beater",
"Paper product making machine operator",
"Paper pulp plant operator",
"Papermaking machine operator",
"Paramedic",
"Park ranger",
"Park/Garden/Nature reserve manager",
"Parking valet",
"Parks officer",
"Pasteuriser attendant (malt liquor)",
"Pastry and confectionery maker",
"Pastry Chef",
"Pathologist",
"Pawnbroker",
"Payroll/Wages clerk",
"Personnel/Human resource clerk",
"Personnel/Human resource manager",
"Personnel/Human resource officer",
"Pet groomer",
"Petrol station attendant",
"Petroleum and natural gas extraction technician",
"Petroleum and natural gas refining plant operator",
"Petroleum/Natural gas engineer",
"Pharmaceutical products machine operator",
"Pharmacist (patient care)",
"Pharmacologist",
"Pharmacy technician",
"Photo-engraver",
"Photographer",
"Photographic products machine operator",
"Photogravure cameraman",
"Photogravure etcher",
"Photogravure retoucher",
"Physical fitness instructor (including yoga instructor and aerobics instructor)",
"Physical metallurgy technician",
"Physicist/Astronomer",
"Physics technician",
"Physiologist",
"Physiotherapist",
"Picture frame maker",
"Pile driver/Drilling machine operator",
"Pipe fitter",
"Pipe/Drain layer",
"Planing machine setter-operator",
"Plant pathologist",
"Plant tissue culture technician",
"Plant tissue culturist",
"Plasterer",
"Plastic products machine operator",
"Plastic surgeon",
"Plumber",
"Plywood core layer",
"Plywood press operator",
"Podiatrist",
"Police inspector",
"Police officer",
"Policy administration professional (e.g. policy analyst)",
"Policy manager",
"Political scientist",
"Polytechnic lecturer",
"Port captain",
"Port equipment engineer",
"Port limit helmsman/steersman/engine driver/tugmaster",
"Port master/Dockmaster",
"Port/Shipping operations manager",
"Port/Shipping operations supervisor (including post-fixture and planner)",
"Porter (except hotel)",
"Postal service counter clerk",
"Postal service manager",
"Postal/Courier service supervisor",
"Postman",
"Pottery worker",
"Poultry farm worker (general)",
"Poultry hatchery worker",
"Poultry inseminator",
"Power generating plant operator",
"Power generation and distribution engineer",
"Pre-primary education teacher",
"Pre-school education manager",
"Pre-school Education Managers",
"Precast concrete product making machine operator",
"Precision grinding machine setter-operator",
"Precision optics technician",
"Precision wood sawyer",
"Premises and facilities maintenance manager (excluding building security)",
"Premises and facilities maintenance officer",
"Primary school teacher",
"Printing machine operator",
"Prison officer",
"Private investigator",
"Private security officer",
"Private tutor (academic)",
"Private-hire car driver",
"Process engineer",
"Process engineering technician",
"Process server",
"Procurement/purchasing clerk",
"Procurement/Purchasing manager",
"Producer (stage, film, television, game, commercial, video and radio)",
"Product and industrial designer",
"Production engineer",
"Production engineering technician",
"Production manager in agriculture and fisheries",
"Production Managers in Agriculture and Fisheries",
"Production planning clerk",
"Proofreading clerk",
"Prostheist/Orthopaedic technician",
"Psychiatrist",
"Psychologist",
"Public health physician",
"Public relations/Corporate communications professional",
"Pumping station operator",
"Purchasing agent",
"Quality assurance manager",
"Quality assurance technician",
"Quantity surveying technician",
"Quantity surveyor",
"Radiation therapist",
"Radio and television studio equipment operator",
"Radio presenter",
"Radio-telephone operator (land-based)",
"Radio, television and radar broadcasting/transmitting equipment fitter",
"Rail station service assistant",
"Rail transport equipment project executive",
"Railway brakeman/signalman/shunter",
"Railway carriage cleaner (include MRT, LRT and monorail)",
"Railway/Road vehicle loader",
"Rattan furniture maker",
"Real estate agent",
"Receptionist (general)",
"Recreation Centre Managers",
"Recyclables sorter",
"Refining machine operator (oils and fats)",
"Refining/Smelting technician",
"Refractory worker",
"Refrigeration system operator",
"Refrigeration/Air-conditioning equipment insulator",
"Regional sales manager",
"Registered nurse and other nursing professionals (e.g. clinical nurse, nurse educator, excluding enrolled nurse)",
"Reinforced concreter (general)",
"Reinforcing iron worker/Steel reinforcement worker",
"Relief teacher",
"Research and development manager",
"Research officer (non-statistical)",
"Research officer (statistical)",
"Resident technical officer",
"Residential area cleaner (e.g. HDB estates, condominiums, private apartments, common areas within residential estates)",
"Respiratory physician",
"Restaurant manager",
"Restroom attendant",
"Retail manager",
"Revenue specialist",
"Road making machine operator",
"Road transport equipment project executive",
"Road transport supervisor",
"Roofer",
"Rope and cable splicer (general)",
"Rubber laminating machine operator",
"Rubber millman",
"Safety and security consultant",
"Safety inspector (vehicles, processes and products)",
"Sales and marketing manager",
"Sales and related associate professional n.e.c.",
"Sales demonstrator",
"Sales professionals (institutional sales of financial products)",
"Sales supervisor",
"Sales worker n.e.c. (e.g. ship chandler)",
"Salesperson (door-to-door)",
"Sandblaster/Shotblaster (metal)",
"Sauce making machine operator",
"Sausage making machine operator",
"Sawyer",
"Scaffolder",
"School counsellor",
"School principal",
"Script writer",
"Sculptor",
"Sea traffic controller",
"Seamless pipe and tube drawer",
"Secondary school teacher (including integrated programme year 1-4 teacher)",
"Secretary",
"Securities and finance dealer/broker",
"Securities clerk",
"Security supervisor",
"Semi-conductor engineer",
"Semi-conductor technician",
"Senior cook (e.g. station chef, commis cook 1, station cook)",
"Senior government official",
"Senior official of employers’, workers' and other economic-interest organisation",
"Senior official of humanitarian and other special-interest organisation",
"Senior official of political party organisation",
"Senior statutory board official",
"Service workers n.e.c.",
"Sewerage/Sanitary engineer",
"Sewing machine operator",
"Shaping and honing machine setter-operator",
"Sheet metal worker (general)",
"Ship broker (e.g. charter or sales & purchase)",
"Ship captain / Chief mate",
"Ship charterer",
"Ship plater",
"Ship rigger",
"Ship's deck navigating officer (including second, third and fourth mate)",
"Ship's fireman",
"Ship's oiler/greaser",
"Ship's radio officer",
"Ship/Ship tank cleaner",
"Shipping agent/Boarding officer",
"Shipping clerk",
"Shoemaker",
"Shop sales assistant",
"Sign painter",
"Silk screen/Block/Textile printer",
"Singapore armed forces personnel",
"Singer",
"Slaughterer",
"Slimming consultant",
"Soap-making machine operator",
"Social escort",
"Social research analyst",
"Social welfare manager",
"Social worker (delinquency)",
"Social worker (general)",
"Sociologist",
"Software and applications developer and analyst n.e.c.",
"Software and applications manager",
"Software developer",
"Soil mechanic/Piling engineer",
"Soil scientist",
"Sommelier",
"Sonographer",
"Sound recording equipment operator",
"Specialised dentist (e.g. orthodontist)",
"Speech and drama instructor (extracurriculum)",
"Speech therapist",
"Spinner (thread and yarn)",
"Sports and recreational attendant (e.g. golf marshal, golf caddie, fun fair attendant, bowling alley attendant, swimming ","pool attendant)",
"Sports centre manager",
"Sports coach",
"Sports official",
"Sports physician",
"Spray painter (except ships, motor vehicles and signs)",
"Stationary engine operator (general)",
"Stationary plant and machine operator n.e.c.",
"Stationary plant and machine supervisor/general foreman",
"Statistical clerk (general)",
"Statistical officer/Data analyst",
"Statistician",
"Stenographer",
"Stereotyper",
"Stevedore",
"Stock records clerk",
"Stone cutter/Carver",
"Stonemason",
"Storekeeper",
"Strategic planning manager",
"Structural engineer (general)",
"Structural engineering technician",
"Structural steel erector",
"Structural steel worker (workshop)/fabricator",
"Structural steel/Ship painter",
"structurer/product controller)",
"Sugar processing and refining machine operator",
"Supervisor/General foreman (building and related trades)",
"Supervisor/General foreman (electrical and electronic trades)",
"Supervisor/General foreman (food processing, woodworking, garment, leather and related trades)",
"Supervisor/General foreman (metal, machinery and related trades)",
"Supervisor/General foreman (precision, handicraft, printing and related trades)",
"Supervisor/general foreman of assemblers and quality checkers",
"Supply and distribution/Logistics/Warehousing manager",
"Surveyor (general)",
"Synthetic fibre making machine operator",
"Syrup making machine operator",
"Systems Analysts",
"Systems designer/analyst",
"Tailor/Dressmaker",
"Tattooist",
"Tax accountant",
"Taxi driver",
"Tea server/steward (excluding bartender, barista and food/drink stall assistant)",
"Teacher of the deaf",
"Teacher of the mentally intellectually disabled",
"Teacher of the visually impaired",
"Teachers' aide",
"Teaching aid specialist (including audio-visual aid specialist)",
"Teaching assistant/Tutor in universities",
"Technical sales professionals",
"Technical writer",
"Technical/Engineering services manager (excluding transport)",
"Technical/Vocational/Commercial education institute teacher andtrainer",
"Telecommunications engineer",
"Telecommunications installer/servicer",
"Telecommunications lineman",
"Telecommunications service supervisor",
"Telecommunications technician",
"Telemarketer",
"Telephone operator",
"Television presenter",
"Television/Motion picture camera operator",
"Terrazzo tile making machine operator",
"Textile bleaching machine operator",
"Textile washing/Shrinking machine operator",
"Theme park guide",
"Ticket inspector",
"Tool and die maker",
"Tool grinder (machine tools)",
"Tool/Mould designer",
"Tour guide",
"Town planner",
"Toy assembler",
"Trade broker (including oil and bunker trader)",
"Traditional and complementary medicine associate professional (e.g. homeopath and naturopath)",
"Traditional Chinese medicine acupuncturist",
"Traditional Chinese medicine practitioner",
"Trailer-truck driver (including prime mover driver)",
"Training manager",
"Training officer",
"Tram driver",
"Translator",
"Transport/Technical operations manager (except port/shipping operations)",
"Travel agency manager",
"Travel consultant/reservation executive",
"Treasury manager",
"Tree cutter",
"Tunnelling machine operator (including pipe jacking machine operator)",
"Typist",
"Undertaker",
"Underwater diver",
"University lecturer",
"Urologist",
"Van driver",
"Vehicle upholsterer",
"Veneer cutter",
"Verbatim reporter",
"Veterinarian",
"Veterinary technician/assistant",
"Video camera operator",
"Visual merchandiser",
"Volunteer manager",
"Waiter",
"Waiter and bartender n.e.c. (e.g. food checker (catering services))",
"Wall/Ceiling paperhanger",
"Warehouse worker",
"Waste and recyclables collection supervisor",
"Waste truck driver (including hooklift trucks with roll-on containers)",
"Watch and clock assembler",
"Watch and clock repairer",
"Water transport equipment project executive",
"Water treatment machine operator (waterworks)",
"Weaving machine operator",
"Web and mobile applications developer",
"Website administrator/Webmaster",
"Welder",
"Well driller (petroleum and gas wells)",
"Well driller/borer (except oil and gas wells)",
"Wellness centre manager (e.g. hair/beauty/slimming/spa/manicure/massage)",
"Wholesale trade manager",
"Winder (thread and yarn)",
"Window cleaner",
"Wire drawer",
"Wire-coating machine operator",
"Wood furniture finisher",
"Wood grader",
"Wood-treating worker",
"Woodworking machine setter-operator",
"pawn-broking",
"Worker not reporting any occupation",
"Worker reporting unidentifiable or inadequately described occupation",
"Working proprietor (agriculture and fisheries)",
"Working proprietor (business services and administrative services)",
"Working proprietor (construction)",
"Working proprietor (information and communications technology service)",
"Working proprietor (lodging services)",
"Working proprietor (manufacturing)",
"Working proprietor (professional, financial, community and social services)",
"Working proprietor (restaurant and other catering services)",
"Working proprietor (retail trade)",
"Working proprietor (sports, recreation, arts and other services)",
"Working proprietor (transport, storage and courier)",
"Working proprietor (wholesale trade)",
"Youth worker",
"Zoologist"]