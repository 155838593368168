import React from "react";
import {Redirect } from "react-router-dom";
import Stepper from 'react-stepper-horizontal';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Button, FormGroup} from 'reactstrap';
import UserProfileModel from "views/User/UserProfileModel.jsx";
import UserModel from "views/User/UserModel.jsx";
import {nationality} from "variables/PhoneCode.js";
import {phoneCodes} from "variables/PhoneCode.js";
import moment from 'moment';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import country from "variables/Country.js";
import mime from "mime-types";
import BlockUi from 'react-block-ui';
// import date from "assets/img/date.png";
import uuid from "uuid";
import Notification from "components/modals/Notification";
import { Storage } from 'aws-amplify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CorpUser extends React.Component{
    constructor(props){
        super(props)
        this.state ={updateCustomer:false,customerLoad:true,redirect:false,verify:"new",validationFlag:false,profilePic:"new",profilePicLoder:'upload',
                      myInfoFlagMobdis:false,profileImage:false,userPageLoad:false,loadPhone:false,download:true,sendOTPButton:true ,companyLogo:"new",
                      companyLogoState:false,companyLogoLoader:'upload',loadButton:false}
        this.userModel               = new UserProfileModel()
        this.companypic              = React.createRef();
        this.profilepicFile          = React.createRef();
        this.profilePicModal         = React.createRef();
        this.handleSubmit            = this.handleSubmit.bind(this);
        this.handleChange            = this.handleChange.bind(this);
        this.getCustomer             = this.getCustomer.bind(this);
        this.getProfilePicAWS        = this.getProfilePicAWS.bind(this);
        this.profilePic              = this.profilePic.bind(this);
        this.companyLogo             = this.companyLogo.bind(this);
        this.OTPbutton               = this.OTPbutton.bind(this);
        this.OTPText                 = this.OTPText.bind(this);
        this.sourceOfIncomeList      = this.sourceOfIncomeList.bind(this);
        this.SaveButton              = this.SaveButton.bind(this);
        this.sourceOfIncomeOther     = this.sourceOfIncomeOther.bind(this);
        this.purposeOther            = this.purposeOther.bind(this);
        this.handleChangeMobOtp      = this.handleChangeMobOtp.bind(this);
        this.onFocus                 = this.onFocus.bind(this);
        this.onBlur                  = this.onBlur.bind(this);
        this.StepperDisplay          = this.StepperDisplay.bind(this);
        this.handleForm              = this.handleForm.bind(this);
        this.error                   = React.createRef();
        this.breadcrumb              = this.breadcrumb.bind(this);
        this.uploadProfilePic        = this.uploadProfilePic.bind(this);
        this.purposeList             = this.purposeList.bind(this);   
        this.getProfilePicAWSComapny = this.getProfilePicAWSComapny.bind(this);
        this.getCustomer();
        this.today                   = new Date();
    }
    
    getCustomer(){

        axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/profile')
        .then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
          if(UserModel.busiessRegistrationNumber !== '' && UserModel.busiessRegistrationNumber !== undefined){
            if(UserModel.sourceOfIncome.includes("Other")){
              this.setState({sourceOfIncomeOther:true})
            }
            if(UserModel.purpose.includes("Other")){
              this.setState({purposeOther:true})
            }
            this.setState({download:false});
        }else{
          this.userModel.updateValuesUserModel(res.data.customer);
          if(res.data.customer.busiessRegistrationNumber !== '' && res.data.customer.busiessRegistrationNumber !== undefined ){
            this.setState({download:false});
          }
          if(UserModel.sourceOfIncome.includes("Other")){
            this.setState({sourceOfIncomeOther:true})
          }
          if(UserModel.purpose.includes("Other")){
            this.setState({purposeOther:true})
          }
          this.setState({updateCustomer:true});  

        }

        if( UserModel.callingCode === '' || UserModel.callingCode === undefined){
          UserModel.callingCode = "+65";
        }
        if( UserModel.country === '' || UserModel.country === undefined){
          UserModel.country = "Singapore";
        }

        this.getProfilePicAWS(); 
        this.getProfilePicAWSComapny();   
        if(UserModel.phoneNumber === '' || UserModel.phoneNumber === undefined){
          this.setState({loadPhone:true});
        }
        if(this.props.user.attributes.profile === "Approved" || this.props.user.attributes.profile === "Rejected" || this.props.user.attributes.profile === "PendingApproval" || this.props.user.attributes.profile === "AutoApproveWith2Year" || this.props.user.attributes.profile === "AutoApproveWith3Year"){
          this.setState({customerStatedisable : "true" });
        }
        if(this.props.user.attributes.profile === "Approved" || this.props.user.attributes.profile === "AutoApproveWith2Year" || this.props.user.attributes.profile === "AutoApproveWith3Year"){
          this.setState({customerStatedisable : "true" });
        }
          }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} }) 
      }

    getProfilePicAWS(){
        this.setState({profilePicLoder:'loading'})
        const fileName= UserModel.profileImageFileName;

      if(fileName !== '' && fileName !== undefined){
       Storage.vault.get(fileName)
       .then((res) => {
             this.setState({
                 profilePic:res,
                 profilePicLoder:'uploaded',
                 profileImage:true,
               })
       })
       .catch();
      }
    }

    getProfilePicAWSComapny(){
      this.setState({companyLogoLoader:'loading'})
          const fileName= UserModel.companyLogo;
          if(fileName !== '' && fileName !== undefined){
              Storage.vault.get(fileName)
                  .then((res) => {
              this.setState({
                  companyLogo:res,
                  companyLogoLoader:'uploaded',
                  companyLogoState:true,
              })
              })
              .catch();
    }
      {this.setState({userPageLoad:false})}
    }

    handleChangeMobOtp =(e) =>{
      if(e.target.type=="text"){
        e.target.classList.remove('valid','invalid')
        e.target.classList.add('activeFocus')
      }
    
      UserModel.mobOTP = e.target.value;
      if(e.target.value.length === 4){
         this.otp=e.target.value;
        this.verifyOTP();
      }
      this.setState({mobOTP: e.target.value})
    }

    uploadProfilePic = (e) =>{
      if(e.target.name === "ProfilePicture"){
          this.setState({profilePicLoder:'loading',profilePic:'uploading'})
      }else{
          this.setState({companyLogoLoader:'loading',companyLogo:'uploading'})
      }
     
      let originalFileName=e.target.files[0].name;
      let file = e.target.files[0];
      let doctype = e.target.name;
      const fileName = uuid.v4()+"."+mime.extension(mime.contentType(e.target.files[0].name))
      Storage.vault.put(fileName, file)
      .then (result => {
        if(doctype === "ProfilePicture"){
            UserModel.profileImageFileName=result.key;
            this.setState({updateCustomer: true});
            this.getProfilePicAWS();
        }else{
            UserModel.companyLogo=result.key;
            this.setState({updateCustomer: true});
            this.getProfilePicAWSComapny();
        }
      })
      .catch();    
    }

    onFocus(event){
     if(event.target.disabled)return
     event.target.nextElementSibling.classList.remove('spanMiddle')
     event.target.nextElementSibling.classList.add('spanTop')
     if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
    }
    onBlur(event){
      event.target.classList.remove('activeFocus','invalid','valid')
      event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
      if(event.target.value==""){
        event.target.classList.remove('activeFocus','invalid','valid')
        event.target.nextElementSibling.classList.remove('spanTop')
        event.target.nextElementSibling.classList.add('spanMiddle')
        }
        if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
    }

    sourceOfIncomeList = (e) => {
      let lists = UserModel.sourceOfIncome
      this.setState({updateCustomer: true,sourceOfIncomeValid:false});
      if(e.target.checked){
          if(e.target.value === "Other"){
            this.setState({updateCustomer: true,sourceOfIncomeOther:true});
          }
        lists.push(e.target.value);
        UserModel.sourceOfIncome=lists;
        this.setState({updateCustomer: true});
      } else {
        if(e.target.value === "Other"){
          UserModel.sourceOfIncomeOther ='';
        this.setState({sourceOfIncomeOther:false});
        }
        let listsDelete = UserModel.sourceOfIncome.filter(el => el != e.target.value);
        UserModel.sourceOfIncome=listsDelete;
        this.setState({updateCustomer: true});
      }
    }

    purposeList =(e) => {
     let lists = UserModel.purpose
     this.setState({purposelist: lists,pruposeValid:false});
     if(e.target.checked){
       if(e.target.value === "Other"){
         this.setState({updateCustomer: true,purposeOther:true});
       }
         lists.push(e.target.value);
         UserModel.purpose = lists;
         this.setState({purposelist: lists});
    }
     else {
       if(e.target.value === "Other"){
         UserModel.purposeOther=""
       this.setState({purposeOther: false});
       }
       let listsDelete = UserModel.purpose.filter(el => el != e.target.value);
       UserModel.purpose = listsDelete;
       this.setState({updateCustomer: true});
     }
    }

    SaveButton(){
        if(this.state.customerStatedisable){
          return ;
        } 
        else{
        return (<Button type="secondary"  className="pageButton primary" disabled={this.state.download}  style={{float:"right",backgroundColor: '#8c0204'}} onClick={this.handleForm} >Continue</Button>);
        }
    }

    downloadButton(){
      if(this.state.customerStatedisable){
        return ;
      } else if(this.state.loadButton){
        return (<Button type="secondary" className="pageButton primary" style={{backgroundColor: '#8c0204'}} type="submit"><Loader type="Oval" color="#ddd" height="13"  width="13"/></Button>);
      }else{
        return (<Button id="back" disabled={this.state.customerStatedisable} style={{backgroundColor: '#8c0204'}} type="submit" className="pageButton primary">Download</Button>);
      }
    }

    documentDownload(){
      if(this.state.customerStatedisable){
        return ;
      }else {
        return <>
              <Col md="12" className="inputField">
                <div style={{textAlign:"center",border: '1px solid'}}>   
                <label style={{fontSize:'18px'}}>Please download the "Account Opening Form" , take a printout, fill-up the details,<br/> scan/take a picture and upload it on the next screen..</label>
                <br/>
                  {this.downloadButton()}
                </div> 
            </Col>
        </>
      }
    }

      
      
    sourceOfIncomeOther(){
      if(this.state.sourceOfIncomeOther){
        return <>
          <div className="inputGroup">
            <input type="text" className="inputText" id="sourceOfIncomeOther"
              name="sourceOfIncomeOther" onFocus={this.onFocus} onBlur={this.onBlur}
                value={UserModel.sourceOfIncomeOther} autoComplete="off"
                fieldCheck={UserModel.sourceOfIncomeOther === ""?"unfilled":"filled"}
                onChange={this.handleChange} required 
                disabled={this.state.customerStatedisable}
                style={{width:'200px',height:'40px'}}
                />
            <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Remarks</span>
        </div>
                </>
      }
    }
      
    purposeOther(){
      if(this.state.purposeOther){
        return <>
        <div className="inputGroup">
            <input type="text" className="inputText" id="otherP"
              name="purposeOther" onFocus={this.onFocus} onBlur={this.onBlur}
                value={UserModel.purposeOther} autoComplete="off"
                fieldCheck={UserModel.purposeOther === ""?"unfilled":"filled"}
                onChange={this.handleChange} required 
                disabled={this.state.customerStatedisable}
                style={{width:'200px',height:'40px'}}
                />
            <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Remarks</span>
        </div>
        </>
      }
    }

    handleChange = (e) =>{
      if(e.target.type=="text"){
        e.target.classList.remove('valid','invalid')
        e.target.classList.add('activeFocus')
      }
      this.props.list[e.target.name] = e.target.value;
      // if(e.target.name === "faxNumber" || e.target.name === "contactNumber" || e.target.name === "phoneNumber"){
      //   let val= e.target.value.split('')
      //   if(val[0]==='+'){UserModel[e.target.name]=e.target.value}
      //     else if(val[0] === undefined || val[0] === ''){UserModel[e.target.name]='';}
      //     else{UserModel[e.target.name]="+65"+e.target.value;}}
          // if(e.target.name === "phoneNumber" && e.target.value.length === 11){ this.setState({sendOTPButton:false})}
          if((e.target.name === "phoneNumber" ||  e.target.name === "callingCode") && UserModel.callingCode !== undefined && UserModel.callingCode !== '' && UserModel.phoneNumber !== ''){ this.setState({sendOTPButton:false})}
          else{this.setState({sendOTPButton:true})}
      this.setState({updateCustomer:true});
    }

    profilePic(){
      if(this.state.customerStatedisable){
        return <>
        <img className="imgProfile block-example imageProfilePic float-left"   style={{width:'100px',height:'100px',cursor:'pointer'}} src={this.state.profilePic} alt="Upload Profile pic"></img>
        <label htmlFor="file-upload" className="custom-pic-file-uploa-updateCrop"  >Profile Photo
        </label>
        </>
      } 
      if(this.state.profilePic === "new" || UserModel.profileImageFileName === undefined){
        return <>
        <input type="file" className="form-control" style={{cursor:'pointer'}} ref={this.profilepicFile}  name="ProfilePicture" disabled={this.state.customerStatedisable} onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg" />
        <span onClick={()=> this.profilepicFile.current.click()}> <label htmlFor="file-upload" className="custom-pic-file-uploadCrop"><u>Profile Photo</u>
              <p style={{color:'#bcbcbc',fontSize:'12px',paddingLeft:'78px',paddingTop:'2px'}}>Used for document verification</p>
              </label></span>
        </>
      }else if(this.state.profilePicLoder === "loading"){
        return<> <Loader type="Oval" color="#ddd" height="60"  width="60"/></>
      }else{
        return <>
        <input type="file" className="form-control"  name="ProfilePicture" ref={this.profilepicFile} disabled={this.state.customerStatedisable} onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg" />
        <img className="imgProfile block-example imageProfilePic float-left" onClick={() => this.profilepicFile.current.click()} style={{width:'100px',height:'100px',cursor:'pointer'}} src={this.state.profilePic} alt="Upload Profile pic"></img>
        <span onClick={()=> this.profilepicFile.current.click()}><label htmlFor="file-upload" className="custom-pic-file-uploa-updateCrop"><u>Profile Photo</u>
        </label></span>
        </>
      }
    }

    companyLogo(){
        if(this.state.customerStatedisable){
          if(this.state.companyLogo === "new" || UserModel.companyLogo === undefined){
            return <>
            <input type="file" className="form-control" style={{cursor:'pointer'}} disabled={this.state.customerStatedisable}  name="CompanyLogoFile" onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg" />
                  <label htmlFor="file-upload" className="custom-pic-file-uploadCrop"><u>Company Logo</u>
                  </label>
            </>
          }else{
          return<>  
          <img className="imgProfile block-example imageProfilePic float-left"  style={{width:'100px',height:'100px',cursor:'pointer'}} src={this.state.companyLogo} alt="Upload Profile pic"></img>
          <label htmlFor="file-upload" className="custom-pic-file-uploa-updateCrop">
          </label>
          </>
          }
        }
        if(this.state.companyLogo === "new" || UserModel.companyLogo === undefined){
          return <>
          <input type="file" className="form-control" style={{cursor:'pointer'}} ref={this.companypic} disabled={this.state.customerStatedisable}  name="CompanyLogoFile" onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg"/>
          <span onClick={()=> this.companypic.current.click()}><label htmlFor="file-upload" className="custom-pic-file-uploadCrop"><u>Company Logo</u>
                <p style={{color:'#bcbcbc',fontSize:'12px',paddingLeft:'60px',paddingTop:'2px'}}>Used for document verification</p>
                </label></span>
          </>
        }else if(this.state.companyLogoLoader === "loading"){
          return<> <Loader type="Oval" color="#ddd" height="60"  width="60"/></>
        }else{
          return <>
          <img className="imgProfile block-example imageProfilePic float-left" onClick={(e) => e.target.nextElementSibling.click()}  style={{width:'100px',height:'100px',cursor:'pointer'}} src={this.state.companyLogo} alt="Upload Profile pic"></img>
          <input type="file" className="form-control"  name="CompanyLogoFile" disabled={this.state.customerStatedisable} ref={this.companypic} onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg" />
          <span onClick={()=> this.companypic.current.click()}><label htmlFor="file-upload" className="custom-pic-file-uploa-updateCrop" ><u>Company Logo</u>
          </label></span>
          </>
        }
    }

      OTPText(){
        if(!this.state.loadPhone){
          return "";
        }else if((this.state.verify === "error" || this.state.verify !=="new") && this.state.verify !=="verified" ){
          return <>
            <div className="inputGroup">
               <input type="text" className="inputText" id="otp"
                name="mobOTP" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern="[0-9]{4}" value={UserModel.mobOTP} autoComplete="off" required
                title="OTP can contain only Digits" maxLength="4" fieldCheck={UserModel.mobOTP === "" || UserModel.mobOTP === undefined?"unfilled":"filled"}
                onChange={this.handleChangeMobOtp}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>OTP</span>
            </div>
            <a href="javascript:void(0)"  onClick={this.sendOTP}><span style={{color:'#052d99',fontSize:'13px'}}>Resend OTP</span></a>
           </>
        }else if(this.state.verify === "verified"){
          return ( 
            <div style={{ paddingRight: '160px', marginRight: '20px;', paddingTop:'15px'}}>
               <Button color="forexSecondary"  className="float-right btn-circledoc" active><i class="fa fa-check" style={{color:'white'}} aria-hidden="true"></i></Button>
            </div>
            );
        }else { 
          return <>
             <div className="inputGroup">
               <input type="text" className="inputText" id="otp"
                name="mobOTP" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern="[0-9]{4}" value={UserModel.mobOTP} autoComplete="off" required
                title="OTP can contain only Digits" maxLength="4" fieldCheck={UserModel.mobOTP === "" || UserModel.mobOTP === undefined?"unfilled":"filled"}
                onChange={this.handleChangeMobOtp}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>OTP</span>
            </div>
          </>
        }
      }

      OTPbutton(){
        if(!this.state.loadPhone){
          return "";
        }
        else if(this.state.verify === "new") {
          return(
            <Button type="secondary" id="back" className="pageButton secondary" onClick={this.sendOTP}
            disabled={this.state.sendOTPButton } style={{float:"right",width:'110px'}}>Get OTP</Button>
             );
        }else if(this.state.verify === "error"){
          return ( 
            <div style={{ paddingRight: '160px', marginRight: '20px;', paddingTop:'15px'}}>
               <Button color="forexSecondary"  className="float-right btn-circle"><i class="fa fa-times" style={{color:'white'}} aria-hidden="true"></i></Button>
            </div>
          );
        }else if(this.state.otpLoader){
          return (
            <div style={{ paddingRight: '160px', marginRight: '20px;', paddingTop:'15px'}}>
            <Button color="forexSecondary"  className="float-right btn-circledoc">
                <Loader type="Oval" color="#ddd" height="13"  width="13"/>
              </Button>
              </div>);
        } else {
          return "";
         }
      }

      sendOTP =() =>{
        this.setState({otpLoader:true,verify:"disable",myInfoFlagMob:false});
        let mobileNum=UserModel.callingCode+UserModel.phoneNumber;
        let phoneNumber = {
          "phoneNumber":mobileNum
        }
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/misc/otp";
        axios.post(url,phoneNumber).then(res =>{
          this.setState({otpLoader:false})
        }).catch(function (error) {})
    
      }
    
      verifyOTP = () => {
        let data = {
          "phoneNumber":UserModel.phoneNumber,
          "mobOTP":this.otp,
          "callingCode":UserModel.callingCode
        }
        this.setState({otpLoader:true})
        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/misc/verify";
        axios.post(url,data).then(res =>{
          this.status=res.status;
          if(res.data.status === 200) {
            this.setState({verify:"verified", mobOTP: "",otpLoader:false,myInfoFlagMob:false,myInfoFlagMobdis:false});
          }else{
            UserModel.mobOTP='';
            this.setState({verify:"error", mobOTP: ""})
          }
        }).catch(function (error) {})
      }

      handleSubmit =(e) =>{
        e.preventDefault()
        this.setState({loadButton:true})
        if(!this.state.profileImage) {
          this.setState({loadButton:false})
          this.profilePicModal.current.toggle();
          return ;
        }
        e.preventDefault()
        let data = {
                  "name": UserModel.name,
                  "registeredBusinessName": UserModel.registeredBusinessName,
                  "busiessRegistrationNumber": UserModel.busiessRegistrationNumber,
                  "dateOfRegistration": UserModel.dateOfRegistration,
                  "nationality": UserModel.nationality,
                  "typeOfRegistration": UserModel.typeOfRegistration,
                  "idNumber": UserModel.idNumber,
                  "princpalBusinessActivites": UserModel.princpalBusinessActivites,
                  "contactNumber": UserModel.contactNumber,
                  "personnelEmailId": UserModel.personnelEmailId,
                  "email":UserModel.email,
                  "faxNumber": UserModel.faxNumber,
                  "profileType":"Corporate",
                  "jobTile": UserModel.jobTile,
                  "profileImageFileName":UserModel.profileImageFileName,
                  "sourceOfIncome":UserModel.sourceOfIncome,
                  "purpose":UserModel.purpose,
                  "residentialAddress": {
                    "country": UserModel.country,
                    "apartmentNumber": UserModel.apartmentNumber,
                    "street": UserModel.street,
                    "street2":UserModel.street2,
                    // "streetNumber": UserModel.streetNumber,
                    "block": UserModel.block,
                    "postal": UserModel.postal,
                    "floor": UserModel.floor,
                    "building": UserModel.building
                  },
                  // "registeredBusinessAdderss": UserModel.registeredBusinessAdderss,
                  // "registeredBusinessAdderss2": UserModel.registeredBusinessAdderss2,
                  "companyLogo":UserModel.companyLogo,
                  "indicativeTransactionLimit":UserModel.indicativeTransactionLimit,    
                  "sourceOfIncomeOther": UserModel.sourceOfIncomeOther,
                  "purposeOther":UserModel.purposeOther,
        }
          const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/profile";
          axios.post(url,data).then(res =>{
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
            if(res.data.status===200){
              window.open(res.data.html);
              this.setState({download:false,loadButton:false})
            }
            if(UserModel.sourceOfIncome.length === 1){
              let val=UserModel.sourceOfIncome[0].split(":");
              if(val[0] === "Other"){
                let list=[];
                list.push(val[0])
                UserModel.sourceOfIncome=list;
                UserModel.sourceOfIncomeOther=val[1];
                this.setState({sourceOfIncomeOther:true})
              } 
            }
            if(UserModel.purpose.length === 1){
              let val=UserModel.purpose[0].split(":");
              if(val[0] === "Other"){
                let list=[];
                list.push(val[0])
                UserModel.purpose=list;
                UserModel.purposeOther=val[1];
                this.setState({purposeOther:true})
              }
            }
          }).catch(function (error) { 
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} 
              this.error.current.toggle();
          })
      }

      handleForm(){
        this.setState({redirect:true});
      }

      StepperDisplay(){
          if(!this.state.customerStatedisable){
            return <><Row style={{margin: "0% 2%"}}>
              <Col md="2" style={{padding:0}}> 
               <Stepper steps={ [{}, {}] } activeColor="#585859" defaultColor="#d8d8d8" completeColor="#8b0304"  />
              </Col>
               </Row>
              </>
          }
      }

      constructClass(){
        if(!this.state.customerStatedisable){
          return "color mainContent"
        }
        else{
          return "mainContent"
        }
      }

      breadcrumb(){
        if(!this.state.customerStatedisable){
          return<>
          <BreadcrumbItem className="breadcrumbItem initial" tag="a">Log In/Register</BreadcrumbItem>
          <BreadcrumbItem className="breadcrumbItem" active tag="span">Register</BreadcrumbItem>
        </>
        }else{
        return<>
        <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
        <BreadcrumbItem className="breadcrumbItem" active tag="span">Profile</BreadcrumbItem>
        </>
        }
      }

    render(){
      let text='';
      if(!this.state.customerStatedisable){
          text='Provide your basic details to create an account with us.';
      }
      if (this.state.redirect) {
        return <Redirect to='/corpDocument' exact/>
      }
        return(
          <>
          <Notification ref={this.profilePicModal}  modalTitle="Please upload Profile Image"/>
          <Notification ref={this.error}  modalTitle="Something went wrong. Please try again." />
           <BlockUi tag="div" blocking={this.state.userPageLoad} keepInView={true} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className={this.constructClass()}>
            <Row className="breadcrumbContainer">
               <Col md="12" className="breadcrumbContainerColumn">
               <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                 {this.breadcrumb()}
               </Breadcrumb>
               </Col>
            </Row>
            <Row className="pageHeading">
              <Col md="6"  className="pageHeadingColumn">
                <h2>Profile Details</h2>
                <h5>{text}</h5>
              </Col>
            </Row>
            
           {this.StepperDisplay()}
            <form onSubmit={this.handleSubmit}>

            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Company Details</h4>
              </Col>
            </Row>
            
            <Row style={{marginTop:'0px !important'}}>
              <Col md="4" className="sectionHeading">
              {this.companyLogo()}
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                <div className="inputGroup">
                  <input type="text" className="inputText" id="name"
                    name="registeredBusinessName" onFocus={this.onFocus} onBlur={this.onBlur}
                    pattern="^[_ a-zA-Z0-9-,.;]+$" value={UserModel.registeredBusinessName} autoComplete="off"
                    title="Name should be of alphanumeric" fieldCheck={UserModel.registeredBusinessName === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required  
                    disabled={this.state.customerStatedisable}
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Registered Business Name</span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
                  <input type="text" className="inputText" id="name"
                    name="busiessRegistrationNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                    pattern="^[a-zA-Z0-9\\s ]+" value={UserModel.busiessRegistrationNumber} autoComplete="off"
                    title="Registration Number can contain alphanumeric" fieldCheck={UserModel.busiessRegistrationNumber === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required  
                    disabled={this.state.customerStatedisable}
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Busiess Registration Number</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
              <div className="inputGroup">
                  <DatePicker
                    required
                    name="dateOfRegistration"
                    dateFormat="yyyy-MM-dd"
                    selected={UserModel.dateOfRegistration === ''? "":new Date(moment(UserModel.dateOfRegistration))}
                    onChange={(date) => {
                    this.props.list["dateOfRegistration"] = moment(date).format("YYYY-MM-DD");
                    UserModel.dateOfRegistration = moment(date).format("YYYY-MM-DD");
                    this.setState({updateCustomer:true,sendOTPButton:true});
                    }}
                    disabled={this.state.customerStatedisable}
                    maxDate={this.today}
                    placeholderText="Date of Registration"
                  />
                  <label style={{color:'#bcbcbc'}}>Date Of Registration</label>
              </div>
                {/* <div style={{display:"flex"}}> */}
                {/* <img src={date} style={{width:'20px',height:'20px',marginTop:'6px'}}></img> */}
               
                {/* <Input type="date" className="inputText" style={{height:'25px',width: '180px'}} name="dateOfRegistration" id="dateOfRegistration"
                value={UserModel.dateOfRegistration}   onChange={this.handleChange} required
                max={moment(new Date()).format("YYYY-MM-DD")}/> */}
                {/* </div> */}
                {/* <label style={{color:'#4a4a4a',marginLeft:'35px'}} forHtml="dateOfRegistration">Date Of Registration</label> */}
            </Col>
            <Col md="9" className="inputField">
              <div className="inputGroup">
                  <input type="text" className="inputText" id="emailId"
                    name="email" onFocus={this.onFocus} onBlur={this.onBlur}
                    pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]+$" value={UserModel.email} autoComplete="off"
                    title="Email should of format example@gmail.com" fieldCheck={UserModel.email === ""?"unfilled":"filled"}
                    onChange={this.handleChange}  
                    disabled
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Company Email Id</span>
            </div>
              <label style={{color:'#bcbcbc'}}>Used for all communications</label>
            </Col>
            
            </Row>
            <Row className="inputRow">
              <Col md="2" className="inputField">
                <select name="typeOfRegistration" disabled={this.state.customerStatedisable} onChange={this.handleChange} required>
                <option selected disabled hidden value=''>Select</option>
                <option value="Public Company" selected={(UserModel.typeOfRegistration) === "Public Company"}>Public Company</option>
                <option value="Private Limited" selected={(UserModel.typeOfRegistration) === "Private Limited"}>Private Limited</option>
                <option value="Partnership" selected={(UserModel.typeOfRegistration) === "Partnership"}>Partnership</option>
                <option value="Proprietor" selected={(UserModel.typeOfRegistration) === "Proprietor"}>Proprietor</option>
              </select>
              <label>Type Of Registration</label>
            </Col>
            <Col md="4"></Col>
            <Col md="6"  className="inputField">
              <div className="inputGroup">
                <input type="text" className="inputText" id="name"
                  name="princpalBusinessActivites"  onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern="^[a-zA-Z0-9\\s ]+" value={UserModel.princpalBusinessActivites} autoComplete="off"
                  title="Princpal Business Activites can contain alphanumeric" fieldCheck={UserModel.princpalBusinessActivites === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required  
                    disabled={this.state.customerStatedisable}
                    />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Princpal Business Activites</span>
            </div>
            </Col>
           
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
              <div className="inputGroup">
                <input type="text" className="inputText" id="mobile"
                  name="contactNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                  // pattern= "[0-9]+$" 
                  // title="Contact number can contain only digits"
                 value={UserModel.contactNumber} autoComplete="off"
                 fieldCheck={UserModel.contactNumber === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required  
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Contact Number</span>
            </div>
            <label style={{color:'#bcbcbc'}}>Please include Dialing Code (ex: +Dialing Code-XXXXXXXXXX)</label>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
                <input type="text" className="inputText" id="fax"
                  name="faxNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern= "[0-9]+$" 
                  title="Fax number can contain only digits"
                   value={UserModel.faxNumber} autoComplete="off"
                    fieldCheck={UserModel.faxNumber === "" || UserModel.faxNumber=== undefined?"unfilled":"filled"}
                    onChange={this.handleChange}   data-optional="true"
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Fax Number</span>
            </div>
            </Col>
            </Row>

            {/* <Row className="inputRow">
            <Col md="6" className="inputField">
              <div className="inputGroup">
                <input type="text" className="inputText" id="building"
                  name="registeredBusinessAdderss" onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern="^[_ a-zA-Z0-9-,.;#]+$" value={UserModel.registeredBusinessAdderss} autoComplete="off"
                  title="Adderss can contain alphanumeric value" fieldCheck={UserModel.registeredBusinessAdderss === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required 
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Registered Business Adderss Line 1</span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
                <input type="text" className="inputText" id="building"
                  name="registeredBusinessAdderss2" onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern="^[_ a-zA-Z0-9-,.;#]+$" value={UserModel.registeredBusinessAdderss2} autoComplete="off"
                  title="Adderss can contain alphanumeric value" fieldCheck={UserModel.registeredBusinessAdderss2 === ""?"unfilled":"filled"}
                    onChange={this.handleChange} data-optional="true"
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Registered Business Adderss Line 2 </span>
            </div>
            </Col>
            </Row> */}
            {/* <Container className="section"> */}
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Corporate Address</h4>
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <div className="inputGroup">
               <input type="text" className="inputText"
                name="block" onFocus={this.onFocus} onBlur={this.onBlur} id="block"
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.block} autoComplete="off"
                // title="Block can contain alphanumeric value" 
                fieldCheck={UserModel.block === ""?"unfilled":"filled"}
                onChange={this.handleChange} required
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Block / Street Number</span>
            </div>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText"
                name="street" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.street} autoComplete="off"
                // title="Street can contain alphanumeric value" 
                fieldCheck={UserModel.street==="" || UserModel.street === undefined ?"unfilled":"filled"}
                onChange={this.handleChange} id="street" required
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Street Name Line 1</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="street2" id="street" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.street2} autoComplete="off"
                // title="Street can contain alphanumeric value" 
                fieldCheck={UserModel.street2 === "" || UserModel.street2 === undefined?"unfilled":"filled"}
                onChange={this.handleChange} 
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Street Name Line 2 </span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="building" onFocus={this.onFocus} id="building" onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.building} autoComplete="off"
                // title="Building can contain alphanumeric" 
                fieldCheck={UserModel.building===""?"unfilled":"filled"}
                onChange={this.handleChange} 
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Building Name</span>
            </div>
            </Col>
            {/* */}
            {/* */}
            
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
                 <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="floor" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.floor} autoComplete="off"
                // title="Floor can contain alphanumeric value" 
                fieldCheck={UserModel.floor === ""?"unfilled":"filled"}
                onChange={this.handleChange}  id="floor"
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Floor </span>
            </div>
            </Col> 
            <Col md="3" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="apartmentNumber" onFocus={this.onFocus} onBlur={this.onBlur} id="apartment"
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.apartmentNumber} autoComplete="off"
                // title="Apartment Number can contain alphanumeric value" 
                fieldCheck={UserModel.apartmentNumber === "" || UserModel.apartmentNumber === undefined?"unfilled":"filled"}
                onChange={this.handleChange} 
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Unit No.</span>
            </div>
            </Col> 
            <Col md="4" className="inputField">
             <div className="inputGroup">
               <input type="text" className="inputText"
                name="postal" onFocus={this.onFocus} onBlur={this.onBlur} id="postal"
                // pattern="^(?!000000)[0-9]{6}$"  maxLength="6" 
                value={UserModel.postal} autoComplete="off"
                // title="Postal code can contain 6 digits and it can't be zeros" 
                fieldCheck={UserModel.postal === ""?"unfilled":"filled"}
                onChange={this.handleChange} 
                required
                disabled={this.state.customerStatedisable}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Postal Code</span>
            </div>
            </Col>
            <Col md="2" className="inputField countrySelect" style={{left:'10px'}}>
                <select name="country" required onChange={this.handleChange} disabled={this.state.customerStatedisable}>
                {/* <option selected disabled hidden value=''>Select</option> */}
                {country.map((prop, key) => {
                      return <option  value={prop}  key={key} selected={prop === UserModel.country}>{prop}</option> 
                  })}
                </select>
                <label>Country</label>
            </Col>
            {/* <Col md="3" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="street"
                name="streetNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern="^[_ a-zA-Z0-9-#.,;]+$" value={UserModel.streetNumber} autoComplete="off"
                title="Street number can contain alphanumeric value" fieldCheck={UserModel.streetNumber === "" || UserModel.streetNumber === undefined ?"unfilled":"filled"}
                onChange={this.handleChange} 
                required
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Street Number</span>
            </div>
            </Col> */}
            {/* */}
            {/*  */}
            </Row>
            {/* </Container> */}
            <Row>
            <Col md="5" className="sectionHeading">
              <h4>Source of Wealth:</h4>
            </Col>
            <Col md="6" className="sectionHeading">
              <h4>Remittance Purpose:</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="6" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck"> Income
              <input type="checkbox" value="Income" disabled={this.state.customerStatedisable} name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
              checked={UserModel.sourceOfIncome.includes("Income")} multiple/>
              <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Loan
            <input type="checkbox" value="Loan" onChange={this.sourceOfIncomeList} disabled={this.state.customerStatedisable} 
            name="sourceOfIncome" checked={UserModel.sourceOfIncome.includes("Loan")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Savings
            <input type="checkbox" value="Savings" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Savings")} disabled={this.state.customerStatedisable} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Investment
            <input type="checkbox" value="Investment" name="sourceOfIncome" 
            checked={UserModel.sourceOfIncome.includes("Investment")} disabled={this.state.customerStatedisable} onChange={this.sourceOfIncomeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="6" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Payment for overseas property purchased
            <input type="checkbox" name="RemittancePurpose" value="Payment for overseas property purchased" 
            checked={UserModel.purpose.includes("Payment for overseas property purchased")} disabled={this.state.customerStatedisable} onChange={this.purposeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Loan
            <input type="checkbox" name="RemittancePurpose" value="Loan" disabled={this.state.customerStatedisable} onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Loan")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Sale of property
            <input type="checkbox" value="Sale of Property" name="sourceOfIncome" disabled={this.state.customerStatedisable} onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Sale of Property")}multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Other
            <input type="checkbox" value="Other" name="sourceOfIncome" disabled={this.state.customerStatedisable} onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Other")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="3" className="inputField">
          {this.sourceOfIncomeOther()}
          <br/>
          </Col>
          <Col md="6" className="inputField">
          <FormGroup check inline style={{marginBottom:'25px'}}>
            <label className="containerCheck">Capital injection
            <input type="checkbox" name="RemittancePurpose" value="Capital Injection" disabled={this.state.customerStatedisable} onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Capital Injection")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Goods and services
            <input type="checkbox" name="RemittancePurpose" value="Goods and services" disabled={this.state.customerStatedisable} onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Goods and services")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Income
            <input type="checkbox" name="RemittancePurpose" value="Income" disabled={this.state.customerStatedisable} onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Income")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Savings
            <input type="checkbox"name="RemittancePurpose" value="Savings"  disabled={this.state.customerStatedisable} onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Savings")} multiple/>
            <span className="checkmark"></span></label>
            </FormGroup>
            <FormGroup check inline>
            <label className="containerCheck">Private banking wealth management
            <input type="checkbox" name="RemittancePurpose" value="Private banking wealth management" 
            checked={UserModel.purpose.includes("Private banking wealth management")} disabled={this.state.customerStatedisable} onChange={this.purposeList} multiple/>
            <span className="checkmark" ></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField">
          </Col>
          <Col md="3"></Col>
          <Col md="1" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Other
            <input type="checkbox" name="RemittancePurpose" value="Other" 
            checked={UserModel.purpose.includes("Other")} disabled={this.state.customerStatedisable} onChange={this.purposeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="2" className="inputField">
            {this.purposeOther()}
          </Col>
          </Row>
          <Row>
            <Col md="5" className="sectionHeading">
            <h4>Indicative Transaction Amount</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField transactionAmount">
          <select name="indicativeTransactionLimit" disabled={this.state.customerStatedisable} onChange={this.handleChange} required>
          <option selected disabled hidden value=''>Select</option>
                <option value="UpTo100K" selected={UserModel.indicativeTransactionLimit === "UpTo100K"}>Upto S$100,000</option>
                <option value="UpTo500K"selected={UserModel.indicativeTransactionLimit === "UpTo500K"}>S$100,001 - S$500,000</option>
                <option value="UpTo1M" selected={UserModel.indicativeTransactionLimit === "UpTo1M"}>S$500,001 - S$1,000,000</option>
                <option value="Over1M" selected={UserModel.indicativeTransactionLimit === "Over1M"}>More than S$1,000,000</option>
              </select>
              <label>SGD</label>
          </Col>
          </Row>
            </Container>

            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Authorised Person Details</h4>
              </Col>
            </Row>
            <Row style={{marginTop:'0px !important'}}>
              <Col md="4" className="sectionHeading">
              {this.profilePic()}
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                <div className="inputGroup">
                  <input type="text" className="inputText" id="name"
                  name="name" onFocus={this.onFocus} onBlur={this.onBlur}
                  // pattern="^[_ a-zA-Z0-9-.,;]+$" 
                  value={UserModel.name} autoComplete="off"
                  // title="Name Should contain only Alphabets" 
                  fieldCheck={UserModel.name === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required 
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Full Name</span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
                  <input type="text" className="inputText" id="name"
                  name="jobTile" onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern="^[a-zA-Z0-9,\\s ]+" value={UserModel.jobTile} autoComplete="off"
                  title="Value can contain alphanumeric" fieldCheck={UserModel.jobTile === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required 
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Job Title</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                 <div className="inputGroup">
                  <input type="text" className="inputText" id="name"
                  name="idNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern="^[a-zA-Z0-9,\\s ]+" value={UserModel.idNumber} autoComplete="off"
                  title="Value can contain alphanumeric" fieldCheck={UserModel.idNumber === ""?"unfilled":"filled"}
                    onChange={this.handleChange} required 
                    disabled={this.state.customerStatedisable}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>ID Number</span>
            </div>
            </Col>
            <Col md="4" className="inputField genderSelect">
            <select name="nationality"  onChange={this.handleChange} required  disabled={this.state.customerStatedisable}>
            <option selected disabled hidden value=''>Select</option>
                {nationality.map((prop, key) => {
                      return <option  value={prop} key={key} selected={prop === UserModel.nationality}>{prop}</option> 
                  })}
                </select>
                <label>Nationality</label>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                <div className="inputGroup">
                  <input type="text" className="inputText" id="emailId"
                  name="personnelEmailId" onFocus={this.onFocus} onBlur={this.onBlur}
                  pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]+$" autoComplete="off"
                    title="Email should of format example@gmail.com" 
                    fieldCheck={UserModel.personnelEmailId === ""?"unfilled":"filled"}
                    value={UserModel.personnelEmailId}
                    required  onChange={this.handleChange}
                    />
                <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Email Id</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="1" className="inputField">
            <select name="callingCode"  onChange={this.handleChange} style={{marginRight:'20px'}}
            disabled= {((this.state.verify === 'verified') || (!this.state.loadPhone))? true : false} required>
            {/* <option selected disabled hidden value=''>Select</option> */}
                {phoneCodes.map((prop, key) => {
                      return <option  value={prop} key={key} selected={prop === UserModel.callingCode}>{prop}</option> 
                  })}
                </select>
                <label>Dialing Code</label>
            </Col>
            <Col md="5" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="mobile"
                name="phoneNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                value={UserModel.phoneNumber} autoComplete="off"
                pattern= "[0-9]+$" 
                title="Mobile number can contain only digits"
                fieldCheck={UserModel.phoneNumber === ""?"unfilled":"filled"}
                onChange={this.handleChange} required
                disabled= {((this.state.verify === 'verified') || (!this.state.loadPhone))? true : false}
                  />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Mobile Number</span>
            </div>
              <label style={{color:'#bcbcbc'}}>We will verify this number via OTP</label>
              </Col>
              <Col md="3">
            {this.OTPText()}
            </Col>
            <Col md="3" className="inputField" style={{bottom:'10px',paddingRight: '45px'}}>
            {this.OTPbutton()}
            </Col>
              </Row>
              <Row className="inputRow">
                  {this.documentDownload()}
              </Row>
            </Container>
            <Container className="pageFooter" style={{backgroundColor:"#fbfbfb"}}>
                      <Row className="inputRow" style={{paddingRight:'30px'}}>
                        <Col md="2"></Col>
                        <Col md="3">
                        </Col><Col md="3">
                        {this.SaveButton()}
                        </Col>
                      </Row>
              </Container>
            </form>
            </Container>
            </BlockUi>
        </>
        )
    }
}
export default CorpUser;