import React  from "react";
import {Button, Row, Col, Modal, ModalHeader, Form, ModalBody, ModalFooter  } from "reactstrap";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class BeneficiaryModal extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
      modal: false,
      editingDisabled:true,
      loading:false,
      loaderMessage:""
    };

    this.toggle       = this.toggle.bind(this);
    this.okHandle     = this.okHandle.bind(this);
    this.saveHandle   = this.saveHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFocus      = this.onFocus.bind(this);
    this.onBlur       = this.onBlur.bind(this);
  }
  onFocus(event){
    if(event.target.disabled)return
    event.target.nextElementSibling.classList.remove('spanMiddle')
    event.target.nextElementSibling.classList.add('spanTop')
    if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
  }
  onBlur(event){
    event.target.classList.remove('activeFocus','invalid','valid')
    event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
    if(event.target.value==""){
      event.target.classList.remove('activeFocus','invalid','valid')
      event.target.nextElementSibling.classList.remove('spanTop')
      event.target.nextElementSibling.classList.add('spanMiddle')
      }
      if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  
  okHandle(){
    if(this.state.editingDisabled){
    this.toggle()
    }
    else{
      this.setState({editingDisabled:true})
      this.toggle()
    }
    
    
  }

  saveHandle(e){
     e.preventDefault();
    if(this.state.editingDisabled){
      this.setState({editingDisabled:false})
    }
    else{
      this.onSave()
    }
    
  }

  handleChange = (event) => {
    if(event.target.type=="text"){
      event.target.classList.remove('valid','invalid')
      event.target.classList.add('activeFocus')
    }
    this.setState({ [event.target.name]:event.target.value });
  }

  onSave(){
    this.setState({loading:true,loaderMessage:"Saving..."})
    let data={
      "beneficiaryId":this.props.beneficiary.beneficiaryId,
      "bankName":this.state.bankName?this.state.bankName:this.props.beneficiary.bankName,
      "swiftBIC":this.state.swiftBIC?this.state.swiftBIC:this.props.beneficiary.swiftBIC,
      "accountNumber":this.state.accountNumber?this.state.accountNumber:this.props.beneficiary.accountNumber,
      // "branchCode":this.state.branchCode?this.state.branchCode:this.props.beneficiary.branchCode,
      "address":this.state.address?this.state.address:this.props.beneficiary.address,
      "country":this.state.country?this.state.country:this.props.beneficiary.country,
      "countryCode":this.state.countryCode?this.state.countryCode:this.props.beneficiary.countryCode,
    }
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/editbeneficiary";
      axios.post(url,data).then(res =>{
        if(res.data.status==200){
          alert(res.data.message);
          this.setState({loading:false,loaderMessage:"",editingDisabled:true})
          window.location.reload();
        }
        else{
          alert(res.data.message);
          this.setState({loading:false,loaderMessage:"",editingDisabled:true})
        }
        }).catch(function (error) {})
        
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" className={this.props.className} backdrop="static">
          <ModalHeader toggle={this.toggle}>{this.props.modalTitle}</ModalHeader>
          <BlockUi tag="div" blocking={this.state.loading} keepInView={true} message={this.state.loaderMessage}>
          <Form onSubmit={this.saveHandle}>
          <ModalBody>
          <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
            <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="name" 
                      title="Name" autoComplete="off"
                      value={this.props.beneficiary.name?this.props.beneficiary.name:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Name</span>
                </div>
           </Col>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="contactNumber" 
                      title="Contact Number" autoComplete="off"
                      value={this.props.beneficiary.contactNumber?this.props.beneficiary.contactNumber:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Contact Number (Optional)</span>
                </div>
           </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="emailId" 
                      title="Email ID" autoComplete="off"
                      value={this.props.beneficiary.emailId?this.props.beneficiary.emailId:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Email ID (Optional)</span>
                </div>
           </Col>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="type" 
                      title="Type" autoComplete="off"
                      value={this.props.beneficiary.type==="INDIVIDUAL"?"Individual":"Company"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Type</span>
                </div>
           </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="idNumber" 
                      title="Beneficiary Identification Number" autoComplete="off"
                      value={this.props.beneficiary.idNumber?this.props.beneficiary.idNumber:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Identification Number</span>
                </div>
           </Col>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="contactPerson" 
                      title="Contact Person" autoComplete="off"
                      value={this.props.beneficiary.contactPerson?this.props.beneficiary.contactPerson:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Contact Person</span>
                </div>
           </Col>
         </Row>
	       <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="bankAddressLine1" 
                      title="Bank Address" autoComplete="off"
                      value={this.props.beneficiary.bankAddressLine1?(this.props.beneficiary.bankAddressLine1 +" "+(this.props.beneficiary.bankAddressLine2?this.props.beneficiary.bankAddressLine2:"")):"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Address</span>
                </div>
           </Col>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="IBANCode" 
                      title="IBAN Code" autoComplete="off"
                      value={this.props.beneficiary.IBANCode?this.props.beneficiary.IBANCode:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>IBAN Code</span>
                </div>
           </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="4" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="sortCode" 
                      title="Sort Code" autoComplete="off"
                      value={this.props.beneficiary.sortCode?this.props.beneficiary.sortCode:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Sort Code</span>
                </div>
           </Col>
           <Col md="4" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="ABACode" 
                      title="ABA Code" autoComplete="off"
                      value={this.props.beneficiary.ABACode?this.props.beneficiary.ABACode:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>ABA Code</span>
                </div>
           </Col>
           <Col md="4" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="BSBCode" 
                      title="BSB Code" autoComplete="off"
                      value={this.props.beneficiary.BSBCode?this.props.beneficiary.BSBCode:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>BSB Code</span>
                </div>
           </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="relation" 
                      title="Relation" autoComplete="off"
                      value={this.props.beneficiary.relation?this.props.beneficiary.relation:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Relation</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="bankName" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Bank Name" autoComplete="off"
                      onChange={this.handleChange} />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Name</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={!this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="bankName" 
                      title="Bank Name" autoComplete="off"
                      value={this.props.beneficiary.bankName?this.props.beneficiary.bankName:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Bank Name</span>
                </div>
           </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           <Col md="6" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="swiftBIC" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="SWIFT Code" autoComplete="off"
                      onChange={this.handleChange} />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>SWIFT Code</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={!this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="swiftBIC" 
                      title="SWIFT Code" autoComplete="off"
                      value={this.props.beneficiary.swiftBIC?this.props.beneficiary.swiftBIC:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>SWIFT Code (Optional)</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="accountNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Account Number can contain only 10-15 Digits" autoComplete="off"
                      pattern="[0-9]{10,15}"
                      onChange={this.handleChange} />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Account Number</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={!this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="accountNumber" 
                      title="Account Number can contain only 10-15 Digits" autoComplete="off"
                      value={this.props.beneficiary.accountNumber?this.props.beneficiary.accountNumber:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Account Number</span>
                </div>
           </Col>
         </Row>
         <Row className="inputRow" style={{margin:"0px 10px",width:"100%"}}>
           {/* <Col md="6" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="branchCode" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Can be of 3 digits, except 000" autoComplete="off"
                      pattern="^(?!000)[0-9]{3}$" maxLength="3" data-optional="true"
                      onChange={this.handleChange} />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Branch Code</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={!this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="branchCode" data-optional="true"
                      title="Can be of 3 digits, except 000" autoComplete="off"
                      value={this.props.beneficiary.branchCode} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Branch Code (Optional)</span>
                </div>
           </Col> */}
           <Col md="6" className="inputField" hidden={this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" id="bankName"
                      name="address" onFocus={this.onFocus} onBlur={this.onBlur}
                      title="Bank Address" autoComplete="off"
                      onChange={this.handleChange} />
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Address</span>
                </div>
           </Col>
           <Col md="6" className="inputField" hidden={!this.state.editingDisabled}>
                <div className="inputGroup">
                    <input type="text" className="inputText" 
                      name="address" 
                      title="Bank Address" autoComplete="off"
                      value={this.props.beneficiary.address?this.props.beneficiary.address:"No value entered"} disabled/>
                    <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Beneficiary Address (Optional)</span>
                </div>
           </Col>
           <Col md="6" className="inputField">
                    <div className="inputGroup">
                        <input type="text" className="inputText" 
                          name="country" 
                          title="Country" autoComplete="off"
                          value={this.props.beneficiary.country?this.props.beneficiary.country:"No value entered"} disabled/>
                        <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Country</span>
                    </div>
             </Col>
         </Row>
         <Row className="inputRow">
                <Col md="12" className="inputField" style={{marginBottom:"0px",marginLeft:"20px"}}>
                    {/* <select multiple size={2} name="beneficiaryCurrencies" style={{display:"table-row",borderSpacing: "10px"}} >
                        {(this.props.beneficiary.currencies===undefined?[]:this.props.beneficiary.currencies).map((prop) => {
                                            return <option disabled style={{fontSize:'16px !important',background:"#007bff",color:"#ffffff",borderRadius:"20px",display:"table-cell"}}  value={prop}>{prop}</option>
                            })}
                    </select> */}
                    {
                      (this.props.beneficiary.currencies===undefined?[]:this.props.beneficiary.currencies).map((prop,key) => {
                        return <div class="selectContainer">
                                 <input type="checkbox" disabled checked value={prop} id={key}/>
                                 <label onClick={this.toggleCurrencies} for={key}>{prop}</label>
                               </div>
                      })
                    }
                    <label style={{marginLeft:"10px"}}>Currencies available for transactions.</label>
                </Col>
         </Row>
          </ModalBody>
          <ModalFooter>
            <Button style={{backgroundColor:"#8b0304"}} hidden={this.props.beneficiary.beneficiaryStatus!=="ApprovalPending"} disabled={this.props.beneficiary.beneficiaryStatus!=="ApprovalPending"} type="submit">{this.state.editingDisabled?"Edit":"Save"}</Button>{' '}
            <Button color="secondary" onClick={this.okHandle}>{this.state.editingDisabled?"OK":"Cancel"}</Button>
          </ModalFooter>
        </Form>
        </BlockUi>
        </Modal>
      </>
    );
  }
}

export default BeneficiaryModal;