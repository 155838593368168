const currencyHome = {
"AUD":{"name":"Australia dollar","min":1,"max":10000000000},//
"CAD":{"name":"Canadian dollar","min":1,"max":10000000000},//
"CHF":{"name":"Swiss franc","min":1,"max":10000000000},//
"CNY":{"name":"China yuan renminbi","min":1,"max":10000000000},//
"EUR":{"name":"Euro","min":1,"max":10000000000},//
"GBP":{"name":"United Kingdom pound","min":1,"max":10000000000},
"HKD":{"name":"Hong Kong dollar","min":1,"max":10000000000},//
"IDR":{"name":"Indonesian Rupiah","min":1,"max":10000000000},//
"INR":{"name":"Indian Rupee","min":1,"max":10000000000},//
"JPY":{"name":"Japan yen","min":1,"max":10000000000},//
"MYR":{"name":"Malaysian Ringgit","min":1,"max":10000000000},
"NZD":{"name":"New Zealand dollar","min":1,"max":10000000000},//
"PHP":{"name":"Philippine peso","min":1,"max":10000000000},//
"THB":{"name":"Thailand baht","min":1,"max":10000000000},//
"TWD":{"name":"New Taiwan Dollar","min":1,"max":10000000000},//
"USD":{"name":"United States dollar","min":1,"max":10000000000},//
"BDT":{"name":"Bangladeshi taka","min":1,"max":10000000000},//
"MMK":{"name":"Myanmar Kyat","min":1,"max":10000000000}//
};//
export default currencyHome;


export const currencyG7 = ['USD','EUR','GBP','JPY','AUD','NZD','CAD','CHF']
export const currencyAsian = ['MYR','CNY','PHP','BDT','INR','IDR','HKD','THB','TWD','MMK']
//AUD	1000	100000
//NZD	1000	100000
//CHF	1000	100000
//EUR	1000	100000
//CAD	1000	100000
//USD	1000	100000
//CNY	5000	500000
//HKD	5000	500000
//IDR	10000000	200000000
//INR	50000	500000
//JPY	100000	10000000
//MYR	3000 300000
//NTD	20000	500000
//PHP	30000	400000
//THB	20000	100000