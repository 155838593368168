import React from "react";
import {Container} from "reactstrap";
import Loader from 'react-loader-spinner';
class CallBack extends React.Component {
  constructor(props) {
    super(props); 
    var urlParams = new URLSearchParams(window.location.search);
    let code=urlParams.get('code')
    window.location="/user?code="+code;
  }

  render() {
    return (
        <Container>
              <div style={{marginLeft: '500px'}}>
              <Loader type="Oval" color="black" height="600"  width="100" />
              </div>
        </Container>
    );
  }
}

export default CallBack;
