
import React from "react";
import { Link } from "react-router-dom";
import Stepper from 'react-stepper-horizontal';
import { UncontrolledTooltip, Container, Row, Col,Breadcrumb, BreadcrumbItem, Button,Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loader from 'react-loader-spinner';
import Spacer from '../../components/Spacer/Spacer';
import axios from 'axios';
import uuid from "uuid";
import { Storage } from 'aws-amplify';
import DocumentsModel from 'views/User/DocumentsModel.jsx';
import BlockUi from 'react-block-ui';
import mime from "mime-types";
import Notification from "components/modals/Notification";
import info from "assets/img/info.svg";




class userDocument extends React.Component{
  constructor(props){
      super(props)
      this.state = {visible:false,document: "loading",documentState:false,allDocumentsUploaded:false,loading:'',
      docTypyeLoad:'',fileUrl:'',loaddocs: true,redirectTo:'',displayStepper:false,additionalDoc:false,addNext:"add",
        documentList:[],displayName:false,docSubLoader:false,IdentityProofCount:false,AddressProofCount:false,IncomeProofCount:false,docNum:false,callmodal:false,docName :'',displayButton:true,docPageLoad:true,documentStateSet:false
      }
      this.uploadedFiles          = []
      this.IdentityProofFile      = React.createRef();
      this.AddressProofFile       = React.createRef();
      this.IncomeProofFile        = React.createRef();
      this.AdditionalDocumentFile = React.createRef();
      this.IdentityProof          = React.createRef();
      this.AddressProof           = React.createRef();
      this.IncomeProof            = React.createRef();
      this.AdditionalDocument     = React.createRef();
      this.checkIDType            = this.checkIDType.bind(this);
      this.IdentityProofErr       = React.createRef();
      this.AddressProofErr        = React.createRef();
      this.IncomeProofErr         = React.createRef();
      this.AdditionalDocumentErr  = React.createRef();
      this.buttonLoader           = this.buttonLoader.bind(this);
      this.fetchDocumentData      = this.fetchDocumentData.bind(this);
      this.toggle                 = this.toggle.bind(this);
      this.displayNames           = this.displayNames.bind(this);
      this.additionalDoc          = this.additionalDoc.bind(this);
      this.fetchDocByTypeSelect   = this.fetchDocByTypeSelect.bind(this);
      this.handleSubmit           = this.handleSubmit.bind(this);
      this.callmodalmethod        = this.callmodalmethod.bind(this);
      this.displayStepper         = this.displayStepper.bind(this);
      this.uploadDoc              = this.uploadDoc.bind(this);
      this.buttonDisplay          = this.buttonDisplay.bind(this);
      this.breadcrumb             = this.breadcrumb.bind(this);
      this.clearError             = this.clearError.bind(this);
      this.docButton              = this.docButton.bind(this);
      this.error                   = React.createRef();
      this.docName                = '';
      this.documentModel          = [];
      this.count                  = 0;
      this.IdentityProofCount     = false;
      this.AddressProofCount      = false;
      this.IncomeProofCount       = false;
      this.fetchDocumentData();
  }

  buttonLoader(docType){
    if(this.state.loading === undefined || this.state.docTypyeLoad === '' ){
      return "";
    }else if(this.state.loading === docType && this.state.docTypyeLoad === "loading"){
      return ( 
        <Button className="float-right btn-circledoc" >
            <Loader type="Oval" color="#ddd" height="13"  width="13"/>
      </Button>);
    }else if(this.state.loading === docType && this.state.docTypyeLoad === "uploaded"){
      return(
      <div style={{ paddingRight: '30px' ,marginRight: '20px;'}}>
        <Button  className="float-right btn-circledoc" >
        <i class="fa fa-check" style={{color:'white'}} aria-hidden="true"></i>
          </Button>
          </div>
      );
    }else {
      return "";
     }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  onChange=(e) => {
    e.preventDefault()
    let doctype,file;
    doctype = e.target.name;
    file = e.target.files;
    if(file.length>0){
    if(!(this[doctype+'Count'])){
      this.count = this.count+1;
      this[e.target.name+'Count']=true;
    } 
     this.uploadedFiles=[];
     this.setState({loading:e.target.name , docTypyeLoad:"loading"})
        this.uploadDoc(file,doctype,0);
     }
  }  
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  clearError =(e) =>{
    this[e.target.name+'Err'].current.innerHTML =''
  }
  
  uploadDoc = (files,doctype,pageNum) => {
    let fileName = uuid.v4()+'.'+files[pageNum].name.slice((files[pageNum].name.lastIndexOf(".") - 1 >>> 0) + 2);
    Storage.put(fileName,files[pageNum], {
      level: 'private',
      contentType: mime.contentType(files[pageNum].name)
  })
    .then (result => {
      this.uploadedFiles.push({
        "documentType":doctype,
        "fileName": result.key,
        "pageNumber": pageNum+1,
        "mimeType": mime.contentType(files[pageNum].name),
        "originalFileName":files[pageNum].name
      })
      if(pageNum < files.length -1 )  {
        this.uploadDoc(files,doctype,pageNum+1)
      } else {
        this.setState({[doctype]:this.uploadedFiles})
        this.docurl(doctype);
      }
    }).catch();  
  }
  
  
  docurl = (doctype) => {
    let data ={
      "documentType": doctype,
      "documentName":this[doctype].current.value,
      "uploadedAtTime": new Date().toISOString(),
      "pages":this.uploadedFiles
    }
    const docurl = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/document";
      axios.post(docurl, data)
          .then(response => {
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(response)}
        this.setState({displayName:true,loading: doctype, docTypyeLoad:"uploaded",loaddocs:false})
        }).catch(error => { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} })
        if(this.count >1){
          this.setState({docNum:true,loading: doctype, docTypyeLoad:"uploaded",loaddocs:false})
        }else if(doctype=== "AdditionalDocument"){
          this.setState({loading: doctype , docTypyeLoad:"uploaded",loaddocs:false,docNum:true})
        }
  }

  fetchDocByTypeSelect(docType){
      const docs = this.documentModel.filter(doc => doc.documentType===docType);
      return docs.length === 0 ? {} : docs[0];
  }
  
  additionalDoc(){
      if(this.state.additionalDoc){
          return<>
          <Row>
          <Col md="12" className="sectionHeading">
            <h4>Additional Document</h4>
          </Col>
        </Row>
        <Row className="inputRow">
        <Col md="7" className="inputField">
        <p style={{color:'#9f9f9f',fontSize:'15px',marginBottom: '0px'}}>Please upload required document</p>
        </Col>
        </Row>
        <Row className="inputRow">
        <Col md="4" className="inputField" style={{paddingTop:'0px',marginTop:'0px'}}>
            <div className="inputGroup">
                    <input type="text" className="inputText" id="otherDoc"
                    ref={this.AdditionalDocument}
                    style={{width:'180px',height:'38px',marginTop:'0px',paddingTop:'0px'}}
                value={this.fetchDocByTypeSelect("AdditionalDocument").documentName}  autoComplete="off"
                disabled
                />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Document Name</span>
            </div>
          </Col>
          <Col md="5" className="inputField">
            <div class="input-container" onClick={() => this.AdditionalDocumentFile.current.click()} >
              <input type="file" id="real-input" name="AdditionalDocument"  accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState}ref={this.AdditionalDocumentFile} onChange={(event) => this.onChange(event)} multiple></input>
                      <button class="browse-btn">Browse..</button>
                  <span class="file-info">{this.displayNames("AdditionalDocument")}</span>
            </div>
            </Col>
            <Col md="1">
            <div style={{ paddingRight: '30px',paddingTop:'6px',marginRight: '20px',paddingLeft:'30px'}}>
              {this.buttonLoader("AdditionalDocument")}
          </div>
          </Col>
        </Row>
        <Row>
        <span className="forexSubtitleErr" ref={this.AdditionalDocumentErr} style={{display: 'none',marginLeft: '15px'}}></span>
        </Row>
          </>
      }
  }

  displayNames(docType){
    let pageCount= 0;
    const docs = this.state.documentList.filter(doc => doc.documentType===docType);
    if(docs[0] !== undefined && docs[0] !== '' && this.uploadedFiles !== undefined && this.uploadedFiles !==''){
      let getDocType = this.uploadedFiles.filter((d) => d.documentType === docType)
      if(getDocType.length !== 0 && docs[0].originalFileName !== getDocType[0].originalFileName){
        let name = this.state[docType].map((name) => {
          pageCount = pageCount+1;
          return <><label  style={{padding:'5px'}}>{name.originalFileName}</label>
          {(this.state[docType].length === pageCount)?'': <label>{' '}|{' '}</label>}
          </>
          })
        return name
      }
    }
    if(this.state.loading === docType && this.state.docTypyeLoad === "loading"){
      return "";
    }
    if(docs[0] === undefined || docs[0] === ''){
        if(this.state.displayName){

    if(this.state[docType] === undefined || this.state[docType] === ""){
        return <label  style={{padding:'10px'}}>Select single or multiple files</label>;
    } 
    else{ 
        let name = this.state[docType].map((name) => {
          pageCount = pageCount+1;
                 return <><label  style={{padding:'5px'}}>{name.originalFileName}</label>
                 {(this.state[docType].length === pageCount)?'': <label>{' '}|{' '}</label>}
                 </>
       })
       return name
    }
    }
    return <label style={{padding:'10px'}}>Select single or multiple files</label>;
    } else{
        let name =docs[0].pages.map((name) => {
          pageCount = pageCount+1;
            return <><label  style={{padding:'5px'}}>{name.originalFileName}</label>
              {(docs[0].pages.length === pageCount)?'':<label>{' '}|{' '}</label>}
          </>
           })
        return name
    }

  }

  callmodalmethod(){
    if(this.state.callmodal){
      this.toggle();
    }
  }

  fetchDocumentData=()=>{
    axios.get( (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/document')
    .then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      if(res.data.status === 200){
        this.documentModel=res.data.documents.map((d) => {this.count =this.count+1;this[d.documentType+'Count']=true;return new DocumentsModel(d)});
        if(this.count > 1){
          this.setState({docNum:true});
        }
        this.setState({document: "loaded",documentList:res.data.documents})
        if(this.props.user.attributes.profile === "Approved" && this.fetchDocByTypeSelect("AdditionalDocument").documentType !== undefined){
          this.setState({additionalDoc:true,documentStateSet : true,documentState : true,displayStepper:true,displayButton:false});
        }else if(this.props.user.attributes.profile === "Approved"||this.props.user.attributes.profile === "Rejected" || this.props.user.attributes.profile === "AutoApproveWith2Year" || this.props.user.attributes.profile === "AutoApproveWith3Year" || this.props.user.attributes.profile === "PendingApproval"){
          this.setState({documentState : true,displayStepper:true,displayButton:false});
        }else if(this.props.user.attributes.profile === "PendingClarification"){
          this.setState({additionalDoc:true,displayStepper:true,documentStateSet : true,docName:this.fetchDocByTypeSelect("AdditionalDocument").documentName,callmodal : true});
          this.callmodalmethod();
        }
      } 
      this.setState({docPageLoad:false})
    }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} })
     
  }


  buttonDisplay(){
    if((this.state.documentState) && !(this.state.displayButton)){
      return "";
    }else {
        return <>
         <Col md="4"></Col>
          <Col md="3" style={{marginLeft:'40px'}}>
            <Link to="/user/page2"> <Button type="secondary" id="back" className="pageButton secondary" style={{float:"right"}} disabled={this.state.documentState}>Back</Button></Link>
              </Col><Col md="3" style={{paddingRight:'135px'}}>
                        {this.docButton()}
                </Col>
        </>
    }
  }

  docButton(){
    if(this.state.docSubLoader){
      return (<Button type="secondary" className="pageButton primary" type="submit" style={{float:"right",backgroundColor:'#8c0204'}}><Loader type="Oval" color="#ddd" height="13"  width="13"/></Button>);
    }
    else{
      return (<Button type="secondary" className="pageButton primary" onClick={this.handleSubmit} style={{float:"right",backgroundColor:'#8c0204'}} disabled={this.state.documentState || !this.state.docNum} >Continue</Button>);
    }
  }

  handleSubmit(){
    this.setState({docSubLoader:true})
    axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/document/verify')
    .then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      if(res.data.status === 200){
        this.setState({docSubLoader:false});
        localStorage.setItem("redirect","true")
        window.location.reload(true);
      }
    }).catch(function (error) {
      this.error.current.toggle();
    })
  }


  displayStepper(){
    if(!(this.state.displayStepper)){
      return <>
       <Row style={{margin: "0% 2%"}}>
  <Col md="3" style={{padding:0}}> 
      <Stepper steps={ [{}, {}, {}] } activeColor="#585859" defaultColor="#d8d8d8" lineHeight='28px !important' activeOpacity="100px" completeColor="#8b0304" lineMarginOffset={6} activeStep={ 2 } />
  </Col>
  </Row>
      </>
    }
  }

  breadcrumb(){
    if(!(this.state.displayStepper)){
      return<>
      <BreadcrumbItem className="breadcrumbItem initial" tag="a" href="#">Log In/Register</BreadcrumbItem>
      <BreadcrumbItem className="breadcrumbItem" active tag="span">Register</BreadcrumbItem>
    </>
    }else{
     return<>
     <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                  <BreadcrumbItem className="breadcrumbItem" active tag="span">Document</BreadcrumbItem>
     </>
    }
  }


  checkIDType(e){
      if(this[e].current.value === '' || this[e].current.value === undefined){
          this[e+'Err'].current.innerHTML ="Please select the document type";
          this[e+'Err'].current.style.display = "inline";
          return '';
      } else{
        this[e+'File'].current.click()
      }
  }

  render(){
      return(
        <>
        <Notification ref={this.error}  modalTitle="Something went wrong. Please try again." />
        <Modal isOpen={this.state.modal}  toggle={this.toggle} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}}  className={this.props.className}>
        <ModalHeader style={{borderBottom:'transparent'}} toggle={this.toggle}>
        <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">Please upload {this.state.docName}</p> </ModalHeader>
        <ModalBody>
        <center>
          <Button color="primary" onClick={this.toggle} style={{marginRight:'15px',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}} >OK</Button>{' '}
          <Button color="primary" onClick={this.toggle} style={{marginRight:'15px',backgroundColor:'transparent',color:'#8c0204',borderColor:'transparent'}} >Cancel</Button>
        </center>
        </ModalBody>
        </Modal>
        <BlockUi tag="div" blocking={this.state.docPageLoad} keepInView={true} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className="color mainContent" >
            <Row className="breadcrumbContainer">
             <Col md="12" className="breadcrumbContainerColumn">
             <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
               {this.breadcrumb()}
             </Breadcrumb>
             </Col>
          </Row>
          <div >
         
          <Row className="pageHeading">
            <Col md="6"  className="pageHeadingColumn">
              <h4>Upload Documents</h4>
            </Col>
          </Row>
            {this.displayStepper()}
          <Container className="section" onFocus={window.scrollTo(0, 0)}>
          <Row>
            <Col md="12" className="sectionHeading" style={{display:'flex'}}>
            <Spacer height={30}/>
              <label style={{fontSize:'24px',paddingRight:'5px',width:'170px'}}>Identity Proof *</label>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="7" className="documentPage inputField">
          <p style={{color:'#9f9f9f',fontSize:'15px',marginBottom: '0px'}}>Accepted proofs of identity are passport, NRIC, ...</p>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField documentSelect" style={{paddingTop:'5px'}}>
                <select ref={this.IdentityProof} name="IdentityProof" onChange={(event) => this.clearError(event)}  disabled={this.state.documentState || this.state.documentStateSet} required>
                <option selected disabled hidden value=''>Select</option>
                  <option value="Passport" value="Passport"  selected={this.fetchDocByTypeSelect("IdentityProof").documentName == "Passport"}>Passport</option>
                  <option value="NRIC" value="NRIC" selected={this.fetchDocByTypeSelect("IdentityProof").documentName == "NRIC"}>National Photo ID</option>
                  <option value="DL" selected={this.fetchDocByTypeSelect("IdentityProof").documentName == "DL"}>Driving License</option>
                  <option value="FIN" selected={this.fetchDocByTypeSelect("IdentityProof").documentName == "FIN"}>FIN</option>
                </select>
                <label>Identity Type</label>
            </Col>
            <Col md="1"></Col>
            <Col md="5" className="inputField">
                <div class="input-container" style={{marginBottom:'5px'}} onClick={() => this.checkIDType("IdentityProof")} >
                <input type="file" id="real-input" name="IdentityProof"  accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.IdentityProofFile} onChange={(event) => this.onChange(event)} multiple></input>
                        <button class="browse-btn">Browse..</button>
                    <span class="file-info">{this.displayNames("IdentityProof")}</span>
                  </div>
                  <span className="forexSubtitleErr" ref={this.IdentityProofErr} style={{display: 'none'}}></span>
            </Col>
            <Col md="1">
            <div style={{ paddingRight: '30px' ,paddingTop:'6px',marginRight: '20px',paddingLeft:'30px'}}>
            {this.buttonLoader("IdentityProof")}
            </div>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row>
            <Col md="12" className="sectionHeading" style={{display:'flex'}}>
            <label style={{fontSize:'24px',paddingRight:'5px',width:'170px'}}>Address Proof *</label>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="7" className="inputField">
            <p style={{color:'#9f9f9f',fontSize:'15px',marginBottom:'0px'}}>Accepted proofs of address are NRIC, a recent utility bill, ...</p>
          </Col>
            </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField documentSelect" style={{paddingTop:'5px'}}>
                <select name="AddressProof" ref={this.AddressProof} style={{paddingRight: '1px !important',paddingLeft: '2px'}} onChange={(event) => this.clearError(event)}  disabled={this.state.documentState || this.state.documentStateSet} required>
                <option selected disabled hidden value=''>Select</option>
                  <option value="NRIC" selected={this.fetchDocByTypeSelect("AddressProof").documentName == "NRIC"}>National ID</option>
                  <option value="recentUtilityBill" selected={this.fetchDocByTypeSelect("AddressProof").documentName == "recentUtilityBill"}>Recent Utility Bill</option>
                  <option value="BCstatement" selected={this.fetchDocByTypeSelect("AddressProof").documentName == "BCstatement"}>Bank/Credit Card Statement</option>
                </select>
                <label>Address Type</label>
            </Col>
            <Col md="1"></Col>
            <Col md="5" className="inputField">
                <div class="input-container" style={{marginBottom:'5px'}} onClick={() => this.checkIDType("AddressProof")} >
                <input type="file" id="real-input" name="AddressProof"  accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.AddressProofFile} onChange={(event) => this.onChange(event)} multiple></input>
                        <button class="browse-btn">Browse..</button>
                    <span class="file-info">{this.displayNames("AddressProof")}</span>
                  </div>
                  <span className="forexSubtitleErr" ref={this.AddressProofErr} style={{display: 'none'}}></span>
            </Col>
            <Col md="1">
            <div style={{ paddingRight: '30px', paddingTop:'6px',marginRight: '20px',paddingLeft:'30px'}}>
            {this.buttonLoader("AddressProof")}
            </div>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row>
            <Col  md="12" className="sectionHeading" style={{display:'flex'}}>
            <label style={{fontSize:'24px',paddingRight:'5px',width:'170px'}} id="incomeProof">Income Proof <img src={info} height={15} width={15}  style={{marginLeft:"5px"}}/></label>
            <UncontrolledTooltip placement="right" target="incomeProof" style={{fontSize:"10px",textAlign:"left"}}>
            This information allows you to remit a cumulative amount of more than S$20,000 (we have the discretion to request for income proof whenever necessary)
            </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="7" className="inputField">
          <p style={{color:'#9f9f9f',fontSize:'15px',marginBottom:'0px'}}>Accepted proofs of income are salary slips,<br/>tax assessments, ... that show a regular income.</p>
          </Col>
            </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField documentSelect" style={{paddingTop:'5px'}}>
                <select  name="IncomeProof" ref={this.IncomeProof} disabled={this.state.documentState || this.state.documentStateSet} onChange={(event) => this.clearError(event)}  required>
                <option selected disabled hidden value=''>Select</option>
                  <option value="salaryslips" selected={this.fetchDocByTypeSelect("IncomeProof").documentName == "salaryslips"}>Salary Statement</option>
                  <option value="taxAssessments" selected={this.fetchDocByTypeSelect("IncomeProof").documentName == "taxAssessments"}>Tax Assessments</option>
                  <option value="CPFStatement" selected={this.fetchDocByTypeSelect("IncomeProof").documentName == "CPFStatement"} >CPF Statement</option>
                  <option value="Bankstatement" selected={this.fetchDocByTypeSelect("IncomeProof").documentName == "Bankstatement"}>Bank Statement</option>
                </select>
            <label>Income Type</label>
            </Col>
            <Col md="1"></Col>
            <Col md="5" className="inputField">
                <div class="input-container" style={{marginBottom:'5px'}} onClick={() => this.checkIDType("IncomeProof")} >
                <input type="file" id="real-input" name="IncomeProof"  accept=".png,.jpeg,.pdf,.jpg" disabled={this.state.documentState || this.state.documentStateSet} ref={this.IncomeProofFile} onChange={(event) => this.onChange(event)} multiple></input>
                        <button class="browse-btn">Browse..</button>
                    <span class="file-info">{this.displayNames("IncomeProof")}</span>
                  </div>
                  <span className="forexSubtitleErr" ref={this.IncomeProofErr} style={{display: 'none'}}></span>
            </Col>
            <Col md="1" >
            <div style={{ paddingRight: '30px' ,paddingTop:'6px',marginRight: '20px',paddingLeft:'30px'}}>
            {this.buttonLoader("IncomeProof")}
            </div>
            </Col>
          </Row>
          <Row>
          </Row>
          <div>
          {this.additionalDoc()}
          </div>
          </Container>
          <Container className="pageFooter" style={{backgroundColor:"#fbfbfb"}}>
                    <Row className="inputRow" style={{paddingRight:'30px'}}>
                     
                      {this.buttonDisplay()}
                    </Row>
            </Container>
           
            </div>
          </Container>
          </BlockUi>
          </>
      )
  }
}
export default userDocument;

