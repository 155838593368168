import React from "react";
import { Link } from "react-router-dom";
import TransactionModal from "components/modals/transactionModal.jsx";
import { Container, Row, Col,Breadcrumb, BreadcrumbItem, Input,Table, } from 'reactstrap';
import add from 'assets/img/add.svg';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import download from 'assets/img/download.svg';

class ListTransaction extends React.Component{
    constructor(props) {
        super(props);
        this.state={
          modal: false,
          modalEdit: false,
          disable:"true",
          paymentToDelete: "",
          currentPayment:"",
        };
        this.flag         = 0
        this.modalRef     = React.createRef();
        this.state.length = 0;

        const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment";
        axios.get(url).then(res =>{
          if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)} 
          this.setState({payment: res.data.payments});
          }).catch(function () {})

        this.toggle     = this.toggle.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this)
      }

    toggle() {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }    
    handleChange = (event) => {
      this.setState({ [event.target.name]:event.target.value });
        this.flag = 1
    }
    modalEditPayment=(i)=>{
      this.setState({currentPayment:this.state.payment[i]})
      if(this.state.payment[i].paymentStatus==="Draft"){
      this.modalRef.current.setState({editingDisabled:false})
      }
      this.modalRef.current.toggle();
    }    
    modalViewPayment=(i)=>{
      this.setState({currentPayment:this.state.payment[i]})
      this.modalRef.current.toggle();
    }
    display_content = () => {
      const no_list_content=<Row>
      <Col md="6">
      {this.state.searchCriteria?"No Results found": (this.flag==0?<b>Currently there are no transactions added to this list. <Link to="/transaction/add">Go Ahead and Create One</Link></b>:"No Results Found. Press Enter to continue")}
      </Col>
    </Row>
      ;      
    if(this.state.payment.length>0){
      return <><Table striped>
      <thead className="tableHeading">
          <tr>
          <th>#</th>
          <th>Beneficiary Name</th>
          <th>Bank Name</th>
          <th>Execution Date</th>
          <th>Amount to Transfer</th>
          <th>Reference ID</th>
          <th>Transaction Type</th>
          <th>Status</th>
          <th></th>
          </tr>
      </thead>
      <tbody className="tableContent">
      {this.createTable()} 
      </tbody>
      </Table>
    </>;
    }
    if(this.state.payment.length==0)
    return no_list_content;
    }      
    createTable = () => {
      let table = []
      let j=0
      for (let i = 0; i < this.state.payment.length; i++) {
        let children = []
        children.push(<th scope="row">{`${j + 1}`}</th>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}>{this.state.payment[i].beneficiaryName}</a></td>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}>{this.state.payment[i].bankName}</a></td>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}>{this.state.payment[i].executionDate}</a></td>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}><b>{this.state.payment[i].currency}</b>{" "+parseInt(this.state.payment[i].amountToTransfer.toString().replace(/,/g,"")).toLocaleString()}</a></td>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}>{this.state.payment[i].mwaTransactionId}</a></td>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}>{this.state.payment[i].transactionType}</a></td>)
        children.push(<td onClick={() =>this.modalViewPayment(i)}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none"}} className={this.colorClass(this.state.payment[i].paymentStatus)}>{this.state.payment[i].paymentStatusPrettyName}</a></td>)
        
        children.push(<td style={{padding:"10px 0px"}}><a href="" onHover={(e)=>e.preventDefault()} onClick={(e)=>e.preventDefault()}
        style={{underline:"none",color:"#000"}}><span hidden={((((this.state.payment[i].paymentStatus=="Draft" || this.state.payment[i].paymentStatus=="Initialised" || this.state.payment[i].paymentStatus=="OpsApproved" || this.state.payment[i].paymentStatus=="OpsRejected"  || this.state.payment[i].paymentStatus=="ConfirmationPending") && this.state.payment[i].transactionType==="OUTWARD")|| ((this.state.payment[i].paymentStatus==="Initialised" || this.state.payment[i].paymentStatus==="BankInstructionGenerated") && this.state.payment[i].transactionType==="INWARD"))?true:false)} 
                      disabled={((((this.state.payment[i].paymentStatus=="Draft" || this.state.payment[i].paymentStatus=="Initialised" || this.state.payment[i].paymentStatus=="OpsApproved" || this.state.payment[i].paymentStatus=="OpsRejected"  || this.state.payment[i].paymentStatus=="ConfirmationPending") && this.state.payment[i].transactionType==="OUTWARD")|| ((this.state.payment[i].paymentStatus==="Initialised" || this.state.payment[i].paymentStatus==="BankInstructionGenerated") && this.state.payment[i].transactionType==="INWARD"))?true:false)} onClick={() =>{window.open(this.state.payment[i].transactionReciptURL, '_blank'); return false;}}>
        <img title="Download" style={{marginRight:"10px",height:"20px",width:"20px"}} src={download} /></span></a></td>)
        table.push(<tr>{children}</tr>)
        j++
      }
      return table
    }
    colorClass(status){
        if(status==="Completed"){
            return "completed"
        }
        else if(status==="Failed"){
          return "failed"
        }
        else{
          return "inProgress"
        }
    }
    onKeyPress(event) {
      if (event.which === 13) {
          event.preventDefault();
          const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment?searchCriteria="+this.state.searchCriteria;
          axios.get(url).then(res =>{
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)} 
          this.setState({payment: res.data.payments});
          }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} })
      }
    }
      
    render(){
        while(this.state.payment==undefined){
            return <div className="loader">
            <Loader type="Oval" color="#000" height="80"  width="80" />
            </div>
          }
        return(
            <>
            <Container fluid className="mainContent" style={{height:"100%"}} >
            <TransactionModal ref={this.modalRef} edit={true} action={this.hello} disabled={this.state.disable} modalTitle="Transaction Details" payment={this.state.currentPayment}/>
                  <Row className="breadcrumbContainer">
                   <Col md="12" className="breadcrumbContainerColumn">
                   <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                     <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                     <BreadcrumbItem className="breadcrumbItem" tag="a" >Transaction</BreadcrumbItem>
                     <BreadcrumbItem className="breadcrumbItem" active tag="span">Transaction List</BreadcrumbItem>
                   </Breadcrumb>
                   </Col>
                  </Row>
                  <Row className="pageHeading">
                   <Col md="6"  className="pageHeadingColumn">
                     <h1>Your Transactions</h1>
                   </Col>
                  </Row>
              <Container className="section">
              <Row className="inputRow">
                <Col md="6" className="inputField" style={{display:"flex"}}>
                <Input type="text" className="inputText" id="search" name="searchCriteria" placeholder="Search by Beneficiary, Bank, A/C"
                  onChange={this.handleChange} onKeyPress={this.onKeyPress} />
                  <Link to="/transaction/add"><span><img src={add} style={{width:"30px",height:"30px",marginLeft:"15px",marginTop:"2px"}}></img></span></Link>
                </Col>
              </Row>
              </Container>
              <Container className="section">
              <Row className="inputRow">
              <Col md="12" className="inputField">
              {this.display_content()}
              </Col>
              </Row>
              </Container>
            </Container>
            </>
        )
    }
}
export default ListTransaction;