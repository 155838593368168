import React from "react";
import { Container } from "reactstrap";
import Loader from 'react-loader-spinner';
import { Auth } from 'aws-amplify';
class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modal: false,callmodal:true}  
    var urlParams = new URLSearchParams(window.location.search);
    let code=urlParams.get('code')
    let email=urlParams.get('email');

    Auth.confirmSignUp(email, code)
        .then(user => {
        window.location="/login?state=true"
        }
        )
        .catch(err => {
            if(err.code === "CodeMismatchException") {
                this.setState({verifying: false, otp: null}, () => {
                    this.otpError.current.innerHTML = 'Wrong OTP <br/>'
                    this.otpError.current.style.display = 'inline'
                })
            }
        })
  }

  render() {
    return (
    <Container>
        <div style={{marginLeft: '500px'}}>
        <Loader type="Oval" color="black" height="600"  width="100" />
        </div>
    </Container>
    );
  }
}

export default EmailVerification;
