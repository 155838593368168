import userProfile from 'views/User/userProfile';
import userProfileNext from 'views/User/userProfileNext';
import userDocument from 'views/User/userDocument';
import CorpUser from "views/User/CorpUser.jsx";
import CorpDocument from "views/User/CorpDocument.jsx";
import callback from "views/User/CallBack.jsx";

var userRoutes = [
  {
    path: "/user",
    name: "User Detail",
    component: userProfile
  },
  {
    path: "/corpUser",
    name: "Corp Detail",
    component: CorpUser
  },
  {
    path: "/corpDocument",
    name: "Corp Detail",
    component: CorpDocument
  },
  {
    path: "/user/page2",
    name: "userProfileNext",
    component: userProfileNext
  },
  {
    path: "/user/document",
    name: "userDocument",
    component: userDocument
  },
  {
    path: "/callback",
    name: "userDocument",
    component: callback
  },
  { redirect: true, path: "/", pathTo: "/user", name: "User Detail" }
]

export default userRoutes;
