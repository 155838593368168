import React from "react";
import { Redirect,Link } from "react-router-dom";
import Stepper from 'react-stepper-horizontal';
import { Container, Row, Col,Breadcrumb, BreadcrumbItem, Button,FormGroup } from 'reactstrap';
import UserModel from "views/User/UserModel.jsx";
import axios from 'axios';
import {occupation} from "variables/occupation.js";
import {Typeahead} from 'react-bootstrap-typeahead';
import BlockUi from 'react-block-ui';
import Loader from 'react-loader-spinner';
import Notification from "components/modals/Notification";
import UserProfileModel from "views/User/UserProfileModel.jsx";

class userProfileNext extends React.Component{
  constructor(props){
      super(props)
      this.state={sourceOfIncomeValid:false,validationFlag:false,pruposeValid:false,userPageLoad:true,redirect:false,myInfoNRIC:false
      ,occupationFlag:false,loadButton:false,occupationFlagError:false,occupationvalidation : true}
      this.userModel           = new UserProfileModel()
      this.handleChange        = this.handleChange.bind(this);
      this.sourceOfIncomeList  = this.sourceOfIncomeList.bind(this);
      this.purposeList         = this.purposeList.bind(this);
      this.requiredDetails     = React.createRef(); 
      this.nricVal             = React.createRef();
      this.yearVal             = React.createRef();
      this.occupationVal       = React.createRef();
      this.error               = React.createRef();
      this.sourceOfIncomeOther = this.sourceOfIncomeOther.bind(this);
      this.purposeOther        = this.purposeOther.bind(this);
      this.getCustomer         = this.getCustomer.bind(this);
      this.handleOccupation    = this.handleOccupation.bind(this);
      this.onBlur              = this.onBlur.bind(this);
      this.onFocus             = this.onFocus.bind(this);
      this.errorFun            = this.errorFun.bind(this);
      this.validateYear        = this.validateYear.bind(this);
      this.getCustomer();
  }

  getCustomer(){
    if(UserModel.monthlyIncome === undefined || UserModel.monthlyIncome ==='' || UserModel.monthlyIncome.length === 0){
      UserModel.monthlyIncome =''
    }

    let pattren=/^\d+$/;
    axios.get( (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/profile')
    .then(res =>{
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
      if(UserModel.politicallyExposed !== '' && UserModel.politicallyExposed !== undefined){
          if(UserModel.occupation !== '' && UserModel.occupation !== undefined)
          this.setState({occupationvalidation:false})
      }else{
        this.userModel.updateValuesUserModel(res.data.customer);
        this.setState({updateCustomer:true});
        if(UserModel.sourceOfIncome.includes("Other")){
          this.setState({sourceOfIncomeOther:true})
        }
        if(UserModel.purpose.includes("Other")){
          this.setState({purposeOther:true})
        } 
      }
        
      if(UserModel.phoneNumber === '' || UserModel.phoneNumber === undefined){
        this.setState({loadPhone:true});
      }
      if(UserModel.occupation !== '' && UserModel.occupation !== undefined)
          this.setState({occupationvalidation:false})
      if(res.data.customer.myInfoNRIC !== '' && res.data.customer.myInfoNRIC !== undefined ){
        if(UserModel.phoneNumber === '' || UserModel.phoneNumber === undefined){
          this.setState({myInfoFlag:true,myInfoFlagMobdis:true});
        }else{
        this.setState({myInfoFlag:true})
        }
      }
      if(UserModel.sourceOfIncome.includes("Other")){
        this.setState({sourceOfIncomeOther:true})
      }
      if(UserModel.purpose.includes("Other")){
        this.setState({purposeOther:true})
      }                 
      if( (UserModel.monthlyIncome !== '') && (UserModel.monthlyIncome !== undefined) && (UserModel.monthlyIncome.length !== 0) && (pattren.test(parseInt(UserModel.monthlyIncome)))){
        let monthly= UserModel.monthlyIncome.split("-");
        if(monthly[1]!=='' && monthly[1]!== undefined){
          UserModel.monthlyIncome =  UserModel.monthlyIncome
        }else{
          UserModel.monthlyIncome=parseInt(UserModel.monthlyIncome.toString().replace(/,/g,"")).toLocaleString()
            this.setState({updateCustomer: true});
        }
      }

      if(UserModel.assessableIncome !== '' && UserModel.assessableIncome !== undefined && pattren.test(parseInt(UserModel.assessableIncome))){
        UserModel.assessableIncome=parseInt(UserModel.assessableIncome.toString().replace(/,/g,"")).toLocaleString()
        this.setState({updateCustomer: true});
      }
      this.setState({userPageLoad:false})
      }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} }) 
  }

  onFocus(event){
    if(event.target.disabled)return
    event.target.nextElementSibling.classList.remove('spanMiddle')
    event.target.nextElementSibling.classList.add('spanTop')
    if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
  }

  onBlur(event){
    event.target.classList.remove('activeFocus','invalid','valid')
    event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
    if(event.target.value==""){
      event.target.classList.remove('activeFocus','invalid','valid')
      event.target.nextElementSibling.classList.remove('spanTop')
      event.target.nextElementSibling.classList.add('spanMiddle')
      }
      if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
  }


  validateYear(value){
    let year = new Date().getFullYear();
    if(value > year){
      this.setState({validationFlag:true})
    }else{
      this.setState({validationFlag:false})
    }
  }

  errorFun(){
    if(this.state.validationFlag){
      return (<label  style={{ color:'#FB404B'}}>Invalid Year</label>);
    } 
  }

  handleChange = (e) =>{

    let pattren=/^\d+$/;
    this.props.list[e.target.name] = e.target.value;
    this.setState({updateCustomer: true});
    if( e.target.name === "monthlyIncome" && e.target.value !== '' && pattren.test(parseInt(e.target.value))){
      UserModel.monthlyIncome=parseInt(e.target.value.toString().replace(/,/g,"")).toLocaleString()
      this.setState({updateCustomer: true});
    }else if(e.target.name === "assessableIncome" && e.target.value !== '' && pattren.test(parseInt(e.target.value))){
      UserModel.assessableIncome=parseInt(e.target.value.toString().replace(/,/g,"")).toLocaleString()
      this.setState({updateCustomer: true});
    }
    if(e.target.name === "yearofAssessment"){

        this.validateYear(e.target.value);
    }
   
  }

  sourceOfIncomeList = (e) => {
    let lists = UserModel.sourceOfIncome
    this.setState({updateCustomer: true,sourceOfIncomeValid:false});
    if(e.target.checked){
        if(e.target.value === "Other"){
          this.setState({updateCustomer: true,sourceOfIncomeOther:true});
        }
      lists.push(e.target.value);
      UserModel.sourceOfIncome=lists;
      this.setState({updateCustomer: true});
    } else {
      if(e.target.value === "Other"){
        UserModel.sourceOfIncomeOther ='';
      this.setState({sourceOfIncomeOther:false});
      }
      let listsDelete = UserModel.sourceOfIncome.filter(el => el != e.target.value);
      UserModel.sourceOfIncome=listsDelete;
      this.setState({updateCustomer: true});
    }
  }

  purposeList =(e) => {
    let lists = UserModel.purpose
    this.setState({purposelist: lists,pruposeValid:false});
    if(e.target.checked){
      if(e.target.value === "Other"){
        this.setState({updateCustomer: true,purposeOther:true});
      }
        lists.push(e.target.value);
        UserModel.purpose = lists;
        this.setState({purposelist: lists});
    }
    else {
      if(e.target.value === "Other"){
        UserModel.purposeOther=""
      this.setState({purposeOther: false});
      }
      let listsDelete = UserModel.purpose.filter(el => el != e.target.value);
      UserModel.purpose = listsDelete;
      this.setState({updateCustomer: true});
    }
  }

  handleSubmit = (e) => {
      this.setState({loadButton:true})
      let Monthly=0
      let monHigh='';
      let monLow='';
      e.preventDefault()
      // if(UserModel.occupation === undefined){
      //   this.setState({loadButton:false});
      //   this.occupationVal.current.toggle();
      //   return ;
      // }
      if(UserModel.politicallyExposed === '' || UserModel.politicallyExposed === undefined){
        this.setState({loadButton:false});
        this.requiredDetails.current.toggle();
        return ;
      }
      if(this.state.validationFlag){
        this.setState({loadButton:false});
        this.yearVal.current.toggle();
        return ;
      }
      if(UserModel.monthlyIncome !== undefined){
        Monthly = UserModel.monthlyIncome.split("-")
       if(Monthly[1]!=='' && Monthly[1]!==undefined){
         UserModel.monthlyIncome ='';
         monHigh=Monthly[0];
         monLow=Monthly[1];
       }else{
         UserModel.monthlyIncome = UserModel.monthlyIncome.replace(/,/g,"")
        monHigh='';
        monLow='';
       }
      }

      let data = {
              "name": UserModel.name,
              "alias": UserModel.alias,
              "gender": UserModel.gender,
              "dateOfBirth": UserModel.dateOfBirth,
              "residentialStatus": UserModel.residentialStatus,
              "nationality": UserModel.nationality,
              "idType": UserModel.idType,
              "idNumber": UserModel.idNumber,
              "idExpiryDate": UserModel.idExpiryDate,
              "politicallyExposed": UserModel.politicallyExposed,
              "homeTelephoneNumber": UserModel.homeTelephoneNumber,
              "faxNumber": UserModel.faxNumber,
              "residentialAddress": {
                "country": UserModel.country,
                "apartmentNumber": UserModel.apartmentNumber,
                "street": UserModel.street,
                "street2":UserModel.street2,
                // "streetNumber": UserModel.streetNumber,
                "block": UserModel.block,
                "postal": UserModel.postal,
                "floor": UserModel.floor,
                "building": UserModel.building
              },
              "occupation": UserModel.occupation,
              "employer": UserModel.employer,
              "monthlyIncome": {
                "singleValue": UserModel.monthlyIncome?UserModel.monthlyIncome.replace(/,/g,""):"",
                "high":monHigh,
                "low":monLow
              },
              "assessableIncome": UserModel.assessableIncome?UserModel.assessableIncome.replace(/,/g,""):"",
              "yearofAssessment": UserModel.yearofAssessment,
              "profileImageFileName":UserModel.profileImageFileName,
              "profileType":"Individual",
              "sourceOfIncome":UserModel.sourceOfIncome,
              "purpose":UserModel.purpose,
              "sourceOfIncomeOther": UserModel.sourceOfIncomeOther,
              "purposeOther":UserModel.purposeOther,
              "indicativeTransactionLimit":UserModel.indicativeTransactionLimit,
              "chineseName":UserModel.chineseName,
              "taxresident":UserModel.taxresident,
              "fieldsNotFromMyInfo": ["gender"],        
      }
      const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/profile";
      axios.post(url,data).then(res =>{
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
        if(res.data.status===200){
          this.setState({loadButton:false,redirect:true})
        }
        if(res.data.status === 403){
          this.setState({loadButton:false})
          this.nricVal.current.toggle();
        }
      }).catch(function (error) { 
      if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
      this.error.current.toggle();
     })
  }

  SaveButton(){
    if(this.state.loadButton){
      return (<Button type="secondary" className="pageButton userprimary " type="submit" style={{float:"left",backgroundColor:'#8c0204'}}><Loader type="Oval" color="#ddd" height="13"  width="13"/></Button>);
    }else{
    return (<Button type="secondary" className="pageButton userprimary" type="submit" style={{float:"left",backgroundColor:'#8c0204'}}>Continue</Button>);
    }
  }

  sourceOfIncomeOther(){
    if(this.state.sourceOfIncomeOther){
      return <> 
       <div className="inputGroup">
               <input type="text" className="inputText" id="otherS"
                name="sourceOfIncomeOther" onFocus={this.onFocus} onBlur={this.onBlur}
                 value={UserModel.sourceOfIncomeOther} autoComplete="off"
                 fieldCheck={UserModel.sourceOfIncomeOther === ""?"unfilled":"filled"}
                onChange={this.handleChange}
                style={{width:'200px',height:'40px'}} required  
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Remarks</span>
       </div>
       </>
    }
  }

  purposeOther(){
    if(this.state.purposeOther){
      return <>  <div className="inputGroup">
                 <input type="text" className="inputText" id="otherP"
                    name="purposeOther" onFocus={this.onFocus} onBlur={this.onBlur}
                     value={UserModel.purposeOther} autoComplete="off"
                     fieldCheck={UserModel.purposeOther === ""?"unfilled":"filled"}
                    onChange={this.handleChange}
                    style={{width:'200px',height:'40px'}} required  
        />
      <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Remarks</span>
      </div>
      </>
    }
  }

  handleOccupation=(event)=>{
    if(typeof event[0] === 'object' && event[0] !== null){
      UserModel.occupation=event[0].occupation;
      this.setState({occupationFlag : false,occupationvalidation:true});
    }
    else{
        UserModel.occupation=event[0];
        this.setState({occupationFlag : false,occupationvalidation:true});
    }
  }

  render(){
    if(UserModel.politicallyExposed === '' || UserModel.politicallyExposed === undefined){
      return <Redirect to='/user' />
    }
    if (this.state.redirect) {
      return <Redirect to='/user/document' exact/>
    }
      return(
        <>
        <Notification ref={this.requiredDetails}  modalTitle="Please fill the required details!!" />
        <Notification ref={this.nricVal}  modalTitle="NRIC already exists." />
        <Notification ref={this.occupationVal}  modalTitle="Please enter occupation." />
        <Notification ref={this.yearVal}  modalTitle="Please enter valid year." />
        <Notification ref={this.error}  modalTitle="Something went wrong. Please try again." />
        <BlockUi tag="div" blocking={this.state.userPageLoad} keepInView={true} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
          <Container fluid className="color mainContent" >
          <Row className="breadcrumbContainer">
             <Col md="12" className="breadcrumbContainerColumn">
             <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
               <BreadcrumbItem className="breadcrumbItem initial" tag="a" href="#">Log In/Register</BreadcrumbItem>
               <BreadcrumbItem className="breadcrumbItem" active tag="span">Register</BreadcrumbItem>
             </Breadcrumb>
             </Col>
          </Row>
          <Row className="pageHeading">
            <Col md="6"  className="pageHeadingColumn">
              <h2>Tell us more about you</h2>
            </Col>
          </Row>
          <Row style={{margin: "0% 2%"}}>
            <Col md="3" style={{padding:0}}> 
            <Stepper steps={ [{}, {}, {}] } activeColor="#585859" defaultColor="#d8d8d8" completeColor="#8b0304" lineMarginOffset={6} activeStep={ 1 } />
            </Col>
          </Row>
         
          <form className="justify-content-center align-items-center"  onSubmit={this.handleSubmit}>
          <Container className="section">
          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Occupation Details</h4>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row className="inputRow">
          <Col md="6" className="inputField">
          <Typeahead 
                              id="occupation"
                              labelKey="occupation"
                              name="occupation"
                              className="border-bottom"
                              options={occupation}
                              newSelectionPrefix="New Occupation: "
                              placeholder="Occupation"
                              onChange={this.handleOccupation} 
                              // required
                              className="form-controlType"
                              placeholder={UserModel.occupation?UserModel.occupation:"Occupation"}
                              disabled={this.state.myInfoFlag && !(UserModel.idType !== 'NRICBlue')}
                              allowNew={() => true}
                              onFocus={this.onFocus} onBlur={this.onBlur}
                          />  
            <label  style={{ color:'#5d5d5d'}}>Please type your occupation and select from dropdown</label>
            
          </Col>
          <Col md="6" className="inputField">
              <div className="inputGroup">
                  <input type="text" className="inputText" id="employer"
                    name="employer" onFocus={this.onFocus} onBlur={this.onBlur}
                    // pattern="^[a-zA-Z0-9\\s ]+" 
                    value={UserModel.employer} autoComplete="off"
                    data-optional="true"
                    title="Value should be of alphanumeric" fieldCheck={UserModel.employer === ""?"unfilled":"filled"}
                    onChange={this.handleChange}
                    // required  
                    disabled={this.state.myInfoFlag && !(UserModel.idType !== 'NRICBlue')}
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Employer</span>
            </div>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="4" className="inputField">
            <div className="inputGroup">
                  <input type="text" className="inputText" id="netMonthly"
                    data-optional="true"
                    name="monthlyIncome" onFocus={this.onFocus} onBlur={this.onBlur}
                    pattern="[0-9,-\\s]+" value={UserModel.monthlyIncome} autoComplete="off"
                    title="Monthly income can contain only Digits" fieldCheck={UserModel.monthlyIncome === "" || UserModel.monthlyIncome === undefined?"unfilled":"filled"}
                    onChange={this.handleChange} 
                    // required  
                    // disabled={this.state.myInfoFlag && UserModel.monthlyIncome !==''}
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Monthly Income (S$)</span>
            </div>
          </Col>
          
          <Col md="5" className="inputField">
            <div className="inputGroup">
                  <input type="text" className="inputText" id="assessable"
                    data-optional="true"
                    name="assessableIncome" onFocus={this.onFocus} onBlur={this.onBlur}
                    data-optional="true"
                    pattern="[0-9,]+" value={UserModel.assessableIncome} autoComplete="off"
                    title="Total assessable income income can contain only Digits" fieldCheck={UserModel.assessableIncome === "" || UserModel.assessableIncome === undefined?"unfilled":"filled"}
                    onChange={this.handleChange} 
                    // required  
                    // disabled={this.state.myInfoFlag && UserModel.assessableIncome !== ''}
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Total Assessable Income (Yearly) (S$)</span>
            </div>
          </Col>
          
          <Col md="3" className="inputField">
              <div className="inputGroup">
                  <input type="text" className="inputText" id="year"
                    name="yearofAssessment" maxLength="4" onFocus={this.onFocus} onBlur={this.onBlur}
                    pattern="[0-9]+" value={UserModel.yearofAssessment} autoComplete="off"
                    data-optional="true"
                    title="Total assessable income income can contain only Digits" fieldCheck={UserModel.yearofAssessment === "" || UserModel.yearofAssessment === undefined?"unfilled":"filled"}
                    onChange={this.handleChange} 
                    // required  
                    // disabled={this.state.myInfoFlag && UserModel.yearofAssessment !== ''}
              />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Year of Assessment</span>
            </div>
          </Col>
          </Row>
          </Container>
          <Container className="section">
          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Indicative Transaction Amount</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField transactionAmount">
          <select name="indicativeTransactionLimit" onChange={this.handleChange} required>
          <option selected disabled hidden value=''>Select</option>
                <option value="UpTo100K" selected={UserModel.indicativeTransactionLimit === "UpTo100K"}>Upto S$100,000</option>
                <option value="UpTo500K" selected={UserModel.indicativeTransactionLimit === "UpTo500K"}>S$100,001 - S$500,000</option>
                <option value="UpTo1M" selected={UserModel.indicativeTransactionLimit === "UpTo1M"}>S$500,001 - S$1,000,000</option>
                <option value="Over1M" selected={UserModel.indicativeTransactionLimit === "Over1M"}>More than S$1,000,000</option>
              </select>
              <label>SGD</label>
          </Col>
          </Row>
          </Container>

          <Container className="section">
          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Source of Wealth</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="9" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck"> Income
              <input type="checkbox" value="Income" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
              checked={UserModel.sourceOfIncome.includes("Income")} multiple/>
              <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Loan
            <input type="checkbox" value="Loan" onChange={this.sourceOfIncomeList} onChange={this.sourceOfIncomeList} 
            name="sourceOfIncome" checked={UserModel.sourceOfIncome.includes("Loan")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Savings
            <input type="checkbox" value="Savings" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Savings")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
            <FormGroup check inline>
            <label className="containerCheck">Investment
            <input type="checkbox" value="Investment" name="sourceOfIncome" 
            checked={UserModel.sourceOfIncome.includes("Investment")}onChange={this.sourceOfIncomeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="9" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Sale of property
            <input type="checkbox" value="Sale of Property" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Sale of Property")}multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Inheritance
            <input type="checkbox" value="Inheritance" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Inheritance")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="1" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Other
            <input type="checkbox" value="Other" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Other")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="2" className="inputField">
          <Col md="6" className="inputField">
            {this.sourceOfIncomeOther()}
            </Col>
          </Col>
          
          </Row>

          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Remittance Purpose</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="12" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Payment for overseas property purchased
            <input type="checkbox" name="RemittancePurpose" value="Payment for overseas property purchased" 
            checked={UserModel.purpose.includes("Payment for overseas property purchased")} onChange={this.purposeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Loan
            <input type="checkbox" name="RemittancePurpose" value="Loan" onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Loan")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Family living expenses
            <input type="checkbox" name="RemittancePurpose" value="Familiy living expenses" onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Familiy living expenses")} multiple />
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="9" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Capital injection
            <input type="checkbox" name="RemittancePurpose" value="Capital Injection" onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Capital Injection")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Goods and services
            <input type="checkbox" name="RemittancePurpose" value="Goods and services" onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Goods and services")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Income
            <input type="checkbox" name="RemittancePurpose" value="Income" onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Income")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Savings
            <input type="checkbox"name="RemittancePurpose" value="Savings" onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Savings")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="5" className="inputField">
          <FormGroup check inline>
            <label className="containerCheck">Private banking wealth management
            <input type="checkbox" name="RemittancePurpose" value="Private banking wealth management" 
            checked={UserModel.purpose.includes("Private banking wealth management")} onChange={this.purposeList} multiple/>
            <span className="checkmark" ></span></label>
          </FormGroup>
          <FormGroup check inline>
            <label className="containerCheck">Other
            <input type="checkbox" name="RemittancePurpose" value="Other" 
            checked={UserModel.purpose.includes("Other")} onChange={this.purposeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="2" className="inputField">
            {this.purposeOther()}
          </Col>
          </Row>

          <Container className="pageFooter" style={{backgroundColor:"#fbfbfb"}}>
                    <Row className="inputRow" style={{paddingRight:'30px'}}>
                      <Col md="4"></Col>
                      <Col md="3" className="documentButton">
                      <Link to='/user' state={{ testvalue: "hello" }}>  <Button type="secondary" id="back" className="pageButton secondary" style={{float:"right"}}>Back</Button></Link>
                      </Col><Col md="3" className="documentButton">
                      {this.SaveButton()}
                      </Col>
                    </Row>
            </Container>
          </Container>
          </form>
          </Container>
          </BlockUi>
          </>
      )
    }
  } 
export default userProfileNext;