import React from 'react'
class Spacer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const divStyle = {
            height: this.props.height
        };
        return (
            <div style={divStyle}></div>
        )
    } 

}

export default Spacer;