import React from "react";
import { Container, Row, Col,Breadcrumb, BreadcrumbItem, Input,FormGroup } from 'reactstrap';
import UserModel from "views/User/UserModel.jsx";
import country from "variables/Country.js";
import {nationality} from "variables/PhoneCode.js";
import moment from 'moment';
import axios from 'axios';
import UserProfileModel from "views/User/UserProfileModel.jsx";
import BlockUi from 'react-block-ui';
import { Storage } from 'aws-amplify';
import date from "assets/img/date.png";
import notAvailable from "assets/img/notAvailable.svg";

class userProfile extends React.Component{
    constructor(props){
        super(props)
        this.state = {sourceOfIncomeOther:false,sourceOfIncomeValid:false,pruposeValid:false,redirect:false,profilePic:'',userPageLoad:true}
        this.userModel           = new UserProfileModel()
        this.sourceOfIncomeOther = this.sourceOfIncomeOther.bind(this);
        this.purposeOther        = this.purposeOther.bind(this); 
        this.getCustomer         = this.getCustomer.bind(this);
        this.getProfilePicAWS    = this.getProfilePicAWS.bind(this);
        if(localStorage.getItem("redirect") && localStorage.getItem("redirect") === "true"){
          window.location = "/home"
        }
        this.getCustomer();
    }

    getCustomer(){
      let pattren=/^\d+$/;
      axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/profile')
      .then(res =>{
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
        this.userModel.updateValuesUserModel(res.data.customer);
        this.setState({updateCustomer:true});
        if(res.data.customer.myInfoNRIC !== '' && res.data.customer.myInfoNRIC !== undefined ){
          this.setState({myInfoFlag:true})
        }

        if(UserModel.sourceOfIncome.includes("Other")){
          this.setState({sourceOfIncomeOther:true})
        }
        if(UserModel.purpose.includes("Other")){
          this.setState({purposeOther:true})
        } 
        if(UserModel.monthlyIncome !== undefined){
          if( UserModel.monthlyIncome !== '' || UserModel.monthlyIncome !== undefined || pattren.test(parseInt(UserModel.monthlyIncome))){
            let monthly= UserModel.monthlyIncome.split("-");
            if(monthly[1]!=='' && monthly[1]!== undefined){
              UserModel.monthlyIncome =  UserModel.monthlyIncome
            }else{
              UserModel.monthlyIncome=parseInt(UserModel.monthlyIncome.toString().replace(/,/g,"")).toLocaleString()
                this.setState({updateCustomer: true});
            }
          }
        }
        if(UserModel.assessableIncome !== undefined){
          if(UserModel.assessableIncome !== '' || UserModel.assessableIncome !== undefined || pattren.test(parseInt(UserModel.assessableIncome))){
            UserModel.assessableIncome=parseInt(UserModel.assessableIncome.toString().replace(/,/g,"")).toLocaleString()
            this.setState({updateCustomer: true});
          }
        }
        this.getProfilePicAWS();
      }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} }) 
    }

    getProfilePicAWS(){
      this.setState({profilePicLoder:'loading'})
      const fileName= UserModel.profileImageFileName;
      if(fileName !== '' && fileName !== undefined){
        Storage.vault.get(fileName)
        .then((res) => {
          this.setState({
            profilePic:res,
            profilePicLoder:'uploaded',
            profileImage:true,
          })
        })
        .catch();
      }else {
        this.setState({
          profilePic:notAvailable,
          profilePicLoder:'uploaded',
          profileImage:true,
        })
      }
      {this.setState({userPageLoad:false})}
    }

    sourceOfIncomeOther(){
      if(this.state.sourceOfIncomeOther){
        return <><div className="inputGroup">
                      <input type="text" className="inputText" id="otherS" name="sourceOfIncomeOther" value={UserModel.sourceOfIncomeOther}  pattern="^[a-zA-Z0-9\\s ]+"
                          onChange={this.handleChange} style={{width:'130px',height:'30px'}} required disabled/>
                      <span>Remarks</span>
                  </div>
                </>
      }
    }
  
    purposeOther(){
      if(this.state.purposeOther){
        return <>
            <div className="inputGroup">
                <input type="text" className="inputText"id="otherP" name="purposeOther" value={UserModel.purposeOther}  pattern="^[a-zA-Z0-9\\s ]+"
                   onChange={this.handleChange} style={{width:'130px',height:'30px'}} required disabled/>
                <span>Remarks</span>
            </div>
            </>
      }
    }

    render(){
        return(
          <BlockUi tag="div" blocking={this.state.userPageLoad} keepInView={true} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className="mainContent" >

            <Row className="breadcrumbContainer">
               <Col md="12" className="breadcrumbContainerColumn">
               <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                 <BreadcrumbItem className="breadcrumbItem initial" tag="a" >Home</BreadcrumbItem>
                 <BreadcrumbItem className="breadcrumbItem" active tag="span">Profile</BreadcrumbItem>
               </Breadcrumb> 
               </Col>
            </Row>
           
            <form onSubmit={this.handleSubmit}>
            <Container className="section" style={{marginBottom:'0px !important'}}>
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Personal Details</h4>
              </Col>
            </Row>
            
            <Row style={{marginTop:'0px !important'}}>
              <Col md="4" className="sectionHeading" disabled>
              <img className="imgProfile block-example imageProfilePic float-left" style={{width:'100px',height:'100px'}} src={this.state.profilePic}></img>
              <label htmlFor="file-upload" className="custom-pic-file-uploa-updateCrop">Profile Photo</label>
            
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" id="name" name="name" value={UserModel.name}  pattern="^[a-zA-Z0-9\\s ]+"
                onChange={this.handleChange} required disabled/>
                <span>Full Name</span>
            </div>
            </Col>
            <Col md="3" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" id="name" name="chineseName" value={UserModel.chineseName}  pattern="^[ A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+"
                  onChange={this.handleChange} disabled/>
                <span>Chinese Name</span>
            </div>
            </Col>
            <Col md="3" className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" name="alias" id="name" pattern="^[a-zA-Z0-9\\s ]+"
                      value={UserModel.alias} onChange={this.handleChange} disabled/>
                    <span>Alias Name</span>
                </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="email" className="inputText" name="email" id="emailId" value={UserModel.email} onChange={this.handleChange} required pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]+$"
                            disabled/>
                <span>Email ID</span>
            </div>
              <label style={{color:'#bcbcbc'}}>Used for all communications</label>
            </Col>
            <Col md="3" className="inputField">
            <div style={{display:"flex"}}>
            <img src={date} style={{width:'20px',height:'20px',marginTop:'6px'}}></img>
           
            <Input type="date" className="inputText" style={{height:'25px',width: '180px'}} name="dateOfBirth" id="dob"
            value={UserModel.dateOfBirth}  disabled onChange={this.handleChange} required
            max={moment(new Date()).format("YYYY-MM-DD")}/>
            </div>
            <label style={{color:'#4a4a4a',marginLeft:'35px'}} forHtml="dateOfBirth">Date Of Birth</label>
            </Col>
            <Col md="1" className="inputField genderSelect">
                <select name="gender" onChange={this.handleChange} disabled>
                  <option selected={UserModel.gender === "male"} value="Male" >Male</option>
                  <option selected={UserModel.gender === "female"} value="female">Female</option>
                  <option value="unknown" selected={UserModel.gender === "unknown"} >Others</option>
                </select>
                <label>Gender</label>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                      <input type="text" className="inputText" id="mobile" name="phoneNumber" disabled  onChange={this.handleChange} maxLength="8" pattern= "[6,8,9][0-9]{7}"
                            value={UserModel.phoneNumber} required/>
                      <span>Mobile Number</span>
                  </div>
              <label style={{color:'#bcbcbc'}}>We'll verify this number via OTP</label>
            </Col>
            <Col md="3">
            </Col>
            <Col md="3" className="inputField" style={{bottom:'10px',paddingRight: '45px'}}>

            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" id="homephone" name="homeTelephoneNumber" disabled onChange={this.handleChange}  pattern="[+][6][5][0-9]+"
                         value={UserModel.homeTelephoneNumber}/>
                <span>Home Number</span>
            </div>
            </Col>
            <Col md="6" className="inputField">
                  <div className="inputGroup">
                      <input type="text" className="inputText" name="faxNumber" onChange={this.handleChange} disabled id="fax" pattern="[+][6][5][0-9]+"
                           value={UserModel.faxNumber} />
                      <span>Fax Number</span>
                  </div>
            </Col>
            </Row>

            </Container>
            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Citizenship Details</h4>
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3"  className="inputField nounhome">
                <select name="residentialStatus" disabled onChange={this.handleChange} required >
                  <option value="Inside Singapore" selected={(UserModel.residentialStatus) === "Inside Singapore"} >Inside Singapore</option>
                  <option value="Outside Singapore" selected={(UserModel.residentialStatus) === "Outside Singapore"}>Outside Singapore</option>
                </select>
                <label>Residential Status</label>
            </Col>
            <Col md="4" className="inputField nounflag">
                <select name="nationality" disabled  onChange={this.handleChange} required>
                {nationality.map((prop, key) => {
                      return <option  value={prop} key={key} selected={prop === UserModel.nationality}>{prop}</option> 
                  })}
                </select>
                <label>Nationality</label>
            </Col>
            </Row>
            <Row className="inputRow">
            {
              UserModel.myInfoNRIC && UserModel.myInfoNRIC !== "" &&
              <Col md="3" style={{marginLeft:'18px'}} className="inputField">
                <div className="inputGroup">
                    <input type="text" className="inputText" disabled value={UserModel.myInfoNRIC} srequired/>
                    <span>MyInfo NRIC</span>
                </div>
                <label style={{color:'#bcbcbc'}}>MyInfo NRIC</label>
              </Col>
            }
            {
              (!UserModel.myInfoNRIC || UserModel.myInfoNRIC == "") && <>
              <Col md="2" className="inputField nricSelect" style={{marginLeft:'7px'}}>
                  <select name="idType" disabled onChange={this.handleChange} required>
                    <option  value="NRICPink" selected={(UserModel.idType) === "NRICPink"}>NRIC (Pink)</option>
                    <option value="NRICBlue" selected={(UserModel.idType) === "NRICBlue"}>NRIC (Blue)</option>
                    <option value="PASSPORT" selected={(UserModel.idType) === "PASSPORT"}>Passport Number</option>
                    <option value="FIN" selected={(UserModel.idType) === "FIN"}>FIN</option>
                  </select>
                  <label>ID Type</label>
              </Col>
              <Col md="3" style={{marginLeft:'18px'}} className="inputField">
              <div className="inputGroup">
                  <input type="text" className="inputText" disabled name="idNumber" value={UserModel.idNumber} id="name" onChange={this.handleChange} required/>
                  <span>{this.state.myInfoFlag?"Passport ":""}Number</span>
              </div>
                <label style={{color:'#bcbcbc'}}>Only for citizens or PR of singapore</label>
              </Col>
              <Col md="3" className="inputField">
              <div style={{display:"flex"}}>
              <img src={date} style={{width:'20px',height:'20px',marginTop:'6px'}}></img>
             
              <Input type="date" className="inputText" style={{height:'25px',width: '180px'}} name="idExpiryDate" id="idExpiryDate"
              value={UserModel.idExpiryDate}  disabled onChange={this.handleChange} required
              max={moment(new Date()).format("YYYY-MM-DD")}/>
              </div>
              <label style={{color:'#4a4a4a',marginLeft:'33px'}} forHtml="dateOfBirth">Expiry Date</label>
              </Col></>
            }
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <FormGroup tag="fieldset" >
                <legend style={{fontSize:'15px',color:'#4a4a4a'}}>Are you a politically exposed person?</legend>
                <FormGroup check inline>
                <label className="containerRadio">Active
                  <input type="radio" name="radio" value="Active" disabled onChange={this.handleChange} required checked={(UserModel.politicallyExposed === "Active")? true :false}  name="politicallyExposed"/>
                  <span className="checkmarkRadio"></span>
                </label>
                </FormGroup>
                <FormGroup check inline>
                <label className="containerRadio">Inactive
                  <input type="radio" name="radio" value="Inactive" disabled onChange={this.handleChange} required checked={(UserModel.politicallyExposed === "Inactive")? true :false}  name="politicallyExposed"/>
                  <span className="checkmarkRadio"></span>
                </label>
                </FormGroup>
                <FormGroup check inline>
                <label className="containerRadio">No
                  <input type="radio" name="radio" onChange={this.handleChange} disabled value="No" required checked={(UserModel.politicallyExposed === "No")? true :false}  name="politicallyExposed"/>
                  <span className="checkmarkRadio"></span>
                </label>
                </FormGroup>
            </FormGroup>
            </Col>
            </Row>
            </Container>

            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Residential Address</h4>
              </Col>
            </Row>
            <Row className="inputRow">
            
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="text"  name="block" className="inputText" id="block" disabled onChange={this.handleChange}  pattern="[a-zA-Z0-9_\\s ]+$"
                          value={UserModel.block}/>
                <span>Block / Street Number</span>
            </div>
            </Col>
            {/* <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="text"name="building" id="building" onChange={this.handleChange}  pattern="[a-zA-Z0-9_\\s ]+$"
                           className="inputText" disabled value={UserModel.building} />
                <span>Building</span>
            </div>
            </Col> */}
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" id="building" name="street" required disabled onChange={this.handleChange}  pattern="[a-zA-Z0-9_\\s ]+$"
                          value={UserModel.street}/>
                <span>Street Name Line 1</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" name="street2" id="street" disabled onChange={this.handleChange}  pattern="[a-zA-Z0-9_\\s ]+$"
                          value={UserModel.street2}/>
                <span>Street Name Line 2</span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
              <input type="text" className="inputText" name="building" id="building" disabled
                          value={UserModel.building}/>
               <span>Building Name</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" name="floor" id="floor" disabled
                          value={UserModel.floor} onChange={this.handleChange} />
                <span>Floor</span>
            </div>
            </Col>
            <Col md="3" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" name="apartmentNumber" id="apartment" disabled
                           value={UserModel.apartmentNumber} />
                <span>Unit No.</span>
            </div>
            </Col>
            {/* <Col md="3" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" name="streetNumber" id="street" disabled required onChange={this.handleChange} pattern="[a-zA-Z0-9_\\s ]+$"
                          value={UserModel.streetNumber} />
                <span>Street Number</span>
            </div>
            </Col> */}
            <Col md="4" className="inputField">
            <div className="inputGroup">
                <input type="text" className="inputText" id="postal" name="postal" disabled required onChange={this.handleChange} 
                          value={UserModel.postal}  />
                <span>Postal Code</span>
            </div>
            </Col>
            <Col md="2" className="inputField countrySelect" style={{left:'10px'}}>
                <select name="country" onChange={this.handleChange} disabled >
                {country.map((prop, key) => {
                      return <option  value={prop}  key={key} selected={prop === UserModel.country}>{prop}</option> 
                  })}
                </select>
                <label>Country</label>
            </Col>
            </Row>
            </Container>
            <Container className="section">
          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Occupation Details</h4>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row className="inputRow">
          <Col md="6" className="inputField">
            <div className="inputGroup">
                  <input type="text" className="inputText" id="occupation"
                    name="occupation" style={{textIndent:'55px'}}  value={UserModel.occupation}  disabled  
              />
              <span>Occupation</span>
            </div>
          </Col>
          <Col md="6" className="inputField">
          <div className="inputGroup">
                <input type="text" className="inputText" name="employer" pattern="[a-zA-Z0-9\\s]+$"
                  onChange={this.handleChange} disabled value={UserModel.employer} id="employer" />
                <span>Employer</span>
          </div>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="4" className="inputField">
          <div className="inputGroup">
                <input type="text" className="inputText" id="netMonthly" disabled  name="monthlyIncome" required onChange={this.handleChange} 
                  value={UserModel.monthlyIncome} pattern="[0-9,]+" />
                <span>Monthly Income (S$)</span>
          </div>
          </Col>
          <Col md="5" className="inputField">
          <div className="inputGroup">
                <input type="text" className="inputText" name="assessableIncome" disabled id="assessable" required onChange={this.handleChange} 
            value={UserModel.assessableIncome} pattern="[0-9,]+"/>
                <span>Total Assessable Income (Yearly) (S$)</span>
          </div>
          </Col>
          <Col md="3" className="inputField">
          <div className="inputGroup">
                <input type="text" className="inputText" name="yearofAssessment" id="year" required onChange={this.handleChange} 
            value={UserModel.yearofAssessment} disabled/>
                <span>Year of Assessment</span>
          </div>
          </Col>
          </Row>
          </Container>
          <Container className="section">
          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Indicative Transaction Amount</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="3" className="inputField transactionAmount">
          <select name="indicativeTransactionLimit" onChange={this.handleChange} required disabled>
                <option value="Upto100K">Upto S$100,000</option>
                <option value="Upto500K">S$100,001 - S$500,000</option>
                <option value="Upto1M">S$500,001 - S$1,000,000</option>
                <option value="Over1M">More than S$1,000,000</option>
              </select>
              <label>SGD</label>
          </Col>
          </Row>
          </Container>

          <Container className="section">
          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Source of Wealth</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="9" className="inputField">
          <FormGroup check inline disabled>
            <label className="containerCheck"> Income
              <input type="checkbox" value="Income" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
              checked={UserModel.sourceOfIncome.includes("Income")} multiple disabled/>
              <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Loan
            <input type="checkbox" value="Loan" onChange={this.sourceOfIncomeList} onChange={this.sourceOfIncomeList} 
            name="sourceOfIncome" checked={UserModel.sourceOfIncome.includes("Loan")} multiple disabled/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Savings
            <input type="checkbox" value="Savings" name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Savings")} multiple disabled/>
            <span className="checkmark"></span></label>
          </FormGroup>
            <FormGroup check inline disabled>
            <label className="containerCheck">Investment
            <input type="checkbox" value="Investment" name="sourceOfIncome" 
            checked={UserModel.sourceOfIncome.includes("Investment")}onChange={this.sourceOfIncomeList} multiple disabled/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="9" className="inputField">
          <FormGroup check inline disabled>
            <label className="containerCheck">Sale of property
            <input type="checkbox" value="Sale of Property" disabled name="sourceOfIncome" onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Sale of Property")}multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Inheritance
            <input type="checkbox" value="Inheritance" name="sourceOfIncome" disabled onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Inheritance")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="2" className="inputField">
          <FormGroup check inline disabled>
            <label className="containerCheck">Other
            <input type="checkbox" value="Other" name="sourceOfIncome" disabled onChange={this.sourceOfIncomeList} 
            checked={UserModel.sourceOfIncome.includes("Other")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="2" className="inputField">
          <Col md="6" className="inputField">
            {this.sourceOfIncomeOther()}
            </Col>
          </Col>
          
          </Row>

          <Row>
            <Col md="12" className="sectionHeading">
              <h4>Remittance Purpose</h4>
            </Col>
          </Row>
          <Row className="inputRow">
          <Col md="12" className="inputField">
          <FormGroup check inline disabled>
            <label className="containerCheck">Payment for overseas property purchased
            <input type="checkbox" name="RemittancePurpose" disabled value="Payment for overseas property purchased" 
            checked={UserModel.purpose.includes("Payment for overseas property purchased")} onChange={this.purposeList} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Loan
            <input type="checkbox" name="RemittancePurpose" value="Loan" disabled onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Loan")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Family living expenses
            <input type="checkbox" name="RemittancePurpose" value="Familiy living expenses" disabled onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Familiy living expenses")} multiple />
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="9" className="inputField">
          <FormGroup check inline disabled>
            <label className="containerCheck">Capital injection
            <input type="checkbox" name="RemittancePurpose" value="Capital Injection" disabled onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Capital Injection")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Goods and services
            <input type="checkbox" name="RemittancePurpose" value="Goods and services" disabled onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Goods and services")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Income
            <input type="checkbox" name="RemittancePurpose" value="Income" disabled onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Income")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Savings
            <input type="checkbox"name="RemittancePurpose" value="Savings" disabled onChange={this.purposeList} 
            checked={UserModel.purpose.includes("Savings")} multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          </Row>
          <Row className="inputRow">
          <Col md="7" className="inputField">
          <FormGroup check inline disabled>
            <label className="containerCheck">Private banking wealth management
            <input type="checkbox" name="RemittancePurpose" value="Private banking wealth management" 
            checked={UserModel.purpose.includes("Private banking wealth management")} disabled onChange={this.purposeList} multiple/>
            <span className="checkmark" ></span></label>
          </FormGroup>
          <FormGroup check inline disabled>
            <label className="containerCheck">Other
            <input type="checkbox" name="RemittancePurpose" value="Other" 
            checked={UserModel.purpose.includes("Other")} onChange={this.purposeList} disabled multiple/>
            <span className="checkmark"></span></label>
          </FormGroup>
          </Col>
          <Col md="2" className="inputField">
            {this.purposeOther()}
          </Col>
          </Row>

          <Container className="footer pageFooter" >
                    <Row className="inputRow" style={{paddingRight:'30px'}}>
                      <Col md="2"></Col>
                      <Col md="3">
                      </Col><Col md="3">
                      </Col>
                    </Row>
            </Container>
          </Container>
            </form>
            </Container>
            </BlockUi>
        )
    }
}
export default userProfile;