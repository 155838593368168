import React from 'react';
import './App.css';
import DashboardLayout from "layouts/dashboard.jsx";
import OnBoardLayout from "layouts/onBoard.jsx";
import indexRoutes from "./routes/index.jsx";
import { Redirect, Route, Switch } from "react-router-dom";
import Loader from 'react-loader-spinner';
import awsconfig from './aws-exports';
import Amplify, { Auth } from 'aws-amplify';
import attachInterceptor from 'utils/RequestInterceptor.jsx'
import axios from 'axios';
// import { Offline, Online } from "react-detect-offline";

Amplify.configure(awsconfig);

export const UserContext = React.createContext()

class App extends React.Component {

  constructor(props) {
    attachInterceptor()
    super(props)
    this.state = {user: undefined}
    // axios.get((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/payment/conversion-rates").then(res =>{
    //   if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res);console.log("DATA LOADED")}
    // }).catch(function (error) {
    //   if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}
    // })
  }
  fetchUserDetails() {
    Auth.currentAuthenticatedUser({bypassCache: true})
    .then((data) => {
      this.setState({user: data})
    })
    .catch((err) =>  {
      this.setState({user: null})
    })
  }

  render() {
    if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log("AppRender", this.state.user)}
    const path = window.location.pathname
    if(this.state.user === "loading" || this.state.user === undefined) {
      if(this.state.user === undefined)
          this.fetchUserDetails()
      return (
        <div className="loader">
          {/* <Offline><p style={{fontWeight:"bold",marginTop:"40vh",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}>You're offline right now. Please check your internet connection.</p></Offline>
          <Online> */}
              <Loader type="Oval" color="#000" height="80"  width="80" />
          {/* </Online> */}
        </div>
     )
    }else if(this.state.user === null) {
        return (
          <>
          {/* <Offline><p style={{fontWeight:"bold",marginTop:"40vh",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}>You're offline right now. Please check your internet connection.</p></Offline>
          <Online> */}
              <Switch>
                {indexRoutes.map((prop, key) => {
                  if(prop.redirect) return <Route path='/' component={() => { 
                    window.location.href = 'https://www.moneyworld.com.sg/'; 
                    return null;
               }}/>
                  return <Route path={prop.path} key={key} exact component={prop.component} />;
                })}
              </Switch>
              {/* {path != "/verify-email" && <Redirect to='/login' />} */}
          {/* </Online> */}
          </>
        )
      
    }else {
      {console.log(this.state.user.attributes)}
      if(this.state.user.attributes.profile === "ProfileSetup" || this.state.user.attributes.profile === "PendingUploadDocument" || this.state.user.attributes.profile === "PendingClarification"){
        return <> 
        {/* <Offline><p style={{fontWeight:"bold",marginTop:"40vh",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}>You're offline right now. Please check your internet connection.</p></Offline> <Online>  */}
          <Route path="/" component={()=><OnBoardLayout user={this.state.user} />} />; 
          {/* </Online>  */}
          </>
      }
      else{
        return <> 
        {/* {localStorage.setItem("redirect","true")} */}
        {/* <Offline><p style={{fontWeight:"bold",marginTop:"40vh",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}>You're offline right now. Please check your internet connection.</p></Offline> 
        <Online>  */}
          <Route path="/" component={()=><DashboardLayout user={this.state.user} />} />; 
          {/* </Online>  */}
        </>
      }
    }
  }
}

export default App;
