import Login from "layouts/Login/Login.jsx";
import VerifyEmail from "layouts/Login/EmailVerification.jsx";
import CallBack from "views/User/CallBack.jsx";
import PrivacyPolicy from "../views/Misc/PrivacyPolicy";
import TermsAndConditions from "../views/Misc/TermsAndConditions";


var indexRoutes = [
                    // { path: "/login", name: "Login", component: Login },
                    // {path: "/verify-email", name: "VerifyEmail", component: VerifyEmail},
                    // { path: "/callback", name: "CallBack", component: CallBack },
                    // { path: "/privacy-policy", name: "PrivacyPolicy", component: PrivacyPolicy },
                    // { path: "/terms-and-conditions", name: "TermsAndConditions", component: TermsAndConditions },
                    {redirect:true}
                  ];

export default indexRoutes;