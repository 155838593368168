import React from "react";
import {Redirect } from "react-router-dom";
import Stepper from 'react-stepper-horizontal';
import { Container, Row, Col,Breadcrumb, BreadcrumbItem, Input,Button,FormGroup,Modal, ModalHeader, ModalBody} from 'reactstrap';
import UserProfileModel from "views/User/UserProfileModel.jsx";
import UserModel from "views/User/UserModel.jsx";
import country from "variables/Country.js";
import {nationality} from "variables/PhoneCode.js";
import moment from 'moment';
import Loader from 'react-loader-spinner';
import {phoneCodes} from "variables/PhoneCode.js";
import axios from 'axios';
import mime from "mime-types";
import Notification from "components/modals/Notification";
import BlockUi from 'react-block-ui';
import MyInfoLogo from "assets/img/MyInfoLogo.png";
import date from "assets/img/date.png";
import uuid from "uuid";
import { Storage } from 'aws-amplify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from 'query-string';

class userProfile extends React.Component{
    constructor(props){
        super(props)
        this.state ={updateCustomer:false,customerLoad:true,redirect:false,sendOTPButton:true,verify:"new",oadPhone:false
               ,loadPhone:false,validationFlag:false,profilePic:"new",profilePicLoder:'upload',myInfoFlag:false,myInfoFlagMob:false,profileImage:false,userPageLoad:true,
              buttonDisabled:true}
        this.userModel          = new UserProfileModel()
        this.today              = new Date()
        this.calMinDate         = new Date(this.today.getFullYear() - 99,this.today.getMonth(),this.today.getDay());
        this.calMaxDate         = new Date(this.today.getFullYear() - 18,this.today.getMonth(),this.today.getDay());
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.profilepicFile     = React.createRef();
        this.profilePicModal    = React.createRef();
        this.myInfoError        = React.createRef(); 
        this.nameFill           = React.createRef(); 
        this.handleChange       = this.handleChange.bind(this);
        this.OTPbutton          = this.OTPbutton.bind(this);
        this.OTPText            = this.OTPText.bind(this);
        this.getCustomer        = this.getCustomer.bind(this);
        this.handleChangeMobOtp = this.handleChangeMobOtp.bind(this);
        this.errorFun           = this.errorFun.bind(this);
        this.getProfilePicAWS   = this.getProfilePicAWS.bind(this);
        this.profilePic         = this.profilePic.bind(this);
        this.displayMyInfo      = this.displayMyInfo.bind(this);
        this.toggle             = this.toggle.bind(this);
        this.validateProfilePic = this.validateProfilePic.bind(this);
        this.handleMyInfo       = this.handleMyInfo.bind(this);
        this.onBlur             = this.onBlur.bind(this);
        this.onLoadFunction     = this.onLoadFunction.bind(this);
        this.onFocus            = this.onFocus.bind(this);
        this.altEmail           = this.altEmail.bind(this);
        var urlParams           = new URLSearchParams(window.location.search);
        this.myInfocode         = urlParams.get('code')
        console.log(urlParams,this.myInfocode)
        if(this.myInfocode !== null && this.myInfocode !== "null"){
          this.setState({userPageLoad:true})
          let data ={"code":this.myInfocode}
          axios.post((process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/customer/myinfo",data).then(res =>{
            if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)}
            if(res.data.status===200){
              this.myInfocode = null;
              window.location.reload(true)
              // this.userModel.updateValuesUserModel(res.data.myInfoProfile);
              // Object.keys(UserModel).forEach((item)=>{
              //   if(document.getElementsByName(item).length>0 && item!=="phoneNumber" && item!=="callingCode"){
              //     document.getElementsByName(item)[0].disabled = (UserModel[item]==="" || UserModel[item]===undefined)?false:true
              //   }
              //   if(item==="phoneNumber" && UserModel[item]!=="" && UserModel[item]!==undefined){
              //     this.setState({buttonDisabled:false})
              //   }
              // })
              // this.setState({updateCustomer: true,myInfoFlag:true,sendOTPButton:false,verify:"new"
              // ,userPageLoad:false,loadPhone:true});
            }
            // console.log("Myinfo",UserModel)
            if(res.data.status === 500){
              this.myInfoError.current.toggle();
            }
          }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)} })
        }
    }
    componentDidMount(){
        this.getCustomer();
        this.uploadProfilePic = this.uploadProfilePic.bind(this);
        this.getProfilePicAWS();
    }

    getCustomer(){
      let pattren=/^\d+$/;
      axios.get( (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+'/profile')
      .then(res =>{
        if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(res)} 
        if(UserModel.politicallyExposed !== '' && UserModel.politicallyExposed !== undefined){
        }else{
          this.userModel.updateValuesUserModel(res.data.customer);
          if(UserModel.sourceOfIncome.length === 1){
            let val=UserModel.sourceOfIncome[0].split(":");
            if(val[0] === "Other"){
              let list=[];
              list.push(val[0])
              UserModel.sourceOfIncome=list;
              UserModel.sourceOfIncomeOther=val[1];
              this.setState({sourceOfIncomeOther:true})
            }
          }
          if(UserModel.purpose.length === 1){
            let val=UserModel.purpose[0].split(":");
            if(val[0] === "Other"){
              let list=[];
              list.push(val[0])
              UserModel.purpose=list;
              UserModel.purposeOther=val[1];
              this.setState({purposeOther:true})
            }
          }
          console.log("Customer",UserModel)
          this.setState({updateCustomer:true});  
        }
       
        // if(UserModel.phoneNumber === '' || UserModel.phoneNumber === undefined){
        //   this.setState({myInfoFlagMobdis:true,loadPhone:true});
        // }
        if(!UserModel.mobileNumberVerified){
          this.setState({verify:"new"})
        } else {
          this.setState({verify:"done"})
        }
        if(res.data.customer.myInfoNRIC !== '' && res.data.customer.myInfoNRIC !== undefined ){
            this.setState({myInfoFlag:true});
            Object.keys(UserModel).forEach((item)=>{
              if(document.getElementsByName(item).length>0 && item!=="phoneNumber" && item!=="callingCode"){
                document.getElementsByName(item)[0].disabled = (UserModel[item]==="" || UserModel[item]===undefined)?false:true
              }
              if(item==="phoneNumber" && UserModel[item]!=="" && UserModel[item]!==undefined){
                this.setState({buttonDisabled:false})
              }
            })
        }

        if( (UserModel.monthlyIncome !== '') && (UserModel.monthlyIncome !== undefined) && (UserModel.monthlyIncome.length !== 0) && (pattren.test(parseInt(UserModel.monthlyIncome)))){
          let monthly= UserModel.monthlyIncome.split("-");
          if(monthly[1]!=='' && monthly[1]!== undefined){
            UserModel.monthlyIncome =  UserModel.monthlyIncome
          }else{
            UserModel.monthlyIncome=parseInt(UserModel.monthlyIncome.toString().replace(/,/g,"")).toLocaleString()
              this.setState({updateCustomer: true});
          }
        }
        if( UserModel.callingCode === '' || UserModel.callingCode === undefined){
          UserModel.callingCode = "+65";
        }
        if( UserModel.country === '' || UserModel.country === undefined){
          UserModel.country = "Singapore";
        }
        
        this.getProfilePicAWS();
        if(UserModel.assessableIncome !== '' && UserModel.assessableIncome !== undefined && pattren.test(parseInt(UserModel.assessableIncome))){
          UserModel.assessableIncome=parseInt(UserModel.assessableIncome.toString().replace(/,/g,"")).toLocaleString()
          this.setState({updateCustomer: true});
        }
      }).catch(function (error) { if(process.env.REACT_APP_IS_IN_PROD === "false"){console.log(error)}  }) 
    }
    getProfilePicAWS(){
      this.setState({profilePicLoder:'loading'})
      const fileName= UserModel.profileImageFileName;
      if(fileName !== '' && fileName !== undefined){
        Storage.vault.get(fileName)
        .then((res) => {
          this.setState({
            profilePic:res,
            profilePicLoder:'uploaded',
            profileImage:true,
          })
        })
        .catch();
      }
      {this.setState({userPageLoad:false})}
    }

    uploadProfilePic = (e) =>{
      this.setState({profilePicLoder:'loading',profilePic:'uploading'})
      let originalFileName=e.target.files[0].name;
      let file = e.target.files[0];
      const fileName = uuid.v4()+"."+mime.extension(mime.contentType(e.target.files[0].name))
      Storage.vault.put(fileName, file)
      .then (result => {
        UserModel.profileImageFileName=result.key;
        this.setState({updateCustomer: true});
        this.getProfilePicAWS();
      })
      .catch();    
    }

    toggle() {
      this.setState(prevState => ({
      modal: !prevState.modal
    }));
    }

    validateProfilePic(){
      if(this.state.profilePic === "new" || UserModel.profileImageFileName === undefined ){
        return (<label  style={{ color:'#FB404B'}}>Please upload your profile picture</label>);
      }
    }

    onLoadFunction(event){
    }

    onFocus(event){
      if(event.target.disabled)return
      event.target.nextElementSibling.classList.remove('spanMiddle')
      event.target.nextElementSibling.classList.add('spanTop')
      if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML+" (Optional)")}
    }
  onBlur(event){
    event.target.classList.remove('activeFocus','invalid','valid')
    event.target.validity.valid?event.target.classList.add('valid'):event.target.classList.add('invalid')
    if(event.target.value==""){
      event.target.classList.remove('activeFocus','invalid','valid')
      event.target.nextElementSibling.classList.remove('spanTop')
      event.target.nextElementSibling.classList.add('spanMiddle')
      }
    if(event.target.getAttribute("data-optional")==="true"){event.target.nextElementSibling.innerHTML=(event.target.nextElementSibling.innerHTML.replace(" (Optional)",""))}
  }


  handleChange = (e) =>{
    if(e.target.type=="text"){
      e.target.classList.remove('valid','invalid')
      e.target.classList.add('activeFocus')
    }
    this.props.list[e.target.name] = e.target.value;
    this.setState({updateCustomer:true});
    /** to add +65  */
    // if(e.target.name==="homeTelephoneNumber" || e.target.name === "faxNumber" || e.target.name === "phoneNumber"){
    //   let val= e.target.value.split('')
    //   if(val[0]==='+'){UserModel[e.target.name]=e.target.value}
    //     else if(val[0] === undefined || val[0] === ''){UserModel[e.target.name]='';}
    //     else{UserModel[e.target.name]="+65"+e.target.value;}}
    // if(e.target.name === "phoneNumber" && e.target.value.length === 11){ this.setState({sendOTPButton:false})}
    console.log(e.target.name,"   ",e.target.value)
    if(e.target.name === "phoneNumber" && e.target.value.match("[0-9]+$")){
      this.setState({buttonDisabled:false})
    }
    if(e.target.name === "phoneNumber" && e.target.value===""){
      this.setState({buttonDisabled:true})
    }
    if((e.target.name === "phoneNumber" || e.target.name === "callingCode") && UserModel.callingCode !== undefined && UserModel.callingCode !== '' && UserModel.phoneNumber !== ''){ 
      this.setState({sendOTPButton:false})}
    else{this.setState({sendOTPButton:true})}
    if(UserModel.idType === "NRICPink"|| UserModel.idType === "NRICBlue"){
      UserModel.idExpiryDate='';
    }
    if(e.target.name === "idNumber"){
      this.validation();
    }
  }

  validation(){
    let idNum=UserModel.idNumber;
    if(UserModel.idType === "Passport" || UserModel.idType === "FIN" ){
      this.setState({validationFlag:false})
      }
    if(UserModel.idType === "NRICPink" || UserModel.idType === "NRICBlue"){
      if (idNum.length != 9) {
        this.setState({validationFlag:true})
      } else {
              var ar = idNum.split('');
              for(let i=1; i<=8; i++){
                if(isNaN(ar[i])){
                  this.setState({validationFlag:true})
                }
              }
              if(!(UserModel.idType === "NRICPink" && ar[0] === "S") || !(UserModel.idType === "NRICPink" && ar[0] === "T")){
                this.setState({validationFlag:true})
              }
              if( !(UserModel.idType === "NRICBlue" && ar[0] === "F") || !(UserModel.idType === "NRICBlue" && ar[0] === "G")){
                this.setState({validationFlag:true})
              }
              let weight=0;
              let w1=ar[1]; let w2=ar[2];let w3=ar[3];let w4=ar[4];let w5=ar[5];let w6=ar[6];let w7=ar[7];                weight +=  parseInt(w1) * 2;
              weight += parseInt(w2) * 7;
              weight += parseInt(w3) * 6;
              weight += parseInt(w4) * 5;
              weight += parseInt(w5) * 4;
              weight += parseInt(w6) * 3;
              weight += parseInt(w7) * 2;
              if (ar[0] == "T" || ar[0] == "G"){
                weight += 4
              }
              let temp = parseInt(weight) % 11;
              var st = ["J","Z","I","H","G","F","E","D","C","B","A"];
              var fg = ["X","W","U","T","R","Q","P","N","M","L","K"];
              var theAlpha="";
              if (ar[0] == "S" || ar[0] == "T") {
                theAlpha = st[temp];
            }
            else if (ar[0] == "F" || ar[0] == "G") {theAlpha = fg[temp];}
            let w9=ar[8];
            if(w9 === theAlpha){ this.setState({validationFlag:false})}
            else{this.setState({validationFlag:true})}
        }  
      }
  }

  errorFun(){
    if(this.state.validationFlag){
    return (<label  style={{ color:'#FB404B'}}>Invalid Number</label>);
    } 
  }

  handleChangeMobOtp =(e) =>{
    if(e.target.type=="text"){
      e.target.classList.remove('valid','invalid')
      e.target.classList.add('activeFocus')
    }

    UserModel.mobOTP = e.target.value;
    if(e.target.value.length === 4){
        this.otp=e.target.value;
      this.verifyOTP();
    }
    this.setState({mobOTP: e.target.value})
  }

  profilePic(){
    if(this.state.profilePic === "new" || UserModel.profileImageFileName === undefined){
      return <>
      <input type="file" className="form-control" style={{cursor:'pointer',marginTop:"0px"}} ref={this.profilepicFile} name="ProfilePicture" onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg"/>
      <span onClick={()=> this.profilepicFile.current.click()}><label htmlFor="file-upload" className="custom-pic-file-upload" style={{paddingBottom:"10px",fontSize:"20px",fontWeight:"bold !important"}}><span>Profile Photo</span>
            <p style={{color:'#bcbcbc',fontSize:'12px',paddingLeft:'70px',paddingTop:'2px'}}>Used for document verification</p>
            </label></span>
      </>
    }else if(this.state.profilePicLoder === "loading"){
      return<> <Loader type="Oval" color="#ddd" height="60"  width="60"/></>
    }else{
      return <>
      <input type="file" className="form-control"  name="ProfilePicture" ref={this.profilepicFile} onChange={(event) => this.uploadProfilePic(event)} accept=".png,.jpeg,.jpg" />
      <img className="imgProfile block-example imageProfilePic  float-left"  onClick={() => this.profilepicFile.current.click()} style={{width:'100px',height:'100px',cursor:'pointer'}} src={this.state.profilePic} alt="Upload Profile pic"></img>
      <span onClick={()=> this.profilepicFile.current.click()}> <label htmlFor="file-upload" className="custom-pic-file-uploa-update" ><u>Profile Photo</u>
      </label></span>        
      </>
    }
  }

  OTPbutton(){
   if(this.state.verify === "new") {
      return(
        <Button type="secondary" id="back" className="pageButton secondary" onClick={this.sendOTP} style={{float:"right",width:'110px'}}
        disabled={this.state.buttonDisabled} >Get OTP</Button>
         );
    }else if(this.state.verify === "error"){
      return ( 
        <div style={{ paddingRight: '100px', marginRight: '20px;', paddingTop:'16px'}}>
           <Button className="float-right btn-circle" ><i class="fa fa-times" style={{color:'white'}} aria-hidden="true"></i></Button>
        </div>
      );
    }else if(this.state.otpLoader){
      return (
        <div style={{ paddingRight: '100px', marginRight: '20px;', paddingTop:'16px'}}>
        <Button className="float-right btn-circledoc" >
            <Loader type="Oval" color="#ddd" height="13"  width="13"/>
          </Button>
          </div>);
    } else {
      return "";
    }
  }

  OTPText(){
    if(UserModel.mobileNumberVerified){
        return ""
    }
     else if(this.state.verify === "error" || this.state.verify ==="new" || this.state.verify === "disable" ){
      return <>
        <div className="inputGroup">
           <input type="text" className="inputText" id="otp"
                name="mobOTP" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern="[0-9]{4}" value={UserModel.mobOTP} autoComplete="off" required
                title="OTP can contain only Digits" maxLength="4" fieldCheck={UserModel.mobOTP === ""?"unfilled":"filled"}
                onChange={this.handleChangeMobOtp}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>OTP</span>
        </div>
        <a href="javascript:void(0)"  onClick={this.sendOTP}><span style={{color:'#052d99',fontSize:'13px'}}>Resend OTP</span></a>
       </>
    }else if(this.state.verify === "verified"){
      return ( 
        <div style={{ paddingRight: '160px', marginRight: '20px;', paddingTop:'6px'}}>
           <Button className="float-right btn-circledoc" ><i class="fa fa-check" style={{color:'white'}} aria-hidden="true"></i></Button>
        </div>
        );
    }else { 
      // return <>
      //    <div className="inputGroup">
      //       <input type="text" className="inputText" id="otp"
      //           name="mobOTP" onFocus={this.onFocus} onBlur={this.onBlur}
      //           pattern="[0-9]{4}" value={UserModel.mobOTP} autoComplete="off" required
      //           title="OTP can contain only Digits" maxLength="4" fieldCheck={UserModel.mobOTP === "" || UserModel.mobOTP === undefined ?"unfilled":"filled"}
      //           onChange={this.handleChangeMobOtp}
      //            />
      //          <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>OTP</span>
      //    </div>
      // </>
      return "";
    }
  }

  altEmail(){
    if(this.state.myInfoFlag){
      return<> 
       <label style={{color:'#bcbcbc'}}>MyInfo Email ID</label>
       <div className="inputGroup">
          <input type="email" className="inputText" id="altEmail"
                name="altEmail" onFocus={this.onFocus} onBlur={this.onBlur}
                 value={UserModel.altEmail} autoComplete="off" fieldCheck={UserModel.altEmail === ""?"unfilled":"filled"}
                onChange={this.handleChange}
                 />
       </div>
      </>
    }
  }

  handleMyInfo(){
    //,householdincome,noa-basic
    let clientId = process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_MYINFO_CLIENT_ID:process.env.REACT_APP_DEV_MYINFO_CLIENT_ID;
    let attributes ="uinfin,name,aliasname,sex,nationality,passportnumber,passportexpirydate,residentialstatus,regadd,homeno,dob,email,mobileno,occupation,employment";
    let purpose = "Borrower profile for loan"
    let state   = uuid();
    let redirectUrl = process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_MYINFO_REDIRECT_URL:process.env.REACT_APP_DEV_MYINFO_REDIRECT_URL;
    let authApiUrl  = process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_MYINFO_AUTHAPI_URL:process.env.REACT_APP_DEV_MYINFO_AUTHAPI_URL;
    var authoriseUrl = authApiUrl + "?client_id=" + clientId +
        "&attributes=" + attributes +
        "&purpose=" + purpose +
        "&state=" + state +
        "&redirect_uri=" + redirectUrl;
      window.location = authoriseUrl;
  }

  sendOTP =() =>{
    this.setState({otpLoader:true,verify:"disable"});
    let mobileNum= UserModel.callingCode+UserModel.phoneNumber;
    let phoneNumber = {
      "phoneNumber":mobileNum
    }
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/misc/otp";
    axios.post(url,phoneNumber).then(res =>{
      this.setState({otpLoader:false})
    }).catch(function (error) {})
  }

  verifyOTP = () => {
    let data = {
      "phoneNumber":UserModel.phoneNumber,
      "mobOTP":this.otp,
      "callingCode":UserModel.callingCode
    }
    this.setState({otpLoader:true})
    const url = (process.env.REACT_APP_IS_IN_PROD=="true"?process.env.REACT_APP_PROD_API_BASE_URL:process.env.REACT_APP_DEV_API_BASE_URL)+"/misc/verify";
    axios.post(url,data).then(res =>{
      this.status=res.status;
      if(res.data.status === 200) {
        this.setState({verify:"verified", mobOTP: "",otpLoader:false});
      }else{
        UserModel.mobOTP='';
        this.setState({verify:"error", mobOTP: ""})
      }
    }).catch(function (error) {})
  }

  handleSubmit =(e) =>{
    e.preventDefault()
    if(this.state.validationFlag){
      alert("Plese enter valid NRIC");
      this.setState({loadButton:false})
      return ;
    }
    // if(!this.state.profileImage) {
    //   this.setState({loadButton:false})
    //   this.profilePicModal.current.toggle();
    //   return ;
    // }

    if(UserModel.name === '' && UserModel.chineseName === '') {
      this.setState({loadButton:false})
      this.nameFill.current.toggle();
      return ;
    }

    this.setState({redirect:true})
  }


  displayMyInfo(){
    if(this.state.myInfoFlag && UserModel.myInfoNRIC !== undefined && UserModel.myInfoNRIC !== null){
      return <>
          <Col md="2" className="inputField">
            <div className="inputGroup">
              <input type="text" className="inputText" id="name"
              name="myInfoNRIC" data-optional="true"
              value={UserModel.myInfoNRIC} autoComplete="off" 
              disabled
                />
              <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>NRIC Number</span>
          </div>
          </Col>
      </>
    }else {
      return <>
        <Col md="2" className="inputField nricSelect" style={{marginLeft:'7px'}}>
      <select  name="idType" onChange={this.handleChange} required>
      <option selected disabled hidden value=''>Select</option>
                <option  value="NRICPink" selected={(UserModel.idType) === "NRICPink"}>NRIC (Pink)</option>
                <option value="NRICBlue" selected={(UserModel.idType) === "NRICBlue"}>NRIC (Blue)</option>
                <option value="Passport" selected={(UserModel.idType) === "Passport"}>Passport Number</option>
                <option value="FIN" selected={(UserModel.idType) === "FIN"}>FIN</option>
              </select>
              <label>ID Type</label>
      </Col>
      </>
    }
  }

    render(){
      if (this.state.redirect) {
        return <Redirect to='/user/page2' exact/>
      }
        return(
          <>
          {/* <BeneficiaryModal ref={this.modalRef} action={this.hello} disabled={this.state.disable} modalTitle="Beneficiary Details" beneficiary={this.state.currentBeneficiary}/> */}
          <Notification ref={this.profilePicModal}  modalTitle="Please upload your profile picture."/>
          <Notification ref={this.nameFill}  modalTitle="Please enter Full name or Chinese name"/>
          <Notification ref={this.myInfoError}  modalTitle="Something went wrong. Please try again." />
          <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className}>
            <ModalHeader style={{borderBottom:'transparent'}} toggle={this.toggle}>
            <p style={{fontSize:'20px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">I allow MWA Forex to access my profile details.</p>
            </ModalHeader>
             <ModalBody>
               <center>
                <Button color="primary" onClick={this.handleMyInfo} style={{marginRight:'15px',backgroundColor:'transparent',color:'#052d99',borderColor:'transparent'}}>Yes</Button>
                <Button color="primary" onClick={this.toggle} style={{marginRight:'15px',backgroundColor:'transparent',color:'#8c0204',borderColor:'transparent'}} >No</Button>
                </center>
             </ModalBody>
          </Modal>
          <BlockUi tag="div" blocking={this.state.userPageLoad} keepInView={true} style={{height: "100vh", position: "relative", maxHeight: "calc(100vh - 70px)", display: "flex", flexFlow: "column"}}>
            <Container fluid className="color mainContent">
           <Row className="breadcrumbContainer">
               <Col md="12" className="breadcrumbContainerColumn">
               <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
                 <BreadcrumbItem className="breadcrumbItem initial" tag="a" href="#">Log In/Register</BreadcrumbItem>
                 <BreadcrumbItem className="breadcrumbItem" active tag="span">Register</BreadcrumbItem>
               </Breadcrumb>
               </Col>
            </Row>
            <Row className="pageHeading">
              <Col md="6"  className="pageHeadingColumn">
                <h2 className="userHead">Tell us about yourself</h2>
                <button type="submit" className="btn-roundMyInfo" style={{'backgroundColor':'transparent','width': '160px' ,'height':'30px',fontSize: 'initial',paddingLeft:'10px'}} onClick={this.toggle}> 
                  <label style={{ color:'#6d0000','fontSize':'12px',paddingRight:'4px'}} className="font-weight-bold text-left">Apply using</label><img    src={MyInfoLogo}  style={{ 'width': '45px' ,'height':'28px'}} className="img-responsive"></img>
               </button>
              </Col> 
            </Row>
            <Row style={{margin: "0% 2%"}}>
              <Col md="3" style={{padding:0}}> 
              <Stepper steps={ [{}, {}, {}] } activeColor="#585859" defaultColor="#d8d8d8" completeColor="#8b0304"  />
              </Col>
            </Row>
            <form onSubmit={this.handleSubmit}>
            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h4 style={{marginBottom:"-20px"}}>Personal Details</h4>
              </Col>
            </Row>
            
            <Row className="inputRow">
              <Col md="6" className="inputField" >
              {this.profilePic()}
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="name" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[a-zA-Z.,;-\\s ]+" 
                value={UserModel.name} autoComplete="off"
                // title="Name should be alphanumeric" 
                fieldCheck={UserModel.name==""?"unfilled":"filled"}
                onChange={this.handleChange} required
                // disabled={this.state.myInfoFlag && UserModel.name !== ''}
                />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Full Name</span>
            </div>
            </Col>
            <Col md="3" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="chineseName" onFocus={this.onFocus} onBlur={this.onBlur} 
                data-optional="true"
                // pattern="^[\u3000\u3400-\u4DBF\u4E00-\u9FFF.,;\-\\s ]+" 
                pattern="\p{Han}"
                value={UserModel.chineseName} autoComplete="off"
                title="Name should be alphanumeric" fieldCheck={UserModel.chineseName ==="" ?"unfilled":"filled"}
                onChange={this.handleChange}  />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Chinese Name</span>
            </div>
            </Col>
            <Col md="3" className="inputField">
             <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="alias" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern="^[a-zA-Z.,;\\s ]+" value={UserModel.alias} autoComplete="off"
                title="Name should be alphanumeric" data-optional="true" fieldCheck={UserModel.alias===""?"unfilled":"filled"}
                onChange={this.handleChange}
                // disabled={this.state.myInfoFlag && UserModel.alias !== ''}  
                />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Alias Name</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
               <div className="inputGroup">
               <input type="text" className="inputText" id="emailId"
                name="email" onFocus={this.onFocus} onBlur={this.onBlur}
                 value={UserModel.email} autoComplete="off"  disabled />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Email ID</span>
               </div>
              <label style={{color:'#bcbcbc'}}>Used for all communications</label>
              {this.altEmail()}
            </Col>

            <Col md="3" className="inputField">
               <div className="inputGroup">
                  <DatePicker
                    required
                    dateFormat="yyyy-MM-dd"
                    selected={UserModel.dateOfBirth === ''? "":new Date(moment(UserModel.dateOfBirth))}
                    onChange={(date) => {
                    this.props.list["dateOfBirth"] = moment(date).format("YYYY-MM-DD");
                    UserModel.dateOfBirth = moment(date).format("YYYY-MM-DD");
                    this.setState({updateCustomer:true});
                    }}
                    disabled={this.state.myInfoFlag && UserModel.dateOfBirth !== ''}
                    minDate={this.calMinDate} 
                    maxDate={this.calMaxDate}
                    placeholderText="Date of Birth"
                  />
                  <label style={{color:'#bcbcbc'}}>Date of Birth</label>
               </div>
            {/* <div style={{display:"flex"}}>
            <img src={date} style={{width:'20px',height:'20px',marginTop:'6px'}}/>
            <Input type="date" className="inputText" style={{height:'25px',width: '180px'}} name="dateOfBirth" id="dob"
            value={UserModel.dateOfBirth}  
            disabled={this.state.myInfoFlag && UserModel.dateOfBirth !== ''} 
            onChange={this.handleChange} required 
            placeholder="YYYY-MM-DD"
            max={moment(this.calMaxDate).format("YYYY-MM-DD")} min={moment(this.calMinDate).format("YYYY-MM-DD")} />
            </div>
            <label style={{color:'#4a4a4a',marginLeft:'35px'}} forHtml="dateOfBirth">Date of Birth</label> */}
            </Col>

            <Col md="1" className="inputField genderSelect">
                <select name="gender" onChange={this.handleChange} required
                //  disabled={this.state.myInfoFlag && UserModel.gender!== ''}
                 >
                <option selected disabled hidden value=''>Select</option>
                  <option selected={UserModel.gender === "male"} value="male" >Male</option>
                  <option selected={UserModel.gender === "female"} value="female">Female</option>
                </select>
                <label>Gender</label>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="1" className="inputField">
            <select name="callingCode"  onChange={this.handleChange} style={{marginRight:'20px'}} required 
            disabled= {(UserModel.mobileNumberVerified || this.state.verify === "verified")? true : false}>
            {/* <option selected value='+65'>+65</option> */}
                {phoneCodes.map((prop, key) => {
                      return <option value={prop} key={key} selected={prop === UserModel.callingCode}>{prop}</option> 
                  })}
                </select>
                <label>Dailing Code</label>
            </Col>
            <Col md="5" className="inputField">
            {/* pattern= "^[+][6][5][689][0-9]{7}$" maxLength="11" title="Mobile number can contain only 11 digits"*/}
            <div className="inputGroup">
               <input type="text" className="inputText" id="mobile"
                name="phoneNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                 value={UserModel.phoneNumber} autoComplete="off"
                 pattern= "[0-9]+$" 
                 title="Mobile number can contain only digits"
                 fieldCheck={UserModel.phoneNumber===""?"unfilled":"filled"}
                onChange={this.handleChange} required
                disabled= {(UserModel.mobileNumberVerified || this.state.verify === "verified")? true : false}
                  />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Mobile Number</span>
            </div>
              <label style={{color:'#bcbcbc'}}>We will verify this number via OTP</label>
            </Col>
            <Col md="3" className="inputField" style={{paddingLeft: '0px'}}>
            {this.OTPText()}
            </Col>
            <Col md="3" className="inputField" style={{bottom:'15px',paddingRight: '80px'}}>
            {this.OTPbutton()}
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            {/* pattern="[+][6][5][0-9]+"  title="Home number can contain only digits"*/}
             <div className="inputGroup">
               <input type="text" className="inputText" id="homephone" data-optional="true"
                name="homeTelephoneNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern= "[0-9+\s]+$" 
                 title="Home number can contain only digits"
                value={UserModel.homeTelephoneNumber} autoComplete="off"
                 fieldCheck={UserModel.homeTelephoneNumber===""?"unfilled":"filled"}
                onChange={this.handleChange} 
                // disabled={this.state.myInfoFlag && UserModel.homeTelephoneNumber !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Home Number </span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText" id="fax" data-optional="true"
                name="faxNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern= "[0-9+\s]+$" 
                 title="Fax number can contain only digits"
                value={UserModel.faxNumber} autoComplete="off"
                 fieldCheck={UserModel.faxNumber==="" || UserModel.faxNumber === undefined ?"unfilled":"filled"}
                onChange={this.handleChange} 
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Fax Number </span>
            </div>
            </Col>
            </Row>

            </Container>
            <Container className="section">
            <Row className="inputRow">
            <Col md="3"  className="inputField nounhome">
                <select name="residentialStatus" style={{width: `${(3*UserModel.residentialStatus.length) + 100}px`}} required onChange={this.handleChange} required >
                <option selected disabled hidden value=''>Select</option>
                  <option value="Inside Singapore" selected={(UserModel.residentialStatus) === "Inside Singapore"} >Inside Singapore</option>
                  <option value="Outside Singapore" selected={(UserModel.residentialStatus) === "Outside Singapore"}>Outside Singapore</option>
                </select>
                <label>Residential Status</label>
            </Col>
            <Col md="3" className="inputField nounflag" >
                <select name="nationality" required   onChange={this.handleChange} 
                // disabled={this.state.myInfoFlag && UserModel.nationality!== ''} 
                required>
                <option selected disabled hidden value=''>Select</option>
                {nationality.map((prop, key) => {
                      return <option  value={prop} key={key} selected={prop === UserModel.nationality}>{prop}</option> 
                  })}
                </select>
                <label>Nationality</label>
            </Col>
            </Row>
            <Row className="inputRow">
            {this.displayMyInfo()}
            <Col md="3" style={{marginLeft:'18px'}} className="inputField ">
               <div className="inputGroup">
               <input type="text" className="inputText" id="name"
                name="idNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                value={UserModel.idNumber} autoComplete="off"
                fieldCheck={UserModel.idNumber===""?"unfilled":"filled"}
                onChange={this.handleChange} 
                disabled={this.state.myInfoFlag} required={!this.state.myInfoFlag}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>{this.state.myInfoFlag?"Passport ":""}Number</span>
            </div>
              <label style={{color:'#bcbcbc'}}>Only for citizens or PR of singapore</label>
              {this.errorFun()}
            </Col>
            <Col md="3" className="inputField">
               <div className="inputGroup">
                  <DatePicker
                     dateFormat="yyyy-MM-dd"
                     selected={UserModel.idExpiryDate === ''? "":new Date(moment(UserModel.idExpiryDate))}
                     onChange={(date) => {
                      this.props.list["idExpiryDate"] = moment(date).format("YYYY-MM-DD");
                      UserModel.idExpiryDate = moment(date).format("YYYY-MM-DD");
                      this.setState({updateCustomer:true});
                     }}
                     disabled={(UserModel.idType!=="Passport" && UserModel.idType!=="FIN")  || (this.state.myInfoFlag && UserModel.idExpiryDate !== ' ')}
                     minDate={this.today}
                     placeholderText="Expiry Date"
                  />
                  <label style={{color:'#bcbcbc'}}>Expiry Date</label>
               </div>
            {/* <div style={{display:"flex"}}>
            <img src={date} style={{width:'20px',height:'20px',marginTop:'6px'}}></img>
            <Input type="date" className="inputText" style={{height:'25px',width: '180px'}} name="idExpiryDate" id="exp"
            value={UserModel.idExpiryDate}   disabled={UserModel.idType!=="Passport"  || this.state.myInfoFlag && UserModel.idExpiryDate !== ' '} onChange={this.handleChange}  min={moment(new Date()).format("YYYY-MM-DD")}  />
            </div>
            <label style={{color:'#4a4a4a',marginLeft:'33px'}} forHtml="idExpiryDate">Passport Expiry Date</label> */}
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="4" className="inputField">
            <FormGroup tag="fieldset" >
                <legend style={{fontSize:'15px',color:'#4a4a4a'}}>Are you a politically exposed person?</legend>
                <FormGroup check inline>
                <label className="containerRadio">Active
                  <input type="radio" name="radio" value="Active" onChange={this.handleChange} required checked={(UserModel.politicallyExposed === "Active")? true :false}  name="politicallyExposed"/>
                  <span className="checkmarkRadio"></span>
                </label>
                </FormGroup>
                <FormGroup check inline>
                <label className="containerRadio">Inactive
                  <input type="radio" name="radio" value="Inactive" onChange={this.handleChange} required checked={(UserModel.politicallyExposed === "Inactive")? true :false}  name="politicallyExposed"/>
                  <span className="checkmarkRadio"></span>
                </label>
                </FormGroup>
                <FormGroup check inline>
                <label className="containerRadio">No
                  <input type="radio" name="radio" onChange={this.handleChange} value="No" required checked={(UserModel.politicallyExposed === "No")? true :false}  name="politicallyExposed"/>
                  <span className="checkmarkRadio"></span>
                </label>
                </FormGroup>
            </FormGroup>
            </Col>
            <Col md="3"  className="inputField nounTax">
                <select name="taxresident" style={{width: `${(3*UserModel.residentialStatus.length) + 100}px`}} required onChange={this.handleChange} >
                <option selected disabled hidden value=''>Select</option>
                  <option value="Yes" selected={(UserModel.taxresident) === "Yes"} >Yes</option>
                  <option value="No" selected={(UserModel.taxresident) === "No"}>No</option>
                </select>
                <label>Are you a US tax resident?</label>
            </Col>
            </Row>
            </Container>

            <Container className="section">
            <Row>
              <Col md="12" className="sectionHeading">
                <h4>Residential Address</h4>
              </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
            <div className="inputGroup">
                <div className="inputGroup">
               <input type="text" className="inputText"
                name="block" onFocus={this.onFocus} onBlur={this.onBlur} id="block"
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.block} autoComplete="off"
                // title="Block can contain alphanumeric value" 
                fieldCheck={UserModel.block === ""?"unfilled":"filled"}
                onChange={this.handleChange} required
                // disabled={this.state.myInfoFlag && UserModel.block !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Block / Street Number</span>
            </div>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText"
                name="street" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.street} autoComplete="off"
                // title="Street can contain alphanumeric value" 
                fieldCheck={UserModel.street==="" || UserModel.street === undefined ?"unfilled":"filled"}
                onChange={this.handleChange} id="street" required
                // disabled={this.state.myInfoFlag && UserModel.street !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Street Name Line 1</span>
            </div>
            </Col>
            </Row>
            <Row className="inputRow">
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="street2" id="street" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.street2} autoComplete="off"
                // title="Street can contain alphanumeric value" 
                fieldCheck={UserModel.street2 === "" || UserModel.street2 === undefined?"unfilled":"filled"}
                onChange={this.handleChange} 
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Street Name Line 2 </span>
            </div>
            </Col>
            <Col md="6" className="inputField">
              <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="building" onFocus={this.onFocus} id="building" onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.building} autoComplete="off"
                // title="Building can contain alphanumeric" 
                fieldCheck={UserModel.building===""?"unfilled":"filled"}
                onChange={this.handleChange} 
                // disabled={this.state.myInfoFlag && UserModel.building !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Building Name</span>
            </div>
            </Col>
            {/* */}
            {/* */}
            
            </Row>
            <Row className="inputRow">
            <Col md="3" className="inputField">
                 <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="floor" onFocus={this.onFocus} onBlur={this.onBlur}
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.floor} autoComplete="off"
                // title="Floor can contain alphanumeric value" 
                fieldCheck={UserModel.floor === ""?"unfilled":"filled"}
                onChange={this.handleChange}  id="floor"
                // disabled={this.state.myInfoFlag && UserModel.floor !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Floor </span>
            </div>
            </Col> 
            <Col md="3" className="inputField">
             {/* <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="apartmentNumber" onFocus={this.onFocus} onBlur={this.onBlur} id="apartment"
                pattern="^[_ a-zA-Z0-9-#.,;]+$" value={UserModel.apartmentNumber} autoComplete="off"
                title="Apartment Number can contain alphanumeric value" fieldCheck={UserModel.apartmentNumber === "" || UserModel.apartmentNumber === undefined?"unfilled":"filled"}
                onChange={this.handleChange} 
                disabled={this.state.myInfoFlag && UserModel.apartmentNumber !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Unit No.</span>
            </div> */}
            <div className="inputGroup">
               <input type="text" className="inputText" data-optional="true"
                name="apartmentNumber" onFocus={this.onFocus} onBlur={this.onBlur} id="apartment"
                // pattern="^[_ a-zA-Z0-9-#.,;]+$" 
                value={UserModel.apartmentNumber} autoComplete="off"
                // title="Apartment Number can contain alphanumeric value" 
                fieldCheck={UserModel.apartmentNumber === "" || UserModel.apartmentNumber === undefined?"unfilled":"filled"}
                onChange={this.handleChange} 
                // disabled={this.state.myInfoFlag && UserModel.apartmentNumber !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Unit No.</span>
            </div>
            </Col> 
            <Col md="4" className="inputField">
             <div className="inputGroup">
               <input type="text" className="inputText"
                name="postal" onFocus={this.onFocus} onBlur={this.onBlur} id="postal"
                // pattern="^(?!000000)[0-9]{6}$" 
                //  maxLength="6" 
                pattern= "[0-9+\s]+$"
                 value={UserModel.postal} autoComplete="off"
                // title="Postal code can contain 6 digits and it can't be zeros" 
                fieldCheck={UserModel.postal === ""?"unfilled":"filled"}
                onChange={this.handleChange} 
                required
                // disabled={this.state.myInfoFlag && UserModel.postal !== ''}
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Postal Code</span>
            </div>
            </Col>
            <Col md="2" className="inputField countrySelect" style={{left:'10px'}}>
                <select name="country" required onChange={this.handleChange} 
                // disabled={this.state.myInfoFlag && UserModel.country !== undefined}
                >
                {/* <option selected disabled hidden value=''>Select</option> */}
                {country.map((prop, key) => {
                      return <option  value={prop}  key={key} selected={prop === UserModel.country}>{prop}</option> 
                  })}
                </select>
                <label>Country</label>
            </Col>
            {/* <Col md="3" className="inputField">
            <div className="inputGroup">
               <input type="text" className="inputText" id="street"
                name="streetNumber" onFocus={this.onFocus} onBlur={this.onBlur}
                pattern="^[_ a-zA-Z0-9-#.,;]+$" value={UserModel.streetNumber} autoComplete="off"
                title="Street number can contain alphanumeric value" fieldCheck={UserModel.streetNumber === "" || UserModel.streetNumber === undefined ?"unfilled":"filled"}
                onChange={this.handleChange} 
                required
                 />
               <span onClick={(e)=>{e.target.previousElementSibling.focus()}}>Street Number</span>
            </div>
            </Col> */}
            {/* */}
            {/*  */}
            </Row>

            <Container className="pageFooter" style={{backgroundColor:"#fbfbfb"}}>
                      <Row className="inputRow" style={{paddingRight:'30px'}}>
                        <Col md="2"></Col>
                        <Col md="3">
                        </Col><Col md="3">
                        <Button type="secondary" type="submit" className="pageButton primary" style={{float:"right",backgroundColor:'#8c0204'}}>Continue</Button>
                        </Col>
                      </Row>
              </Container>
            </Container>
            </form>
            </Container>
            </BlockUi>
        </>
        )
    }
}
export default userProfile;