import axios from 'axios';
import { Auth } from 'aws-amplify';


function attachInterceptor () {
    axios.interceptors.request.use(function (config) {
        return new Promise((resolve, reject) => {
            if((localStorage.getItem("timestamp") && (new Date().getTime() - localStorage.getItem("timestamp"))>(30*60*1000))){
                Auth.signOut().then(()=> {
                    localStorage.removeItem("timestamp")
                    window.location="/login"
                }).catch();
                Promise.reject("");
                return
            }
            return Auth.currentSession().then(data => {
                localStorage.setItem("timestamp",new Date().getTime())
                config.headers.Authorization = data.getIdToken().getJwtToken()
                config.headers.common['Content-Type'] = 'application/json; charset=utf-8';
                resolve(config)
            }).catch(error => {
                reject(error);
            });
          });
        
    }, function (error) {
        return Promise.reject(error);
    });
}


export default attachInterceptor