import React from "react";
import Navbar from "components/navbar/navbar.jsx"
import Sidebar from "components/sidebar/sidebar.jsx"
import onBoardRoutes from "../routes/onBoard.jsx"
import User from "views/User/user.jsx"
import { Auth } from "aws-amplify";


class OnBoardLayout extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        backgroundColor: "Vdarkred",
        activeColor: "info",
      }
      this.sidebarRef         = React.createRef();
      this.collapseController = this.collapseController.bind(this);
      this.logout             = this.logout.bind(this)
    
      Auth.currentAuthenticatedUser({bypassCache: true})
        .then((data) => {
          if(data === undefined)
            this.setState({loggedOut: true})
        })
        .catch(() =>  {
          this.setState({loggedOut: true})
        })
    }

    handleActiveClick = (color) => {
      this.setState({ activeColor: color });
    }

    handleBgClick = (color) => {
      this.setState({ backgroundColor: color });
    }

    logout() {
      Auth.signOut()
      .then(() => {
        localStorage.removeItem("timestamp")
        window.location="/"
      })
      .catch();
    }

    collapseController(){
        this.sidebarRef.current.toggle();
    }

    render() {
      return (
        <div style={{overflowX:"hidden",backgroundColor:"#fbfbfb"}} id="main-wrapper">
            <Navbar toggle={this.collapseController} {...this.props} logout={this.logout}/>
            <div id="wrapper">
            <Sidebar 
                   ref={this.sidebarRef}
                   {...this.props}
                   routes={onBoardRoutes}
                   bgColor={true}
                />
                <div id="pageContentWrapper" ref="mainPanel" style={{height:"100%",position:"relative"}}>
                <User user={this.props.user}/>
                </div>
            </div>
          </div>
      );
    }
  }

  export default OnBoardLayout;
  