import React  from "react";
import { Button, Modal, ModalHeader, ModalBody  } from "reactstrap";


class Notification extends React.Component {
  constructor(props) {
    super(props);
     this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" size="md" centered={true} style={{ marginLeft: "520px", marginRight: "520px"}} className={this.props.className}>
            <ModalHeader  style={{borderBottom:'transparent'}} toggle={this.toggle}>
              <p style={{fontSize:'18px',color:'#4a4a4a',letterSpacing:'-0.11px',paddingLeft:'12px',marginBottom:'0px',paddingTop:'6px'}} className="text-center">{this.props.modalTitle}</p> 
            </ModalHeader>
            <ModalBody>
              <center>
                 {(this.props.redirectTo === undefined || this.props.redirectTo === "")?<Button color="primary" onClick={this.toggle} style={{marginRight:'15px',backgroundColor:'transparent',fontSize:'1rem',color:'#052d99',borderColor:'transparent'}}>OK</Button>:<Button color="primary" onClick={()=>{window.location = this.props.redirectTo}} style={{marginRight:'15px',backgroundColor:'transparent',fontSize:'1rem',color:'#052d99',borderColor:'transparent'}}>OK</Button>}
              </center>
            </ModalBody>
            </Modal>
      </>
    );
  }
}

export default Notification;